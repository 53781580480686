<template>
  <table
    border="0"
    :style="tbStyle"
    :width="props.width ? props.width : '100%'"
    height="100%"
    cellpadding="0"
    cellspacing="0"
  >
    <tbody>
      <tr>
        <td :style="style"></td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "DdDivider",
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    style() {
      return {
        borderBottom: this.props.border ? this.props.border : "",
        background: "none",
        height: "1px",
        width: "100%",
        margin: "0px 0px 0px 0px",
      };
    },
    tbStyle() {
      if (this.props.responsive) {
        return {};
      } else {
        return {
          display: "inline-table",
          width: this.props.width
            ? this.props.width + " !important"
            : "100% !important",
        };
      }
    },
  },
};
</script>
