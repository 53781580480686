<template>
  <div>
    <dd-label>Image size</dd-label>
    <b-row>
      <b-col>
        <b-form-group class="dd-radio-froup">
          <b-form-radio v-model="selected" value="auto" @change="emitSize"
            >Automaticaly adjust</b-form-radio
          >
          <b-form-radio v-model="selected" value="exact" @change="emitSize"
            >Exact width and height</b-form-radio
          >
          <b-form-radio v-model="selected" value="custom" @change="emitSize"
            >Custom</b-form-radio
          >
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="selected === 'custom'" class="dd-mt-20">
      <b-col>
        <dd-label-light>Width</dd-label-light>
        <count-range
          label="width"
          :value="width"
          :inc-by="1"
          :min="1"
          :max="maxWidth"
          @input="emitValue('width', $event)"
        />
      </b-col>
      <b-col>
        <b-link
          @click="changeProportion"
          class="dd-size-proportion"
          :class="{ active: proportion }"
          v-b-tooltip.hover
          title="Link and unlink"
        >
          <img src="../../assets/img/icon/link-ico.svg" alt="" />
        </b-link>
        <dd-label-light>Height</dd-label-light>
        <count-range
          label="height"
          :value="height"
          :inc-by="1"
          :min="1"
          :max="maxHeight"
          @input="emitValue('height', $event)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  getPadding,
  imageMaxHeight,
  imageHeight,
  imageWidth,
} from "../../plugins/JsonHelper.js";
export default {
  name: "ImageSize",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.setValue(this.value);
  },
  data() {
    return {
      selected: "",
      proportion: false,
    };
  },
  computed: {
    ...mapGetters("ddb", {
      columnWidth: "getSelectedColumnWidth",
    }),
    isFullWidth() {
      return (
        this.$store.state.ddb.htmlJson.properties.containerType === "fullwidth"
      );
    },
    maxWidth() {
      if (this.isFullWidth) {
        return 1500;
      }
      const width = this.columnWidth - getPadding(this.value.padding);
      return width < 1 ? 1 : width;
    },
    maxHeight() {
      if (this.isFullWidth) {
        return 1500;
      }
      const height = imageMaxHeight(this.value, this.maxWidth);
      return height < 1 ? 1 : height;
    },
    width() {
      return imageWidth(this.value, this.maxWidth);
    },
    height() {
      return imageHeight(this.value, this.maxHeight);
    },
  },
  methods: {
    setValue(val) {
      this.selected =
        val.imageSize || (this.maxWidth === this.width ? "auto" : "exact");
      if (val.proportion === false) {
        this.proportion = false;
      } else {
        this.proportion = true;
      }
    },
    emitSize(val) {
      if (val === "custom" && !this.proportion) {
        this.changeProportion();
      }
      this.emitValue("imageSize", val);
    },
    changeProportion() {
      this.proportion = !this.proportion;
      this.emitValue("proportion", this.proportion);
    },
    emitValue(key, val) {
      this.$emit("change", { key, val });
    },
  },
  watch: {
    value(val) {
      this.setValue(val);
    },
  },
};
</script>
