<template>
  <b-row>
    <b-col>
      <p class="dd-label">Email width</p>
      <b-row>
        <b-col cols="6">
          <count-range
            v-model="emailWidthValue"
            label="email width"
            :max="1920"
            :min="minEmailWidth"
          >
          </count-range>
        </b-col>
      </b-row>
      <b-media no-body class="dd-help-text">
        <b-media-aside>
          <img
            src="../../assets/img/icon/info-circle-fill.svg"
            width="14"
            class="dd-mt-3"
          />
        </b-media-aside>
        <b-media-body class="ml-2">
          <p class="mb-0">
            600 px is the recommended width.<br />
            {{
              emailWidthValue > 600
                ? "Exceeding this limit, won’t appear good in your customer’s inbox."
                : ""
            }}
          </p>
        </b-media-body>
      </b-media>
    </b-col>
  </b-row>
</template>
<script>
import CountRange from "../Controls/CountRange.vue";
const minEmailWidthVal = 320;
export default {
  name: "EmailWidth",
  props: {
    value: {
      default() {
        return minEmailWidthVal;
      },
      type: Number(),
    },
  },
  components: { CountRange },
  data() {
    return {
      minEmailWidth: minEmailWidthVal,
      emailWidthValue: this.value ? this.value : minEmailWidthVal,
    };
  },
  watch: {
    emailWidthValue(val) {
      this.$emit("input", val);
    },
  },
};
</script>
