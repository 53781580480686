<template comments>
  <tr
    class="mld-container"
    :class="{
      'ml-desk-hidden': props.hideElement === 'hide-pc',
      'ml-mobile-hidden': props.hideElement === 'hide-mobile',
    }"
  >
    <td
      align="center"
      valign="top"
      :style="{ margin: '0px', padding: '0px', width }"
      :class="{ 'ml-m-p20b': align == 'left' && paddingMobile }"
    >
      <dd-table
        width="100%"
        :bgcolor="setColor(props.backgroundColor)"
        :style="{
          ...padding,
          ...border,
          ...borderRadius,
          backgroundColor: setColor(props.backgroundColor),
          ...backgroundImage,
        }"
      >
        <slot />
      </dd-table>
    </td>
    <td
      class="ml-hidden"
      style="padding: 0; margin: 0"
      :style="{ width: parseInt(columnIndent) + 'px' }"
      v-if="columnIndent"
    ></td>
  </tr>
</template>
<script>
import { mapState } from "vuex";
import {
  parseStyleProperty,
  parseBorderStyle,
  parseBorderRadius,
} from "../../plugins/StyleConverter.js";
export default {
  name: "ContainerPreview",
  props: {
    columnIndent: {
      type: Number,
      default: 0,
    },
    align: {
      type: String,
      default: "left",
    },
    paddingMobile: {
      type: Boolean,
      default: false,
    },
    columnProps: {
      type: Object,
      default: null,
    },
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      emailWidth: (state) => state.ddb.htmlJson.properties.emailWidth,
      containerType: (state) => state.ddb.htmlJson.properties.containerType,
    }),
    backgroundImage() {
      if (this.props.backgroundImage) {
        return {
          backgroundImage: "url(" + this.props.backgroundImage + ")",
          backgroundRepeat: this.props.backgroundRepeat || "no-repeat",
          backgroundPosition: this.props.backgroundPosition || "center top",
        };
      }
      return {};
    },
    padding() {
      return parseStyleProperty(this.props.padding);
    },
    border() {
      return parseBorderStyle(this.props.border);
    },
    borderRadius() {
      const borderRadius = parseBorderRadius(this.props.borderRadius);
      borderRadius["border-collapse"] =
        borderRadius["border-radius"] === "0px" ? "collapse" : "separate";
      return borderRadius;
    },
    width() {
      if (this.containerType === "fullwidth") {
        return "100%";
      } else {
        return (this.columnProps.width || this.emailWidth) + "px";
      }
    },
  },
};
</script>
