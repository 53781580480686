<template>
  <draggable
    tag="dd-table"
    draggable=".mld-container"
    direction="vertical"
    handle=".dragg-handle"
    group="container"
    :invertSwap="true"
    :value="value"
    :style="style"
    :class="
      'ml-' +
      align +
      (hover ? ' mld-container-frame mld-frame mld-hover mld-activated' : '')
    "
    :component-data="{ attrs: { align } }"
    @input="emitter"
    @start="$emit('start', $event)"
    @end="$emit('end', $event)"
    @remove="onRemove"
    @add="onAdd"
    @update="onUpdate"
  >
    <slot />
  </draggable>
</template>
<script>
import { mapState } from "vuex";
import draggable from "../Draggable";
export default {
  name: "ContainerFrame",
  components: { draggable },
  props: {
    colIndex: {
      type: Number,
      default: -1,
    },
    structIndex: {
      type: Number,
      default: -1,
    },
    mainIndex: {
      type: Number,
      default: -1,
    },
    align: {
      type: String,
      default: "left",
    },
    columnProps: {
      type: Object,
      default: null,
    },
    value: {
      required: false,
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapState({
      containerType: (state) => state.ddb.htmlJson.properties.containerType,
      state: (state) => state.ddb,
    }),
    style() {
      if (this.containerType === "fullwidth") {
        return { width: "100%" };
      }
      return {};
    },
    hover() {
      return (
        this.state.structIndex === this.structIndex &&
        this.state.mainIndex === this.mainIndex &&
        this.state.hoverColIndex === this.colIndex
      );
    },
  },
  methods: {
    emitter(value) {
      this.$emit("input", value);
    },
    onRemove() {
      this.$store.dispatch("ddb/addEmptyContainer", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
        colIndex: this.colIndex,
      });
    },
    onUpdate(e) {
      if (
        e.newDraggableIndex > e.oldDraggableIndex &&
        e.newDraggableIndex !== this.value.length
      ) {
        const value = [...this.value];
        const el = value.splice(e.newDraggableIndex, 1);
        value.splice(e.newDraggableIndex - 1, 0, el[0]);
        this.$emit("input", value);
      }
    },
    onAdd() {
      const params = {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
        colIndex: this.colIndex,
      };
      this.$store.dispatch("ddb/removeEmptyContainer", params);
      this.$store.dispatch("ddb/relalculateColumnContent", params);
    },
  },
};
</script>
