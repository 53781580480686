<template>
  <div class="dd-wrapper" :class="{ 'allow-mobile-edit': responsive }">
    <dd-load v-if="ddLoading" />
    <div v-if="!ddLoading && !responsive" class="warn-responsive">
      <div class="text-center mx-auto">
        <img
          class="dd-mb-30 img-fluid"
          src="./assets/img/icon/cloud-confuced.png"
          width="205"
          alt="screen not fit"
        />
        <h2 class="dd-mb-10 dd-font-24">The screen size does not fit !</h2>
        <p class="dd-mb-20 dd-font-15">
          For a better experience, <br />please open the editor on a device,
          wider than 1200px
        </p>
        <div class="dd-mb-20">
          <b-button variant="darker" class="dd-dadius-4" @click="gotoBack"
            >Ok, close</b-button
          >
        </div>
        <b-link
          href="javascript:void(0)"
          class="dd-text-underline text-reset"
          @click="responsive = true"
          >Continue with this screen</b-link
        >
      </div>
    </div>
    <header
      class="dd-header"
      :class="{ 'dd-transparent': emailPreview }"
      @click="$store.dispatch('ddb/setSelected', '')"
    >
      <layout-header
        v-show="!emailPreview"
        :finish-buttons="finishButtons"
        :show-layout="showLayout"
        :undo-array="undoArray"
        :redo-array="redoArray"
        @undo="undo"
        @redo="redo"
        @export="save"
        @back="gotoBack"
        @preview="showPreview(1)"
        @showLayout="$emit('showLayout', true)"
      >
        <template slot="header"><slot name="header"></slot></template>
      </layout-header>
      <layout-preview-header v-if="emailPreview" @preview="showPreview" />
    </header>
    <section class="dd-content">
      <div class="dd-columns">
        <main class="dd-main">
          <div v-show="!emailPreview">
            <div class="horizontal-panes">
              <div class="pane" :class="{ 'pane-iframe': isHtml }">
                <v-frame :common-style="defaultStyle">
                  <dd-text v-show="ddLoading" @ready="loadingFinished" />
                  <builder
                    @confirmDelete="confirmDelete"
                    @click="resetSidebars"
                    @mousemove.native="onMove"
                  />
                </v-frame>
              </div>
              <div v-if="isHtml" class="pane-editor" :style="{ flexGrow: 1 }">
                <div class="code-header">
                  <i>HTML Editor</i>
                  <span
                    class="editor-close"
                    @click="$store.dispatch('ddb/setSelected', '')"
                    >×</span
                  >
                </div>
                <code-editor v-model="props.value" />
              </div>
            </div>
          </div>
          <div
            v-if="emailPreview"
            class="preview-canvas"
            :class="mobilePreview ? 'mobile-canvas' : 'desktop-canvas'"
          >
            <div
              class="preview-device"
              :class="mobilePreview ? 'mobile' : 'desktop'"
            >
              <div class="mock-wrapper">
                <preview-frame
                  :mobile-preview="mobilePreview && disableResponsive"
                />
              </div>
            </div>
          </div>
        </main>
        <aside
          v-show="!emailPreview"
          class="dd-sidebar-left"
          :class="{ 'dd-sidebar-close': LeftSidebar }"
          @click="$store.dispatch('ddb/setSelected', '')"
        >
          <a
            href="#"
            class="dd-sidebar-control"
            :class="{ 'dd-sidebar-closed': LeftSidebar }"
            @click.prevent="LeftSidebar = !LeftSidebar"
          >
            <img src="./assets/img/icon/double-arrow.svg" width="14" alt="" />
          </a>
          <sidebar-left />
        </aside>
        <aside
          v-show="!emailPreview"
          class="dd-sidebar-right"
          :class="{ 'dd-sidebar-close': RightSidebar }"
        >
          <a
            href="#"
            class="dd-sidebar-control"
            :class="{ 'dd-sidebar-closed': RightSidebar }"
            @click.prevent="RightSidebar = !RightSidebar"
          >
            <img src="./assets/img/icon/double-arrow.svg" width="14" alt="" />
          </a>
          <sidebar-right />
        </aside>
      </div>
    </section>
    <modal-confirm
      id="modalConfirmDelete"
      btnVariant="danger"
      confirmText="Yes, Delete"
      content="Are you sure you want to delete this?<br />This action cannot be undone."
      @cancel="modalTrigger(false)"
      @confirm="modalTrigger(true)"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { setStyleToHtml, rgbToHex } from "./plugins/HtmlConverter.js";
import { migrateHtmlJson } from "./plugins/JsonMigrator.js";
import { defaultHtmlJson, commonHead } from "./constants";
import { getCSS } from "./plugins/StyleConverter.js";
import LayoutHeader from "./components/Header/LayoutHeader.vue";
import LayoutPreviewHeader from "./components/Header/LayoutPreviewHeader.vue";
import SidebarLeft from "./components/LeftSidebar/SidebarLeft.vue";
import SidebarRight from "./components/RightSidebar/SidebarRight.vue";
import Builder from "./components/Builder/Builder.vue";
import PreviewFrame from "./components/Preview/PreviewFrame.vue";
import BuilderPreview from "./components/Preview/BuilderPreview.vue";
import VFrame from "./components/Builder/VFrame.vue";
import CodeEditor from "./components/Common/CodeEditor.vue";
import DdLoad from "./components/Common/DdLoad.vue";
import DdText from "./components/ContentBlocks/DdText.vue";
export default {
  name: "DdBuilder",
  components: {
    LayoutHeader,
    LayoutPreviewHeader,
    SidebarLeft,
    SidebarRight,
    Builder,
    PreviewFrame,
    VFrame,
    CodeEditor,
    DdLoad,
    DdText,
  },
  props: {
    htmlJson: {
      type: Object,
      default: null,
    },
    finishButtons: {
      type: Array,
      default: null,
    },
    templateName: {
      type: String,
      default: "",
    },
    showLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      responsive: false,
      RightSidebar: false,
      deleteAction: "",
      LeftSidebar: false,
      ddLoading: true,
      emailPreview: false,
      mobilePreview: false,
      smallScreenSize: 1330,
      autoSaveTimer: null,
      undoRedoTimer: null,
      time: 0,
      submitted: false,
      undoArray: [],
      redoArray: [],
    };
  },
  watch: {
    htmlJson(val) {
      this.setHtmlJson(val);
    },
    undoArray(val) {
      this.$ls.set("undoArray", JSON.stringify(val));
    },
    redoArray(val) {
      this.$ls.set("redoArray", JSON.stringify(val));
    },
    "$store.state.ddb.htmlJson": {
      handler(val, oldVal) {
        if (!this.ddLoading) {
          let oldJson = this.$ls.get("htmlJson");
          this.$ls.set("htmlJson", JSON.stringify(val));
          if (val && oldVal && JSON.stringify(val) !== oldJson) {
            if (this.autoSaveTimer) {
              clearTimeout(this.autoSaveTimer);
            }
            if (this.undoRedoTimer) {
              clearTimeout(this.undoRedoTimer);
            }
            let autoSaveMinTimeout =
              parseInt(process.env.VUE_APP_AUTO_SAVE_MIN_TIMEOUT || 10) * 1000;
            let time = Math.round(new Date().getTime() / 1000);
            if (!this.time) {
              this.time = time;
            }
            if (
              this.time +
                parseInt(process.env.VUE_APP_AUTO_SAVE_MAX_TIMEOUT || 60) <=
              time
            ) {
              autoSaveMinTimeout = 0;
            }
            this.autoSaveTimer = setTimeout(() => {
              this.time = 0;
              this.save("autoSave");
            }, autoSaveMinTimeout);
            this.undoRedoTimer = setTimeout(() => {
              this.redoArray = [];
              this.undoArray.push(oldJson);
              if (this.undoArray.length > 20) {
                this.undoArray.shift();
              }
            }, 1000);
          }
        }
      },
      deep: true,
    },
    dragging(val) {
      if (window.innerWidth < this.smallScreenSize) {
        if (val) {
          this.LeftSidebar = true;
        } else {
          this.LeftSidebar = false;
        }
      }
    },
    "props.value": {
      handler(val) {
        if (this.isHtml && !val) {
          this.props.value = "<p>Insert your HTML in the Code editor</p>";
        }
      },
    },
  },
  computed: {
    ...mapGetters("ddb", {
      tag: "getSelectedElementTag",
      props: "getSelectedElementProps",
      commonProps: "getCommonStyles",
      commonHeaderStyles: "getCommonHeaderStyles",
      responsiveStyle: "getMobileResponsiveStyles",
      properties: "getCommonProps",
      dragging: "getDragging",
    }),
    disableResponsive() {
      return (
        this.$store.state.ddb.htmlJson.properties.disableResponsive || false
      );
    },
    defaultStyle() {
      return (
        getCSS(this.commonHeaderStyles) +
        "\n\n" +
        getCSS(this.commonProps) +
        "\n\n" +
        this.mobileResponsive
      );
    },
    mobileResponsive() {
      if (this.disableResponsive) {
        return '*[class="fix-gmail"] {display: none !important;}';
      } else {
        return (
          "@media only screen and (max-width:600px) { " +
          getCSS(this.responsiveStyle) +
          " }"
        );
      }
    },
    isHtml() {
      return this.tag === "dd-html";
    },
  },
  created() {
    window.confirmOld = window.confirm;
    window.confirm = function () {
      return true;
    };
    window.alertOld = window.alert;
    window.alert = (msg) => {
      this.ddToastMessage(msg, "warning");
    };
    let htmlJson = this.htmlJson;
    if (!htmlJson) {
      htmlJson = defaultHtmlJson;
    }
    this.setHtmlJson(htmlJson);
    if (this.$ls.get("undoArray")) {
      this.undoArray = JSON.parse(this.$ls.get("undoArray"));
    }
    if (this.$ls.get("redoArray")) {
      this.redoArray = JSON.parse(this.$ls.get("redoArray"));
    }
  },
  beforeDestroy() {
    window.confirm = window.confirmOld;
    window.alert = window.alertOld;
    if (!this.submitted && this.showLayout) {
      this.gotoBack();
    }
  },
  methods: {
    undo() {
      if (this.undoArray.length) {
        let json = this.undoArray.pop();
        this.redoArray.push(this.$ls.get("htmlJson"));
        this.$ls.set("htmlJson", json);
        this.$store.commit("ddb/setHtmlJson", JSON.parse(json));
        this.$nextTick(() => {
          this.save("autoSave");
        });
      }
    },
    redo() {
      if (this.redoArray.length) {
        let json = this.redoArray.pop();
        this.undoArray.push(this.$ls.get("htmlJson"));
        this.$ls.set("htmlJson", json);
        this.$store.commit("ddb/setHtmlJson", JSON.parse(json));
        this.$nextTick(() => {
          this.save("autoSave");
        });
      }
    },
    modalTrigger(value) {
      if (value) {
        this.$store.dispatch(this.deleteAction.action, this.deleteAction.data);
      }
    },
    confirmDelete(data) {
      this.deleteAction = data;
      this.$bvModal.show("modalConfirmDelete");
    },
    resetSidebars() {
      this.RightSidebar = false;
      if (window.innerWidth < this.smallScreenSize) {
        this.LeftSidebar = true;
      }
    },
    onMove() {
      if (this.dragging) {
        this.$store.commit("ddb/setDragging", "");
      }
    },
    showPreview(state) {
      this.emailPreview = !!state;
      this.mobilePreview = state === 2;
    },
    loadingFinished() {
      let newJson = JSON.stringify(this.$store.state.ddb.htmlJson);
      let oldJson = (this.$ls.get("htmlJson") || "")
        .replace(/"key":\d+,/g, "")
        .replace(/,"key":\d+/g, "");
      if (
        newJson.replace(/"key":\d+,/g, "").replace(/,"key":\d+/g, "") !==
        oldJson
      ) {
        this.$ls.remove("undoArray");
        this.$ls.remove("redoArray");
        this.undoArray = [];
        this.redoArray = [];
      }
      this.$ls.set("htmlJson", newJson);
      this.$nextTick(() => {
        this.ddLoading = false;
      });
    },
    setHtmlJson(htmlJson) {
      if (htmlJson && htmlJson.contents && htmlJson.properties) {
        this.$store.dispatch("ddb/loadHtmlJson", htmlJson);
      } else if (htmlJson && htmlJson.tree) {
        this.$store.dispatch(
          "ddb/loadOldEditorJson",
          migrateHtmlJson(htmlJson, this.$ls.get("imageDomain") || "")
        );
      } else {
        this.$store.dispatch("ddb/loadHtmlJson", defaultHtmlJson);
      }
    },
    gotoBack() {
      this.$ls.remove("htmlJson");
      this.save("saveAndExit");
    },
    getBodyContent() {
      const vm = new Vue({
        comments: true,
        components: { BuilderPreview },
        render: (h) => h(BuilderPreview),
        store: this.$store,
      }).$mount();
      setStyleToHtml(vm.$el, this.$store.getters["ddb/getCommonStyles"]);
      let content = vm.$el.outerHTML;
      vm.$destroy();
      return content;
    },
    save(exportType) {
      let content = this.getBodyContent();
      let html =
        `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
   <head>
      ` +
        commonHead(this.$ls.get("imageDomain"), this.disableResponsive) +
        `
<style type="text/css">
    ` +
        getCSS(this.commonHeaderStyles) +
        `
    ` +
        this.mobileResponsive +
        `
</style>
<!--[if gte mso 12]>
<style type="text/css">
.mso-right {
  padding-left: 20px;
}
</style>
<![endif]--></head><body style="width:100%;font-family:` +
        this.properties.fontFamily +
        `;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;Margin:0">`;
      html += content + "\n</body></html>";
      let domain = "";
      if (this.$ls.get("imageDomain")) {
        domain = this.$ls.get("imageDomain") + "/";
      }
      html = html
        .replace(/<!---->/g, "")
        .replace(/src="\//g, 'src="' + domain)
        .replace(/class=""/g, "")
        .replace(/id="(\w+)"/g, "")
        .replace(/\s{2,}/g, " ");
      html = html.replace(/(rgb\().+?(\))/g, function () {
        return rgbToHex(arguments[0]);
      });
      // eslint-disable-next-line
      if (exportType === 'export') {
        html = html
          .replace(/%/g, "%25")
          .replace(/&/g, "%26")
          .replace(/#/g, "%23")
          .replace(/"/g, "%22")
          .replace(/'/g, "%27");
        var currentdate = new Date();
        var a = document.createElement("a");
        a.href = "data:text/html;charset=UTF-8," + html;
        a.download =
          (this.templateName || "Template") +
          "-" +
          currentdate.getDate() +
          "-" +
          (currentdate.getMonth() + 1) +
          "_" +
          currentdate.getHours() +
          "-" +
          currentdate.getMinutes() +
          "-" +
          currentdate.getSeconds() +
          ".html";
        var event = new MouseEvent("click");
        a.dispatchEvent(event);
      } else if (exportType === "autoSave") {
        const json = JSON.parse(JSON.stringify(this.$store.state.ddb.htmlJson));
        this.$emit("autoSave", { html, json });
      } else {
        this.$ls.remove("htmlJson");
        const json = JSON.parse(JSON.stringify(this.$store.state.ddb.htmlJson));
        this.submitted = true;
        this.$emit("save", {
          html,
          json,
          isSaveAs: exportType === "saveAs",
          isExit: exportType === "saveAndExit",
        });
      }
    },
  },
};
</script>
