var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{class:{
    'ml-desk-hidden': _vm.props.hideElement === 'hide-pc',
    'ml-mobile-hidden': _vm.props.hideElement === 'hide-mobile',
  }},[_c('td',{staticClass:"mld-structure",style:({
      backgroundColor: _vm.setColor(_vm.props.backgroundColor),
      ..._vm.backgroundImage,
      ..._vm.padding,
    }),attrs:{"align":"left","bgcolor":_vm.setColor(_vm.props.backgroundColor)}},[(!_vm.isFullWidth)?_c('comment',{attrs:{"value":_vm.msoStart}}):_vm._e(),_vm._t("default"),(!_vm.isFullWidth)?_c('comment',{attrs:{"value":_vm.msoEnd}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }