<template>
  <div>
    <dd-label>Menus</dd-label>
    <draggable
      tag="div"
      handle=".panel-title"
      group="menuBlock"
      v-model="menus"
      class="dd-menu-accrodion"
      role="tablist"
    >
      <b-card
        :class="openIndex === index ? 'active' : ''"
        no-body
        v-for="(col, index) in menus"
        :key="'menu-tab' + index"
      >
        <b-card-header header-tag="header" role="tab">
          <b-row align-v="center">
            <b-col>
              <h4 v-b-toggle="'menu-accordion-' + index" class="panel-title">
                {{ col.name }}
              </h4>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <b-link
                v-b-toggle="'menu-accordion-' + index"
                v-b-tooltip.hover
                title="Edit"
                class="dd-btn-ico dd-32 acc-edit"
                ><img src="../../assets/img/icon/edit-ico.svg" width="14"
              /></b-link>
              <b-link
                :disabled="menus.length === 1 ? true : false"
                @click="deleteItem(index)"
                class="dd-btn-ico dd-32 dd-btn-red dd-ml-10 acc-delete"
                v-b-tooltip.hover
                title="Delete"
                ><img src="../../assets/img/icon/trash-ico.svg" width="14"
              /></b-link>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse
          @shown="openCollapse(index)"
          @hide="hiddenCollapse(index)"
          :id="'menu-accordion-' + index"
          :visible="showVisibleMenu(index)"
          accordion="dd-menu-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-row v-if="tab === 'icons' || tab === 'iconsAndlinks'">
              <b-col>
                <image-upload
                  :width="64"
                  :height="64"
                  :prop="{ index: index, propVal: 'src', label: 'items' }"
                  :value="col.src ? col.src : ''"
                  @input="updateVal('src', index, $event)"
                  :hideLabel="true"
                />
                <ruler class="dd-mx--20" />
              </b-col>
            </b-row>
            <b-row
              v-if="tab === 'icons' || tab === 'iconsAndlinks'"
              class="dd-mb-10"
            >
              <b-col>
                <dd-label-light>Alt</dd-label-light>
                <b-form-input
                  :value="col.alt ? col.alt : ''"
                  @input="updateVal('alt', index, $event)"
                  class="dd-form-control"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row
              v-if="tab === 'links' || tab === 'iconsAndlinks'"
              class="dd-mb-10"
            >
              <b-col>
                <dd-label-light>Menu text</dd-label-light>
                <b-form-input
                  class="dd-form-control"
                  v-model="col.name"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="dd-mb-10">
              <b-col>
                <link-href
                  :value="col.href || ''"
                  @input="updateVal('href', index, $event)"
                />
              </b-col>
            </b-row>
            <b-row class="dd-mt-10">
              <b-col cols="6" v-if="tab !== 'icons'">
                <color-picker
                  :value="col.linkColor || commonFontColor"
                  @input="updateVal('linkColor', index, $event)"
                  label="Link color"
                />
              </b-col>
              <b-col cols="6">
                <color-picker
                  :value="col.backgroundColor || '#ffffff'"
                  @input="updateVal('backgroundColor', index, $event)"
                  label="Background color "
                />
              </b-col>
            </b-row>
            <ruler class="dd-mx--20" />
            <hide-element
              :value="col.hideElement || ''"
              @input="updateVal('hideElement', index, $event)"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </draggable>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from "../Draggable";
import LinkHref from "../Properties/LinkHref.vue";
import HideElement from "../Properties/HideElement.vue";
import ImageUpload from "../Properties/ImageUpload.vue";
export default {
  name: "MenuItem",
  components: {
    LinkHref,
    HideElement,
    draggable,
    ImageUpload,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: String,
      default: "links",
    },
    commonFontColor: {
      type: String,
      default: "#046DFF",
    },
  },
  computed: {
    ...mapGetters("ddb", {
      showMenu: "getSelectedMenu",
    }),
  },
  methods: {
    openCollapse(index) {
      this.openIndex = index;
    },
    hiddenCollapse(val) {
      this.openIndex = "";
      if (this.showMenu.menu === val) {
        this.$store.dispatch("ddb/setSelectedMenu", {
          type: "menu",
          index: "",
        });
      }
    },
    showVisibleMenu(index) {
      return this.showMenu.menu === index;
    },
    deleteItem(index) {
      this.menus.splice(index, 1);
    },
    updateVal(key, index, val) {
      var newValue = this.menus[index];
      newValue[key] = val;
      this.menus.splice(index, 1, newValue);
    },
  },
  watch: {
    menus: {
      handler(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.value)) {
          this.$emit("input", val);
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        this.menus = val;
      },
      deep: true,
    },
  },
  data() {
    return {
      menus: this.value ? this.value : [],
      openIndex: "",
    };
  },
};
</script>
