<template comments>
  <tr
    class="mld-element"
    @mouseenter="activated = true"
    @mouseleave="activated = false"
    @click.stop="onClick"
  >
    <td
      class="mld-frame mld-hover mldev-disable-select mld-draggable mld-block"
      v-alignment
      :align="tag === 'dd-html' ? null : props.align || 'left'"
      :height="tag === 'dd-spacer' ? props.height : false"
      :bgcolor="setColor(props.backgroundColor)"
      :style="{ ...padding, backgroundColor: setColor(props.backgroundColor) }"
      :class="{
        'mld-hidden-element': props.hideElement,
        'mld-activated': activated && 'el-' + propKey != selected,
        'mld-active': 'el-' + propKey == selected,
        'mld-block-menu': tag === 'dd-menu',
        'mld-block-social': tag === 'dd-share',
        'mld-block-image': tag === 'dd-image',
        'mld-block-text': tag === 'dd-text',
        'mld-block-spacer': tag === 'dd-spacer',
        'mld-block-divider': tag === 'dd-divider',
        'mld-block-html': tag === 'dd-html',
        'mld-block-nutton': tag === 'dd-button',
        ...mobileAlign,
        ...screenAlignment,
      }"
      @align="setScreenAlign"
    >
      <action-buttons
        :selected="'el-' + propKey == selected"
        @delete="onDelete"
        @copy="onCopy"
      />
      <slot />
      <div v-if="props.hideElement" class="mld-hidden-status">
        <span
          :class="
            props.hideElement === 'hide-mobile'
              ? 'ms-icon-hide-on-mobile'
              : 'ms-icon-hide-on-desktop'
          "
        ></span>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapState } from "vuex";
import { parseStyleProperty } from "../../plugins/StyleConverter.js";
import Alignment from "../../directives/alignment.js";
import ActionButtons from "./ActionButtons";
export default {
  name: "ElementFrame",
  directives: { Alignment },
  components: {
    ActionButtons,
  },
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
    contIndex: {
      type: Number,
      default: -1,
    },
    colIndex: {
      type: Number,
      default: -1,
    },
    structIndex: {
      type: Number,
      default: -1,
    },
    mainIndex: {
      type: Number,
      default: -1,
    },
    elIndex: {
      type: Number,
      default: -1,
    },
    propKey: {
      type: Number,
      default: -1,
    },
    tag: {
      type: String,
      default: "",
    },
  },
  data() {
    return { activated: false, screenAlign: "" };
  },
  computed: {
    ...mapState({
      selected: (state) => state.ddb.selected,
    }),
    padding() {
      return parseStyleProperty(this.props.padding);
    },
    mobileAlign() {
      const align = {};
      if (this.props.alignMobile) {
        align["ml-m-txt-" + this.props.alignMobile[0]] = true;
      }
      return align;
    },
    screenAlignment() {
      const align = {};
      if (this.screenAlign) {
        align[this.screenAlign] = true;
      }
      return align;
    },
  },
  methods: {
    setScreenAlign(val) {
      this.screenAlign = val;
    },
    onDelete() {
      this.$emit("confirmDelete", {
        action: "ddb/deleteElement",
        data: {
          mainIndex: this.mainIndex,
          structIndex: this.structIndex,
          colIndex: this.colIndex,
          contIndex: this.contIndex,
          elIndex: this.elIndex,
        },
      });
    },
    onCopy() {
      this.$store.dispatch("ddb/duplicateElement", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
        colIndex: this.colIndex,
        contIndex: this.contIndex,
        elIndex: this.elIndex,
      });
    },
    onClick() {
      if (this.propKey) {
        this.$store.dispatch("ddb/setAllIndexes", {
          mainIndex: this.mainIndex,
          structIndex: this.structIndex,
          colIndex: this.colIndex,
          contIndex: this.contIndex,
          elIndex: this.elIndex,
        });
        this.$store.dispatch("ddb/setSelected", "el-" + this.propKey);
        this.$emit("click");
      }
    },
  },
};
</script>
