<template>
  <div>
    <dd-label>Align</dd-label>
    <b-row align-v="center">
      <b-col>
        <div class="dd-btn-ico-group dd-align-settings">
          <a
            href="javascript:void(0)"
            @click="emitValue('left')"
            :class="{ active: posX === 'left' }"
            v-b-tooltip.hover
            title="Align left"
          >
            <img
              src="../../assets/img/icon/align/align-left.svg"
              width="18"
              alt="L"
            />
          </a>
          <a
            href="javascript:void(0)"
            @click="emitValue('center')"
            :class="{ active: posX === 'center' }"
            v-b-tooltip.hover
            title="Align center"
          >
            <img
              src="../../assets/img/icon/align/align-center.svg"
              width="18"
              alt="C"
            />
          </a>
          <a
            href="javascript:void(0)"
            @click="emitValue('right')"
            :class="{ active: posX === 'right' }"
            v-b-tooltip.hover
            title="Align right"
          >
            <img
              src="../../assets/img/icon/align/align-right.svg"
              width="18"
              alt="R"
            />
          </a>
        </div>
      </b-col>
    </b-row>
    <dd-label class="dd-mt-15"
      >Position
      <img
        src="../../assets/img/icon/info-circle-fill.svg"
        width="14"
        class="dd-text-top"
      />
    </dd-label>
    <b-row>
      <b-col>
        <b-input-group class="dd-input-group">
          <b-input-group-prepend>
            <b-input-group-text>X</b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            class="dd-form-control"
            placeholder="Left"
            v-model="valX"
            @change="changeX"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group class="dd-input-group">
          <b-input-group-prepend>
            <b-input-group-text>Y</b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            class="dd-form-control"
            placeholder="Top"
            v-model="valY"
            @change="changeY"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "BgAlign",
  components: {},
  props: {
    value: { type: String, default: "" },
  },
  data() {
    return {
      posX: "",
      posY: "",
      valX: "",
      valY: "",
    };
  },
  watch: {
    value(val) {
      this.setValue(val);
    },
  },
  created() {
    this.setValue(this.value);
  },
  methods: {
    emitValue(val) {
      if (this.posX !== val) {
        this.posX = val;
        this.emit();
      }
    },
    changeX() {
      let x = this.posX;
      this.valX = this.setPosX(this.valX);
      if (x !== this.posX) {
        this.emit();
      }
    },
    changeY() {
      let y = this.posY;
      this.valY = this.setPosY(this.valY);
      if (y !== this.posY) {
        this.emit();
      }
    },
    emit() {
      this.$emit("input", this.posX + " " + this.posY);
    },
    setValue(val) {
      let posX = "";
      let posY = "";
      if (val && val.split(" ").length === 2) {
        val = val.split(" ");
        posX = val[0];
        posY = val[1];
      }
      this.posX = "center";
      this.posY = "top";
      this.valX = this.setPosX(posX);
      this.valY = this.setPosY(posY);
    },
    setPosX(x) {
      if (["left", "right", "center"].includes(x)) {
        this.posX = x;
      }
      return this.posX;
    },
    setPosY(y) {
      if (["top", "bottom", "center"].includes(y)) {
        this.posY = y;
      }
      return this.posY;
    },
  },
};
</script>
