<template>
  <div>
    <dd-label-light><slot>All</slot></dd-label-light>
    <b-row class="dd-sm-row">
      <b-col cols="4">
        <border-style v-model="borderStyle" @input="emitValue" />
      </b-col>
      <b-col>
        <count-range
          :label="label"
          v-model="borderWidth"
          :incBy="1"
          :min="minValue"
          :max="maxValue"
          @input="emitValue"
        >
        </count-range>
      </b-col>
      <b-col cols="auto">
        <color-picker v-model="borderColor" @input="emitValue" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BorderStyle from "../Properties/BorderStyle.vue";
import CountRange from "../Controls/CountRange.vue";
import ColorPicker from "../Controls/ColorPicker.vue";
export default {
  name: "SingleBorderSettings",
  components: { BorderStyle, CountRange, ColorPicker },
  props: {
    label: { default: "border width", type: String },
    value: { type: String, default: "" },
    minValue: { type: Number, default: 0 },
    maxValue: { type: Number, default: 100 },
  },
  data() {
    return {
      borderStyle: "solid",
      borderWidth: 0,
      borderColor: "#000000",
    };
  },
  created() {
    this.setValue(this.value);
  },
  watch: {
    value(val) {
      this.setValue(val);
    },
  },
  methods: {
    setValue(val) {
      if (val && val.split(" ").length === 3) {
        val = val.split(" ");
        this.borderWidth = isNaN(parseInt(val[0])) ? 0 : parseInt(val[0]);
        this.borderStyle = val[1];
        this.borderColor = val[2];
      } else {
        this.borderWidth = 0;
        this.borderStyle = "solid";
        this.borderColor = "#000000";
      }
    },
    emitValue() {
      this.$emit(
        "input",
        this.borderWidth + "px " + this.borderStyle + " " + this.borderColor
      );
    },
  },
};
</script>
