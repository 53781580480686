<template comments>
  <tr
    class="mld-container"
    @mouseenter="mouseAction(true)"
    @mouseleave="mouseAction(false)"
    @click.stop="onClick"
  >
    <td
      align="center"
      class="mld-container-frame mld-frame mld-hover mldev-disable-select"
      valign="top"
      v-alignment
      :style="{ margin: '0px', padding: '0px', width }"
      :class="{
        'mld-hidden-element': props.hideElement,
        'mld-activated': activated && !selected,
        'mld-active': selected,
        'mld-light': selected && state.selected.includes('cont-'),
        'ml-m-p20b': align == 'left' && paddingMobile,
        ...screenAlignment,
      }"
      @align="setScreenAlign"
    >
      <div class="mld-structure-type" v-show="notEmpty">Container</div>
      <action-buttons
        :selected="selected"
        v-show="notEmpty"
        @delete="onDelete"
        @copy="onCopy"
      />
      <draggable
        style="width: 100%"
        draggable=".mld-element"
        direction="vertical"
        handle=".dragg-handle"
        group="element"
        tag="dd-table"
        :invertSwap="true"
        :value="value"
        :style="{
          ...padding,
          ...border,
          ...borderRadius,
          backgroundColor: setColor(props.backgroundColor),
          ...backgroundImage,
        }"
        :setData="setData"
        @input="emitter"
        @start="$emit('start', $event)"
        @end="$emit('end', $event)"
        @remove="onRemove"
        @add="onAdd"
        @update="onUpdate"
      >
        <slot />
      </draggable>
      <div v-if="props.hideElement" class="mld-hidden-status">
        <span
          :class="
            props.hideElement === 'hide-mobile'
              ? 'ms-icon-hide-on-mobile'
              : 'ms-icon-hide-on-desktop'
          "
        ></span>
      </div>
    </td>
    <td
      class="ml-hidden"
      style="padding: 0; margin: 0"
      :style="{ width: parseInt(columnIndent) + 'px' }"
      v-if="columnIndent"
    ></td>
  </tr>
</template>
<script>
import draggable from "../Draggable";
import { mapState } from "vuex";
import {
  parseStyleProperty,
  parseBorderStyle,
  parseBorderRadius,
} from "../../plugins/StyleConverter.js";
import Alignment from "../../directives/alignment.js";
import ActionButtons from "./ActionButtons";
export default {
  name: "Container",
  directives: { Alignment },
  components: { ActionButtons, draggable },
  props: {
    contIndex: {
      type: Number,
      default: -1,
    },
    colIndex: {
      type: Number,
      default: -1,
    },
    structIndex: {
      type: Number,
      default: -1,
    },
    mainIndex: {
      type: Number,
      default: -1,
    },
    propKey: {
      type: Number,
      default: -1,
    },
    columnIndent: {
      type: Number,
      default: 0,
    },
    notEmpty: {
      type: Boolean,
      default: true,
    },
    columnProps: {
      type: Object,
      default: null,
    },
    align: {
      type: String,
      default: "left",
    },
    paddingMobile: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
      type: Array,
      default: null,
    },
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return { activated: false, screenAlign: "" };
  },
  computed: {
    ...mapState({
      state: (state) => state.ddb,
      emailWidth: (state) => state.ddb.htmlJson.properties.emailWidth,
      containerType: (state) => state.ddb.htmlJson.properties.containerType,
      elIndexMax: (state) => state.ddb.elIndexMax,
    }),
    selected() {
      return (
        this.mainIndex === this.state.mainIndex &&
        this.structIndex === this.state.structIndex &&
        this.colIndex === this.state.colIndex &&
        this.contIndex === this.state.contIndex &&
        (this.state.selected.includes("cont-") ||
          this.state.selected.includes("el-"))
      );
    },
    backgroundImage() {
      if (this.props.backgroundImage) {
        return {
          backgroundImage: "url(" + this.props.backgroundImage + ")",
          backgroundRepeat: this.props.backgroundRepeat || "no-repeat",
          backgroundPosition: this.props.backgroundPosition || "center top",
        };
      }
      return {};
    },
    padding() {
      return parseStyleProperty(this.props.padding);
    },
    border() {
      return parseBorderStyle(this.props.border);
    },
    borderRadius() {
      const borderRadius = parseBorderRadius(this.props.borderRadius);
      borderRadius["border-collapse"] =
        borderRadius["border-radius"] === "0px" ? "collapse" : "separate";
      return borderRadius;
    },
    width() {
      if (this.containerType === "fullwidth") {
        return "100%";
      } else {
        return (this.columnProps.width || this.emailWidth) + "px";
      }
    },
    screenAlignment() {
      const align = {};
      if (this.screenAlign) {
        align[this.screenAlign] = true;
      }
      return align;
    },
  },
  methods: {
    emitter(value) {
      this.$emit("input", value);
    },
    setScreenAlign(val) {
      this.screenAlign = val;
    },
    onAdd(e) {
      if (e.pullMode === "clone") {
        const value = [...this.value];
        let index = e.newDraggableIndex;
        if (!value[e.newDraggableIndex]) {
          index = index - 1;
        }
        value[index] = JSON.parse(JSON.stringify(value[index]));
        value[index] = { ...value[index], key: this.elIndexMax + 1 };
        this.$store.dispatch("ddb/increamentElIndexMax");
        this.$store.dispatch("ddb/setAllIndexes", {
          mainIndex: this.mainIndex,
          structIndex: this.structIndex,
          colIndex: this.colIndex,
          contIndex: this.contIndex,
          elIndex: index,
        });
        this.$store.dispatch("ddb/setSelected", "el-" + value[index].key);
        this.$emit("input", value);
      } else {
        this.$store.dispatch("ddb/relalculateColumnContent", {
          mainIndex: this.mainIndex,
          structIndex: this.structIndex,
          colIndex: this.colIndex,
        });
      }
    },
    onUpdate(e) {
      if (
        e.newDraggableIndex > e.oldDraggableIndex &&
        e.newDraggableIndex !== this.value.length
      ) {
        const value = [...this.value];
        const el = value.splice(e.newDraggableIndex, 1);
        value.splice(e.newDraggableIndex - 1, 0, el[0]);
        this.$emit("input", value);
      }
    },
    onRemove() {
      this.$store.dispatch("ddb/removeEmptyContainerOnElement", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
        colIndex: this.colIndex,
        contIndex: this.contIndex,
      });
    },
    mouseAction(enter) {
      if (this.notEmpty || !enter) {
        this.activated = enter;
        this.$emit("hover", enter);
      }
    },
    onDelete() {
      this.$emit("confirmDelete", {
        action: "ddb/deleteContainer",
        data: {
          mainIndex: this.mainIndex,
          structIndex: this.structIndex,
          colIndex: this.colIndex,
          contIndex: this.contIndex,
        },
      });
    },
    onCopy() {
      this.$store.dispatch("ddb/duplicateContainer", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
        colIndex: this.colIndex,
        contIndex: this.contIndex,
      });
    },
    onClick() {
      this.$store.dispatch("ddb/setAllIndexes", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
        colIndex: this.colIndex,
        contIndex: this.contIndex,
      });
      this.$store.dispatch("ddb/setSelected", "cont-" + this.propKey);
      this.$emit("click");
    },
    setData(dataTransfer) {
      dataTransfer.setData("data", null);
    },
  },
};
</script>
