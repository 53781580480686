<template>
  <div class="dd-mb-20">
    <p class="dd-label-15" id="idContent">Container type</p>
    <b-row class="dd-container-type-blocks">
      <b-col
        v-for="type in [
          { val: 'card', text: 'Card' },
          { val: 'list', text: 'List' },
        ]"
        :key="'content-type-' + type.val"
        @click="setVal(type.val)"
      >
        <div
          class="thumb d-flex align-items-center"
          :class="{ active: containerType === type.val }"
        >
          <span class="thumb-img">
            <img
              :src="
                require('../../assets/img/icon/container-type-' +
                  type.val +
                  '.svg')
              "
              :width="type.val == 'fullwidth' ? 32 : 24"
            />
          </span>
          <span class="mb-0">{{ type.text }}</span>
        </div>
      </b-col>
    </b-row>
    <modal-confirm
      id="contentTypeModal"
      title="Turn on full width mode?"
      content="Turn on full width will remove left and right padding from your email and you'll lose any existing layouts. Your content will be collapsed into a single column layout."
      @confirm="modalTrigger"
    />
  </div>
</template>
<script>
export default {
  name: "ContainerType",
  props: {
    value: { default: "", type: String },
  },
  data() {
    return {
      containerType: this.value || "card",
    };
  },
  watch: {
    containerType(val) {
      if (val !== (this.value || "card")) {
        this.$emit("input", val);
      }
    },
    value(val) {
      this.containerType = val || "card";
    },
  },
  methods: {
    setVal(val) {
      if (val === "fullwidth") {
        this.$bvModal.show("contentTypeModal");
      } else {
        this.containerType = val;
      }
    },
    modalTrigger() {
      this.containerType = "fullwidth";
    },
  },
};
</script>
