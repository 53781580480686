<template>
  <b-row>
    <b-col>
      <dd-label-light><slot>Width</slot></dd-label-light>
      <b-row>
        <b-col cols="auto">
          <count-range
            :label="label"
            :inc-by="1"
            :max="max"
            :min="min"
            :value="value"
            @input="$emit('input', $event)"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import CountRange from "../Controls/CountRange.vue";
export default {
  name: "SizeProperty",
  components: { CountRange },
  props: {
    label: { type: String, default: "" },
    value: { type: Number, default: 0 },
    max: { type: Number, default: 100000 },
    min: { type: Number, default: 0 },
  },
};
</script>
