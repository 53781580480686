var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dd-table',{staticClass:"ml-content",style:(_vm.borderTopBottom),attrs:{"align":_vm.align},nativeOn:{"mouseenter":function($event){_vm.activated = true},"mouseleave":function($event){_vm.activated = false},"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('tr',{staticClass:"mld-hover"},[_c('td',{staticClass:"mld-mailer mld-frame mld-section-frame mldev-disable-select mld-hover",class:{
        'mld-hidden-element': _vm.props.hideElement,
        'mld-activated': _vm.activated && !_vm.hover && 'main-' + _vm.propKey != _vm.selected,
        'mld-active': 'main-' + _vm.propKey == _vm.selected,
      },style:({
        ..._vm.padding,
        backgroundColor: _vm.setColor(_vm.props.backgroundColor),
        ..._vm.backgroundImage,
      }),attrs:{"align":_vm.align,"bgcolor":_vm.setColor(_vm.props.backgroundColor)}},[_c('add-section',{staticClass:"top",on:{"add":function($event){return _vm.addSection(0)}}}),_c('div',{staticClass:"mld-structure-type"},[_vm._v("Section")]),_c('action-buttons',{attrs:{"selected":'main-' + _vm.propKey == _vm.selected,"delete-disabled":_vm.deleteDisabled},on:{"delete":_vm.onDelete,"copy":_vm.onCopy}}),_c('add-section',{staticClass:"bottom",on:{"add":function($event){return _vm.addSection(1)}}}),_c('draggable',{staticClass:"ml-content-body",style:({
          width: _vm.width,
          backgroundColor: _vm.setColor(
            _vm.props.contentBackground || _vm.commonProps.contentBackground
          ),
          ..._vm.border,
        }),attrs:{"draggable":".mld-dragg-structure","handle":".dragg-handle","direction":"vertical","group":"structure","tag":"dd-table","invertSwap":true,"value":_vm.value,"component-data":{ attrs: { align: _vm.align } }},on:{"input":_vm.emitter,"start":function($event){return _vm.$emit('start', $event, 'drag-structure')},"end":function($event){return _vm.$emit('end', $event)},"remove":_vm.onRemove,"add":_vm.onAdd,"update":_vm.onUpdate}},[_vm._t("default")],2),(_vm.props.hideElement)?_c('div',{staticClass:"mld-hidden-status"},[_c('span',{class:_vm.props.hideElement === 'hide-mobile'
              ? 'ms-icon-hide-on-mobile'
              : 'ms-icon-hide-on-desktop'})]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }