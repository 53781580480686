<template>
  <div v-html="props.value" @click.prevent />
</template>
<script>
export default {
  name: "DdHtml",
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
