import axios from "axios";
export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        getPersonalisation() {},
        uploadImage(file, fn) {
          var fd = new FormData();
          fd.append("image", file);
          axios
            .post("http://imgamjad.salesjio.com/api/image/test/upload", fd, {
              headers: {
                "Content-Type": "multipart/form-data",
                "X-Requested-With": "XMLHttpRequest",
              },
            })
            .then((res) => {
              fn(res.data.images[0]);
            })
            .catch((res) => {
              fn("", res.response.data.message);
            });
        },
        setImageDomain(image) {
          if (image[0] === "/" && this.$ls.get("imageDomain")) {
            image = this.$ls.get("imageDomain") + image;
          }
          return image;
        },
        setColor(color = null) {
          if (color && color === "transparent") {
            return null;
          } else if (color) {
            return color;
          }
          return null;
        },
        htmlEncode(val) {
          return String(val); // .replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
        },
        capitalize(word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        },
        showLimitExceedMessage(maxVal, field) {
          var message =
            "You cannot apply " +
            field.toLowerCase() +
            " of more than " +
            maxVal;

          var prefix = "a";
          const prefixField = ["icon size", "icon padding", "internal padding"];
          if (prefixField.includes(field.toLowerCase())) {
            prefix = "an";
          }

          const fieldArr = [
            "width",
            "height",
            "spacer height",
            "internal padding",
            "separator width",
            "font size",
            "icon size",
            "icon padding",
            "top left border radius",
            "top right border radius",
            "bottom left border radius",
            "bottom right border radius",
          ];
          if (fieldArr.includes(field.toLowerCase())) {
            message =
              "You cannot enter " +
              prefix +
              " " +
              field.toLowerCase() +
              " of more than " +
              maxVal;
          }

          const fieldArr2 = [
            "border width",
            "border radius",
            "margin",
            "divider height",
            "divider width",
          ];
          if (fieldArr2.includes(field.toLowerCase())) {
            message =
              "You cannot use " +
              prefix +
              " " +
              field.toLowerCase() +
              " of more than " +
              maxVal;
          }

          this.ddToastMessage(message, "warning");
        },
        ddToastMessage(message, variant = "success") {
          let img = require("../assets/img/icon/success-tick.svg");
          if (variant === "danger") {
            img = require("../assets/img/icon/warning-round-ico.svg");
          } else if (variant === "warning") {
            img = require("../assets/img/icon/info-circle-warning.svg");
          }
          const h = this.$createElement;
          const error = h("div", { class: ["d-flex", "dd-font-15"] }, [
            h("span", {}, [h("img", { attrs: { src: img, width: 24 } })]),
            h("span", message),
          ]);
          this.$bvToast.toast(error, {
            autoHideDelay: 2000,
            appendToast: false,
            variant: variant,
            toaster: "b-toaster-top-right",
            noCloseButton: false,
            toastClass: "dd-toast",
          });
        },
      },
    });
  },
};
