import {
  getCommonStyles,
  getCommonHeaderStyles,
  getMobileResponsiveStyles,
  defaultHtmlJson,
} from "../constants";
import {
  getPadding,
  getBorderWidth,
  getColumnWidth,
} from "../plugins/JsonHelper.js";
const getState = function () {
  return {
    htmlJson: {
      properties: JSON.parse(JSON.stringify(defaultHtmlJson.properties)),
      contents: [],
    },
    mainIndex: -1,
    oldMainIndex: -1,
    structIndex: -1,
    oldStructIndex: -1,
    colIndex: -1,
    oldColIndex: -1,
    contIndex: -1,
    oldContIndex: -1,
    mainIndexMax: 0,
    structIndexMax: 0,
    colIndexMax: 0,
    contIndexMax: 0,
    elIndexMax: 0,
    selected: "",
    elementDefaultProps: {},
    menuSelectedIndex: { menu: "", social: 2 },
    personalisation: [
      { label: "FirstName", value: "[[name]]" },
      { label: "MiddleName", value: "[[middle_name]]" },
      { label: "LastName", value: "[[last_name]]" },
    ],
    personalisationSelectValue: "",
    dragging: "",
    hoverColIndex: -1,
  };
};
const actions = {
  addColumn({ commit }, params) {
    commit("addColumn", params);
    commit("refreshHtmlJson");
  },
  setPersonalizationMenu({ commit }, params) {
    commit("setPersonalizationMenu", params);
  },
  setSelectedMenu({ commit }, params) {
    commit("setSelectedMenu", params);
  },
  setPersonalizationValue({ commit }, params) {
    commit("setPersonalizationValue", params);
  },
  relalculateColumnContent({ commit }, params) {
    commit("relalculateColumnContent", params);
  },
  updateBlockProps({ commit }, params) {
    commit("updateBlockProps", params);
    commit("refreshHtmlJson");
  },
  updateStructureProps({ commit }, params) {
    commit("updateStructureProps", params);
    commit("refreshHtmlJson");
  },
  updateContainerProps({ commit }, params) {
    commit("updateContainerProps", params);
    commit("refreshHtmlJson");
  },
  updateColumnProps({ commit }, params) {
    commit("updateColumnProps", params);
    commit("refreshHtmlJson");
  },
  deleteColumn({ commit }, params) {
    commit("deleteColumn", params);
    commit("refreshHtmlJson");
  },
  setAllIndexes({ commit }, params) {
    commit("setAllIndexes", params);
  },
  updateGneralProperty({ commit }, params) {
    commit("updateGneralProperty", params);
    commit("refreshHtmlJson");
  },
  updateSelectedProps({ commit }, params) {
    commit("updateSelectedProps", params);
    commit("refreshHtmlJson");
  },
  mergeElementProperty({ commit }, params) {
    commit("mergeElementProperty", params);
    commit("refreshHtmlJson");
  },
  updateElementProperty({ commit }, params) {
    commit("updateElementProperty", params);
    commit("refreshHtmlJson");
  },
  increamentElIndexMax({ commit }) {
    commit("increamentElIndexMax");
  },
  addEmptyStructure({ commit }, index) {
    commit("addEmptyStructure", index);
  },
  deleteMainBlock({ commit }, index) {
    commit("setSelected", "");
    commit("deleteMainBlock", index);
  },
  addMainBlock({ commit }, index) {
    commit("addMainBlock", index);
  },
  deleteElement({ commit }, index) {
    commit("setSelected", "");
    commit("deleteElement", index);
  },
  deleteContainer({ commit }, params) {
    commit("setSelected", "");
    commit("deleteContainer", params);
    commit("addEmptyContainer", params);
  },
  deleteStructure({ commit }, params) {
    commit("setSelected", "");
    commit("deleteStructure", params);
  },
  addNewElement({ commit }, params) {
    commit("addNewElement", params);
  },
  duplicateElement({ commit }, params) {
    commit("duplicateElement", params);
    commit("refreshHtmlJson");
  },
  duplicateContainer({ commit }, params) {
    commit("duplicateContainer", params);
    commit("refreshHtmlJson");
  },
  duplicateStructure({ commit }, params) {
    commit("duplicateStructure", params);
    commit("refreshHtmlJson");
  },
  replaceStructure({ commit }, params) {
    commit("replaceStructure", params);
    commit("refreshHtmlJson");
  },
  replaceMainBlock({ commit }, params) {
    commit("replaceMainBlock", params);
    commit("refreshHtmlJson");
  },
  duplicateMainBlock({ commit }, mainIndex) {
    commit("duplicateMainBlock", { mainIndex });
    commit("refreshHtmlJson");
  },
  addEmptyContainer({ commit }, params) {
    commit("addEmptyContainer", params);
    commit("refreshHtmlJson");
  },
  removeEmptyContainer({ commit }, params) {
    commit("removeEmptyContainer", params);
    commit("refreshHtmlJson");
  },
  removeEmptyContainerOnElement({ commit }, params) {
    commit("removeEmptyContainerOnElement", params);
    commit("refreshHtmlJson");
  },
  setSelected({ commit }, selected) {
    commit("setSelected", selected);
  },
  loadOldEditorJson({ commit }, params) {
    commit("loadOldEditorJson", params);
    commit("refreshHtmlJson");
  },
  setElementDefaultProps({ commit }, params) {
    commit("setElementDefaultProps", params);
  },
  loadHtmlJson({ commit }, htmlJson) {
    commit("loadHtmlJson", htmlJson);
    commit("refreshHtmlJson");
  },
};

const copyElement = function (state, element) {
  state.elIndexMax++;
  return {
    key: state.elIndexMax,
    tag: element.tag,
    props: JSON.parse(JSON.stringify(element.props)),
  };
};
const copyContainer = function (state, container) {
  state.contIndexMax++;
  const newContainer = {
    key: state.contIndexMax,
    props: JSON.parse(JSON.stringify(container.props)),
    elements: [],
  };
  newContainer.elements = container.elements
    .filter((val) => val)
    .map((el) => {
      return copyElement(state, el);
    });
  return newContainer;
};
const copyColum = function (state, column) {
  state.colIndexMax++;
  const col = {
    key: state.colIndexMax,
    props: JSON.parse(JSON.stringify(column.props)),
    containers: [],
  };
  col.containers = column.containers
    .filter((val) => val)
    .map((cont) => {
      return copyContainer(state, cont);
    });
  return col;
};
const copyStructure = function (state, structure, content) {
  if (structure.props) {
    state.structIndexMax++;
    const struct = {
      key: state.structIndexMax,
      props: JSON.parse(JSON.stringify(structure.props)),
      columns: [],
    };
    struct.columns = structure.columns
      .filter((val) => val)
      .map((col) => {
        return copyColum(state, col);
      });
    return struct;
  }
  return getEmptyStructure(state, content);
};
const copyMainBlock = function (state, block) {
  state.mainIndexMax++;
  const mainBlock = {
    key: state.mainIndexMax,
    props: JSON.parse(JSON.stringify(block.props)),
    structures: [],
  };
  mainBlock.structures = block.structures
    .filter((val) => val)
    .map((struct) => {
      return copyStructure(state, struct, block);
    });
  return mainBlock;
};
const getEmptyContainers = function (state) {
  state.contIndexMax++;
  return {
    key: state.contIndexMax,
    props: {},
    elements: [],
  };
};
const getEmptyMainBlock = function (state) {
  state.mainIndexMax++;
  const content = {
    key: state.mainIndexMax,
    props: {},
    structures: [],
  };
  content.structures.push(getEmptyStructure(state, content));
  content.structures[0].props.equalWidth = true;
  return content;
};
const getEmptyColumn = function (state, width) {
  state.colIndexMax++;
  const column = {
    key: state.colIndexMax,
    props: { width },
    containers: [],
  };
  column.containers.push(getEmptyContainers(state));
  return column;
};
const getEmptyStructureOnly = function (state) {
  state.structIndexMax++;
  return {
    key: state.structIndexMax,
    props: { padding: state.htmlJson.properties.padding },
    columns: [],
  };
};
const getEmptyStructure = function (state, content) {
  const structure = getEmptyStructureOnly(state);
  let width = 100;
  if (state.htmlJson.properties.containerType !== "fullwidth") {
    const total =
      getPadding(structure.props.padding) +
      getBorderWidth(content.props.border);
    width = state.htmlJson.properties.emailWidth - total;
    width = width < 1 ? 1 : width;
  }
  structure.props.equalWidth = true;
  structure.columns.push(getEmptyColumn(state, width));
  return structure;
};

const getColumnIndex = function (columns, index) {
  if (columns.length <= index) {
    return 0;
  }
  return index;
};

const equalizeColumWidth = function (state, structure, extra) {
  let emailWidth = state.htmlJson.properties.emailWidth;
  if (state.htmlJson.properties.containerType === "fullwidth") {
    emailWidth = 100;
  }
  const columnWidth = Math.floor(
    (emailWidth - extra) / structure.columns.length
  );
  structure.columns.forEach((col) => {
    col.props.width = columnWidth;
    relalculateColumnContent(col);
  });
};

const isColumnsAreEqual = function (columns) {
  const unique = columns
    .map((col) => col.props.width)
    .filter((col, i, ar) => ar.indexOf(col) === i);
  if (unique.length === 1) {
    return true;
  }
  if (
    unique.length === 2 &&
    (unique[0] === unique[1] + 1 || unique[0] === unique[1] - 1)
  ) {
    return true;
  }
  return false;
};

const relalculateColumnContent = (column) => {
  column.containers.forEach((container) => {
    container.elements.forEach((element) => {
      resizeImage(column, container, element);
    });
  });
};

const recalculateColumWidth = function (
  state,
  structure,
  content,
  currentIndex = -1
) {
  let total = (structure.props.indent || 0) * (structure.columns.length - 1);
  let emailWidth = 100;
  if (state.htmlJson.properties.containerType !== "fullwidth") {
    total +=
      getPadding(structure.props.padding) +
      getBorderWidth(content.props.border);
    emailWidth = state.htmlJson.properties.emailWidth;
  }
  if (currentIndex === -1 && structure.props.equalWidth) {
    equalizeColumWidth(state, structure, total);
  }
  total += structure.columns.reduce((total, col) => {
    return total + (col.props.width || 0);
  }, 0);
  let diff = total - emailWidth;
  if (currentIndex !== -1 && structure.columns[currentIndex]) {
    if (diff > 0) {
      let nextIndex = currentIndex;
      relalculateColumnContent(structure.columns[nextIndex]);
      do {
        nextIndex = getColumnIndex(structure.columns, nextIndex + 1);
        if (structure.columns[nextIndex].props.width - diff < 30) {
          diff = diff - (structure.columns[nextIndex].props.width - 30);
          structure.columns[nextIndex].props.width = 30;
        } else {
          structure.columns[nextIndex].props.width -= diff;
          relalculateColumnContent(structure.columns[nextIndex]);
          break;
        }
        if (nextIndex === currentIndex) {
          break;
        }
      } while (diff);
    } else {
      const col =
        structure.columns[getColumnIndex(structure.columns, currentIndex + 1)];
      col.props.width += 0 - diff;
      relalculateColumnContent(col);
    }
  } else {
    let isAdd = false;
    if (diff < 0) {
      isAdd = true;
      diff = 0 - diff;
    }
    let commonDiff = Math.floor(diff / structure.columns.length);
    diff = diff % structure.columns.length;
    for (let i in structure.columns) {
      if (isAdd) {
        structure.columns[i].props.width += commonDiff;
        if (diff) {
          structure.columns[i].props.width += 1;
          diff--;
        }
        relalculateColumnContent(structure.columns[i]);
      } else {
        structure.columns[i].props.width -= commonDiff;
        if (diff) {
          structure.columns[i].props.width -= 1;
          diff--;
        }
        relalculateColumnContent(structure.columns[i]);
      }
    }
  }
};
const recalculateContentWidth = function (state, content) {
  content.structures.forEach((structure) => {
    recalculateColumWidth(state, structure, content);
  });
};
const getCurrentContent = function (state) {
  const contents = state.htmlJson.contents;
  if (!contents || state.mainIndex === -1) {
    return {};
  }
  return contents[state.mainIndex];
};
const getCurrentStructure = function (state) {
  const structures = getCurrentContent(state).structures;
  if (!structures || state.structIndex === -1) {
    return {};
  }
  return structures[state.structIndex];
};
const getCurrentColumn = function (state) {
  const columns = getCurrentStructure(state).columns;
  if (!columns || state.colIndex === -1) {
    return {};
  }
  return columns[state.colIndex];
};
const getCurrentContainer = function (state) {
  const containers = getCurrentColumn(state).containers;
  if (!containers || state.contIndex === -1) {
    return {};
  }
  return containers[state.contIndex];
};
const getCurrentElement = function (state) {
  const elements = getCurrentContainer(state).elements;
  if (!elements || state.elIndex === -1) {
    return {};
  }
  return elements[state.elIndex];
};
const getSelectedColumnWidth = (state) => {
  if (
    state.selected.indexOf("el") === 0 ||
    state.selected.indexOf("cont") === 0
  ) {
    return getColumnWidth(getCurrentContainer(state), getCurrentColumn(state));
  }
  return 0;
};
const resizeImage = (column, container, element) => {
  const props = element.props;
  if (props.width && element.tag === "dd-image") {
    let width = getColumnWidth(container, column) - getPadding(props.padding);
    if (width < 1) {
      width = 1;
    }
    let updateHeight = false;
    if (props.width > width) {
      props.width = width;
      if (props.proportion !== false) {
        updateHeight = true;
      }
    } else if (
      props.width < width &&
      (props.imageSize === "auto" ||
        (props.imageSize === "exact" && width <= props.originalWidth))
    ) {
      props.width = width;
      updateHeight = true;
    }
    if (updateHeight && props.originalHeight) {
      props.height = Math.floor(
        (props.width * props.originalHeight) / props.originalWidth
      );
    }
  }
};
const findSelectedElement = (state, selected) => {
  let key = 0;
  let i = 0;
  if (selected.indexOf("main") === 0) {
    key = parseInt(selected.replace("main-", ""));
    return state.htmlJson.contents.find((item) => item.key === key);
  }
  if (selected.indexOf("struct") === 0) {
    key = parseInt(selected.replace("struct-", ""));
    for (i in state.htmlJson.contents) {
      const struct = state.htmlJson.contents[i].structures.find(
        (item) => item.key === key
      );
      if (struct) {
        return struct;
      }
    }
    return null;
  }
  let j = 0;
  let k = 0;
  if (selected.indexOf("cont") === 0) {
    key = parseInt(selected.replace("cont-", ""));
    for (i in state.htmlJson.contents) {
      for (j in state.htmlJson.contents[i].structures) {
        for (k in state.htmlJson.contents[i].structures[j].columns) {
          const cont = state.htmlJson.contents[i].structures[j].columns[
            k
          ].containers.find((item) => item.key === key);
          if (cont) {
            return cont;
          }
        }
      }
    }
    return null;
  }
  let l = 0;
  if (selected.indexOf("el") === 0) {
    key = parseInt(selected.replace("el-", ""));
    for (i in state.htmlJson.contents) {
      for (j in state.htmlJson.contents[i].structures) {
        for (k in state.htmlJson.contents[i].structures[j].columns) {
          for (l in state.htmlJson.contents[i].structures[j].columns[k]
            .containers) {
            const cont = state.htmlJson.contents[i].structures[j].columns[
              k
            ].containers[l].elements.find((item) => item.key === key);
            if (cont) {
              return cont;
            }
          }
        }
      }
    }
  }
  return null;
};
const changeContainerWidth = (state, isFullWidth) => {
  let oldWidth = 0;
  let newWidth = 0;
  state.htmlJson.contents.forEach((content) => {
    content.structures.forEach((structure) => {
      if (isFullWidth) {
        oldWidth =
          (structure.props.indent || 0) * (structure.columns.length - 1);
        oldWidth += structure.columns.reduce((total, col) => {
          return total + (col.props.width || 0);
        }, 0);
        newWidth = 100;
      } else {
        oldWidth = 100;
        newWidth =
          state.htmlJson.properties.emailWidth -
          getPadding(structure.props.padding) -
          getBorderWidth(content.props.border);
      }
      const ratio = newWidth / oldWidth;
      let indent = Math.floor(structure.props.indent * ratio);
      structure.props.indent = indent < 1 ? 1 : indent;
      newWidth -=
        (structure.props.indent || 0) * (structure.columns.length - 1);
      for (let i in structure.columns) {
        structure.columns[i].props.width = getNewWidth(
          structure.columns[i].props.width,
          ratio,
          isFullWidth
        );
        newWidth -= structure.columns[i].props.width;
        if (parseInt(i) === structure.columns.length - 1 && newWidth > 0) {
          structure.columns[i].props.width += newWidth;
        }
        relalculateColumnContent(structure.columns[i]);
      }
    });
  });
};
const getNewWidth = (width, ratio, isFullWidth) => {
  width = Math.floor(width * ratio);
  const minWidth = isFullWidth ? 1 : 30;
  return width < minWidth ? minWidth : width;
};

const setSelectedElement = function (state, selected) {
  if (!selected) {
    state.mainIndex =
      state.structIndex =
      state.colIndex =
      state.contIndex =
      state.elIndex =
        -1;
  }
  state.selected = selected;
};

const setAllIndexesElement = function (state, indexes) {
  Object.assign(state, indexes);
};

const getters = {
  getDragging: (state) => {
    return state.dragging;
  },
  getSelected: (state) => {
    return state.selected;
  },
  getPersonalisation: (state) => {
    return state.personalisation;
  },
  getMobileResponsiveStyles,
  getCommonHeaderStyles,
  getCommonStyles,
  getCommonProps: (state) => {
    return state.htmlJson.properties ? state.htmlJson.properties : {};
  },
  getSelectedElementProps: (state) => {
    if (state.selected.indexOf("el") === 0) {
      return getCurrentElement(state).props;
    }
    return {};
  },
  getSelectedElementTag: (state) => {
    if (state.selected.indexOf("el") === 0) {
      const el = getCurrentElement(state);
      return el ? el.tag : "";
    }
    return "";
  },
  getSelectedMenu: (state) => {
    return state.menuSelectedIndex;
  },
  getSelectedColumnWidth: (state) => {
    return getSelectedColumnWidth(state);
  },
  getPersonalizationValue: (state) => {
    return state.personalisationSelectValue;
  },
};

const mutations = {
  setDragging(state, dragging) {
    state.dragging = dragging;
  },
  setHoverColIndex(state, hoverColIndex) {
    state.hoverColIndex = hoverColIndex;
  },
  setSelectedMenu(state, { type, index }) {
    state.menuSelectedIndex[type] = index;
  },
  setPersonalizationValue(state, value) {
    state.personalisationSelectValue = value;
  },
  setPersonalizationMenu(state, data) {
    data.forEach(function (item) {
      item.value = "[[" + item.value + "]]";
    });
    state.personalisation = data;
  },
  addColumn(state, { mainIndex, structIndex, colIndex }) {
    const content = state.htmlJson.contents[mainIndex];
    const structure = content.structures[structIndex];
    structure.columns.splice(colIndex, 0, getEmptyColumn(state, 40));
    if (structure.props.equalWidth) {
      colIndex = -1;
    }
    if (structure.columns.length === 2) {
      structure.props.indent =
        state.htmlJson.properties.containerType === "fullwidth" ? 3 : 20;
    }
    recalculateColumWidth(state, structure, content, colIndex);
  },
  relalculateColumnContent(state, { mainIndex, structIndex, colIndex }) {
    const column =
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ];
    relalculateColumnContent(column);
  },
  updateBlockProps(state, { prop, val }) {
    const content = state.htmlJson.contents[state.mainIndex];
    const border = getBorderWidth(content.props.border);
    content.props[prop] = val;
    if (prop === "border" && border !== getBorderWidth(val)) {
      recalculateContentWidth(state, content);
    }
  },
  updateStructureProps(state, { mainIndex, structIndex, prop, val }) {
    const content = state.htmlJson.contents[mainIndex];
    const structure = content.structures[structIndex];
    structure.props[prop] = val;
    if (["indent", "equalWidth", "padding"].includes(prop)) {
      recalculateColumWidth(state, structure, content);
    }
    if (prop === "padding") {
      state.htmlJson.properties["paddingForAll"] = false;
    }
  },
  updateContainerProps(state, { prop, val }) {
    const column =
      state.htmlJson.contents[state.mainIndex].structures[state.structIndex]
        .columns[state.colIndex];
    const container = column.containers[state.contIndex];
    container.props[prop] = val;
    if (["padding", "border"].includes(prop)) {
      container.elements.forEach((element) => {
        resizeImage(column, container, element);
      });
    }
  },
  updateColumnProps(state, { mainIndex, structIndex, colIndex, prop, val }) {
    const content = state.htmlJson.contents[mainIndex];
    const structure = content.structures[structIndex];
    structure.columns[colIndex].props[prop] = val;
    if (prop === "width") {
      recalculateColumWidth(state, structure, content, colIndex);
      structure.props["equalWidth"] = isColumnsAreEqual(structure.columns);
    }
  },
  refreshHtmlJson(state) {
    state.htmlJson = JSON.parse(JSON.stringify(state.htmlJson));
  },
  setHtmlJson(state, json) {
    state.htmlJson = json;
  },
  deleteColumn(state, { mainIndex, structIndex, colIndex }) {
    const content = state.htmlJson.contents[mainIndex];
    const structure = content.structures[structIndex];
    structure.columns.splice(colIndex, 1);
    if (structure.columns.length === 1) {
      structure.props.indent = 0;
    }
    recalculateColumWidth(state, structure, content);
  },
  setAllIndexes(state, indexes) {
    setAllIndexesElement(state, indexes);
  },
  updateSelectedProps(state, { selected, value, prop }) {
    const content = findSelectedElement(state, selected);
    if (content) {
      if (typeof prop === "object") {
        content.props[prop.label][prop.index][prop.propVal] = value;
      } else if (typeof value === "object") {
        Object.assign(content.props, value);
      } else {
        content.props[prop] = value;
      }
    }
  },
  updateGneralProperty(state, { property, value }) {
    if (property === "containerType") {
      if (
        state.htmlJson.properties.containerType === "fullwidth" &&
        value !== "fullwidth"
      ) {
        changeContainerWidth(state, false);
      } else if (
        state.htmlJson.properties.containerType !== "fullwidth" &&
        value === "fullwidth"
      ) {
        changeContainerWidth(state, true);
      }
    }
    state.htmlJson.properties[property] = value;
    if (
      (property === "padding" && state.htmlJson.properties.paddingForAll) ||
      (property === "paddingForAll" && value)
    ) {
      state.htmlJson.contents.forEach((main) => {
        main.structures.forEach((structure) => {
          structure.props.padding = state.htmlJson.properties.padding;
        });
      });
    }
    if (property === "emailWidth") {
      state.htmlJson.contents.forEach((content) => {
        recalculateContentWidth(state, content);
      });
    }
  },
  mergeElementProperty(
    state,
    { mainIndex, structIndex, colIndex, contIndex, elIndex, props }
  ) {
    const el =
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ].containers[contIndex].elements[elIndex];
    el.props = { ...el.props, ...props };
  },
  updateElementProperty(state, { property, value }) {
    const mainIndex = state.mainIndex;
    const structIndex = state.structIndex;
    const colIndex = state.colIndex;
    const contIndex = state.contIndex;
    const elIndex = state.elIndex;
    const column =
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ];
    const container = column.containers[contIndex];
    const element = container.elements[elIndex];
    let propsVal = element.props;
    if (element.tag === "dd-image") {
      if (property === "src") {
        const img = new Image();
        img.onload = (e) => {
          propsVal =
            state.htmlJson.contents[mainIndex].structures[structIndex].columns[
              colIndex
            ].containers[contIndex].elements[elIndex].props;
          propsVal.originalWidth = e.target.width;
          propsVal.originalHeight = e.target.height;
          state.htmlJson = JSON.parse(JSON.stringify(state.htmlJson));
        };
        img.src = value;
        if (!value) {
          propsVal.rolloverEffect = false;
        }
      } else if (
        property === "padding" &&
        getPadding(propsVal.padding) !== getPadding(value)
      ) {
        propsVal[property] = value;
        resizeImage(column, container, element);
      } else if (
        property === "imageSize" &&
        ["exact", "auto"].includes(value)
      ) {
        let width =
          getSelectedColumnWidth(state) - getPadding(propsVal.padding);
        if (width < 1) {
          width = 1;
        }
        if (value === "exact" && propsVal.originalWidth < width) {
          width = propsVal.originalWidth;
        }
        if (state.htmlJson.properties.containerType === "fullwidth") {
          width = propsVal.originalWidth < 1500 ? propsVal.originalWidth : 1500;
        }
        propsVal.width = width;
        propsVal.height = Math.floor(
          (width * propsVal.originalHeight) / propsVal.originalWidth
        );
      } else if (property === "width" && propsVal.proportion !== false) {
        propsVal.height = Math.floor(
          (value * propsVal.originalHeight) / propsVal.originalWidth
        );
      } else if (property === "proportion" && value && propsVal.width) {
        propsVal.height = Math.floor(
          (propsVal.width * propsVal.originalHeight) / propsVal.originalWidth
        );
      } else if (property === "height" && propsVal.proportion !== false) {
        propsVal.width = Math.floor(
          (value * propsVal.originalWidth) / propsVal.originalHeight
        );
      }
    }
    propsVal[property] = value;
  },
  increamentElIndexMax(state) {
    state.elIndexMax++;
  },
  addEmptyStructure(state, mainIndex) {
    if (!state.htmlJson.contents[mainIndex].structures.length) {
      state.htmlJson.contents[mainIndex].structures.push(
        getEmptyStructure(state, state.htmlJson.contents[mainIndex])
      );
      state.htmlJson.contents[mainIndex].structures[0].props.equalWidth = true;
    }
  },
  deleteMainBlock(state, mainIndex) {
    state.htmlJson.contents.splice(mainIndex, 1);
  },
  addMainBlock(state, mainIndex) {
    state.htmlJson.contents.splice(mainIndex, 0, getEmptyMainBlock(state));
  },
  deleteElement(
    state,
    { mainIndex, structIndex, colIndex, contIndex, elIndex }
  ) {
    const containers =
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ].containers;
    const elements = containers[contIndex].elements;
    elements.splice(elIndex, 1);
    if (containers.length > 1 && !elements.length) {
      containers.splice(contIndex, 1);
    }
  },
  deleteContainer(state, { mainIndex, structIndex, colIndex, contIndex }) {
    state.htmlJson.contents[mainIndex].structures[structIndex].columns[
      colIndex
    ].containers.splice(contIndex, 1);
  },
  deleteStructure(state, { mainIndex, structIndex }) {
    state.htmlJson.contents[mainIndex].structures.splice(structIndex, 1);
  },
  addNewElement(
    state,
    { mainIndex, structIndex, colIndex, contIndex, element }
  ) {
    const elements =
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ].containers[contIndex].elements;
    var elementCopy = copyElement(state, element);
    elements.push(elementCopy);
    setSelectedElement(state, "el-" + elementCopy.key);
    setAllIndexesElement(state, {
      mainIndex: mainIndex,
      structIndex: structIndex,
      colIndex: colIndex,
      contIndex: contIndex,
      elIndex: 0,
    });
  },
  duplicateElement(
    state,
    { mainIndex, structIndex, colIndex, contIndex, elIndex }
  ) {
    const elements =
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ].containers[contIndex].elements;
    elements.splice(elIndex, 0, copyElement(state, elements[elIndex]));
    setSelectedElement(state, "el-" + elements[elIndex + 1].key);
    setAllIndexesElement(state, {
      mainIndex,
      structIndex,
      colIndex,
      contIndex,
      elIndex: elIndex + 1,
    });
  },
  duplicateContainer(state, { mainIndex, structIndex, colIndex, contIndex }) {
    const containers =
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ].containers;
    containers.splice(
      contIndex,
      0,
      copyContainer(state, containers[contIndex])
    );
    setSelectedElement(state, "cont-" + containers[contIndex + 1].key);
    setAllIndexesElement(state, {
      mainIndex,
      structIndex,
      colIndex,
      contIndex: contIndex + 1,
    });
  },
  duplicateStructure(state, { mainIndex, structIndex }) {
    const content = state.htmlJson.contents[mainIndex];
    const structures = content.structures;
    structures.splice(
      structIndex,
      0,
      copyStructure(state, structures[structIndex], content)
    );
    setSelectedElement(state, "struct-" + structures[structIndex + 1].key);
    setAllIndexesElement(state, { mainIndex, structIndex: structIndex + 1 });
  },
  replaceMainBlock(state, { mainIndex, domain }) {
    const contents = state.htmlJson.contents;
    contents[mainIndex] = copyMainBlock(
      state,
      JSON.parse(
        JSON.stringify(contents[mainIndex]).replace(
          /"\/img\//g,
          '"' + domain + "/img/"
        )
      )
    );
    state.htmlJson.properties.paddingForAll = false;
    recalculateContentWidth(state, contents[mainIndex]);
    setSelectedElement(state, "main-" + contents[mainIndex].key);
    setAllIndexesElement(state, { mainIndex });
  },
  replaceStructure(state, { mainIndex, structIndex }) {
    const content = state.htmlJson.contents[mainIndex];
    const structure = content.structures[structIndex];
    const structureNew = getEmptyStructureOnly(state);
    let total =
      getPadding(structureNew.props.padding) +
      getBorderWidth(content.props.border);
    total += 20 * (structure.columns.length - 1);
    const cols = structure.columns.map((col) => {
      return getEmptyColumn(
        state,
        Math.floor(
          (col.cols * (state.htmlJson.properties.emailWidth - total)) / 12
        )
      );
    });
    if (cols.length > 1) {
      structureNew.props.indent = 20;
    }
    structureNew.props.equalWidth = isColumnsAreEqual(cols);
    structureNew.columns = cols;
    content.structures[structIndex] = structureNew;
    setSelectedElement(state, "struct-" + structureNew.key);
    setAllIndexesElement(state, {
      mainIndex: mainIndex,
      structIndex: structIndex,
    });
    recalculateColumWidth(state, structureNew, content);
  },
  duplicateMainBlock(state, { mainIndex }) {
    const contents = state.htmlJson.contents;
    contents.splice(mainIndex, 0, copyMainBlock(state, contents[mainIndex]));
    setSelectedElement(state, "main-" + contents[mainIndex + 1].key);
    setAllIndexesElement(state, { mainIndex: mainIndex + 1 });
  },
  addEmptyContainer(state, { mainIndex, structIndex, colIndex }) {
    if (
      !state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ].containers.length
    ) {
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ].containers.push(getEmptyContainers(state));
    }
  },
  setSelected(state, selected) {
    setSelectedElement(state, selected);
  },
  removeEmptyContainer(state, { mainIndex, structIndex, colIndex }) {
    if (
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ].containers.length === 2
    ) {
      if (
        !state.htmlJson.contents[mainIndex].structures[structIndex].columns[
          colIndex
        ].containers[0].elements.length
      ) {
        state.htmlJson.contents[mainIndex].structures[structIndex].columns[
          colIndex
        ].containers.splice(0, 1);
      } else if (
        !state.htmlJson.contents[mainIndex].structures[structIndex].columns[
          colIndex
        ].containers[1].elements.length
      ) {
        state.htmlJson.contents[mainIndex].structures[structIndex].columns[
          colIndex
        ].containers.splice(1, 1);
      }
    }
  },
  removeEmptyContainerOnElement(
    state,
    { mainIndex, structIndex, colIndex, contIndex }
  ) {
    if (
      state.htmlJson.contents[mainIndex].structures[structIndex].columns[
        colIndex
      ].containers.length > 1
    ) {
      if (
        !state.htmlJson.contents[mainIndex].structures[structIndex].columns[
          colIndex
        ].containers[contIndex].elements.length
      ) {
        state.htmlJson.contents[mainIndex].structures[structIndex].columns[
          colIndex
        ].containers.splice(contIndex, 1);
      }
    }
  },
  loadOldEditorJson(state, htmlJson) {
    state.htmlJson.properties = { ...htmlJson.properties };
    state.mainIndexMax =
      state.structIndexMax =
      state.contIndexMax =
      state.colIndexMax =
      state.elIndexMax =
        0;
    state.htmlJson.contents = [];
    htmlJson.contents.forEach((content) => {
      state.htmlJson.contents.push(copyMainBlock(state, content));
    });
  },
  setElementDefaultProps(state, elementDefaultProps) {
    state.elementDefaultProps = {
      ...state.elementDefaultProps,
      ...elementDefaultProps,
    };
  },
  loadHtmlJson(state, htmlJson) {
    Object.assign(state, getState());
    state.htmlJson.contents = htmlJson.contents.map((content) => {
      return copyMainBlock(state, content);
    });
    state.htmlJson.properties = JSON.parse(JSON.stringify(htmlJson.properties));
  },
};

export default {
  namespaced: true,
  state: getState(),
  actions,
  getters,
  mutations,
};
