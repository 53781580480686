<template>
  <div class="dd-list-accrodion single individual" role="tablist">
    <b-card no-body>
      <b-card-body class="dd-min-height">
        <bread-crumb />
        <b-row>
          <b-col>
            <size-property
              label="spacer height"
              :max="800"
              :value="value.height"
              @input="updateProps('height', $event)"
              >Height</size-property
            >
          </b-col>
          <b-col cols="6">
            <color-picker
              label="Background color"
              :value="value.backgroundColor"
              @input="updateProps('backgroundColor', $event)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ruler class="dd-mx--20" />
            <hide-element
              :value="value.hideElement"
              @input="updateProps('hideElement', $event)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import ColorPicker from "../Controls/ColorPicker.vue";
import SizeProperty from "../Properties/SizeProperty.vue";
import HideElement from "../Properties/HideElement.vue";
export default {
  name: "SpacerSettings",
  components: {
    ColorPicker,
    SizeProperty,
    HideElement,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    updateProps(property, value) {
      this.$store.dispatch("ddb/updateElementProperty", { property, value });
    },
  },
};
</script>
