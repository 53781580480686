<template>
  <div class="dd-btn-ico-group dd-font-settings">
    <a
      href="#"
      @click.prevent="
        setVal('fontWeight', fontWeight === 'bold' ? 'normal' : 'bold')
      "
      :class="{ active: fontWeight == 'bold' }"
      v-b-tooltip.hover
      title="Bold"
    >
      <img src="../../assets/img/icon/font/font-bold.svg" width="18" alt="B" />
    </a>
    <a
      href="#"
      @click.prevent="
        setVal('fontStyle', fontStyle === 'italic' ? 'normal' : 'italic')
      "
      :class="{ active: fontStyle == 'italic' }"
      v-b-tooltip.hover
      title="Italic"
    >
      <img
        src="../../assets/img/icon/font/font-italics.svg"
        width="18"
        alt="I"
      />
    </a>
    <a
      href="#"
      @click.prevent="setTextDecoration('underline')"
      :class="{ active: textDecoration.includes('underline') }"
      v-b-tooltip.hover
      title="Underline"
    >
      <img
        src="../../assets/img/icon/font/font-underline.svg"
        width="18"
        alt="U"
      />
    </a>
    <a
      href="#"
      @click.prevent="setTextDecoration('line-through')"
      :class="{ active: textDecoration.includes('line-through') }"
      v-b-tooltip.hover
      title="Strikethrough"
    >
      <img
        src="../../assets/img/icon/font/font-strikethrough.svg"
        width="18"
        alt="ST"
      />
    </a>
    <a
      href="#"
      @click.prevent="setTextDecoration('overline')"
      :class="{ active: textDecoration.includes('overline') }"
      v-b-tooltip.hover
      title="Overline"
    >
      <img
        src="../../assets/img/icon/font/font-overline.svg"
        width="18"
        alt="OL"
      />
    </a>
  </div>
</template>
<script>
export default {
  name: "FontSettings",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fontWeight: "normal",
      fontStyle: "normal",
      textDecoration: [],
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
  },
  created() {
    this.setValue(this.value);
  },
  methods: {
    setVal(key, value) {
      this[key] = value;
      this.$emit("change", { key, value });
    },
    setTextDecoration(value) {
      if (this.textDecoration.includes(value)) {
        this.textDecoration = this.textDecoration.filter((e) => e !== value);
      } else {
        this.textDecoration.push(value);
      }
      this.$emit("change", {
        key: "textDecoration",
        value: this.textDecoration.length
          ? this.textDecoration.join(" ")
          : "none",
      });
    },
    setValue(value) {
      this.fontWeight = value.fontWeight || "normal";
      this.fontStyle = value.fontStyle || "normal";
      this.textDecoration =
        !value.textDecoration || value.textDecoration === "none"
          ? []
          : value.textDecoration.split(" ");
    },
  },
};
</script>
