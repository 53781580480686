<template>
  <div>
    <b-row align-v="center" class="dd-mb-10">
      <b-col>
        <p class="dd-label mb-0">Border radius</p>
      </b-col>
      <b-col cols="auto" class="ml-auto dd-advance-col">
        <b-link :class="{ active: advanceOption }" @click="changeAdvanced">
          <b-row no-gutters align-v="center">
            <b-col class="text-right">
              <span class="dd-text-advance">Advance option</span>
            </b-col>
            <b-col align-self="center" cols="auto" class="ml-auto">
              <span class="ico">
                <img src="../../assets/img/icon/ico-chevron.svg"
              /></span>
            </b-col>
          </b-row>
        </b-link>
      </b-col>
    </b-row>
    <div v-if="!advanceOption">
      <p class="dd-label-light">All</p>
      <b-row class="dd-mb-20">
        <b-col cols="6">
          <count-range
            :label="label"
            v-model="all"
            :min="0"
            :max="100"
            :inc-by="incBy"
          >
          </count-range>
        </b-col>
      </b-row>
    </div>
    <transition name="fade">
      <b-row v-if="advanceOption" class="dd-mb-20">
        <b-col cols="6" class="dd-mb-10">
          <p class="dd-label-light">Top left</p>
          <count-range
            :label="'top left ' + label"
            v-model="result.topLeft"
            :min="0"
            :max="100"
            :inc-by="incBy"
          >
          </count-range>
        </b-col>
        <b-col cols="6" class="dd-mb-10">
          <p class="dd-label-light">Top right</p>
          <count-range
            :label="'top right ' + label"
            v-model="result.topRight"
            :min="0"
            :max="100"
            :inc-by="incBy"
          >
          </count-range>
        </b-col>
        <b-col cols="6">
          <p class="dd-label-light">Bottom left</p>
          <count-range
            :label="'bottom left ' + label"
            v-model="result.bottomLeft"
            :min="0"
            :max="100"
            :inc-by="incBy"
          >
          </count-range>
        </b-col>
        <b-col cols="6">
          <p class="dd-label-light">Bottom right</p>
          <count-range
            :label="'bottom right ' + label"
            v-model="result.bottomRight"
            :min="0"
            :max="100"
            :inc-by="incBy"
          >
          </count-range>
        </b-col>
      </b-row>
    </transition>
  </div>
</template>
<script>
import CountRange from "../Controls/CountRange.vue";
export default {
  name: "BorderRadius",
  props: {
    incBy: { type: Number, default: 1 },
    value: { type: [Object, String], default: "0px" },
  },
  components: { CountRange },
  data() {
    return {
      width: 0,
      all: 0,
      result: {},
      label: "border radius",
      AdvanceOption: false,
    };
  },
  watch: {
    all(val) {
      val = parseInt(val);
      if (isNaN(val)) val = 0;
      var resval = val + "px";
      if (JSON.stringify(resval) !== JSON.stringify(this.value)) {
        this.$emit("input", resval);
      }
    },
    result: {
      handler(val) {
        var length = Object.keys(val).length;
        if (length > 0) {
          const res = {};
          for (const key in val) {
            val[key] = parseInt(val[key]);
            if (isNaN(val[key])) val[key] = 0;
            res[key] = val[key] + "px";
          }
          if (JSON.stringify(res) !== JSON.stringify(this.value)) {
            this.$emit("input", res);
          }
        }
      },
      deep: true,
    },
    value(val) {
      this.setValue(val);
    },
  },
  created() {
    this.setValue(this.value);
  },
  methods: {
    changeAdvanced() {
      this.advanceOption = !this.advanceOption;
      this.setEqual(this.advanceOption);
    },
    setEqual(val) {
      var vm = this;
      var value = 0;
      var padVal = {};
      if (val) {
        value = vm.all ? parseInt(vm.all) : 0;
        vm.all = "";
        padVal["topLeft"] = value;
        padVal["topRight"] = value;
        padVal["bottomLeft"] = value;
        padVal["bottomRight"] = value;
        vm.result = { ...padVal };
      } else {
        var length = Object.keys(vm.result).length;
        let i = 1;
        var arraydiff = 0;
        var firstval = 0;
        if (length === 4) {
          arraydiff = 1;
          for (const key in vm.result) {
            if (i === 1) {
              firstval = vm.result[key];
            }
            if (firstval !== vm.result[key]) {
              arraydiff = 2;
            }
            i++;
          }
        }
        vm.result = {};
        if (arraydiff === 1) {
          vm.all = firstval;
        } else {
          vm.all = 0;
        }
      }
    },
    setValue(val) {
      if (!val) {
        val = "0px";
      }
      var paddingType = typeof val;
      var vm = this;
      if (paddingType === "string") {
        vm.result = {};
        vm.all = parseInt(val);
        vm.advanceOption = false;
      } else {
        vm.all = "";
        var padVal = {};
        for (const key in val) {
          padVal[key] = parseInt(val[key]);
        }
        vm.result = { ...padVal };
        vm.advanceOption = true;
      }
    },
  },
};
</script>
