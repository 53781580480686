<template>
  <a
    href="javascript:void(0)"
    class="dd-add-btn-info"
    :class="'dd-add-structure-' + position"
    v-b-tooltip.hover
    :title="'Add column ' + tooltip"
  >
    <img src="../../assets/img/icon/add-ico-w.svg" width="9" alt="" />
  </a>
</template>
<script>
export default {
  props: {
    position: {
      type: String,
      default: "left",
    },
    tooltip: {
      type: String,
      default: "left",
    },
  },
};
</script>
