<template>
  <div class="dd-mb-20">
    <dd-label><slot>Background color</slot></dd-label>
    <b-row>
      <b-col :cols="cols">
        <color-picker :value="value" @input="$emit('input', $event)" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "BackgroundColor",
  props: {
    value: { type: String, default: "transparent" },
    cols: { type: String, default: "6" },
  },
};
</script>
