<template>
  <a
    v-if="!props.isPreview || props.src"
    @click="onClick"
    target="_blank"
    :href="props.link ? htmlEncode(props.link) : false"
    :class="{ rollover: props.rolloverEffect && props.rolloverSrc }"
  >
    <img
      v-if="props.src"
      :src="props.src"
      :alt="props.alt || ''"
      :width="width"
      :height="
        props.imageSize == 'custom' && props.proportion === false
          ? height
          : false
      "
      :title="props.alt ? props.alt : null"
      :class="{
        'rollover-first': props.rolloverEffect,
        'adapt-img': props.responsive !== false,
      }"
      :style="{ display: 'block', ...maxWidthStyle }"
      @load="imageOnload"
    />
    <img
      class="mld-empty-img"
      v-else
      :src="defaultSrc"
      width="100%"
      height="100"
    />
    <div v-if="props.rolloverEffect && props.rolloverSrc">
      <img
        :src="props.rolloverSrc"
        :alt="props.alt || ''"
        :width="width"
        :height="
          props.imageSize == 'custom' && props.proportion === false
            ? height
            : false
        "
        :title="props.alt ? props.alt : null"
        class="rollover-second"
        :class="{ 'adapt-img': props.responsive !== false }"
        :style="{ display: 'none', ...maxWidthStyle }"
      />
    </div>
  </a>
</template>
<script>
import {
  getPadding,
  imageMaxHeight,
  imageHeight,
  imageWidth,
  getColumnWidth,
} from "../../plugins/JsonHelper.js";
export default {
  name: "DdImage",
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      defaultSrc:
        (process.env.VUE_APP_VUE2_APP_URL || "") + "/img/default-img.png",
    };
  },
  computed: {
    isFullWidth() {
      return (
        this.$store.state.ddb.htmlJson.properties.containerType === "fullwidth"
      );
    },
    maxWidth() {
      if (this.isFullWidth) {
        return 1500;
      }
      const width =
        getColumnWidth(this.props.container, this.props.column) -
        getPadding(this.props.padding);
      return width < 1 ? 1 : width;
    },
    maxHeight() {
      if (this.isFullWidth) {
        return 1500;
      }
      const height = imageMaxHeight(this.props, this.maxWidth);
      if (!height) {
        return null;
      }
      return height < 1 ? 1 : height;
    },
    maxWidthStyle() {
      if (
        this.isFullWidth &&
        ["exact", "custom"].includes(this.props.imageSize)
      ) {
        return { maxWidth: "100%" };
      }
      return {};
    },
    width() {
      if (this.isFullWidth && this.props.imageSize === "auto") {
        return "100%";
      }
      if (this.isFullWidth && this.props.imageSize === "exact") {
        return false;
      }
      return imageWidth(this.props, this.maxWidth);
    },
    height() {
      return imageHeight(this.props, this.maxHeight);
    },
  },
  methods: {
    onClick(e) {
      if (!this.props.isPreview) {
        e.preventDefault();
      }
    },
    imageOnload(e) {
      this.$emit("update", {
        originalHeight: e.target.naturalHeight,
        originalWidth: e.target.naturalWidth,
      });
    },
  },
};
</script>
