<template>
  <div class="dd-list-accrodion single individual" role="tablist">
    <b-card no-body>
      <b-collapse visible id="toggle-general-button-settings" role="tabpanel">
        <b-card-body>
          <bread-crumb />
          <b-row>
            <b-col>
              <single-border-settings
                label="divider height"
                :min-value="0"
                :max-value="40"
                :value="value.border || ''"
                @input="updateProps('border', $event)"
                >Style</single-border-settings
              >
              <ruler />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <color-picker
                :value="value.backgroundColor || '#046DFF'"
                @input="updateProps('backgroundColor', $event)"
                label="Background color"
              />
            </b-col>
          </b-row>
          <ruler />
          <b-row>
            <b-col cols="6">
              <size-property
                label="divider width"
                :min="5"
                :max="100"
                :value="parseInt(value.width) || 100"
                @input="updateProps('width', $event + '%')"
              />
            </b-col>
          </b-row>
          <ruler />
          <b-row>
            <b-col>
              <align-horizontal
                :value="value || {}"
                @change-input="updateProps($event.key, $event.value)"
              />
            </b-col>
          </b-row>
          <ruler />
          <b-row>
            <b-col>
              <padding-all
                :value="value.padding || ''"
                @input="updateProps('padding', $event)"
                ><slot>Padding</slot></padding-all
              >
              <ruler />
              <responsive-divider
                :value="value.responsive || ''"
                @input="updateProps('responsive', $event)"
              />
            </b-col>
          </b-row>
          <ruler class="dd-mx--20" />
          <b-row>
            <b-col>
              <hide-element
                :value="value.hideElement || ''"
                @input="updateProps('hideElement', $event)"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import ColorPicker from "../Controls/ColorPicker.vue";
// import BorderSettings from '../Properties/BorderSettings.vue'
import SizeProperty from "../Properties/SizeProperty.vue";
import AlignHorizontal from "../Properties/AlignHorizontal.vue";
import ResponsiveDivider from "../Properties/ResponsiveDivider.vue";
import HideElement from "../Properties/HideElement.vue";
import SingleBorderSettings from "../Properties/SingleBorderSettings.vue";
export default {
  name: "DividerSettings",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    ColorPicker,
    SingleBorderSettings,
    SizeProperty,
    AlignHorizontal,
    ResponsiveDivider,
    HideElement,
  },
  data() {
    return {};
  },
  methods: {
    updateProps(prop, val) {
      this.$store.dispatch("ddb/updateElementProperty", {
        property: prop,
        value: val,
      });
    },
  },
};
</script>
