<template>
  <div>
    <b-row v-if="!hideHeader" align-v="center" class="dd-mb-10">
      <b-col>
        <dd-label class="mb-0">
          <slot>Internal padding</slot>
        </dd-label>
      </b-col>
      <advance-option :active="advanceOption" @click="changeAdvanced" />
    </b-row>
    <b-row v-if="!advanceOption" align-v="center">
      <b-col>
        <b-row>
          <b-col cols="6">
            <dd-label-light>
              {{ hideLeftRight ? label + " top/bottom" : "All" }}
            </dd-label-light>
            <count-range
              :label="label"
              v-model="all"
              :min="0"
              :max="maxValue"
              :inc-by="incBy"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <transition name="fade">
      <b-row v-if="advanceOption">
        <b-col cols="6" class="dd-mb-10">
          <dd-label-light> {{ label }} top </dd-label-light>
          <count-range
            :label="'top ' + label"
            v-model="result.top"
            :min="0"
            :max="maxValue"
            :inc-by="incBy"
          />
        </b-col>
        <b-col cols="6" class="dd-mb-10" v-if="!hideLeftRight">
          <dd-label-light> {{ label }} left </dd-label-light>
          <count-range
            :label="'left ' + label"
            v-model="result.left"
            :min="0"
            :max="maxValue"
            :inc-by="incBy"
          >
          </count-range>
        </b-col>
        <b-col cols="6">
          <dd-label-light> {{ label }} bottom </dd-label-light>
          <count-range
            :label="'bottom ' + label"
            v-model="result.bottom"
            :min="0"
            :max="maxValue"
            :inc-by="incBy"
          >
          </count-range>
        </b-col>
        <b-col cols="6" v-if="!hideLeftRight">
          <dd-label-light> {{ label }} right </dd-label-light>
          <count-range
            :label="'right ' + label"
            v-model="result.right"
            :min="0"
            :max="maxValue"
            :inc-by="incBy"
          >
          </count-range>
        </b-col>
      </b-row>
    </transition>
  </div>
</template>
<script>
import CountRange from "../Controls/CountRange.vue";
export default {
  name: "PaddingAll",
  props: {
    hideHeader: { type: Boolean, default: false },
    showAdvance: { type: Boolean, default: false },
    hideLeftRight: { type: Boolean, default: false },
    incBy: { type: Number, default: 1 },
    label: { type: String, default: "Padding" },
    value: { type: [Object, String], default: "0px" },
    maxValue: { type: Number, default: 100 },
  },
  components: { CountRange },
  watch: {
    showAdvance(val) {
      this.setEqual(val);
      this.advanceOption = val;
    },
    value(val) {
      this.setValue(val);
    },
    all(val) {
      val = parseInt(val);
      if (isNaN(val)) val = 0;
      if (!this.advanceOption && this.value !== val + "px") {
        this.$emit("input", val + "px");
      }
    },
    result: {
      handler(val) {
        const res = {};
        for (const key in val) {
          val[key] = parseInt(val[key]);
          if (isNaN(val[key])) val[key] = 0;
          res[key] = val[key] + "px";
        }
        if (
          JSON.stringify(res) !== JSON.stringify(this.value) &&
          this.advanceOption
        ) {
          this.$emit("input", res);
        }
      },
      deep: true,
    },
  },
  created() {
    this.setValue(this.value);
  },
  methods: {
    changeAdvanced() {
      this.advanceOption = !this.advanceOption;
      this.setEqual(this.advanceOption);
    },
    setEqual(val) {
      var vm = this;
      var value = 0;
      var padVal = {};
      if (val) {
        value = vm.all ? parseInt(vm.all) : 0;
        vm.all = "";
        padVal["top"] = value;
        padVal["bottom"] = value;
        if (!this.hideLeftRight) {
          padVal["left"] = value;
          padVal["right"] = value;
        }
        vm.result = { ...padVal };
      } else {
        var length = Object.keys(vm.result).length;
        let i = 1;
        var arraydiff = 0;
        var firstval = 0;
        if (length === 4) {
          arraydiff = 1;
          for (const key in vm.result) {
            if (i === 1) {
              firstval = vm.result[key];
            }
            if (firstval !== vm.result[key]) {
              arraydiff = 2;
            }
            i++;
          }
        }
        vm.result = {};
        if (arraydiff === 1) {
          vm.all = firstval;
        } else {
          vm.all = 0;
        }
      }
    },
    setValue(val) {
      if (!val) {
        val = "0px";
      }
      if (typeof val === "string") {
        this.all = parseInt(val);
        this.result = { top: this.all, bottom: this.all };
        if (!this.hideLeftRight) {
          this.result.left = this.result.right = this.all;
        }
        this.advanceOption = false;
      } else {
        this.all = "";
        var padVal = {};
        for (const key in val) {
          padVal[key] = parseInt(val[key]);
        }
        this.result = padVal;
        this.advanceOption = true;
      }
    },
  },
  data() {
    return {
      all: 0,
      result: {},
      advanceOption: this.showAdvance,
    };
  },
};
</script>
