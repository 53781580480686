<script>
import Vue from "vue";
import BuilderPreview from "./BuilderPreview.vue";
import { mapGetters } from "vuex";
import { setStyleToHtml } from "../../plugins/HtmlConverter.js";
import { getCSS } from "../../plugins/StyleConverter.js";
import { commonHead } from "../../constants";
export default {
  props: {
    mobilePreview: { type: Boolean, default: false },
  },
  data() {
    return { iApp: null, styleEl: null };
  },
  watch: {
    mobilePreview(val) {
      if (val) {
        this.styleEl.innerHTML =
          "html { transform: scale(0.53) !important; transform-origin: left top !important; overflow-x: visible !important; height: 100% !important; }";
      } else {
        this.styleEl.innerHTML = "";
      }
    },
  },
  computed: {
    ...mapGetters("ddb", {
      commonProps: "getCommonStyles",
      commonHeaderStyles: "getCommonHeaderStyles",
      responsiveStyle: "getMobileResponsiveStyles",
      properties: "getCommonProps",
    }),
    disableResponsive() {
      return (
        this.$store.state.ddb.htmlJson.properties.disableResponsive || false
      );
    },
    mobileResponsive() {
      if (this.disableResponsive) {
        return '*[class="fix-gmail"] {display: none !important;}';
      } else {
        return (
          "@media only screen and (max-width:600px) { " +
          getCSS(this.responsiveStyle) +
          " }"
        );
      }
    },
  },
  beforeDestroy() {
    if (this.iApp) {
      this.iApp.$destroy();
    }
  },
  methods: {
    renderChildren() {
      if (!this.iApp) {
        this.$el.contentWindow.document.open();
        this.$el.contentWindow.document.write(
          '<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">'
        );
        this.$el.contentWindow.document.close();
        const body = this.$el.contentDocument.body;
        const head = this.$el.contentDocument.head;
        head.innerHTML =
          commonHead(this.$ls.get("imageDomain"), this.disableResponsive) +
          "\n" +
          `<link rel="stylesheet" href="` +
          (process.env.VUE_APP_VUE2_APP_URL || "") +
          `/custom-scroll.css">
      <style type="text/css">\n` +
          getCSS(this.commonHeaderStyles) +
          `
      ` +
          this.mobileResponsive +
          `
      </style>`;
        const el = document.createElement("DIV"); // we will mount or nested app to this element
        body.appendChild(el);
        this.styleEl = document.createElement("style");
        this.styleEl.type = "text/css";
        head.appendChild(this.styleEl);
        body.setAttribute(
          "style",
          "width:100%;font-family:" +
            this.properties.fontFamily +
            ";-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;Margin:0"
        );

        const iApp = new Vue({
          name: "IApp",
          comments: true,
          render(h) {
            return h(BuilderPreview);
          },
          store: this.$store,
        });

        iApp.$mount(el); // mount into iframe
        setStyleToHtml(iApp.$el, this.commonProps);

        this.iApp = iApp; // cache instance for later updates
      }
    },
  },
  render(h) {
    return h("iframe", {
      on: { load: this.renderChildren },
    });
  },
};
</script>
