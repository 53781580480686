<template>
  <div>
    <b-button-group class="dd-goto-group">
      <b-button
        :class="value === 'links-images-left' ? 'active' : ''"
        @click="$emit('input', 'links-images-left')"
        variant="light"
        v-b-tooltip.hover
        title="left"
        ><img src="../../assets/img/icon/align/menu-left.svg" width="18" alt=""
      /></b-button>
      <b-button
        :class="value === 'links-images-top' ? 'active' : ''"
        @click="$emit('input', 'links-images-top')"
        variant="light"
        v-b-tooltip.hover
        title="Center"
        ><img
          src="../../assets/img/icon/align/menu-center.svg"
          width="18"
          alt=""
      /></b-button>
      <b-button
        :class="value === 'links-images-right' ? 'active' : ''"
        @click="$emit('input', 'links-images-right')"
        variant="light"
        v-b-tooltip.hover
        title="Right"
        ><img
          src="../../assets/img/icon/align/menu-right.svg"
          width="18"
          alt=""
      /></b-button>
    </b-button-group>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: String, default: "" },
  },
  name: "MenuAlign",
  data() {
    return {};
  },
};
</script>
