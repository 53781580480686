import {
  parseBorderRadius,
  parseBorderStyle,
  parseBorderWidth,
  parseToSingleStyle,
} from "../plugins/StyleConverter.js";
const f = "font";
const t = "text";
const b = "border";
const p = "padding";
const c = "color";
const w = "width";
const ff = f + "Family";
const fs = f + "Size";
const fsl = f + "Style";
const fc = f + "Color";
const fw = f + "Weight";
const td = t + "Decoration";
const br = b + "Radius";
const blk = "block";
const inb = "inline-" + blk;
const hp = "100%";
const zeroPt = "0pt";
const zeroPx = "0px";
const imp = " !important";
const n = "none";
const auto = "auto";
const iht = "inherit";
const nml = "normal";
const ctr = "center";
const spr = {};
spr[p] = "20px";
spr["equalWidth"] = true;
const btn = {
  buttonColor: "#007BFF",
  internalPadding: { top: "10px", bottom: "10px", left: "20px", right: "20px" },
};
btn[fc] = "#FFFFFF";
btn[fs] = "16px";
btn[ff] = "arial,'helvetica neue',helvetica,sans-serif";
btn[fw] = nml;
btn[fsl] = nml;
btn[td] = n;
btn[br] = "5px";
btn[b] = "0px solid #1C3F6F";
const h1 = {};
h1[fs] = "30px";
h1[c] = "#333333";
h1[fsl] = nml;
h1[fw] = nml;
const h2 = { ...h1 };
h2[fs] = "24px";
const h3 = { ...h1 };
h3[fs] = "20px";
const paragraph = { ...h1 };
paragraph[fs] = "16px";
const prps = {
  emailWidth: 600,
  containerType: "",
  textColor: "#000000",
  linkColor: "#1376c8",
  backgroundColor: "#F2F6F8",
  button: btn,
  h1,
  h2,
  h3,
  paragraph,
};
prps[p] = { left: "20px", right: "20px", top: "20px", bottom: "0px" };
prps[ff] = "arial,'helvetica neue',helvetica,sans-serif";
const maxFontSize = 72;
const defaultHtmlJson = {
  contents: [
    {
      props: {},
      structures: [
        {
          props: spr,
          columns: [
            {
              props: { width: 560 },
              containers: [
                {
                  props: {},
                  elements: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  properties: prps,
};
const getCommonStyles = (state) => {
  if (!state.htmlJson || !state.htmlJson.properties) {
    return {};
  }
  const commonProps = state.htmlJson.properties;
  const button = commonProps.button;
  const body = {};
  body[w] = hp;
  body[ff] = commonProps[ff];
  body["-webkit-text-size-adjust"] = hp;
  body["-ms-text-size-adjust"] = hp;
  const table = {};
  const collapse = "collapse";
  table["mso-table-lspace"] = zeroPt;
  table["mso-table-rspace"] = zeroPt;
  table[b + "-" + collapse] = collapse;
  table[b + "Spacing"] = zeroPx;
  const paraSize =
    commonProps.paragraph && commonProps.paragraph.fontSize
      ? commonProps.paragraph.fontSize
      : "16px";
  const h1FontSize =
    commonProps.h1 && commonProps.h1.fontSize
      ? commonProps.h1.fontSize
      : "30px";
  const h2FontSize =
    commonProps.h2 && commonProps.h2.fontSize
      ? commonProps.h2.fontSize
      : "24px";
  const h3FontSize =
    commonProps.h3 && commonProps.h3.fontSize
      ? commonProps.h3.fontSize
      : "20px";
  const commonStyles = {
    body,
    table,
    "table td, body, .ml-wrapper": {
      padding: 0,
      Margin: 0,
    },
    ".ml-content": {
      tableLayout: "fixed" + imp,
      width: hp,
    },
    img: {
      display: blk,
      border: 0,
      outline: n,
      textDecoration: n,
      "-ms-interpolation-mode": "bicubic",
    },
    "p, hr": {
      Margin: 0,
    },
    "h1, h2, h3, h4, h5": {
      Margin: 0,
      lineHeight: commonProps.lineHeight || "150%",
      msoLineHeightRule: "exactly",
      fontFamily: commonProps[ff],
    },
    "p, ul li, ol li, a": {
      "-webkit-text-size-adjust": n,
      "-ms-text-size-adjust": n,
      msoLineHeightRule: "exactly",
    },
    ".ml-left": {
      float: "left",
    },
    ".ml-right": {
      float: "right",
    },
    ".ml-menu td": {
      border: 0,
    },
    ".ml-menu td a img": {
      display: "inline" + imp,
    },
    s: {
      textDecoration: "line-through",
    },
    "ul li, ol li": {
      marginBottom: "15px",
    },
    "p, ul li, ol li": {
      fontFamily: commonProps[ff] || n,
      lineHeight: commonProps.lineHeight || "150%",
    },
    a: {
      textDecoration: "underline",
    },
    ".ml-menu td a": {
      textDecoration: n,
      display: blk,
    },
    ".ml-wrapper": {
      width: hp,
      height: hp,
    },
    ".ml-content-body p, .ml-content-body ul li, .ml-content-body ol li": {
      color: commonProps.textColor || "#000000",
      fontSize: paraSize,
    },
    ".ml-content-body a": {
      color: commonProps.linkColor || "#2cb543",
      // fontSize: paraSize
    },
    h1: commonProps.h1,
    h2: commonProps.h2,
    h3: commonProps.h3,
    ".ml-content-body h1 a": {
      fontSize: h1FontSize,
    },
    ".ml-content-body h2 a": {
      fontSize: h2FontSize,
    },
    ".ml-content-body h3 a": {
      fontSize: h3FontSize,
    },
    p: commonProps.paragraph,
    "a.ml-button, button.ml-button": {
      borderStyle: "solid",
      borderColor: button.buttonColor,
      ...parseBorderWidth(button.internalPadding),
      display: inb,
      background: button.buttonColor,
      ...parseBorderRadius(button[br]),
      fontSize: button[fs],
      fontFamily: button[ff],
      fontWeight: button[fw],
      fontStyle: button[fsl],
      lineHeight: "120%",
      color: button[fc],
      textDecoration: button[td],
      width: auto,
      textAlign: ctr,
    },
    ".ml-button-border": {
      ...parseBorderStyle(button[b]),
      background: button.buttonColor,
      display: inb,
      ...parseBorderRadius(button[br]),
      width: auto,
    },
    ".ml-button img": {
      display: inb,
      verticalAlign: "middle",
    },
    ".ml-desk-hidden": {
      display: n,
      float: "left",
      overflow: "hidden",
      width: 0,
      maxHeight: 0,
      lineHeight: 0,
      msoHide: "all",
    },
  };
  if (commonProps.bottomSpace) {
    const commLineHeight = parseInt(commonProps.lineHeight || "150") / 200;
    commonStyles["p, ul li, ol li"].marginBottom =
      Math.round(parseInt(paraSize) * commLineHeight) + "px";
    commonStyles["h1"].marginBottom =
      Math.round(parseInt(h1FontSize) * commLineHeight) + "px";
    commonStyles["h2"].marginBottom =
      Math.round(parseInt(h2FontSize) * commLineHeight) + "px";
    commonStyles["h3"].marginBottom =
      Math.round(parseInt(h3FontSize) * commLineHeight) + "px";
    delete commonStyles["ul li, ol li"];
  }
  return commonStyles;
};
const getCommonHeaderStyles = (state) => {
  const commonProps = state.htmlJson.properties;
  const styles = {};
  const rollover = ".rollover";
  styles[rollover + ":hover " + rollover + "-first"] = {
    maxHeight: "0px" + imp,
    display: n + imp,
  };
  styles[rollover + ":hover " + rollover + "-second"] = {
    maxHeight: n + imp,
    display: blk + imp,
  };
  styles["#outlook a"] = {
    padding: 0,
  };
  styles[".ml-button"] = {
    msoStylePriority: "100" + imp,
    textDecoration: n,
  };
  styles["a[x-apple-data-detectors]"] = {
    color: iht + imp,
    textDecoration: n + imp,
    fontSize: iht + imp,
    fontFamily: iht + imp,
    fontWeight: iht + imp,
    lineHeight: iht + imp,
  };
  styles[".ml-desk-hidden"] = {
    display: n,
    float: "left",
    overflow: "hidden",
    width: 0,
    maxHeight: 0,
    lineHeight: 0,
    msoHide: "all",
  };
  styles["[data-ogsb] .ml-button"] = {
    borderWidth: "0" + imp,
    padding: parseToSingleStyle(commonProps.button.internalPadding) + imp,
  };
  return styles;
};
const getMobileResponsiveStyles = (state) => {
  const commonProps = state.htmlJson.properties;
  return {
    ".st-br": {
      paddingLeft: "10px" + imp,
      paddingRight: "10px" + imp,
    },
    "p, ul li, ol li, a": {
      lineHeight: (commonProps.lineHeight || "150%") + imp,
    },
    h1: {
      fontSize: "30px" + imp,
      // textAlign: ctr,
      lineHeight: (commonProps.lineHeight || "150%") + imp,
    },
    h2: {
      fontSize: "26px" + imp,
      // textAlign: ctr,
      lineHeight: (commonProps.lineHeight || "150%") + imp,
    },
    h3: {
      fontSize: "20px" + imp,
      // textAlign: ctr,
      lineHeight: (commonProps.lineHeight || "150%") + imp,
    },
    // 'h1 a': {
    //   textAlign: ctr
    // },
    ".ml-content-body h1 a": {
      fontSize: "30px" + imp,
    },
    // 'h2 a': {
    //   textAlign: ctr
    // },
    ".ml-content-body h2 a": {
      fontSize: "26px" + imp,
    },
    // 'h3 a': {
    //   textAlign: ctr
    // },
    ".ml-content-body h3 a": {
      fontSize: "20px" + imp,
    },
    ".ml-menu td a": {
      fontSize: "14px" + imp,
    },
    // '.ml-content-body p, .ml-content-body ul li, .ml-content-body ol li, .ml-content-body a': {
    //   fontSize: '16px' + imp
    // },
    '*[class="gmail-fix"]': {
      display: "none" + imp,
    },
    ".ml-m-txt-c, .ml-m-txt-c h1, .ml-m-txt-c h2, .ml-m-txt-c h3": {
      textAlign: ctr + imp,
    },
    ".ml-m-txt-r, .ml-m-txt-r h1, .ml-m-txt-r h2, .ml-m-txt-r h3": {
      textAlign: "right" + imp,
    },
    ".ml-m-txt-l, .ml-m-txt-l h1, .ml-m-txt-l h2, .ml-m-txt-l h3": {
      textAlign: "left" + imp,
    },
    ".ml-m-txt-r img, .ml-m-txt-c img, .ml-m-txt-l img": {
      display: "inline" + imp,
    },
    ".ml-button-border": {
      display: blk + imp,
    },
    "a.ml-button, button.ml-button": {
      // fontSize: '16px' + imp,
      display: blk + imp,
      borderLeftWidth: "0px" + imp,
      borderRightWidth: "0px" + imp,
    },
    ".ml-adaptive table, .ml-left, .ml-right": {
      width: "100%" + imp,
    },
    ".ml-content table, .ml-content": {
      width: "100%" + imp,
      maxWidth: "600px" + imp,
    },
    ".ml-adapt-td": {
      display: blk + imp,
      width: "100%" + imp,
    },
    ".adapt-img": {
      width: "100%" + imp,
      height: "auto" + imp,
    },
    ".ml-m-p0": {
      padding: "0" + imp,
    },
    ".ml-m-p0r": {
      paddingRight: "0" + imp,
    },
    ".ml-m-p0l": {
      paddingLeft: "0" + imp,
    },
    ".ml-m-p0t": {
      paddingTop: "0" + imp,
    },
    ".ml-m-p0b": {
      paddingBottom: "0" + imp,
    },
    ".ml-m-p20b": {
      paddingBottom: "20px" + imp,
    },
    ".ml-mobile-hidden, .ml-hidden": {
      display: "none" + imp,
    },
    "tr.ml-desk-hidden, td.ml-desk-hidden, table.ml-desk-hidden": {
      width: "auto" + imp,
      overflow: "visible" + imp,
      float: "none" + imp,
      maxHeight: "inherit" + imp,
      lineHeight: "inherit" + imp,
    },
    "tr.ml-desk-hidden": {
      display: "table-row" + imp,
    },
    "table.ml-desk-hidden": {
      display: "table" + imp,
    },
    "td.ml-desk-menu-hidden": {
      display: "table-cell" + imp,
    },
    ".ml-menu td": {
      width: "1%" + imp,
    },
    "table.ml-table-not-adapt, .esd-block-html table": {
      width: "auto" + imp,
    },
    "table.ml-smedia": {
      display: inb + imp,
    },
    "table.ml-smedia td": {
      display: inb + imp,
    },
  };
};
const commonHead = (imageDomain, disableResponsive = false) =>
  `<meta charset="UTF-8">
      ` +
  (disableResponsive
    ? ``
    : `<meta content="width=device-width, initial-scale=1" name="viewport">`) +
  `
      <meta name="x-apple-disable-message-reformatting">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta content="telephone=no" name="format-detection">
      <title></title>
      <!--[if (mso 16)]>
        <style type="text/css">
         a {text-decoration: none;}
        </style>
      <![endif]-->
      <!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]-->
      <!--[if gte mso 9]>
        <xml>
        <o:OfficeDocumentSettings>
        <o:AllowPNG></o:AllowPNG>
        <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
       <![endif]-->
      <style type="text/css">@import url("` +
  imageDomain +
  `/uploads/fonts.css");</style>`;
export {
  defaultHtmlJson,
  getCommonStyles,
  getCommonHeaderStyles,
  getMobileResponsiveStyles,
  commonHead,
  maxFontSize,
};
