<template>
  <b-row align-v="center">
    <b-col>
      <dd-label class="mb-0"><slot /></dd-label>
    </b-col>
    <b-col cols="auto" class="ml-auto text-right">
      <span class="pull-right dd-switch">
        <b-form-checkbox
          v-model="inputVal"
          name="check-button"
          switch
          :value="checkedValue"
          :unchecked-value="uncheckedValue"
          @input="$emit('input', $event)"
        >
        </b-form-checkbox>
      </span>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "DdSwitch",
  props: {
    value: { type: [String, Object, Boolean, Number], default: false },
    checkedValue: { type: [String, Object, Boolean, Number], default: true },
    uncheckedValue: { type: [String, Object, Boolean, Number], default: false },
  },
  data() {
    return {
      inputVal: false,
    };
  },
  created() {
    this.inputVal =
      this.value === this.checkedValue ? this.value : this.uncheckedValue;
  },
  watch: {
    value(val) {
      this.inputVal = val === this.checkedValue ? val : this.uncheckedValue;
    },
  },
};
</script>
