const allowedTagInCapitalCase = [
  "o:officedocumentsettings",
  "o:allowpng",
  "o:pixelsperinch",
  "o:officedocumentsettings",
];
const getFilteredHtml = (html) => {
  var xss = require("xss");
  var whiteListArray = getWhiteListTags();
  var options = {
    whiteList: whiteListArray, // empty, means filter out all tags
    stripIgnoreTag: false, // filter out all HTML not in the whilelist
    stripIgnoreTagBody: ["script"], // the script tag is a special case, we need to filter out its content
    allowCommentTag: true,
    onIgnoreTag: function (tag, val) {
      if (
        tag.toLowerCase() === "!doctype" ||
        tag.indexOf("!--") === 0 ||
        allowedTagInCapitalCase.includes(tag)
      ) {
        return val;
      }
    },
    escapeHtml: function (val) {
      if (val.trim() === "<!--") {
        let split = html.split("<!--");
        if (split.length && split[split.length - 1].indexOf("-->") !== -1) {
          return val;
        }
      } else if (val.trim() === "-->") {
        return val;
      }
      return val.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    },
    onTagAttr: function (tag, name, value, isWhiteAttr) {
      var exceptionArr = [
        "%%unsubscribe%%",
        "%%view_in_browser%%",
        "%%report_abuse%%",
        "%%mailing_preference%%",
      ];
      if (name === "href") {
        for (const key of exceptionArr) {
          if (value.includes(key)) {
            value = key;
          }
        }
      }
      // blocking meta refresh as it can cause spamming
      if (
        tag === "meta" &&
        name === "http-equiv" &&
        value.toLowerCase() === "refresh"
      ) {
        return "";
      } else if (
        isWhiteAttr &&
        (exceptionArr.includes(value) || name.toLowerCase() === "style")
      ) {
        value = value.replace(/\s\s+/g, " ");
        return name + '="' + value + '"';
      } else if (isWhiteAttr && !value) {
        return name + '=" "';
      }
    },
  };
  var myxss = new xss.FilterXSS(options);
  return myxss.process(html);
};

const getWhiteListTags = () => {
  var whiteListedTags = {
    a: [
      "target",
      "href",
      "download",
      "hreflang",
      "media",
      "rel",
      "type",
      "align",
    ],
    abbr: [],
    address: [],
    area: [
      "shape",
      "coords",
      "href",
      "alt",
      "download",
      "hreflang",
      "media",
      "rel",
      "target",
    ],
    article: [],
    aside: [],
    // audio: ['autoplay', 'controls', 'loop', 'preload', 'src', 'muted'], // not allowed in mail template
    b: [],
    base: ["href", "target"],
    bdi: ["dir"],
    bdo: ["dir"],
    big: [],
    blockquote: ["cite"],
    body: [],
    br: [],
    button: [
      "autofocus",
      "disabled",
      "form",
      "formaction",
      "name",
      "type",
      "value",
    ],
    canvas: ["height", "width"],
    caption: [],
    center: [],
    cite: [],
    code: [],
    col: ["align", "valign", "span", "width"],
    colgroup: ["align", "valign", "span", "width"],
    data: [],
    datalist: [],
    dd: [],
    del: ["datetime", "cite"],
    details: ["open"],
    dfn: [],
    dialog: [],
    div: ["itemscope", "itemtype", "itemprop", "align"],
    dl: [],
    dt: [],
    em: [],
    // embed: ['height', 'src', 'type', 'width'], // not allowed in mail template
    fieldset: ["disabled", "form", "name"],
    figure: [],
    figcaption: [],
    font: ["color", "size", "face"],
    footer: [],
    form: [], // 'accept-charset', 'action', 'autocomplete', 'enctype', 'method', 'name', 'novalidate', 'target'
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    head: [],
    header: [],
    hgroup: [],
    hr: [],
    html: ["xmlns", "xmlns:o", "xmlns:v"],
    i: ["align"],
    img: [
      "src",
      "alt",
      "width",
      "height",
      "align",
      "ismap",
      "sizes",
      "srcset",
      "usemap",
      "border",
      "line-height",
    ],
    input: [
      "accept",
      "alt",
      "autocomplete",
      "autofocus",
      "checked",
      "dirname",
      "disabled",
      "form",
      "formaction",
      "height",
      "list",
      "max",
      "maxlength",
      "min",
      "multiple",
      "name",
      "pattern",
      "placeholder",
      "readonly",
      "required",
      "size",
      "src",
      "step",
      "type",
      "value",
      "width",
    ],
    ins: ["datetime", "cite"],
    kbd: [],
    keygen: [],
    label: ["for", "form"],
    legend: [],
    li: ["value"],
    link: ["href", "hreflang", "media", "rel", "sizes", "type", "itemprop"],
    main: [],
    map: ["name"],
    mark: [],
    // marquee: ['behavior', 'bgcolor', 'direction', 'height', 'hspace', 'loop', 'scrollamount', 'scrolldelay', 'truespeed', 'vspace', 'width'], //removed because tag deprecated
    menu: ["type"],
    menuitem: [],
    meta: ["charset", "content", "http-equiv", "name", "itemprop"],
    meter: ["form", "high", "low", "max", "min", "optimum", "value"],
    nav: [],
    noscript: [],
    /**
     * removed support because
     * <object data=data:text/html;base64,PHN2Zy9vbmxvYWQ9YWxlcnQoMik+></object> alerts '2'
     * data:text/html;base64,PHN2Zy9vbmxvYWQ9YWxlcnQoMik+ decoded as <svg/onload=alert(2)>
     * also it is used for embed multi media contents which is not allowed in mail
     */
    // object: ['data', 'form', 'height', 'name', 'type', 'usemap', 'width'],
    ol: ["reversed", "start"],
    optgroup: ["disabled", "label"],
    option: ["disabled", "label", "selected", "value"],
    output: ["for", "form", "name"],
    p: [],
    param: ["name", "value"],
    pre: [],
    progress: ["max", "value"],
    q: ["cite"],
    rb: [],
    rp: [],
    rt: [],
    rtc: [],
    ruby: [],
    s: [],
    samp: [],
    section: [],
    select: [
      "autofocus",
      "disabled",
      "form",
      "multiple",
      "name",
      "required",
      "size",
    ],
    small: [],
    source: ["media", "sizes", "src", "srcset", "type"],
    span: ["align"],
    strong: [],
    strike: [],
    style: ["media", "type"],
    sub: [],
    summary: [],
    sup: [],
    table: [
      "role",
      "width",
      "height",
      "border",
      "align",
      "valign",
      "cellpadding",
      "cellspacing",
      "bgcolor",
      "background",
    ],
    tbody: ["align", "valign", "background"],
    td: [
      "width",
      "rowspan",
      "colspan",
      "align",
      "valign",
      "headers",
      "bgcolor",
      "height",
      "background",
    ],
    template: [],
    textarea: [
      "wrap",
      "autofocus",
      "cols",
      "dirname",
      "disabled",
      "form",
      "maxlength",
      "name",
      "placeholder",
      "readonly",
      "required",
      "rows",
    ],
    tfoot: ["align", "valign"],
    th: [
      "width",
      "rowspan",
      "colspan",
      "align",
      "valign",
      "headers",
      "scope",
      "background",
    ],
    thead: ["align", "valign", "background"],
    time: ["datetime"],
    title: [],
    tr: ["rowspan", "align", "valign", "bgcolor", "background"],
    track: ["default", "kind", "label", "src", "srcdoc", "srclang"],
    u: [],
    ul: [],
    var: [],
    // video: ['autoplay', 'controls', 'loop', 'preload', 'src', 'height', 'width', 'muted', 'poster'], // not allowed in mail template
    wbr: [],
  };

  // add or remove something here
  for (var key in whiteListedTags) {
    if (!Object.prototype.hasOwnProperty.call(whiteListedTags, key)) continue;

    whiteListedTags[key].push("id", "class", "style", "title");
  }
  whiteListedTags["xml"] = [];
  whiteListedTags["![endif]--"] = [];
  whiteListedTags["v:roundrect"] = [
    "xmlns:v",
    "xmlns:w",
    "href",
    "style",
    "arcsize",
    "stroke",
    "fillcolor",
  ];
  whiteListedTags["w:anchorlock"] = [];
  whiteListedTags["v:background"] = ["xmlns:v", "fill"];
  whiteListedTags["v:fill"] = ["type", "color"];

  return whiteListedTags;
};

export { getWhiteListTags, getFilteredHtml };
