<template comments>
  <tr
    :class="{
      'ml-desk-hidden': props.hideElement === 'hide-pc',
      'ml-mobile-hidden': props.hideElement === 'hide-mobile',
    }"
  >
    <td
      class="mld-structure"
      align="left"
      :bgcolor="setColor(props.backgroundColor)"
      :style="{
        backgroundColor: setColor(props.backgroundColor),
        ...backgroundImage,
        ...padding,
      }"
    >
      <comment v-if="!isFullWidth" :value="msoStart" />
      <slot />
      <comment v-if="!isFullWidth" :value="msoEnd" />
    </td>
  </tr>
</template>
<script>
import { parseStyleProperty } from "../../plugins/StyleConverter.js";
import Comment from "../Builder/Comment";
export default {
  name: "StructurePreview",
  components: {
    Comment,
  },
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      msoEnd: "[if mso]></tr></table><![endif]",
    };
  },
  computed: {
    msoStart() {
      return (
        '[if mso]><table style="width:' +
        this.columnWidth +
        'px" cellpadding="0" cellspacing="0"><tr><![endif]'
      );
    },
    columnWidth() {
      let width = this.columns.reduce((total, col) => {
        return total + (col.props ? col.props.width || 0 : 0);
      }, 0);
      if (!width) {
        return this.properties.emailWidth;
      }
      return width + (this.columns.length - 1) * (this.props.indent || 0);
    },
    backgroundImage() {
      if (this.props.backgroundImage) {
        return {
          backgroundImage: "url(" + this.props.backgroundImage + ")",
          backgroundRepeat: this.props.backgroundRepeat || "no-repeat",
          backgroundPosition: this.props.backgroundPosition || "center top",
        };
      }
      return {};
    },
    padding() {
      return parseStyleProperty(this.props.padding);
    },
    properties() {
      return this.$store.state.ddb.htmlJson &&
        this.$store.state.ddb.htmlJson.properties
        ? this.$store.state.ddb.htmlJson.properties
        : {};
    },
    isFullWidth() {
      return this.properties.containerType === "fullwidth";
    },
  },
};
</script>
