var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"mld-container",on:{"mouseenter":function($event){return _vm.mouseAction(true)},"mouseleave":function($event){return _vm.mouseAction(false)},"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('td',{directives:[{name:"alignment",rawName:"v-alignment"}],staticClass:"mld-container-frame mld-frame mld-hover mldev-disable-select",class:{
      'mld-hidden-element': _vm.props.hideElement,
      'mld-activated': _vm.activated && !_vm.selected,
      'mld-active': _vm.selected,
      'mld-light': _vm.selected && _vm.state.selected.includes('cont-'),
      'ml-m-p20b': _vm.align == 'left' && _vm.paddingMobile,
      ..._vm.screenAlignment,
    },style:({ margin: '0px', padding: '0px', width: _vm.width }),attrs:{"align":"center","valign":"top"},on:{"align":_vm.setScreenAlign}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.notEmpty),expression:"notEmpty"}],staticClass:"mld-structure-type"},[_vm._v("Container")]),_c('action-buttons',{directives:[{name:"show",rawName:"v-show",value:(_vm.notEmpty),expression:"notEmpty"}],attrs:{"selected":_vm.selected},on:{"delete":_vm.onDelete,"copy":_vm.onCopy}}),_c('draggable',{staticStyle:{"width":"100%"},style:({
        ..._vm.padding,
        ..._vm.border,
        ..._vm.borderRadius,
        backgroundColor: _vm.setColor(_vm.props.backgroundColor),
        ..._vm.backgroundImage,
      }),attrs:{"draggable":".mld-element","direction":"vertical","handle":".dragg-handle","group":"element","tag":"dd-table","invertSwap":true,"value":_vm.value,"setData":_vm.setData},on:{"input":_vm.emitter,"start":function($event){return _vm.$emit('start', $event)},"end":function($event){return _vm.$emit('end', $event)},"remove":_vm.onRemove,"add":_vm.onAdd,"update":_vm.onUpdate}},[_vm._t("default")],2),(_vm.props.hideElement)?_c('div',{staticClass:"mld-hidden-status"},[_c('span',{class:_vm.props.hideElement === 'hide-mobile'
            ? 'ms-icon-hide-on-mobile'
            : 'ms-icon-hide-on-desktop'})]):_vm._e()],1),(_vm.columnIndent)?_c('td',{staticClass:"ml-hidden",staticStyle:{"padding":"0","margin":"0"},style:({ width: parseInt(_vm.columnIndent) + 'px' })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }