<template comments>
  <wrapper v-model="htmlJson.contents" @start="onStart" @end="onEnd">
    <template v-for="(content, mainIndex) in htmlJson.contents">
      <main-block
        v-if="content"
        :key="'content-' + content.key"
        :hover="hover || hoverStuct"
        :prop-key="content.key"
        :props="content.props"
        :mainIndex="mainIndex"
        :delete-disabled="htmlJson.contents.length === 1"
        v-model="content.structures"
        @confirmDelete="confirmDelete"
        @start="onStart"
        @end="onEnd"
        @click="$emit('click')"
      >
        <template v-for="(structure, structIndex) in content.structures">
          <structure
            v-if="structure"
            :key="'structure-' + (structure.key || getRandom())"
            :hover="hover"
            :prop-key="structure.key"
            :props="structure.props"
            :mainIndex="mainIndex"
            :structIndex="structIndex"
            :delete-disabled="content.structures.length === 1"
            @confirmDelete="confirmDelete"
            @hover="hoverStuct = $event"
            @click="$emit('click')"
          >
            <template v-for="(column, colIndex) in structure.columns">
              <container-frame
                v-if="column && column.hasOwnProperty('key')"
                v-model="column.containers"
                :column-props="column.props"
                :key="'column-' + (column.key || getRandom())"
                :mainIndex="mainIndex"
                :structIndex="structIndex"
                :colIndex="colIndex"
                :align="getColumAlign(colIndex, structure.columns.length)"
                @start="onStart"
                @end="onEnd"
              >
                <template v-for="(container, contIndex) in column.containers">
                  <container
                    v-if="container"
                    v-model="container.elements"
                    :key="'container-' + (container.key || getRandom())"
                    :prop-key="container.key"
                    :main-index="mainIndex"
                    :struct-index="structIndex"
                    :col-index="colIndex"
                    :cont-index="contIndex"
                    :not-empty="!!container.elements.length"
                    :column-props="column.props"
                    :props="container.props"
                    :column-indent="
                      colIndex + 2 < structure.columns.length
                        ? structure.props.indent
                        : 0
                    "
                    :align="getColumAlign(colIndex, structure.columns.length)"
                    :padding-mobile="
                      structure.props.containerPaddingMobile !== false
                    "
                    @confirmDelete="confirmDelete"
                    @hover="hover = $event"
                    @start="onStart"
                    @end="onEnd"
                    @click="$emit('click')"
                  >
                    <template v-for="(element, elIndex) in container.elements">
                      <element-frame
                        v-if="element && element.hasOwnProperty('key')"
                        :props="element.props"
                        :tag="element.tag"
                        :key="'element' + (element.key || getRandom())"
                        :prop-key="element.key"
                        :mainIndex="mainIndex"
                        :structIndex="structIndex"
                        :colIndex="colIndex"
                        :contIndex="contIndex"
                        :elIndex="elIndex"
                        @confirmDelete="confirmDelete"
                        @click="$emit('click')"
                      >
                        <compoent
                          :is="element.tag"
                          v-model="element.props.value"
                          :props="{
                            ...element.props,
                            container,
                            column,
                            propKey: element.key,
                          }"
                          @update="
                            updateProp(
                              mainIndex,
                              structIndex,
                              colIndex,
                              contIndex,
                              elIndex,
                              $event
                            )
                          "
                        />
                      </element-frame>
                    </template>
                    <empty-container
                      v-show="!container.elements.length"
                      :width="column.props.width"
                      :mainIndex="mainIndex"
                      :structIndex="structIndex"
                      :colIndex="colIndex"
                      :contIndex="contIndex"
                    />
                  </container>
                </template>
              </container-frame>
            </template>
          </structure>
        </template>
      </main-block>
    </template>
  </wrapper>
</template>
<script>
import { mapState } from "vuex";
import Wrapper from "./Wrapper.vue";
import MainBlock from "./MainBlock.vue";
import Structure from "./Structure.vue";
import Container from "./Container.vue";
import ContainerFrame from "./ContainerFrame.vue";
import ElementFrame from "./ElementFrame.vue";
import EmptyContainer from "./EmptyContainer.vue";
import ContentBlocks from "../ContentBlocks";
export default {
  name: "Builder",
  components: {
    Wrapper,
    MainBlock,
    Structure,
    Container,
    ContainerFrame,
    ElementFrame,
    EmptyContainer,
    ...ContentBlocks,
  },
  computed: {
    ...mapState({
      htmlJson: (state) => state.ddb.htmlJson,
    }),
  },
  data() {
    return {
      hover: false,
      hoverStuct: false,
      eleCp: null,
    };
  },
  methods: {
    confirmDelete(data) {
      this.$emit("confirmDelete", data);
    },
    getRandom() {
      return Math.round(Math.random() * 100000000);
    },
    onStart(e, dragging = "drag") {
      this.$store.commit("ddb/setDragging", dragging);
      this.eleCp = e.from.insertBefore(e.item.cloneNode(true), e.item);
      const elaaa = document.createElement("DIV");
      elaaa.innerHTML = '<div class="mld-plc-label"> Drop here </div>';
      elaaa.classList.add("mld-drop-here-block");
      elaaa.classList.add("mld-placement");
      this.eleCp.classList.remove("sortable-chosen");
      this.eleCp.classList.remove("sortable-ghost");
      e.item.insertBefore(elaaa, e.item.firstChild);
      // this.$store.dispatch('ddb/setSelected', '')
    },
    onEnd(e) {
      this.$store.commit("ddb/setDragging", "");
      e.item.firstChild.remove();
      this.eleCp.remove();
    },
    updateProp(mainIndex, structIndex, colIndex, contIndex, elIndex, props) {
      this.$store.dispatch("ddb/mergeElementProperty", {
        mainIndex,
        structIndex,
        colIndex,
        contIndex,
        elIndex,
        props,
      });
    },
    getColumAlign(columnIndex, columnLength) {
      if (columnIndex > 0 && columnIndex === columnLength - 1) {
        return "right";
      }
      return "left";
    },
  },
};
</script>
