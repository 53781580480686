<template>
  <div>
    <dd-label>Personalization</dd-label>
    <multiselect
      v-model="personalizationModel"
      placeholder="Search personalization"
      label="label"
      :options="personalisation"
      :internal-search="false"
      :reset-after="true"
      @search-change="resetPersonalization"
      @select="setPersonalization"
      class="dd-multiselect dd-select-radius-8"
    >
      <template slot="singleLabel" slot-scope="props">
        <span>{{ props.option.label }}</span>
      </template>
      <template slot="option" slot-scope="props">
        <span :style="{ 'font-family': [props.option.value] }">{{
          props.option.label
        }}</span>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Personalization",
  props: { value: { type: String, default: "" } },
  created() {},
  data() {
    return {
      personalizationModel: "",
    };
  },
  computed: {
    ...mapGetters("ddb", {
      personalisation: "getPersonalisation",
    }),
  },
  methods: {
    resetPersonalization(val) {
      this.getPersonalisation(val);
    },
    setPersonalization(val) {
      if (val) {
        this.$store.dispatch("ddb/setPersonalizationValue", val.value);
      }
    },
  },
};
</script>
