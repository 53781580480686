<template>
  <div class="dd-hide-element">
    <p class="dd-label">Hide element</p>
    <b-row align-v="center">
      <b-col>
        <div class="dd-btn-ico-group dd-align-settings">
          <a
            href="javascript:void(0)"
            @click="setVal()"
            :class="hideElement === '' ? 'active' : ''"
          >
            <img
              src="../../assets/img/icon/hide-element/hide-no.svg"
              width="16"
              alt="No"
            />
          </a>
          <a
            href="javascript:void(0)"
            @click="setVal('hide-pc')"
            :class="hideElement === 'hide-pc' ? 'active' : ''"
            v-b-tooltip
            title="On desktop"
          >
            <img
              src="../../assets/img/icon/hide-element/hide-pc.svg"
              width="19"
              alt="pc"
            />
          </a>
          <a
            href="javascript:void(0)"
            @click="setVal('hide-mobile')"
            :class="hideElement === 'hide-mobile' ? 'active' : ''"
            v-b-tooltip
            title="On mobile"
          >
            <img
              src="../../assets/img/icon/hide-element/hide-mobile.svg"
              width="11"
              alt="mobile"
            />
          </a>
        </div>
      </b-col>
    </b-row>
    <dd-help-text v-if="hideElement != ''">
      Warning! The element will be hidden on Yahoo mobile App, as well as in
      mobile email apps which do not support media queries.
    </dd-help-text>
  </div>
</template>
<script>
export default {
  name: "HideElement",
  props: {
    value: { type: String, default: "" },
  },
  components: {},
  methods: {
    setVal(val = "") {
      this.hideElement = val;
    },
  },
  watch: {
    hideElement(val) {
      this.$emit("input", val);
    },
    value() {
      this.hideElement = this.value;
    },
  },
  data() {
    return {
      hideElement: this.value ? this.value : "",
    };
  },
};
</script>
