<template>
  <div class="dd-list-accrodion single individual" role="tablist">
    <b-card no-body>
      <b-card-body>
        <bread-crumb />
        <b-row>
          <b-col>
            <b-button-group class="dd-goto-group">
              <b-button
                :class="value.tab === 'links' ? 'active' : ''"
                @click="setTab('links')"
                variant="light"
                >Links</b-button
              >
              <b-button
                :class="value.tab === 'icons' ? 'active' : ''"
                @click="setTab('icons')"
                variant="light"
                >Icons</b-button
              >
              <b-button
                :class="value.tab === 'iconsAndlinks' ? 'active' : ''"
                @click="setTab('iconsAndlinks')"
                variant="light"
                >Icons & link</b-button
              >
            </b-button-group>
          </b-col>
        </b-row>
        <b-row class="dd-mt-20">
          <b-col>
            <menu-item
              :common-font-color="value.linkColor || generalProperty.linkColor"
              :tab="value.tab"
              :value="value.items"
              @input="updateProps('items', $event)"
            />
            <div
              v-if="value.items.length > 7"
              class="dd-alert-warning dd-mt-10 dd-mb-10"
            >
              <b-media no-body>
                <b-media-aside vertical-align="center">
                  <img
                    src="../../assets/img/icon/warning-ico-light.svg"
                    alt=""
                    width="18"
                  />
                </b-media-aside>
                <b-media-body class="dd-ml-15">
                  <strong>Warning!</strong> adding more menu may cause
                  unsightliness
                </b-media-body>
              </b-media>
            </div>
            <b-button
              @click="addNewItem"
              size="sm"
              block
              variant="outline-primary"
              class="dd-btn-doted"
            >
              <img
                src="../../assets/img/icon/plus-blue-ico.svg"
                width="12"
                alt=""
                class="dd-mr-10"
              />
              Add menu item
            </b-button>
            <div v-if="value.tab === 'iconsAndlinks'">
              <ruler class="dd-mx--20" />
              <dd-label>Menu align style</dd-label>
              <b-row>
                <b-col>
                  <menu-align
                    :value="value.menuAlign"
                    @input="updateProps('menuAlign', $event)"
                  ></menu-align>
                </b-col>
              </b-row>
            </div>
            <ruler class="dd-mx--20" />
            <div v-if="value.tab !== 'links'">
              <dd-label>Size</dd-label>
              <menu-size
                :value="value.menuSize || {}"
                @input="updateProps('menuSize', $event)"
              ></menu-size>
              <ruler class="dd-mx--20" />
            </div>
            <single-border-settings
              label="separator width"
              :min-value="0"
              :max-value="20"
              :value="value.seperator || ''"
              @input="updateProps('seperator', $event)"
            >
              <dd-label>Separator</dd-label>
            </single-border-settings>
            <ruler />
            <div v-if="value.tab !== 'icons'">
              <dd-label>Text style</dd-label>
              <font-family
                :value="value.fontFamily || generalProperty.fontFamily"
                @input="updateProps('fontFamily', $event)"
              />
              <b-row class="dd-mb-20">
                <b-col>
                  <dd-label-light>Font size</dd-label-light>
                  <count-range
                    label="font size"
                    :value="value.fontSize ? parseInt(value.fontSize) : 0"
                    @input="updateProps('fontSize', $event + 'px')"
                    :max="maxFontSize"
                    :min="0"
                  >
                  </count-range>
                </b-col>
                <b-col>
                  <color-picker
                    :value="value.linkColor || generalProperty.linkColor"
                    @input="updateProps('linkColor', $event)"
                    label="Link color"
                  />
                </b-col>
              </b-row>
              <b-row class="dd-mb-20">
                <b-col>
                  <font-settings
                    :value="value"
                    @change="updateProps($event.key, $event.value)"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <text-transform
                    :value="value.textTransform"
                    @input="updateProps('textTransform', $event)"
                  />
                </b-col>
                <b-col>
                  <letter-spacing
                    :value="value.letterSpacing"
                    @input="updateProps('letterSpacing', $event)"
                  />
                </b-col>
              </b-row>
              <ruler class="dd-mx--20" />
              <b-row class="dd-mb-20">
                <b-col>
                  <align-horizontal
                    :value="value || {}"
                    @change-input="updateProps($event.key, $event.value)"
                  >
                  </align-horizontal>
                </b-col>
              </b-row>
              <ruler class="dd-mx--20" />
            </div>
            <padding-all
              :value="value.internalPadding"
              @input="updateProps('internalPadding', $event)"
            >
              <slot>Padding</slot>
            </padding-all>
            <ruler class="dd-mx--20" />
            <hide-element
              :value="value.hideElement || ''"
              @input="updateProps('hideElement', $event)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { maxFontSize } from "../../constants";
import MenuItem from "../Properties/MenuItem.vue";
import HideElement from "../Properties/HideElement.vue";
import CountRange from "../Controls/CountRange.vue";
import SingleBorderSettings from "../Properties/SingleBorderSettings.vue";
import FontFamily from "../Properties/FontFamily.vue";
import FontSettings from "../Properties/FontSettings.vue";
import TextTransform from "../Properties/TextTransform.vue";
import LetterSpacing from "../Properties/LetterSpacing.vue";
import PaddingAll from "../Properties/PaddingAll.vue";
import MenuAlign from "../Properties/MenuAlign.vue";
import MenuSize from "../Properties/MenuSize.vue";
import AlignHorizontal from "../Properties/AlignHorizontal.vue";
export default {
  name: "MenuSettings",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    generalProperty: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    MenuItem,
    HideElement,
    CountRange,
    SingleBorderSettings,
    FontFamily,
    FontSettings,
    TextTransform,
    LetterSpacing,
    PaddingAll,
    MenuAlign,
    MenuSize,
    AlignHorizontal,
  },
  data() {
    return {
      widthOrHeight: "",
      incBy: 1,
      maxFontSize: maxFontSize,
    };
  },
  methods: {
    addNewItem() {
      if (this.value.items) {
        var items = this.value.items;
        var name = "item " + (items.length + 1);
        var newIndex = {
          name: name,
          alt: name,
          backgroundColor: "transparent",
        };
        items.push(newIndex);
        this.updateProps("items", items);
        this.$store.dispatch("ddb/setSelectedMenu", {
          type: "menu",
          index: items.length - 1,
        });
      }
    },
    setTab(val) {
      this.updateProps("tab", val);
    },
    updateProps(prop, val) {
      this.$store.dispatch("ddb/updateElementProperty", {
        property: prop,
        value: val,
      });
    },
  },
};
</script>
