<template>
  <ckeditor
    :read-only="!!$store.state.ddb.dragging"
    :value="value"
    :config="editorConfig"
    :color="props.color"
    :line-height="props.lineHeight"
    :link-text-color="props.linkTextColor"
    :link-under-line="props.linkUnderLine"
    @click.native="selected"
    @input="$emit('input', $event)"
    @namespaceloaded="onNamespaceLoaded"
    @ready="onReady"
    @blur="onBlur"
    @instanceChange="setInstance"
  />
</template>
<script>
import ckeditor from "../../plugins/ckeditor.js";
import { mapGetters } from "vuex";
import { rgbToHex } from "../../plugins/HtmlConverter.js";
import {
  nonStandardFontWarning,
  nonStandardEmojiWarning,
} from "../../constants/messages.js";
export default {
  name: "DdText",
  components: { ckeditor },
  props: {
    value: {
      type: String,
      default: "",
    },
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters("ddb", {
      personalisation: "getPersonalisation",
      personalisationVal: "getPersonalizationValue",
      selectedElement: "getSelected",
    }),
  },
  data() {
    let vm = this;
    return {
      instanc: null,
      editorConfig: {
        title: false,
        font_warning: nonStandardFontWarning,
        emoji_warning: nonStandardEmojiWarning,
        fontSize_sizes:
          "8/8px;9/9px;10/10px;11/11px;12/12px;13/13px;14/14px;15/15px;16/16px;17/17px;18/18px;19/19px;20/20px;21/21px;22/22px;23/23px;24/24px;25/25px;26/26px;27/27px;28/28px;29/29px;30/30px;31/31px;32/32px;33/33px;34/34px;35/35px;36/36px;37/37px;38/38px;39/39px;40/40px;41/41px;42/42px;43/43px;44/44px;45/45px;46/46px;47/47px;48/48px;49/49px;50/50px;51/51px;52/52px;53/53px;54/54px;55/55px;56/56px;57/57px;58/58px;59/59px;60/60px;61/61px;62/62px;63/63px;64/64px;65/65px;66/66px;67/67px;68/68px;69/69px;70/70px;71/71px;72/72px;",
        font_names_standard:
          "Arial/arial,'helvetica neue',helvetica,sans-serif;" +
          "Comic sans ms/'comic sans ms','marker felt-thin',arial,sans-serif;" +
          "Courier New/'courier new',courier,'lucida sans typewriter','lucida typewriter',monospace;" +
          "Georgia/georgia, times, 'times new roman', serif;" +
          "Helvetica/helvetica,'helvetica neue',arial,verdana,sans-serif;" +
          "Lucida/'Lucida Sans Unicode', 'Lucida Grande', sans-serif;" +
          "Tahoma/'tahoma', verdana, segoe, sans-serif;" +
          "Times new roman/'times new roman',times,baskerville,georgia,serif;" +
          "Trebuchet MS/'trebuchet ms', 'lucida grande', 'lucida sans unicode', 'lucida sans', tahoma, sans-serif;" +
          "Verdana/'verdana', geneva, sans-serif",
        font_names:
          "Calibri/'Calibri',Candara,Segoe,Segoe UI,Optima,Arial,sans-serif;" +
          "Poppins/'Poppins', sans-serif;" +
          "Arvo/arvo, courier, georgia,serif;" +
          "Lato/'Lato', 'helvetica neue', helvetica,arial, sans-serif;" +
          "Lora/lora, georgia,'times new roman', serif;" +
          "Merriweather/'Merriweather', 'helvetica neue', helvetica,arial, sans-serif;" +
          "Open Sans/'Open Sans', 'helvetica neue', helvetica,arial, sans-serif;" +
          "Roboto/'Roboto', 'helvetica neue', helvetica,arial, sans-serif;" +
          "Playfair Display/'Playfair Display', georgia, 'times new roman', serif;" +
          "Source Sans Pro/'Source Sans Pro', 'helvetica neue', helvetica,arial, sans-serif;" +
          "Abril Fatface/'Abril Fatface', Georgia, 'Times New Roman', serif;" +
          "Anton/'Anton', Helvetica, Arial, Verdana, sans-serif;" +
          "Raleway/'Raleway', sans-serif;" +
          "Work Sans/'Work Sans', sans-serif;" +
          "Syncopate/'Syncopate', 'Helvetica Neue', Helvetica, Arial, sans-serif;" +
          "Montserrat/'Montserrat', sans-serif;" +
          "Neuton/neuton, serif;" +
          "Palatino Linotype/Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia,serif;" +
          "Copperplate/Copperplate,Copperplate Gothic Light,fantasy;" +
          "Brush Script MT/Brush Script MT,cursive;" +
          "Noto Sans/'Noto Sans', sans-serif;" +
          "Montserrat/'Montserrat', sans-serif;" +
          "PT Sans/'PT Sans', Arial, Helvetica, sans-serif;" +
          "Montserrat Alternates/'Montserrat Alternates', sans-serif;",

        extraAllowedContent: "*{*}",
        toolbar: [
          {
            name: "basicstyles",
            items: [
              "Format",
              "Font",
              "FontSize",
              "Bold",
              "Italic",
              "Underline",
              "Strike",
              "Subscript",
              "Superscript",
              "RemoveFormat",
              "-",
              "Link",
              "Unlink",
            ],
          },
          { name: "color", items: ["TextColor", "BGColor"] },
          {
            name: "align",
            items: [
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
            ],
          },
          { name: "list", items: ["BulletedList", "NumberedList"] },
          ["Personalisation", "Emojione", "Table", "SpecialChar"],
        ],
        fontSize_style: {
          element: "span",
          styles: { "font-size": "#(size)", "line-height": "150%" },
          overrides: [{ element: "font", attributes: { size: null } }],
        },
        forcePasteAsPlainText: true,
        removeButtons: "Cut,Copy,Paste,Undo,Redo,Anchor",
        on: {
          focus: function () {
            vm.setDefaultProps();
          },
        },
      },
    };
  },
  watch: {
    personalisationVal: {
      handler(val) {
        if (
          val &&
          this.selectedElement &&
          this.props.propKey &&
          this.selectedElement === "el-" + this.props.propKey &&
          this.instanc
        ) {
          this.instanc.focus();
          this.instanc.insertText(val);
          this.$store.dispatch("ddb/setPersonalizationValue", "");
        }
      },
    },
    value() {
      this.setDefaultProps();
    },
  },
  methods: {
    setInstance(instance) {
      this.instanc = instance;
    },
    onReady() {
      this.$emit("ready");
    },
    onBlur({ editor }) {
      if (editor.window.$ && editor.window.$.getSelection()) {
        editor.window.$.getSelection().removeAllRanges();
      }
    },
    onNamespaceLoaded(CKEDITOR) {
      window.frameWindow.CKEDITOR = CKEDITOR;
      // eslint-disable-next-line
      CKEDITOR.document = new CKEDITOR.dom.document(window.frameDocument);
    },
    selected() {
      this.$nextTick(() => {
        this.instanc.focus();
      });
    },
    setDefaultProps() {
      if (!this.instanc) {
        return;
      }
      const editorElement = this.instanc.element;
      const element = editorElement.getFirst();
      const props = {};
      if (element) {
        props.color = rgbToHex(getComputedStyle(element.$).color);
      }
      const elements = editorElement.getElementsByTag("A").toArray();
      if (elements.length) {
        props.linkTextColor = rgbToHex(getComputedStyle(elements[0].$).color);
      }
      if (Object.keys(props).length) {
        this.$store.dispatch("ddb/setElementDefaultProps", props);
      }
    },
  },
};
</script>
