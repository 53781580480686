const getBorderSize = function (border) {
  return border
    .split(" ")
    .map((p) => parseInt(p))
    .filter((p) => !isNaN(p))
    .reduce((total, val) => total + val, 0);
};
export const getBorderWidth = function (border) {
  if (typeof border === "string") {
    return getBorderSize(border) * 2;
  }
  if (typeof border === "object" && border.left && border.right) {
    return getBorderSize(border.left) + getBorderSize(border.right);
  }
  return 0;
};
export const getPadding = function (padding) {
  if (padding && padding.left && padding.right) {
    return parseInt(padding.left) + parseInt(padding.right);
  } else if (padding) {
    return parseInt(padding) * 2;
  }
  return 0;
};
const imageRatio = (props) => {
  return props.originalHeight ? props.originalHeight / props.originalWidth : 0;
};
export const imageMaxHeight = (props, maxWidth) => {
  const maxHeight = Math.floor(maxWidth * imageRatio(props));
  if (props.proportion === false) {
    return maxHeight * 2;
  }
  return maxHeight;
};
export const imageWidth = (props, maxWidth) => {
  if (props.width) {
    return props.width;
  }
  const width = props.originalWidth || 0;
  return width && maxWidth > width ? width : maxWidth;
};
export const imageHeight = (props, maxHeight) => {
  if (props.height) {
    return props.height;
  }
  const height = props.originalHeight;
  if (!height) {
    return null;
  }
  return height && maxHeight > height ? height : maxHeight;
};
export const getColumnWidth = (container, column) => {
  const containerProps = container.props;
  return (
    column.props.width -
    getPadding(containerProps.padding) -
    getBorderWidth(containerProps.border)
  );
};

export const getSocialmediaPath = (value, folder) => {
  var logopath = value.logo;
  if (value.type === "custom") {
    return value.src
      ? value.src
      : (process.env.VUE_APP_VUE2_APP_URL || "") + "/img/default-img.png";
  } else if (value.type !== "socialmedia") {
    logopath = value.type + "/" + logopath;
  }
  return "/img/social-media/" + folder + "/" + logopath + ".png";
};
