<template comments>
  <div
    class="ml-wrapper-color"
    :bgcolor="backgroundColor"
    :style="{ backgroundColor }"
  >
    <comment :value="msoBg" />
    <dd-table
      class="ml-wrapper"
      width="100%"
      :bgcolor="lowerBgColor"
      :style="{ ...backgroundImage }"
    >
      <tr v-if="disableResponsive" class="fix-gmail" height="0">
        <td style="margin: 0; padding: 0">
          <table
            cellpadding="0"
            cellspacing="0"
            border="0"
            align="center"
            style="
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
              border-collapse: collapse;
              border-spacing: 0px;
              width: 600px;
            "
          >
            <tbody>
              <tr>
                <td
                  cellpadding="0"
                  cellspacing="0"
                  border="0"
                  height="0"
                  style="
                    padding: 0;
                    margin: 0;
                    line-height: 1px;
                    min-width: 600px;
                  "
                >
                  <img
                    :src="setImageDomain('/img/spacer.png')"
                    height="1"
                    width="600"
                    style="
                      border: 0;
                      outline: none;
                      display: block;
                      text-decoration: none;
                      -ms-interpolation-mode: bicubic;
                      min-height: 0px;
                      max-height: 0px;
                      width: 600px;
                      min-width: 600px;
                    "
                    alt=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <dd-email-paddings>
          <template v-for="(content, mainIndex) in htmlJson.contents">
            <main-block
              v-if="content"
              :key="'content-' + content.key"
              :main-index="mainIndex"
              :props="content.props"
            >
              <template v-for="structure in content.structures">
                <structure
                  v-if="structure"
                  :key="'structure-' + structure.key"
                  :props="structure.props"
                  :columns="structure.columns"
                >
                  <template v-for="(column, colIndex) in structure.columns">
                    <comment
                      v-if="!isFullWidth && column && column.props"
                      :value="
                        msoStart(
                          column.props.width +
                            (colIndex < structure.columns.length - 2
                              ? structure.props.indent
                              : 0)
                        )
                      "
                      :key="'column-msos-' + column.key"
                    />
                    <dd-table
                      v-if="column && column.props"
                      :align="getColumAlign(colIndex, structure.columns.length)"
                      :class="
                        'ml-' +
                        getColumAlign(colIndex, structure.columns.length)
                      "
                      :width="isFullWidth ? '100%' : null"
                      :style="fullWidthStyle"
                      :key="'column-' + column.key"
                    >
                      <tbody>
                        <template v-for="container in column.containers">
                          <container
                            v-if="container"
                            :key="'container-' + container.key"
                            :column-props="column.props"
                            :props="container.props"
                            :align="
                              getColumAlign(colIndex, structure.columns.length)
                            "
                            :padding-mobile="
                              structure.props.containerPaddingMobile !== false
                            "
                            :column-indent="
                              colIndex + 2 < structure.columns.length
                                ? structure.props.indent
                                : 0
                            "
                          >
                            <template v-for="element in container.elements">
                              <tr
                                v-if="element"
                                :key="'element' + element.key"
                                class="mld-element"
                                :class="{
                                  'ml-desk-hidden':
                                    element.props.hideElement === 'hide-pc',
                                  'ml-mobile-hidden':
                                    element.props.hideElement === 'hide-mobile',
                                }"
                              >
                                <td
                                  v-if="
                                    element.tag === 'dd-text' ||
                                    element.tag === 'dd-html'
                                  "
                                  v-html="element.props.value"
                                  :align="
                                    element.tag === 'dd-html'
                                      ? null
                                      : element.props.align || 'left'
                                  "
                                  :class="elementClass(element.props)"
                                  :bgcolor="
                                    setColor(element.props.backgroundColor)
                                  "
                                  :style="{
                                    ...parseStyleProperty(
                                      element.props.padding
                                    ),
                                    backgroundColor: setColor(
                                      element.props.backgroundColor
                                    ),
                                  }"
                                ></td>
                                <td
                                  v-else
                                  :align="element.props.align || 'left'"
                                  :height="
                                    element.tag === 'dd-spacer'
                                      ? element.props.height
                                      : false
                                  "
                                  :class="elementClass(element.props)"
                                  :bgcolor="
                                    setColor(element.props.backgroundColor)
                                  "
                                  :style="{
                                    ...parseStyleProperty(
                                      element.props.padding
                                    ),
                                    backgroundColor: setColor(
                                      element.props.backgroundColor
                                    ),
                                  }"
                                >
                                  <comment
                                    v-if="element.tag === 'dd-button'"
                                    :value="
                                      bttonMsoStart(
                                        element.props,
                                        column.props,
                                        container.props
                                      )
                                    "
                                  />
                                  <compoent
                                    :is="element.tag"
                                    :props="{
                                      ...element.props,
                                      container,
                                      column,
                                      isPreview: true,
                                    }"
                                  />
                                  <comment
                                    v-if="element.tag === 'dd-button'"
                                    :value="bttonMsoEnd"
                                  />
                                </td>
                              </tr>
                            </template>
                          </container>
                        </template>
                      </tbody>
                    </dd-table>
                    <comment
                      v-if="!isFullWidth && column && column.props"
                      :value="
                        msoEnd(
                          colIndex == structure.columns.length - 2
                            ? structure.props.indent
                            : 0
                        )
                      "
                      :key="'column-msoe-' + column.key"
                    />
                  </template>
                </structure>
              </template>
            </main-block>
          </template>
        </dd-email-paddings>
      </tr>
    </dd-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { parseStyleProperty } from "../../plugins/StyleConverter.js";
import MainBlock from "./MainBlockPreview.vue";
import Structure from "./StructurePreview.vue";
import Container from "./ContainerPreview.vue";
import Comment from "../Builder/Comment.vue";
import ContentBlocks from "../ContentBlocks";
export default {
  name: "BuilderPreview",
  components: {
    MainBlock,
    Structure,
    Container,
    Comment,
    ...ContentBlocks,
  },
  computed: {
    ...mapState({
      htmlJson: (state) => state.ddb.htmlJson,
    }),
    disableResponsive() {
      return (
        this.$store.state.ddb.htmlJson.properties.disableResponsive || false
      );
    },
    properties() {
      return this.htmlJson.properties;
    },
    btnProps() {
      return this.properties.button || {};
    },
    backgroundColor() {
      return this.setColor(this.properties.backgroundColor);
    },
    isFullWidth() {
      return this.properties.containerType === "fullwidth";
    },
    fullWidthStyle() {
      if (this.isFullWidth) {
        return { width: "100%" };
      }
      return {};
    },
    backgroundImage() {
      if (this.properties.backgroundImage) {
        return {
          backgroundImage: "url(" + this.properties.backgroundImage + ")",
          backgroundRepeat: this.properties.backgroundRepeat || "no-repeat",
          backgroundPosition:
            this.properties.backgroundPosition || "center top",
        };
      }
      return {};
    },
    lowerBgColor() {
      return this.setColor(this.properties.backgroundColor.toLowerCase());
    },
    msoBg() {
      return (
        `[if gte mso 9]>
      <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
        <v:fill type="tile"` +
        (this.properties.backgroundImage
          ? ' src="' + this.properties.backgroundImage + '"'
          : "") +
        ` color="` +
        this.lowerBgColor +
        `"` +
        (this.backgroundImage.backgroundPosition &&
        this.backgroundImage.backgroundPosition.indexOf("center") === 0
          ? ' origin="0.5, 0" position="0.5, 0"'
          : "") +
        `></v:fill>
      </v:background>
    <![endif]`
      );
    },
  },
  methods: {
    elementClass(prop) {
      const elClass = {};
      if (prop.alignMobile) {
        elClass["ml-m-txt-" + prop.alignMobile[0]] = true;
      }
      return elClass;
    },
    msoStart(width) {
      return (
        '[if mso]><td style="width:' +
        (width || this.properties.emailWidth) +
        'px" valign="top"><![endif]'
      );
    },
    getWidth(value) {
      const valueArr = value.split(" ");
      return parseInt(valueArr[0]);
    },
    calculateWidth(data) {
      var width = 0;
      if (typeof data === "object") {
        width = this.getWidth(data.left) + this.getWidth(data.right);
      } else {
        width = this.getWidth(data) * 2;
      }
      return width;
    },
    getFirstBorderStyle(borderData) {
      for (let bor in borderData) {
        const myArr = borderData[bor].split(" ");
        if (parseInt(myArr[0]) > 0) {
          return borderData[bor];
        }
      }
      return "";
    },
    bttonMsoStart(props, column, conainer) {
      const internalPadding =
        props.internalPadding || this.btnProps.internalPadding || "0";
      const fontSize = props.fontSize || this.btnProps.fontSize || "16px";
      const fontFamily =
        props.fontFamily ||
        this.btnProps.fontFamily ||
        "arial,'helvetica neue',helvetica,sans-serif";
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      context.font = fontSize + " " + fontFamily;
      let width = Math.ceil(context.measureText(props.text).width);
      let borderRadius = parseInt(
        props.borderRadius || this.btnProps.borderRadius || "0"
      );
      let height = Math.round((parseInt(fontSize) * 12) / 10);
      if (typeof internalPadding === "object") {
        height +=
          parseInt(internalPadding.top) + parseInt(internalPadding.bottom);
        width +=
          parseInt(internalPadding.left) + parseInt(internalPadding.right);
      } else {
        height += parseInt(internalPadding) * 2;
        width += parseInt(internalPadding) * 2;
      }
      if (props.fullwidth) {
        var containerBorderWidth = conainer.border
          ? this.calculateWidth(conainer.border)
          : 0;
        var containerWidth = column.width - containerBorderWidth;
        var btnPadding = props.padding ? this.calculateWidth(props.padding) : 0;
        width = containerWidth - (btnPadding + 1);
      }

      var strokeWeight = 'stroke="f"';
      if (props.border) {
        var borderVal = props.border;
        if (typeof props.border === "object") {
          borderVal = this.getFirstBorderStyle(props.border);
        }
        const myArr = borderVal.split(" ");
        if (parseInt(myArr[0]) > 0) {
          strokeWeight =
            'strokecolor="' + myArr[2] + '" strokeweight="' + myArr[0] + '"';
        }
      }

      borderRadius = Math.round((borderRadius * 100) / height);
      return (
        `[if mso]>
<a href="` +
        this.htmlEncode(props.href || "#") +
        `" target="_blank">
   <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="` +
        this.htmlEncode(props.href || "#") +
        `" 
      style="height:` +
        height +
        `px;v-text-anchor:middle;width:` +
        width +
        `px;" arcsize="` +
        borderRadius +
        `%" ` +
        strokeWeight +
        ` fillcolor="` +
        (props.buttonColor || this.btnProps.buttonColor || "") +
        `">
      <w:anchorlock></w:anchorlock>
      <center style="color:` +
        (props.fontColor || this.btnProps.fontColor || "") +
        `;font-family:` +
        fontFamily +
        `;font-size:` +
        fontSize +
        `;font-weight:` +
        (props.fontWeight || this.btnProps.fontWeight || "") +
        `; font-style:` +
        (props.fontStyle || this.btnProps.fontStyle || "") +
        `; text-decoration: ` +
        (props.textDecoration || this.btnProps.textDecoration || "") +
        `; ">` +
        props.text +
        `</center>
   </v:roundrect>
</a>
<![endif]--> 
<!--[if !mso]><!-- `
      );
    },
    msoEnd(indent) {
      let mso = "[if mso]>";
      if (indent) {
        mso += '</td><td width="' + indent + '">';
      }
      return mso + "</td><![endif]";
    },
    getColumAlign(columnIndex, columnLength) {
      if (columnIndex > 0 && columnIndex === columnLength - 1) {
        return "right";
      }
      return "left";
    },
  },
  data() {
    return {
      parseStyleProperty,
      bttonMsoEnd: "<![endif]",
    };
  },
};
</script>
