<template>
  <div class="dd-border-settings">
    <b-row align-v="center" class="dd-mb-10">
      <b-col>
        <dd-label class="mb-0"><slot>Border style</slot></dd-label>
      </b-col>
      <advance-option :active="borderAll" @click="changeAdvanced" />
    </b-row>
    <single-border-settings
      label="border width"
      :max-value="20"
      v-if="!borderAll"
      v-model="all"
      @input="emitValue"
    />
    <transition name="fade">
      <div v-if="borderAll" class="custom">
        <single-border-settings
          label="left border width"
          :max-value="20"
          v-model="result.left"
          @input="emitValue"
          >Left</single-border-settings
        >
        <single-border-settings
          label="right border width"
          :max-value="20"
          v-model="result.right"
          @input="emitValue"
          >Right</single-border-settings
        >
        <single-border-settings
          label="top border width"
          :max-value="20"
          v-model="result.top"
          @input="emitValue"
          >Top</single-border-settings
        >
        <single-border-settings
          label="bottom border width"
          :max-value="20"
          v-model="result.bottom"
          @input="emitValue"
          >Bottom</single-border-settings
        >
      </div>
    </transition>
  </div>
</template>
<script>
import SingleBorderSettings from "./SingleBorderSettings.vue";
export default {
  name: "BorderSettings",
  components: { SingleBorderSettings },
  props: {
    value: { type: [Object, String], default: "0px" },
  },
  data() {
    return {
      borderAll: false,
      all: "",
      result: {},
    };
  },
  watch: {
    value(val) {
      this.setValue(val);
    },
  },
  created() {
    this.setValue(this.value);
  },
  methods: {
    changeAdvanced() {
      this.borderAll = !this.borderAll;
      if (this.borderAll) {
        this.result = {
          left: this.all,
          right: this.all,
          top: this.all,
          bottom: this.all,
        };
      } else {
        if (
          this.result.left === this.result.right &&
          this.result.right === this.result.top &&
          this.result.top === this.result.bottom
        ) {
          this.all = this.result.left;
        } else {
          this.all = "";
        }
      }
      this.emitValue();
    },
    emitValue() {
      if (this.borderAll) {
        this.$emit("input", { ...this.result });
      } else {
        this.$emit("input", this.all);
      }
    },
    setValue(val) {
      if (!val || typeof val === "string") {
        this.borderAll = false;
        this.all = val || "";
      } else {
        this.borderAll = true;
        this.result = { ...val };
      }
    },
  },
};
</script>
