<template>
  <b-modal
    :id="id"
    class="dd-modal"
    centered
    size="sm"
    hide-header
    hide-footer
    @hide="onHide"
  >
    <button
      type="button"
      aria-label="Close"
      class="close ml-0 dd-modal-close"
      @click="closeModal('cancel')"
    >
      ×
    </button>
    <div class="text-center">
      <img
        src="../../assets/img/icon/warning-ico.svg"
        width="42"
        class="dd-mb-10"
      />
      <h2 class="dd-mb-15 dd-font-24">{{ title }}</h2>
      <p class="dd-mb-30 dd-font-15" v-html="content"></p>
      <b-button
        :variant="btnVariant"
        class="dd-mr-15"
        @click="closeModal('confirm')"
        >{{ confirmText }}</b-button
      >
      <b-button variant="outline-secondary" @click="closeModal('cancel')"
        >Cancel</b-button
      >
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "ModalConfirm",
  props: {
    id: { type: String, default: "modalConfirm" },
    title: { type: String, default: "Are you sure?" },
    content: {
      type: String,
      default: "It will affect all structures that were added earlier",
    },
    confirmText: { type: String, default: "Yes, apply" },
    btnVariant: { type: String, default: "primary" },
  },
  methods: {
    closeModal(evt) {
      this.$emit(evt);
      this.$bvModal.hide(this.id);
    },
    onHide(e) {
      if (e.trigger === "backdrop") {
        this.$emit("cancel");
      }
    },
  },
};
</script>
