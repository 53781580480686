<template>
  <b-card no-body class="border-bottom-0">
    <b-card-header header-tag="header" role="tab">
      <panel-title>
        <slot> Background settings </slot>
      </panel-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col>
          <background-color
            cols="12"
            :value="value.backgroundColor"
            @input="updateProps('backgroundColor', $event)"
          />
        </b-col>
        <b-col>
          <background-color
            v-if="isGeneral"
            cols="12"
            :value="value.contentBackground"
            @input="updateProps('contentBackground', $event)"
            >Content background</background-color
          >
        </b-col>
      </b-row>
      <image-upload
        prop="backgroundImage"
        :value="value.backgroundImage"
        @input="updateProps('backgroundImage', $event)"
      />
      <template v-if="value.backgroundImage">
        <ruler />
        <dd-switch
          :value="value.backgroundRepeat"
          checked-value="repeat"
          unchecked-value="no-repeat"
          @input="updateProps('backgroundRepeat', $event)"
          >Repeat image</dd-switch
        >
        <hr class="dd-my-15" />
        <bg-align
          :value="value.backgroundPosition"
          @input="updateProps('backgroundPosition', $event)"
        />
      </template>
      <dd-help-text class="mb-0"
        >Background image may not support some email clients ( Windows Outlook
        2007 – 2019, Windows 10 Mail, Android 4.4, Gmail app on iOS & Android
        with non-Gmail account). Set background colour same as image colour to
        avoid this issue</dd-help-text
      >
    </b-card-body>
  </b-card>
</template>
<script>
import BackgroundColor from "../Properties/BackgroundColor.vue";
import ImageUpload from "../Properties/ImageUpload.vue";
import BgAlign from "../Properties/BgAlign.vue";
export default {
  name: "BackgroundSettings",
  props: {
    value: {
      type: Object,
      default() {
        return { backgroundColor: "transparent", backgroundImage: "" };
      },
    },
    isGeneral: { type: Boolean, default: false },
  },
  components: {
    BackgroundColor,
    ImageUpload,
    BgAlign,
  },
  methods: {
    updateProps(prop, val) {
      const background = { ...this.value };
      background[prop] = val;
      this.$emit("input", background);
      this.$emit("change", { prop, val });
    },
  },
};
</script>
