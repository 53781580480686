<template>
  <b-card no-body class="border-top-0">
    <b-card-header header-tag="header" role="tab">
      <panel-title id="idButton"> General button settings </panel-title>
    </b-card-header>
    <b-card-body>
      <b-row class="dd-mb-20">
        <b-col cols="4">
          <color-picker
            label="Button color"
            :value="value.buttonColor"
            @input="updateProps('buttonColor', $event)"
          />
        </b-col>
        <b-col cols="4">
          <color-picker
            label="Font color"
            :value="value.fontColor"
            @input="updateProps('fontColor', $event)"
          />
        </b-col>
        <b-col cols="4">
          <font-size
            selectClass="fit-right"
            label="Font size"
            :value="value.fontSize"
            @input="updateProps('fontSize', $event)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <font-family
            :value="value.fontFamily"
            @input="updateProps('fontFamily', $event)"
          />
          <div>
            <font-settings
              :value="value"
              @change="updateProps($event.key, $event.value)"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ruler />
          <border-radius
            :value="value.borderRadius"
            @input="updateProps('borderRadius', $event)"
          />
          <border-settings
            :value="value.border"
            @input="updateProps('border', $event)"
          />
          <ruler />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <padding-all
            label="Internal padding"
            :value="value.internalPadding"
            @input="updateProps('internalPadding', $event)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import ColorPicker from "../Controls/ColorPicker.vue";
import FontSize from "../Properties/FontSize.vue";
import FontFamily from "../Properties/FontFamily.vue";
import FontSettings from "../Properties/FontSettings.vue";
import BorderRadius from "../Properties/BorderRadius.vue";
import BorderSettings from "../Properties/BorderSettings.vue";
import PaddingAll from "../Properties/PaddingAll.vue";
export default {
  name: "GeneralButtonSettings",
  components: {
    ColorPicker,
    FontSize,
    FontFamily,
    FontSettings,
    BorderRadius,
    BorderSettings,
    PaddingAll,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    updateProps(prop, value) {
      const val = JSON.parse(JSON.stringify(this.value));
      val[prop] = value;
      this.$emit("input", val);
    },
  },
};
</script>
