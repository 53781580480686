var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-wrapper-color",style:({ backgroundColor: _vm.backgroundColor }),attrs:{"bgcolor":_vm.backgroundColor}},[_c('comment',{attrs:{"value":_vm.msoBg}}),_c('dd-table',{staticClass:"ml-wrapper",style:({ ..._vm.backgroundImage }),attrs:{"width":"100%","bgcolor":_vm.lowerBgColor}},[(_vm.disableResponsive)?_c('tr',{staticClass:"fix-gmail",attrs:{"height":"0"}},[_c('td',{staticStyle:{"margin":"0","padding":"0"}},[_c('table',{staticStyle:{"mso-table-lspace":"0pt","mso-table-rspace":"0pt","border-collapse":"collapse","border-spacing":"0px","width":"600px"},attrs:{"cellpadding":"0","cellspacing":"0","border":"0","align":"center"}},[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"padding":"0","margin":"0","line-height":"1px","min-width":"600px"},attrs:{"cellpadding":"0","cellspacing":"0","border":"0","height":"0"}},[_c('img',{staticStyle:{"border":"0","outline":"none","display":"block","text-decoration":"none","-ms-interpolation-mode":"bicubic","min-height":"0px","max-height":"0px","width":"600px","min-width":"600px"},attrs:{"src":_vm.setImageDomain('/img/spacer.png'),"height":"1","width":"600","alt":""}})])])])])])]):_vm._e(),_c('tr',[_c('dd-email-paddings',[_vm._l((_vm.htmlJson.contents),function(content,mainIndex){return [(content)?_c('main-block',{key:'content-' + content.key,attrs:{"main-index":mainIndex,"props":content.props}},[_vm._l((content.structures),function(structure){return [(structure)?_c('structure',{key:'structure-' + structure.key,attrs:{"props":structure.props,"columns":structure.columns}},[_vm._l((structure.columns),function(column,colIndex){return [(!_vm.isFullWidth && column && column.props)?_c('comment',{key:'column-msos-' + column.key,attrs:{"value":_vm.msoStart(
                        column.props.width +
                          (colIndex < structure.columns.length - 2
                            ? structure.props.indent
                            : 0)
                      )}}):_vm._e(),(column && column.props)?_c('dd-table',{key:'column-' + column.key,class:'ml-' +
                      _vm.getColumAlign(colIndex, structure.columns.length),style:(_vm.fullWidthStyle),attrs:{"align":_vm.getColumAlign(colIndex, structure.columns.length),"width":_vm.isFullWidth ? '100%' : null}},[_c('tbody',[_vm._l((column.containers),function(container){return [(container)?_c('container',{key:'container-' + container.key,attrs:{"column-props":column.props,"props":container.props,"align":_vm.getColumAlign(colIndex, structure.columns.length),"padding-mobile":structure.props.containerPaddingMobile !== false,"column-indent":colIndex + 2 < structure.columns.length
                              ? structure.props.indent
                              : 0}},[_vm._l((container.elements),function(element){return [(element)?_c('tr',{key:'element' + element.key,staticClass:"mld-element",class:{
                                'ml-desk-hidden':
                                  element.props.hideElement === 'hide-pc',
                                'ml-mobile-hidden':
                                  element.props.hideElement === 'hide-mobile',
                              }},[(
                                  element.tag === 'dd-text' ||
                                  element.tag === 'dd-html'
                                )?_c('td',{class:_vm.elementClass(element.props),style:({
                                  ..._vm.parseStyleProperty(
                                    element.props.padding
                                  ),
                                  backgroundColor: _vm.setColor(
                                    element.props.backgroundColor
                                  ),
                                }),attrs:{"align":element.tag === 'dd-html'
                                    ? null
                                    : element.props.align || 'left',"bgcolor":_vm.setColor(element.props.backgroundColor)},domProps:{"innerHTML":_vm._s(element.props.value)}}):_c('td',{class:_vm.elementClass(element.props),style:({
                                  ..._vm.parseStyleProperty(
                                    element.props.padding
                                  ),
                                  backgroundColor: _vm.setColor(
                                    element.props.backgroundColor
                                  ),
                                }),attrs:{"align":element.props.align || 'left',"height":element.tag === 'dd-spacer'
                                    ? element.props.height
                                    : false,"bgcolor":_vm.setColor(element.props.backgroundColor)}},[(element.tag === 'dd-button')?_c('comment',{attrs:{"value":_vm.bttonMsoStart(
                                      element.props,
                                      column.props,
                                      container.props
                                    )}}):_vm._e(),_c(element.tag,{tag:"compoent",attrs:{"props":{
                                    ...element.props,
                                    container,
                                    column,
                                    isPreview: true,
                                  }}}),(element.tag === 'dd-button')?_c('comment',{attrs:{"value":_vm.bttonMsoEnd}}):_vm._e()],1)]):_vm._e()]})],2):_vm._e()]})],2)]):_vm._e(),(!_vm.isFullWidth && column && column.props)?_c('comment',{key:'column-msoe-' + column.key,attrs:{"value":_vm.msoEnd(
                        colIndex == structure.columns.length - 2
                          ? structure.props.indent
                          : 0
                      )}}):_vm._e()]})],2):_vm._e()]})],2):_vm._e()]})],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }