const padding = () => {
  return { left: "40px", right: "40px" };
};
const padding0 = (bottom = "0px", top = "0px") => {
  return { top, bottom };
};
const padding020 = () => {
  return padding0("20px");
};
const padding5 = () => {
  return { top: "5px", bottom: "5px" };
};
const padding35 = () => {
  return { top: "35px", bottom: "35px" };
};
const commonProps = () => {
  return { hideOnMobile: false, background: "#ffffff" };
};
const logo1 = (width = 250, align = "center") => {
  return {
    src: "/sample/images/logo-1.png",
    alt: "Logo",
    link: "",
    width,
    align,
    hideOnMobile: false,
  };
};
const tshirt = (type = "y") => {
  return {
    src: "/img/theme/e-commerce/t-shirt-" + type + ".png",
    alt: "T shirt",
    link: "",
    width: 150,
    align: "center",
    padding: { top: "35px", bottom: "10px" },
  };
};
const logo1WithPadd = () => {
  return { ...logo1(), padding: padding35(), width: 100, align: "left" };
};
const font = () => {
  return {
    bold: true,
    italic: false,
    underline: false,
    strike: false,
    uppercase: false,
  };
};
const heading = (align = "right", text = "Title", fontSize = "20px") => {
  return {
    text,
    align,
    padding: padding35(),
    fontSize,
    font: font(),
    fontColor: "#111111",
  };
};
const subHeading = (align = "center", top = "20px", bottom = "10px") => {
  return {
    text: "Best offer",
    align,
    padding: { top, bottom },
    fontSize: "13px",
    font: { ...font(), italic: true },
    fontColor: "#007bff",
  };
};
const subTitle = (top = "30px", bottom = "20px") => {
  return {
    text: "Subtitle",
    align: "center",
    padding: { top, bottom },
    fontSize: "14px",
    font: { ...font(), bold: false },
    fontColor: "#257bff",
  };
};
const tshirtHeading = (align = "center") => {
  return { ...heading(align, "Moda Rapido", "18px"), padding: padding5() };
};
const price = (align = "center", top = "5px", bottom = "10px") => {
  return { ...heading(align, "$ 99.99", "16px"), padding: { top, bottom } };
};
const oldPrice = () => {
  return {
    text: "$ 150.99",
    fontSize: "16px",
    font: {
      ...font(),
      bold: false,
      strike: true,
    },
    fontColor: "#6f6f6f",
  };
};
const content = (
  align = "center",
  html = "Men Maroon Printed Round Neck T-shirt"
) => {
  if (align === "center") {
    html = '<p style="text-align: center;">' + html + "</p>";
  }
  let content = {
    html,
    padding: padding5(),
    fontSize: "14px",
    font: { ...font(), bold: false },
    fontColor: "#6f6f6f",
  };
  if (align !== "center") {
    content.align = align;
  }
  return content;
};
const button = (align = "center", top = "5px", text = "Buy now") => {
  return {
    text,
    align,
    padding: { top: "15px", bottom: "15px", left: "25px", right: "25px" },
    margin: { top, bottom: "10px" },
    fontFamily: "",
    fontSize: "16px",
    borderRadius: { tl: "3px", tr: "3px", bl: "3px", br: "3px" },
    borderStyle: "none",
    autoWidth: true,
    font: { ...font(), bold: false },
    fontColor: "#ffffff",
    background: "#007bff",
    borderColor: "#1c3f6f",
  };
};
const menu = () => {
  return {
    links: [
      { label: "Link 1", href: "" },
      { label: "Link 2", href: "" },
      { label: "Link 3", href: "" },
    ],
    fontSize: "14px",
    fontColor: "#111111",
    align: "right",
    padding: padding0(),
    hideOnMobile: false,
  };
};
const social = (align = "right", icon = "1") => {
  return {
    icons: ["instagram", "facebook", "linkedin", "twitter"].map((ico) => {
      return {
        link: "",
        icon: ico,
        imgUrl: "/img/theme/social/icon-set-" + icon + "/" + ico + ".png",
      };
    }),
    iconSet: "icon-set-1",
    iconSpacing: 10,
    align,
    padding: padding0(),
    hideOnMobile: false,
  };
};
const galImage = (img = "267X200", fixedWidth = 267) => {
  return {
    src: "/img/theme/gallery/" + img + ".jpg",
    alt: img,
    link: "",
    fixedWidth,
    fixedAlign: "center",
    hideOnMobile: false,
  };
};
const featureImage = (alt = "231-640x300", width = 640, align = "center") => {
  return {
    src: "/img/theme/feature/" + alt + ".jpg",
    link: "",
    alt,
    width,
    align,
  };
};
const btnAndMoreImg = (
  img = "rating-1",
  alt = "Rating",
  width = 300,
  top = "0px"
) => {
  return {
    src: "/img/theme/buttons-and-more/" + img + ".png",
    link: "",
    alt,
    width,
    align: "center",
    padding: { top, bottom: "30px" },
  };
};
const profile = () => {
  return {
    image: {
      src: "/img/theme/special/speeker.png",
      alt: "Speeker",
      width: 128,
    },
    link: "",
    padding: padding0("60px", "60px"),
    background: "#2d8cff",
    fontColor: "#ffffff",
    name: "Victoria",
    position: "Manager mailerclod.com",
  };
};
const signature = (align = "left") => {
  return {
    src: "/img/theme/special/signature.png",
    alt: "Signature",
    link: "",
    width: 85,
    align,
    padding: padding0("0px", "10px"),
  };
};
const text = (text = "Victoria", align = "left") => {
  return {
    text,
    align,
    fontSize: "14px",
    font: font(),
    fontColor: "#6f6f6f",
  };
};
const lorem =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry ' s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";
const lorem2 =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry";
const lorem3 = "Lorem Ipsum is simply dummy text of .";
const address = "4 Water Avenue Ladson, SC 29456";
const unsubText =
  "You received this email because you signed up on our website or made a purchase from us.";
export const getOldJsonDefaultData = (name) => {
  switch (name) {
    case "FooterType1":
      return {
        props: {
          ...commonProps(),
          social: social("left"),
          heading: {
            ...heading("left", "Contact us", "14px"),
            padding: padding0("10px", "30px"),
          },
          content: { ...content("left", address), padding: padding0("20px") },
          content2: {
            ...content("right", unsubText),
            padding: padding0("10px"),
          },
          unsubscribe: {
            ...content("right", "Unsubscribe"),
            padding: padding0("20px"),
            link: "%%unsubscribe%%",
          },
          padding: padding0(),
        },
        structures: [
          { padding: padding(), columns: [{ elements: [{ tag: "heading" }] }] },
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "content" }, { tag: "social" }] },
              { elements: [{ tag: "spacer" }], width: 20 },
              { elements: [{ tag: "content2" }, { tag: "unsubscribe" }] },
            ],
          },
        ],
      };
    case "FooterType2":
      return {
        props: {
          ...commonProps(),
          social: { ...social("center"), padding: padding0("20px", "30px") },
          heading: {
            ...heading("center", "Contact us", "14px"),
            padding: padding0("10px"),
          },
          content: { ...content("center", address), padding: padding0("20px") },
          content2: {
            ...content("center", unsubText),
            padding: padding0("30px"),
          },
          unsubscribe: {
            ...content("center", "Unsubscribe"),
            padding: padding0("30px"),
            link: "%%unsubscribe%%",
          },
          padding: padding0(),
        },
        structures: [
          {
            padding: { left: "150px", right: "150px" },
            columns: [
              {
                elements: [
                  { tag: "social" },
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "content2" },
                  { tag: "unsubscribe" },
                ],
              },
            ],
          },
        ],
      };
    case "FooterType4":
      return {
        props: {
          ...commonProps(),
          social: social("left"),
          logo: { ...logo1(120, "left"), padding: padding0("20px") },
          content: { ...content("left", address), padding: padding0("20px") },
          content2: {
            ...content("right", unsubText),
            padding: padding0("10px"),
          },
          unsubscribe: {
            ...content("right", "Unsubscribe"),
            padding: padding0("20px"),
            link: "%%unsubscribe%%",
          },
          padding: padding0(),
        },
        structures: [
          { padding: padding(), columns: [{ elements: [{ tag: "logo" }] }] },
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "content" }, { tag: "social" }] },
              { elements: [{ tag: "spacer" }], width: 20 },
              { elements: [{ tag: "content2" }, { tag: "unsubscribe" }] },
            ],
          },
        ],
      };
    case "SpecialType1":
      return {
        props: {
          ...commonProps(),
          lineColor: "#ededf3",
          padding: padding0("0px", "20px"),
        },
        structures: [{ columns: [{ elements: [{ tag: "line" }] }] }],
      };
    case "SpecialType2":
      return {
        props: {
          ...commonProps(),
          background: "transparent",
          padding: padding0("10px", "10px"),
        },
        structures: [{ columns: [{ elements: [{ tag: "space" }] }] }],
      };
    case "SpecialType3":
      return {
        props: {
          ...commonProps(),
          content: {
            ...content("center", lorem),
            padding: padding0("20px", "20px"),
          },
          padding: padding0(),
        },
        structures: [
          { padding: padding(), columns: [{ elements: [{ tag: "content" }] }] },
        ],
      };
    case "SpecialType4":
      return {
        props: {
          ...commonProps(),
          button: {
            ...button(),
            margin: padding0("20px", "20px"),
            text: "Button",
            font: font(),
          },
          padding: padding0(),
        },
        structures: [
          { padding: padding(), columns: [{ elements: [{ tag: "button" }] }] },
        ],
      };
    case "SpecialType5":
      return {
        props: {
          ...commonProps(),
          image: {
            src: "https://picsum.photos/id/233/640/300",
            alt: "640x300",
            link: "",
            width: 640,
            align: "center",
          },
          padding: padding0(),
        },
        structures: [{ columns: [{ elements: [{ tag: "image" }] }] }],
      };
    case "SpecialType6":
      return {
        props: {
          ...commonProps(),
          subHeading: {
            ...subHeading("center", "20px", "20px"),
            font: font(),
            text: "EVENT",
          },
          heading: {
            ...heading("center", "Meeting", "28px"),
            padding: padding0("20px"),
          },
          content: {
            ...content("center", "On 22 Jan 2020 at mailercloud office"),
            padding: padding0(),
          },
          button: {
            ...button("center", "", "Participate"),
            font: { ...font(), bold: true },
            margin: { top: "20px", bottom: "30px" },
          },
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "subHeading" },
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "button" },
                ],
              },
            ],
          },
        ],
      };
    case "SpecialType7":
      return {
        props: {
          ...commonProps(),
          subHeading: {
            ...subHeading("left", "60px", "20px"),
            font: font(),
            text: "EVENT",
          },
          heading: {
            ...heading("left", "Meeting", "28px"),
            padding: padding0("20px"),
          },
          content: {
            ...content("left", "On 22 Jan 2020 at mailercloud office"),
            padding: padding0(),
          },
          button: {
            ...button("left", "", "Participate"),
            font: { ...font(), bold: true },
            margin: { top: "20px", bottom: "40px" },
          },
          spacer1: { height: 40 },
          profile: profile(),
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                width: 0,
                elements: [
                  { tag: "subHeading" },
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "button" },
                ],
              },
              {
                width: 0,
                elements: [
                  { tag: "spacer" },
                  { tag: "image" },
                  { tag: "spacer1" },
                  { tag: "heading1" },
                  { tag: "subHeading1" },
                  { tag: "spacer2" },
                ],
              },
            ],
          },
        ],
      };
    case "SpecialType8":
      return {
        props: {
          ...commonProps(),
          heading: {
            ...heading("left", "Best regards", "16px"),
            padding: padding0("10px", "30px"),
          },
          name: {
            ...text("Victoria", "left"),
            font: { ...font(), bold: false },
          },
          position: {
            ...text("Manager", "left"),
            font: { ...font(), bold: false },
          },
          email: {
            ...text("victoria@emailclouder.com", "left"),
            font: { ...font(), bold: false },
          },
          phone: {
            ...text("+91 00000000", "left"),
            font: { ...font(), bold: false },
          },
          button: {
            ...button("left", "", "Participate"),
            font: { ...font(), bold: true },
            margin: { top: "20px", bottom: "40px" },
          },
          spacer: { height: 10 },
          profile: {
            ...profile(),
            padding: padding35(),
            background: "#ffffff",
          },
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { width: 0, elements: [{ tag: "image" }] },
              {
                width: 0,
                elements: [
                  { tag: "heading" },
                  { tag: "name" },
                  { tag: "position" },
                  { tag: "spacer" },
                  { tag: "email" },
                  { tag: "phone" },
                ],
              },
            ],
          },
        ],
      };
    case "SpecialType9":
      return {
        props: {
          ...commonProps(),
          image: signature(),
          text: text(),
          textPosition: {
            ...text("Manager"),
            font: { ...font(), bold: false },
          },
          padding: padding0(),
          spacer: { height: "30px" },
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "spacer" },
                  { tag: "text" },
                  { tag: "textPosition" },
                  { tag: "image" },
                  { tag: "spacer" },
                ],
              },
            ],
          },
        ],
      };
    case "SpecialType10":
      return {
        props: {
          ...commonProps(),
          image: signature("center"),
          text: text("Victoria", "center"),
          textPosition: {
            ...text("Manager", "center"),
            font: { ...font(), bold: false },
          },
          padding: padding0(),
          spacer: { height: "30px" },
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "spacer" },
                  { tag: "text" },
                  { tag: "textPosition" },
                  { tag: "image" },
                  { tag: "spacer" },
                ],
              },
            ],
          },
        ],
      };
    case "SpecialType11":
      return {
        props: {
          ...commonProps(),
          logo: { ...logo1(120), padding: padding0("40px", "40px") },
          content: {
            ...content("left", lorem2 + lorem3),
            padding: padding0("20px", "20px"),
          },
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "logo" }] },
              { elements: [{ tag: "content" }] },
            ],
          },
        ],
      };
    case "ButtonsAndMoreType1":
      return {
        props: {
          ...commonProps(),
          subHeading: {
            ...subHeading("center", "20px", "20px"),
            font: font(),
            text: "Introducing",
          },
          heading: {
            ...heading(
              "center",
              "Making A New Trend In Poster Printing",
              "28px"
            ),
            padding: padding0("20px"),
          },
          content: { ...content("center", lorem), padding: padding0() },
          button: {
            ...button("center", "", "Know more"),
            font: { ...font(), bold: true },
            margin: { top: "20px", bottom: "30px" },
          },
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "subHeading" },
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "button" },
                ],
              },
            ],
          },
        ],
      };
    case "ButtonsAndMoreType2":
      return {
        props: {
          ...commonProps(),
          heading: {
            ...heading("center", "Would you recommend us to a friend?", "28px"),
            padding: padding0("30px", "20px"),
          },
          content: { ...content("center", lorem3), padding: padding0("10px") },
          image: btnAndMoreImg(),
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "image" },
                ],
              },
            ],
          },
        ],
      };
    case "ButtonsAndMoreType3":
      return {
        props: {
          ...commonProps(),
          menu: menu(),
          paddingContent: padding0("0px", "20px"),
          padding: padding0(),
        },
        structures: [{ padding: padding(), columns: [{ elements: [] }] }],
      };
    case "ButtonsAndMoreType4":
      return {
        props: {
          ...commonProps(),
          social: social("center"),
          padding: padding35(),
        },
        structures: [
          { padding: padding(), columns: [{ elements: [{ tag: "social" }] }] },
        ],
      };
    case "ButtonsAndMoreType5":
      return {
        props: {
          ...commonProps(),
          social: social("center", "2"),
          heading: {
            ...heading(
              "center",
              "Do you like the newsletter? Share it!",
              "16px"
            ),
            padding: padding0("20px", "10px"),
          },
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [{ elements: [{ tag: "heading" }, { tag: "social" }] }],
          },
        ],
      };
    case "ButtonsAndMoreType6":
      return {
        props: {
          ...commonProps(),
          heading: {
            ...heading(
              "center",
              "App is available for iOS and Android",
              "28px"
            ),
            padding: padding0("20px", "30px"),
          },
          content: { ...content("center", lorem), padding: padding0("10px") },
          image: btnAndMoreImg("ios", "App store", 150, "10px"),
          image1: btnAndMoreImg("gp", "Play store", 150, "10px"),
          spacer: { height: 10 },
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [{ elements: [{ tag: "heading" }, { tag: "content" }] }],
          },
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "spacer" }] },
              { elements: [{ tag: "image" }] },
              { elements: [{ tag: "spacer" }], width: 20 },
              { elements: [{ tag: "image1" }] },
              { elements: [{ tag: "spacer" }] },
            ],
          },
        ],
      };
    case "FeatureType1":
      return {
        props: {
          ...commonProps(),
          heading: {
            ...heading("center"),
            padding: { top: "30px", bottom: "30px" },
            fontSize: "28px",
          },
          padding: padding0(),
        },
        structures: [
          { padding: padding(), columns: [{ elements: [{ tag: "heading" }] }] },
        ],
      };
    case "FeatureType2":
      return {
        props: {
          ...commonProps(),
          heading: {
            ...heading("center"),
            padding: { top: "0px", bottom: "30px" },
            fontSize: "28px",
          },
          subHeading: subTitle(),
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "subHeading" }, { tag: "heading" }] },
            ],
          },
        ],
      };
    case "FeatureType3":
      return {
        props: {
          ...commonProps(),
          heading: {
            ...heading("left", "Feature", "28px"),
            padding: { top: "23px", bottom: "23px" },
          },
          button: {
            ...button("right", "", "View all"),
            font: { ...font(), bold: true },
            margin: { top: "23px", bottom: "23px" },
            fontColor: "#212529",
            background: "#F7F9FB",
          },
          spacer: { height: 10 },
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "spacer" },
                  { tag: "heading" },
                  { tag: "spacer" },
                ],
              },
              { elements: [{ tag: "button" }] },
            ],
          },
        ],
      };
    case "FeatureType4":
      return {
        props: {
          ...commonProps(),
          image: featureImage(),
          heading: {
            ...heading("center", "Title", "28px"),
            padding: { top: "20px", bottom: "20px" },
          },
          button: {
            ...button("center", "", "Read more"),
            font: { ...font(), bold: true },
            margin: { top: "20px", bottom: "30px" },
          },
          content: { ...content("center", lorem), padding: padding0() },
          padding: padding0(),
        },
        structures: [
          {
            columns: [{ elements: [{ tag: "image" }] }],
          },
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "button" },
                ],
              },
            ],
          },
        ],
      };
    case "FeatureType5":
      return {
        props: {
          ...commonProps(),
          heading: {
            ...heading("center", "Title", "28px"),
            padding: { top: "20px", bottom: "20px" },
            fontColor: "#ffffff",
          },
          button: {
            ...button("center", "", "Read more"),
            font: { ...font(), bold: true },
            margin: { top: "20px", bottom: "30px" },
          },
          content: {
            ...content("center", lorem),
            padding: padding0(),
            fontColor: "#ffffff",
          },
          blockBackground: "#000000",
          bgImage: "/img/theme/feature/231-640x300.jpg",
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "button" },
                ],
              },
            ],
          },
        ],
      };
    case "FeatureType6":
      return {
        props: {
          ...commonProps(),
          image1: featureImage("239-640x300", 267),
          image2: featureImage("240-640x300", 267),
          headingMain: {
            ...heading("center", "Title", "28px"),
            padding: { top: "30px", bottom: "20px" },
          },
          heading1: {
            ...heading("center", "Title", "16px"),
            padding: { top: "0px", bottom: "20px" },
          },
          heading2: {
            ...heading("center", "Title", "16px"),
            padding: { top: "0px", bottom: "20px" },
          },
          subHeading1: subTitle("20px"),
          subHeading2: subTitle("20px"),
          button: {
            ...button("center", "", "Read more"),
            font: { ...font(), bold: true },
            margin: { top: "20px", bottom: "30px" },
          },
          contentMain: { ...content("center", lorem), padding: padding020() },
          content1: { ...content("center", lorem2) },
          content2: { ...content("center", lorem2) },
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "headingMain" }, { tag: "contentMain" }] },
            ],
          },
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "image1" },
                  { tag: "subHeading1" },
                  { tag: "heading1" },
                  { tag: "content1" },
                ],
              },
              {
                elements: [
                  { tag: "image2" },
                  { tag: "subHeading2" },
                  { tag: "heading2" },
                  { tag: "content2" },
                ],
              },
            ],
          },
          {
            padding: padding(),
            columns: [{ elements: [{ tag: "button" }] }],
          },
        ],
      };
    case "FeatureType8":
      return {
        props: {
          ...commonProps(),
          image1: featureImage("239-70x70", 70),
          image2: featureImage("240-70x70", 70),
          image3: featureImage("241-70x70", 70),
          image4: featureImage("242-70x70", 70),
          headingMain: {
            ...heading("left", "Title", "28px"),
            padding: { top: "30px", bottom: "20px" },
          },
          heading1: {
            ...heading("left", "Title", "16px"),
            padding: { top: "2px", bottom: "2px" },
          },
          heading2: {
            ...heading("left", "Title", "16px"),
            padding: { top: "2px", bottom: "2px" },
          },
          heading3: {
            ...heading("left", "Title", "16px"),
            padding: { top: "2px", bottom: "2px" },
          },
          heading4: {
            ...heading("left", "Title", "16px"),
            padding: { top: "2px", bottom: "2px" },
          },
          contentMain: { ...content("left", lorem), padding: padding020() },
          content1: { ...content("left", lorem3), fontSize: "13px" },
          content2: { ...content("left", lorem3), fontSize: "13px" },
          content3: { ...content("left", lorem3), fontSize: "13px" },
          content4: { ...content("left", lorem3), fontSize: "13px" },
          spacer: { height: 20 },
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "headingMain" }, { tag: "contentMain" }] },
            ],
          },
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "image1" }] },
              { elements: [{ tag: "spacer" }], width: 15 },
              { elements: [{ tag: "heading1" }, { tag: "content1" }] },
              { elements: [{ tag: "image2" }] },
              { elements: [{ tag: "spacer" }], width: 15 },
              { elements: [{ tag: "heading2" }, { tag: "content2" }] },
            ],
          },
          {
            padding: padding(),
            columns: [{ elements: [{ tag: "spacer" }] }],
          },
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "image3" }] },
              { elements: [{ tag: "spacer" }], width: 15 },
              { elements: [{ tag: "heading3" }, { tag: "content3" }] },
              { elements: [{ tag: "image4" }] },
              { elements: [{ tag: "spacer" }], width: 15 },
              { elements: [{ tag: "heading4" }, { tag: "content4" }] },
            ],
          },
        ],
      };
    case "FeatureType9":
      return {
        props: {
          ...commonProps(),
          image: { ...featureImage("221-640x300"), padding: padding0("20px") },
          heading: { ...heading("left", "Title", "28px"), padding: padding0() },
          content: { ...content("left", lorem2), padding: padding0("30px") },
          padding: padding0(),
        },
        structures: [
          {
            columns: [{ elements: [{ tag: "image" }] }],
          },
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "heading" }] },
              { elements: [{ tag: "content" }] },
            ],
          },
        ],
      };
    case "FeatureType10":
      return {
        props: {
          ...commonProps(),
          image: {
            ...featureImage("268-267x267", 267),
            padding: padding0("20px"),
          },
          heading: {
            ...heading("left", "Title", "28px"),
            padding: padding0("10px"),
          },
          content: { ...content("left", lorem), padding: padding0() },
          subHeading: { ...subTitle("0px", "15px"), align: "left" },
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "image" }] },
              { elements: [{ tag: "spacer" }], width: 20 },
              {
                elements: [
                  { tag: "subHeading" },
                  { tag: "heading" },
                  { tag: "content" },
                ],
              },
            ],
          },
        ],
      };
    case "GalleryType1":
      return {
        props: {
          ...commonProps(),
          image1: galImage("267X425"),
          image2: galImage(),
          spacer: { height: 25 },
          image3: galImage(),
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "image1" }] },
              {
                elements: [
                  { tag: "image2" },
                  { tag: "spacer" },
                  { tag: "image3" },
                ],
              },
            ],
          },
        ],
      };
    case "GalleryType2":
      return {
        props: {
          ...commonProps(),
          image1: galImage(),
          image2: galImage(),
          spacer: { height: 25 },
          image3: galImage("267X425"),
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "image1" },
                  { tag: "spacer" },
                  { tag: "image2" },
                ],
              },
              { elements: [{ tag: "image3" }] },
            ],
          },
        ],
      };
    case "GalleryType3":
      return {
        props: {
          ...commonProps(),
          image1: galImage("170X170", 170),
          image2: galImage("170X170", 170),
          spacer: { height: 25 },
          image3: galImage("170X170", 170),
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "image1" }] },
              { elements: [{ tag: "spacer" }] },
              { elements: [{ tag: "image2" }] },
              { elements: [{ tag: "spacer" }] },
              { elements: [{ tag: "image3" }] },
            ],
          },
        ],
      };
    case "GalleryType4":
      return {
        props: {
          ...commonProps(),
          image1: galImage("267X425"),
          image2: galImage("267X425"),
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "image1" }] },
              { elements: [{ tag: "image2" }] },
            ],
          },
        ],
      };
    case "GalleryType5":
      return {
        props: {
          ...commonProps(),
          image1: galImage("365X365", 365),
          image2: galImage("170X170", 170),
          image3: galImage("170X170", 170),
          spacer: { height: 25 },
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "image1" }] },
              {
                elements: [
                  { tag: "image2" },
                  { tag: "spacer" },
                  { tag: "image3" },
                ],
              },
            ],
          },
        ],
      };
    case "GalleryType6":
      return {
        props: {
          ...commonProps(),
          image3: galImage("365X365", 365),
          image2: galImage("170X170", 170),
          image1: galImage("170X170", 170),
          spacer: { height: 25 },
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "image1" },
                  { tag: "spacer" },
                  { tag: "image2" },
                ],
              },
              { elements: [{ tag: "image3" }] },
            ],
          },
        ],
      };
    case "ECommerceType1":
      return {
        props: {
          ...commonProps(),
          image: tshirt(),
          subHeading: subHeading(),
          heading: tshirtHeading(),
          content: content(),
          price: price(),
          button: button(),
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "image" },
                  { tag: "subHeading" },
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "price" },
                  { tag: "oldPrice" },
                  { tag: "button" },
                ],
              },
            ],
          },
        ],
      };
    case "ECommerceType2":
      return {
        props: {
          ...commonProps(),
          image: { ...tshirt("b"), padding: { top: "25px", bottom: "10px" } },
          subHeading: {
            ...subHeading("left", "10px"),
            font: { ...font(), bold: false },
          },
          heading: {
            ...tshirtHeading("left"),
            padding: { top: "10px", bottom: "5px" },
          },
          content: {
            ...content("left"),
            padding: { top: "10px", bottom: "5px" },
          },
          price: price("left", "10px"),
          oldPrice: oldPrice(),
          button: button("left", "10px"),
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [{ tag: "image" }],
                width: 270,
              },
              {
                elements: [
                  { tag: "subHeading" },
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "price" },
                  { tag: "oldPrice" },
                  { tag: "button" },
                ],
              },
            ],
          },
        ],
      };
    case "ECommerceType3":
      return {
        props: {
          ...commonProps(),
          image: { ...tshirt("r"), padding: { top: "25px", bottom: "10px" } },
          subHeading: {
            ...subHeading("right", "10px"),
            font: { ...font(), bold: false },
          },
          heading: {
            ...tshirtHeading("right"),
            padding: { top: "10px", bottom: "5px" },
          },
          content: {
            ...content("right"),
            padding: { top: "10px", bottom: "5px" },
          },
          price: price("right", "10px"),
          oldPrice: oldPrice(),
          button: button("right", "10px"),
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "subHeading" },
                  { tag: "heading" },
                  { tag: "content" },
                  { tag: "price" },
                  { tag: "oldPrice" },
                  { tag: "button" },
                ],
              },
              {
                elements: [{ tag: "image" }],
                width: 270,
              },
            ],
          },
        ],
      };
    case "ECommerceType4":
      return {
        props: {
          ...commonProps(),
          image: tshirt(),
          heading1: tshirtHeading(),
          content1: content(),
          price1: price(),
          oldPrice1: oldPrice(),
          button1: button(),
          image1: tshirt("b"),
          heading2: tshirtHeading(),
          content2: content(),
          price2: price(),
          button2: button(),
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "image" },
                  { tag: "heading1" },
                  { tag: "content1" },
                  { tag: "price1" },
                  { tag: "oldPrice1" },
                  { tag: "button1" },
                ],
                width: 0,
              },
              {
                elements: [
                  { tag: "image1" },
                  { tag: "heading2" },
                  { tag: "content2" },
                  { tag: "price2" },
                  { tag: "oldPrice2" },
                  { tag: "button2" },
                ],
                width: 0,
              },
            ],
          },
        ],
      };
    case "ECommerceType5":
      return {
        props: {
          ...commonProps(),
          image: tshirt(),
          heading1: tshirtHeading(),
          content1: content(),
          price1: price(),
          oldPrice1: oldPrice(),
          button1: button(),
          image1: tshirt("b"),
          heading2: tshirtHeading(),
          content2: content(),
          price2: price(),
          oldPrice2: oldPrice(),
          button2: button(),
          image2: tshirt("r"),
          heading3: tshirtHeading(),
          content3: content(),
          price3: price(),
          button3: button(),
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              {
                elements: [
                  { tag: "image" },
                  { tag: "heading1" },
                  { tag: "content1" },
                  { tag: "price1" },
                  { tag: "oldPrice1" },
                  { tag: "button1" },
                ],
                width: 0,
              },
              {
                elements: [
                  { tag: "image1" },
                  { tag: "heading2" },
                  { tag: "content2" },
                  { tag: "price2" },
                  { tag: "oldPrice2" },
                  { tag: "button2" },
                ],
                width: 0,
              },
              {
                elements: [
                  { tag: "image2" },
                  { tag: "heading3" },
                  { tag: "content3" },
                  { tag: "price3" },
                  { tag: "oldPrice3" },
                  { tag: "button3" },
                ],
                width: 0,
              },
            ],
          },
        ],
      };
    case "HeaderType1":
      return {
        props: {
          ...commonProps(),
          logo: logo1(),
          padding: padding35(),
        },
        structures: [
          { padding: padding(), columns: [{ elements: [{ tag: "logo" }] }] },
        ],
      };
    case "HeaderType2":
      return {
        props: {
          ...commonProps(),
          heading: heading(),
          logo: logo1WithPadd(),
          padding: padding0(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "logo" }] },
              { elements: [{ tag: "heading" }] },
            ],
          },
        ],
      };
    case "HeaderType3":
      return {
        props: {
          ...commonProps(),
          logo: { ...logo1(), align: "left", width: 100 },
          menu: menu(),
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "logo" }] },
              { elements: [{ tag: "spacer" }] },
              { elements: [{ tag: "menu" }] },
            ],
          },
        ],
      };
    case "HeaderType4":
      return {
        props: {
          ...commonProps(),
          heading: { ...heading(), align: "left", padding: padding0() },
          menu: menu(),
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "heading" }] },
              { elements: [{ tag: "spacer" }] },
              { elements: [{ tag: "menu" }] },
            ],
          },
        ],
      };
    case "HeaderType5":
      return {
        props: {
          ...commonProps(),
          logo: { ...logo1(), width: 100 },
          menu: { ...menu(), align: "left" },
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "menu" }] },
              { elements: [{ tag: "spacer" }] },
              { elements: [{ tag: "logo" }] },
            ],
          },
        ],
      };
    case "HeaderType6":
      return {
        props: {
          ...commonProps(),
          logo: { ...logo1(), width: 100 },
          menu: { ...menu(), align: "center" },
          divider: { border: "1px solid #ededf3", padding: { bottom: "20px" } },
          padding: { top: "10px", bottom: "20px" },
          menuPadding: { top: "0px", bottom: "10px" },
        },
        structures: [
          { padding: padding(), columns: [{ elements: [{ tag: "logo" }] }] },
          { columns: [{ elements: [{ tag: "divider" }] }] },
          { padding: padding(), columns: [{ elements: [{ tag: "menu" }] }] },
        ],
      };
    case "HeaderType7":
      return {
        props: {
          ...commonProps(),
          logo: { ...logo1(), width: 100 },
          menu: { ...menu(), align: "center" },
          divider: { border: "1px solid #ededf3", padding: { bottom: "20px" } },
          menuPadding: { top: "10px", bottom: "20px" },
          padding: { top: "0px", bottom: "10px" },
        },
        structures: [
          { padding: padding(), columns: [{ elements: [{ tag: "menu" }] }] },
          { columns: [{ elements: [{ tag: "divider" }] }] },
          { padding: padding(), columns: [{ elements: [{ tag: "logo" }] }] },
        ],
      };
    case "HeaderType8":
      return {
        props: {
          ...commonProps(),
          logo: {
            ...logo1(),
            width: 100,
            padding: padding020(),
            align: "left",
          },
          menu: { ...menu(), align: "left" },
          menuPadding: { top: "0px", bottom: "0px" },
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [{ elements: [{ tag: "logo" }, { tag: "menu" }] }],
          },
        ],
      };
    case "HeaderType9":
      return {
        props: {
          ...commonProps(),
          logo: {
            ...logo1(),
            width: 100,
            padding: padding020(),
            align: "right",
          },
          menu: { ...menu() },
          menuPadding: { top: "0px", bottom: "0px" },
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [{ elements: [{ tag: "logo" }, { tag: "menu" }] }],
          },
        ],
      };
    case "HeaderType10":
      return {
        props: {
          ...commonProps(),
          logo: { ...logo1(), align: "left", width: 178 },
          social: social(),
          padding: padding35(),
        },
        structures: [
          {
            padding: padding(),
            columns: [
              { elements: [{ tag: "logo" }] },
              { elements: [{ tag: "social" }] },
            ],
          },
        ],
      };
    default:
      return {
        props: {},
        structures: [{ columns: [{ elements: [{ tag: null }] }] }],
      };
  }
};
