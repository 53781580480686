<template>
  <div class="mld-delete" title="Delete" :disabled="deleteDisabled">
    <a>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        focusable="false"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        class="iconify"
        data-icon="majesticons:trash"
        data-inline="false"
        style="transform: rotate(360deg)"
      >
        <g fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 5a1 1 0 0 0-1 1h4a1 1 0 0 0-1-1h-2zm0-2a3 3 0 0 0-3 3H4a1 1 0 0 0 0 2h1v10a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8h1a1 1 0 1 0 0-2h-4a3 3 0 0 0-3-3h-2zm0 8a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5zm4 0a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5z"
            fill="currentColor"
          ></path>
        </g>
      </svg>
    </a>
  </div>
</template>
<script>
export default {
  name: "DeleteIcon",
  props: {
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
