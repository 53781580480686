<template>
  <div class="dd-list-accrodion single individual" role="tablist">
    <b-card no-body>
      <b-card-body class="dd-min-height">
        <bread-crumb />
        <p class="dd-label">For HTML experts</p>
        <p class="dd-help-text dd-mb-10">
          Ensure that your HTML and CSS code is correct and meets the
          requirements for the layout of email messages.
        </p>
        <div class="dd-alert-warning dd-mt-15">
          <b-media no-body>
            <b-media-aside vertical-align="center">
              <img
                src="../../assets/img/icon/warning-ico-light.svg"
                alt=""
                width="18"
              />
            </b-media-aside>
            <b-media-body class="dd-ml-15">
              <strong>Warning!</strong> Your code may affect the visual
              appearance of email.
            </b-media-body>
          </b-media>
        </div>
        <ruler class="dd-mx--20" />
        <padding-all
          :value="value.padding"
          @input="updateProps('padding', $event)"
          >Padding</padding-all
        >
        <b-row>
          <b-col>
            <ruler class="dd-mx--20" />
            <hide-element
              :value="value.hideElement"
              @input="updateProps('hideElement', $event)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import HideElement from "../Properties/HideElement.vue";
export default {
  name: "SpacerSettings",
  components: {
    HideElement,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    updateProps(property, value) {
      this.$store.dispatch("ddb/updateElementProperty", { property, value });
    },
  },
};
</script>
