var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"mld-container",class:{
    'ml-desk-hidden': _vm.props.hideElement === 'hide-pc',
    'ml-mobile-hidden': _vm.props.hideElement === 'hide-mobile',
  }},[_c('td',{class:{ 'ml-m-p20b': _vm.align == 'left' && _vm.paddingMobile },style:({ margin: '0px', padding: '0px', width: _vm.width }),attrs:{"align":"center","valign":"top"}},[_c('dd-table',{style:({
        ..._vm.padding,
        ..._vm.border,
        ..._vm.borderRadius,
        backgroundColor: _vm.setColor(_vm.props.backgroundColor),
        ..._vm.backgroundImage,
      }),attrs:{"width":"100%","bgcolor":_vm.setColor(_vm.props.backgroundColor)}},[_vm._t("default")],2)],1),(_vm.columnIndent)?_c('td',{staticClass:"ml-hidden",staticStyle:{"padding":"0","margin":"0"},style:({ width: parseInt(_vm.columnIndent) + 'px' })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }