<template>
  <div>
    <multiselect
      v-model="styleValue"
      :options="options"
      :option-height="104"
      :allow-empty="false"
      :show-labels="false"
      class="dd-multiselect dd-select-radius-8 dd-select-border"
    >
      <template slot="singleLabel" slot-scope="props">
        <hr :class="props.option.class" />
      </template>
      <template slot="option" slot-scope="props">
        <hr :class="props.option.class" />
      </template>
    </multiselect>
  </div>
</template>
<script>
export default {
  name: "BorderStyle",
  props: {
    value: { type: String, default: "" },
  },
  components: {},
  data() {
    return {
      styleValue: this.value
        ? { value: this.value, class: this.value }
        : { value: "solid", class: "solid" },
      options: [
        {
          value: "solid",
          class: "solid",
        },
        {
          value: "dashed",
          class: "dashed",
        },
        {
          value: "dotted",
          class: "dotted",
        },
      ],
    };
  },
  watch: {
    styleValue(val) {
      this.$emit("input", val.value);
    },
  },
};
</script>
