<template>
  <div class="dd-link-href">
    <p class="dd-label">
      {{ label }}
      <b-link :href="getHref" target="_blank" class="dd-ml-4 dd-external-link">
        <img src="../../assets/img/icon/link-external.svg" width="9" />
      </b-link>
    </p>
    <b-row :class="{ 'dd-head-group no-gutters': linkType }">
      <b-col cols="4" v-if="linkType">
        <multiselect
          v-model="linkSelect"
          placeholder="Select font"
          :allow-empty="false"
          label="label"
          :options="options"
          @select="changeLinkSelect(linkSelect, $event)"
          class="dd-multiselect dd-select-radius-8"
        >
        </multiselect>
      </b-col>
      <b-col>
        <b-form-input
          @change="changeLinkValue"
          :disabled="linkSelect.disable ? linkSelect.disable : false"
          class="dd-form-control"
          v-model="linkValue"
        ></b-form-input>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { htmlDecode } from "../../functions/common";
const footerTags = [
  "%%unsubscribe%%",
  "%%view_in_browser%%",
  "%%report_abuse%%",
  "%%mailing_preference%%",
];
export default {
  name: "LinkHref",
  props: {
    value: { type: String, default: "https://" },
    label: { type: String, default: "Link" },
    linkType: { type: Boolean, default: true },
  },
  components: {},
  data() {
    return {
      linkSelect: { label: "Site", value: "https://", disable: false },
      linkValue: this.value ? this.value : "https://",
      options: [
        { label: "Site", value: "https://", disable: false },
        { label: "Mail", value: "mailto:", disable: false },
        { label: "Tel", value: "tel:", disable: false },
        { label: "Unsubscribe", value: "%%unsubscribe%%", disable: true },
        {
          label: "View in browser",
          value: "%%view_in_browser%%",
          disable: true,
        },
        { label: "Report abuse", value: "%%report_abuse%%", disable: true },
        {
          label: "Mailing preference",
          value: "%%mailing_preference%%",
          disable: true,
        },
      ],
    };
  },
  watch: {
    linkValue(val) {
      if (val === "https://" || val === "http://") {
        this.$emit("input", "");
      } else {
        this.$emit(
          "input",
          footerTags.includes(val) ? val : encodeURI(decodeURI(htmlDecode(val)))
        );
      }
    },
    value(val) {
      if (this.linkSelect && this.linkSelect.label === "Site") {
        this.linkValue = val || "https://";
      } else {
        this.linkValue = val;
      }
    },
  },
  created() {
    if (this.value) {
      this.linkSelect = this.getSelectedVal(this.value);
    }
  },
  computed: {
    getHref() {
      const links = ["http://", "https://", "mailto:", "tel:", ...footerTags];
      if (!this.linkValue || links.includes(this.linkValue)) {
        return null;
      } else {
        return this.linkValue;
      }
    },
  },
  methods: {
    getSelectedVal(val) {
      var i;
      for (i = 0; i < this.options.length; i++) {
        if (val.indexOf(this.options[i].value) === 0) {
          return this.options[i];
        }
      }
      return this.options[0] ? this.options[0] : "";
    },
    changeLinkSelect(item, event) {
      if (event && event.value) {
        var res = event.value;
        /* if (item && item.value) {
          var str = this.linkValue
          res = str.replace(item.value, event.value)
        } */
        this.linkValue = res;
      }
    },
    changeLinkValue() {
      if (this.linkSelect && this.linkSelect.value) {
        if (this.linkSelect.value === "https://") {
          if (
            this.linkValue.indexOf(this.linkSelect.value) !== 0 &&
            this.linkValue.indexOf("http://") !== 0
          ) {
            this.linkValue = this.linkSelect.value + this.linkValue;
          }
        } else if (this.linkValue.indexOf(this.linkSelect.value) !== 0) {
          this.linkValue = this.linkSelect.value + this.linkValue;
        }
      }
    },
  },
};
</script>
