<template>
  <div class="dd-loader position-absolute">
    <div class="dd-vh-center position-absolute">
      <img src="../../assets/img/icon/svg-loader.svg" width="124" />
      <p class="dd-font-15 dd-text-secondary dd-mt-20">
        <i>Wait for few moment</i>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "DdFullLoad",
  components: {},
  data() {
    return {};
  },
};
</script>
