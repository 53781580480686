import { replaceWithHyphen } from "./StyleConverter.js";
export const htmlToJson = (div, count = 0) => {
  var tag = {};
  if (div.nodeName === "#comment") {
    return null;
  }
  if (
    !div.tagName ||
    (count > 1 &&
      ["P", "H1", "H2", "H3", "H4", "H5", "H6"].includes(div.tagName))
  ) {
    if (div.outerHTML) {
      return div.outerHTML;
    }
    if (div.nodeValue.replace(/\s+/g, " ") !== " ") {
      return div.nodeValue;
    }
    return null;
  }
  tag["tagName"] = div.tagName;
  if (div.attributes) {
    let attrs = {};
    for (let i = 0; i < div.attributes.length; i++) {
      var attr = div.attributes[i];
      if (
        attr.value &&
        !["border", "cellpadding", "cellspacing", "role"].includes(attr.name) &&
        (!["width", "height"].includes(attr.name) ||
          !["0", "0px"].includes(attr.value)) &&
        (attr.name !== "colspan" || !["1"].includes(attr.value))
      ) {
        attrs[attr.name] =
          attr.name === "style" ? parseStyleTagToJson(attr.value) : attr.value;
      }
    }
    if (attrs.style) {
      attrs = { ...attrs, ...attrs.style };
      delete attrs.style;
    }
    if (Object.keys(attrs).length) {
      tag["attrs"] = attrs;
    }
  } else if (div.nodeValue.replace(/\s+/g, " ") !== " ") {
    tag["nodeValue"] = div.nodeValue;
  } else {
    return null;
  }
  let children = [];
  for (let i = 0; i < div.childNodes.length; i++) {
    let val = htmlToJson(div.childNodes[i], count + 1);
    if (val) {
      if (typeof val === "object") {
        children.push(val);
      } else {
        tag["nodeValue"] = val;
      }
    }
  }
  if (children.length) {
    tag["children"] = children;
  }
  if (!tag.children && !tag.attrs && !tag.nodeValue) {
    return null;
  }
  if (!tag.attrs && !tag.nodeValue && tag.children.length === 1) {
    return tag.children[0];
  }
  if (
    tag.children &&
    tag.children.length === 1 &&
    !tag.children[0].attrs &&
    tag.children[0].children
  ) {
    tag.children = tag.children[0].children;
  }
  updateChildren(tag);
  return tag;
};
const updateChildren = (tag) => {
  if (
    tag.children &&
    tag.tagName === "TABLE" &&
    tag.children.length === 1 &&
    tag.children[0].attrs &&
    tag.attrs
  ) {
    if (
      !Object.keys(tag.attrs).includes("padding") &&
      !(
        Object.keys(tag.attrs).includes("width") &&
        Object.keys(tag.children[0].attrs).includes("width")
      ) &&
      !(
        Object.keys(tag.attrs).includes("class") &&
        Object.keys(tag.children[0].attrs).includes("class")
      )
    ) {
      tag.attrs = { ...tag.attrs, ...tag.children[0].attrs };
      if (tag.children[0].children) {
        tag.children = tag.children[0].children;
      } else if (tag.children[0].nodeValue) {
        tag.nodeValue = tag.children[0].nodeValue;
      }
    }
  }
  if (
    tag.children &&
    tag.children.length === 1 &&
    tag.children[0].attrs &&
    tag.attrs
  ) {
    if (JSON.stringify(tag.attrs) === JSON.stringify(tag.children[0].attrs)) {
      tag.oldChildren = tag.children;
    }
  }
};
const parseStyleTagToJson = (styles) => {
  styles = styles.split(";");
  let newStyles = {};
  styles.forEach((style) => {
    if (style.trim()) {
      style = style.split(":");
      if (
        style[0].trim() &&
        style[1].trim() &&
        style[0].trim() !== "word-break" &&
        (["padding", "width"].includes(style[0].trim())
          ? style[1].trim() !== "0px"
          : true)
      ) {
        newStyles[style[0].trim()] = style[1].trim();
      }
    }
  });
  return newStyles;
};
export const rgbToHex = (rgb) => {
  if (!rgb || rgb.indexOf("rgb") !== 0) {
    return rgb;
  }
  rgb = rgb.substr(4).split(")")[0].split(",");
  return rgb.reduce((hex, c) => {
    c = (+c).toString(16);
    if (c.length === 1) c = "0" + c;
    return hex + c;
  }, "#");
};
export const addElementTag = (element, tagName) => {
  if (!element.firstChild || element.firstChild.tagName !== tagName) {
    const tag = document.createElement(tagName);
    tag.innerHTML = element.innerHTML;
    return tag.outerHTML;
  }
  return element.innerHTML;
};
export const removeStyleFromTag = (element, tagName, style) => {
  if (element.childNodes.length === 1) {
    if (
      element.firstChild.tagName === tagName &&
      element.firstChild.style[style]
    ) {
      if (element.firstChild.style.length === 1) {
        return element.firstChild.innerHTML;
      }
      element.firstChild.style.removeProperty(style);
    } else {
      element.firstChild.innerHTML = removeStyleFromTag(
        element.firstChild,
        tagName,
        style
      );
    }
  }
  return element.innerHTML;
};
export const getFirstElementByTag = (element, tagName) => {
  if (element.childNodes.length === 1 && element.firstChild.tagName) {
    if (element.firstChild.tagName === tagName) {
      return element.firstChild;
    } else {
      return getFirstElementByTag(element.firstChild, tagName);
    }
  }
  return element;
};
// export const fullHtmlToJson = (div) => {
//   var tag = {}
//   tag.tagName = div.tagName || div.nodeName
//   if (div.attributes) {
//     let attrs = {}
//     for (let i = 0; i < div.attributes.length; i++) {
//       var attr = div.attributes[i]
//       if (attr.value) {
//         attrs[attr.name] = attr.name === 'style' ? parseStyleTagToJson(attr.value) : attr.value
//       }
//     }
//     if (Object.keys(attrs).length) {
//       tag.attrs = attrs
//     }
//   }
//   if (div.childNodes.length) {
//     let children = []
//     for (let i = 0; i < div.childNodes.length; i++) {
//       let val = fullHtmlToJson(div.childNodes[i])
//       if (val) {
//         children.push(val)
//       }
//     }
//     if (children.length) {
//       tag.children = children
//     }
//   } else if (div.nodeValue && div.nodeValue.replace(/\s+/g, ' ') !== ' ') {
//     tag['nodeValue'] = div.nodeValue
//   } else if (!tag.attrs) {
//     return null
//   }
//   return tag
// }
// export const inlineCss = function (htmlJson, styleJson) {
//   if (htmlJson.tagName === '#text') {
//     return document.createTextNode(htmlJson.nodeValue)
//   }
//   if (htmlJson.tagName === '#comment') {
//     return document.createComment(htmlJson.nodeValue)
//   }
//   let obj = document.createElement(htmlJson.tagName)
//   if (htmlJson.attrs) {
//     for (var attr in htmlJson.attrs) {
//       let value = htmlJson.attrs[attr]
//       if (attr === 'style') {
//         if ()
//         value = createStyleFromJson({ ...value, styleJson[]})
//       }
//       obj.setAttribute(attr, value)
//     }
//   }
//   if (htmlJson.children) {
//     htmlJson.children.forEach(child => {
//       obj.appendChild(inlineCss(child))
//     })
//   }
//   return obj
// }
const createStyleFromJson = (json) => {
  return Object.keys(json)
    .map((k) => k + ":" + json[k])
    .join(";");
};
const replaceKeyWithHyphen = (json) => {
  const newStyles = {};
  for (let k in json) {
    newStyles[replaceWithHyphen(k)] = json[k];
  }
  return newStyles;
};
const getJSonFromStyleTag = (styles) => {
  if (!styles) return {};
  styles = styles.split(";");
  let newStyles = {};
  styles.forEach((style) => {
    if (style.trim()) {
      style = style.split(":");
      const key = style.shift();
      style = style.join(":").trim();
      if (key.trim()) {
        newStyles[key.trim()] = style || "";
      }
    }
  });
  return newStyles;
};
const mergeStyles = (styles, stylesToMerge) => {
  const newStyles = {};
  for (var style in stylesToMerge) {
    if (
      !styles[style] ||
      (stylesToMerge[style] &&
        stylesToMerge[style].toString().indexOf("!important") !== -1)
    ) {
      newStyles[style] = stylesToMerge[style];
    }
  }
  return { ...newStyles, ...styles };
};
export const setStyleToHtml = (div, styles) => {
  const keys = Object.keys(styles);
  for (let i = keys.length - 1; i >= 0; i--) {
    let style = keys[i];
    div.querySelectorAll(style).forEach((el) => {
      const newStyle = mergeStyles(
        getJSonFromStyleTag(el.getAttribute("style")),
        replaceKeyWithHyphen(styles[style])
      );
      if (newStyle["color"]) {
        newStyle["color"] = rgbToHex(newStyle["color"]);
      }
      if (newStyle["background-color"]) {
        newStyle["background-color"] = rgbToHex(newStyle["background-color"]);
      }
      el.setAttribute("style", createStyleFromJson(newStyle));
    });
  }
  return div;
};
