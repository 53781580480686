<template>
  <b-row>
    <b-col>
      <p class="dd-label">Alternate text</p>
      <b-form-input
        v-model="altText"
        class="dd-form-control"
        placeholder="Assign a name to the uploaded image"
      ></b-form-input>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "AltText",
  props: {
    value: { type: String(), default: "" },
  },
  components: {},
  data() {
    return {
      altText: this.value ? this.value : "",
    };
  },
  watch: {
    altText(val) {
      if (this.value !== val) {
        this.$emit("input", val);
      }
    },
    value(val) {
      this.altText = val;
    },
  },
};
</script>
