<template>
  <div class="dd-mb-15">
    <p v-if="label" class="dd-label-light">{{ label }}</p>
    <b-row no-gutters>
      <b-col>
        <div class="dd-head-group">
          <b-row no-gutters>
            <b-col cols="4" class="seperator">
              <font-size
                :value="value.fontSize"
                @input="updateProp('fontSize', $event)"
              />
            </b-col>
            <b-col>
              <font-family
                :value="value.fontFamily"
                @input="updateProp('fontFamily', $event)"
              />
            </b-col>
            <b-col cols="auto">
              <color-picker
                :value="value.color || '#000000'"
                @input="updateProp('color', $event)"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="auto">
        <b-link
          class="dd-advance-btn"
          @click="moreOption = !moreOption"
          :class="{ active: moreOption }"
          v-b-tooltip
          title="Advance option"
        >
          <img src="../../assets/img/icon/ico-chevron.svg"
        /></b-link>
      </b-col>
    </b-row>
    <div v-if="moreOption" class="moreoptions">
      <b-row class="dd-mt-15 dd-mb-15">
        <b-col>
          <font-settings
            :value="value"
            @change="updateProp($event.key, $event.value)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <text-transform
            :value="value.textTransform"
            @input="updateProp('textTransform', $event)"
          />
        </b-col>
        <b-col>
          <letter-spacing
            :value="value.letterSpacing"
            @input="updateProp('letterSpacing', $event)"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import FontSize from "../Properties/FontSize.vue";
import ColorPicker from "../Controls/ColorPicker.vue";
import FontFamily from "../Properties/FontFamily.vue";
import FontSettings from "../Properties/FontSettings.vue";
import TextTransform from "../Properties/TextTransform.vue";
import LetterSpacing from "../Properties/LetterSpacing.vue";
export default {
  name: "FontHeading",
  props: {
    label: { type: String, default: "" },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    FontSize,
    ColorPicker,
    FontFamily,
    FontSettings,
    TextTransform,
    LetterSpacing,
  },
  data() {
    return {
      moreOption: false,
    };
  },
  methods: {
    updateProp(prop, value) {
      const val = JSON.parse(JSON.stringify(this.value));
      val[prop] = value;
      this.$emit("input", val);
    },
  },
};
</script>
