<template>
  <dd-table
    class="ml-content"
    width="100%"
    :align="align"
    :style="borderTopBottom"
    :class="{
      'ml-desk-hidden': props.hideElement === 'hide-pc',
      'ml-mobile-hidden': props.hideElement === 'hide-mobile',
    }"
  >
    <tbody>
      <tr>
        <td
          :align="align"
          :bgcolor="setColor(props.backgroundColor)"
          :style="{
            ...padding,
            backgroundColor: setColor(props.backgroundColor),
            ...backgroundImage,
          }"
        >
          <dd-table
            class="ml-content-body"
            :align="align"
            :width="width === '100%' ? '100%' : parseInt(width)"
            :bgcolor="
              setColor(props.contentBackground || commonProps.contentBackground)
            "
            :style="{
              width,
              backgroundColor: setColor(
                props.contentBackground || commonProps.contentBackground
              ),
              ...border,
            }"
          >
            <tbody>
              <slot />
            </tbody>
          </dd-table>
        </td>
      </tr>
    </tbody>
  </dd-table>
</template>
<script>
import { mapState } from "vuex";
import { parseBorderStyle } from "../../plugins/StyleConverter.js";
export default {
  name: "MainBlockPreview",
  props: {
    mainIndex: {
      type: Number,
      default: -1,
    },
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      commonProps: (state) => state.ddb.htmlJson.properties,
    }),
    align() {
      return this.commonProps.containerAlign || "center";
    },
    border() {
      return parseBorderStyle(this.props.border);
    },
    padding() {
      let padding = { padding: "0px" };
      if (this.props.padding) {
        padding.paddingTop = this.props.padding.top || this.props.padding;
        padding.paddingBottom = this.props.padding.bottom || this.props.padding;
      }
      return padding;
    },
    borderTopBottom() {
      let border = {};
      if (this.commonProps.containerType === "list" && this.mainIndex !== 0) {
        border.borderWidth = "15px 0px 0px";
        border.borderColor = "transparent";
        border.borderStyle = "solid";
        border.borderCollapse = "separate";
      }
      return border;
    },
    backgroundImage() {
      if (this.props.backgroundImage) {
        return {
          backgroundImage: "url(" + this.props.backgroundImage + ")",
          backgroundRepeat: this.props.backgroundRepeat || "no-repeat",
          backgroundPosition: this.props.backgroundPosition || "center top",
        };
      }
      return {};
    },
    width() {
      return this.commonProps.containerType === "fullwidth"
        ? "100%"
        : parseInt(this.commonProps.emailWidth) + "px";
    },
  },
};
</script>
