<template>
  <dd-table
    class="ml-content"
    :align="align"
    :style="borderTopBottom"
    @mouseenter.native="activated = true"
    @mouseleave.native="activated = false"
    @click.native.stop="onClick"
  >
    <tr class="mld-hover">
      <td
        class="mld-mailer mld-frame mld-section-frame mldev-disable-select mld-hover"
        :align="align"
        :bgcolor="setColor(props.backgroundColor)"
        :style="{
          ...padding,
          backgroundColor: setColor(props.backgroundColor),
          ...backgroundImage,
        }"
        :class="{
          'mld-hidden-element': props.hideElement,
          'mld-activated': activated && !hover && 'main-' + propKey != selected,
          'mld-active': 'main-' + propKey == selected,
        }"
      >
        <add-section class="top" @add="addSection(0)" />
        <div class="mld-structure-type">Section</div>
        <action-buttons
          :selected="'main-' + propKey == selected"
          :delete-disabled="deleteDisabled"
          @delete="onDelete"
          @copy="onCopy"
        />
        <add-section class="bottom" @add="addSection(1)" />
        <draggable
          class="ml-content-body"
          draggable=".mld-dragg-structure"
          handle=".dragg-handle"
          direction="vertical"
          group="structure"
          tag="dd-table"
          :invertSwap="true"
          :value="value"
          :style="{
            width,
            backgroundColor: setColor(
              props.contentBackground || commonProps.contentBackground
            ),
            ...border,
          }"
          :component-data="{ attrs: { align } }"
          @input="emitter"
          @start="$emit('start', $event, 'drag-structure')"
          @end="$emit('end', $event)"
          @remove="onRemove"
          @add="onAdd"
          @update="onUpdate"
        >
          <slot />
        </draggable>
        <div v-if="props.hideElement" class="mld-hidden-status">
          <span
            :class="
              props.hideElement === 'hide-mobile'
                ? 'ms-icon-hide-on-mobile'
                : 'ms-icon-hide-on-desktop'
            "
          ></span>
        </div>
      </td>
    </tr>
  </dd-table>
</template>
<script>
import { mapState } from "vuex";
import { parseBorderStyle } from "../../plugins/StyleConverter.js";
import draggable from "../Draggable";
import ActionButtons from "./ActionButtons.vue";
import AddSection from "./AddSection.vue";
export default {
  name: "MainBlock",
  components: {
    draggable,
    ActionButtons,
    AddSection,
  },
  props: {
    mainIndex: {
      type: Number,
      default: -1,
    },
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
      type: Array,
      default: null,
    },
    propKey: {
      type: Number,
      default: -1,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return { activated: false };
  },
  computed: {
    ...mapState({
      selected: (state) => state.ddb.selected,
      commonProps: (state) => state.ddb.htmlJson.properties,
    }),
    align() {
      return this.commonProps.containerAlign || "center";
    },
    border() {
      return parseBorderStyle(this.props.border);
    },
    borderTopBottom() {
      let border = {};
      if (this.commonProps.containerType === "list" && this.mainIndex !== 0) {
        border.borderWidth = "15px 0px 0px";
        border.borderColor = "transparent";
        border.borderStyle = "solid";
        border.borderCollapse = "separate";
      }
      return border;
    },
    padding() {
      let padding = { padding: "0px" };
      if (this.props.padding) {
        padding.paddingTop = this.props.padding.top || this.props.padding;
        padding.paddingBottom = this.props.padding.bottom || this.props.padding;
      }
      return padding;
    },
    backgroundImage() {
      if (this.props.backgroundImage) {
        return {
          backgroundImage: "url(" + this.props.backgroundImage + ")",
          backgroundRepeat: this.props.backgroundRepeat || "no-repeat",
          backgroundPosition: this.props.backgroundPosition || "center top",
        };
      }
      return {};
    },
    width() {
      return this.commonProps.containerType === "fullwidth"
        ? "100%"
        : parseInt(this.commonProps.emailWidth) + "px";
    },
  },
  methods: {
    onAdd(e) {
      if (e.pullMode === "clone") {
        this.$store.dispatch("ddb/replaceStructure", {
          mainIndex: this.mainIndex,
          structIndex: e.newIndex,
        });
      }
    },
    onUpdate(e) {
      if (
        e.newDraggableIndex > e.oldDraggableIndex &&
        e.newDraggableIndex !== this.value.length
      ) {
        const value = [...this.value];
        const el = value.splice(e.newDraggableIndex, 1);
        value.splice(e.newDraggableIndex - 1, 0, el[0]);
        this.$emit("input", value);
      }
    },
    emitter(value) {
      this.$emit("input", value);
    },
    onRemove() {
      this.$store.dispatch("ddb/addEmptyStructure", this.mainIndex);
    },
    onClick() {
      this.$store.dispatch("ddb/setAllIndexes", { mainIndex: this.mainIndex });
      this.$store.dispatch("ddb/setSelected", "main-" + this.propKey);
      this.$emit("click");
    },
    onDelete() {
      if (!this.deleteDisabled) {
        this.$emit("confirmDelete", {
          action: "ddb/deleteMainBlock",
          data: this.mainIndex,
        });
      }
    },
    onCopy() {
      this.$store.dispatch("ddb/duplicateMainBlock", this.mainIndex);
    },
    addSection(index) {
      this.$store.dispatch("ddb/addMainBlock", this.mainIndex + index);
      this.scrollToTop(index);
    },
    scrollToTop(index) {
      if (index === 1) {
        var vm = this;
        setTimeout(function () {
          window.frameWindow.scrollTo(0, vm.currentYPosition() + 163);
        }, 100);
      }
    },
    currentYPosition() {
      if (window.frameWindow.pageYOffset) {
        return window.frameWindow.pageYOffset;
      }
      return 0;
    },
  },
};
</script>
