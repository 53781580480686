<template>
  <div class="dd-right-sidebar-body">
    <container-type v-model="containerType" />
    <email-width v-model="emailWidth" />
    <ruler />
    <b-row>
      <b-col>
        <dd-label>Container alignment</dd-label>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <alignment v-model="containerAlign" hide-align-right />
      </b-col>
    </b-row>
    <ruler class="dd-mx--20" />
    <dd-switch v-model="disableResponsive"
      >Disable responsive design
      <img
        src="../../assets/img/icon/info-circle-fill.svg"
        class="info-tool"
        v-b-tooltip.hover
        title="When you disable , this email creative will not work as responsive"
    /></dd-switch>
    <b-media v-if="disableResponsive" no-body class="dd-help-text">
      <b-media-aside>
        <img
          src="../../assets/img/icon/info-circle-fill.svg"
          width="14"
          class="dd-mt-3"
        />
      </b-media-aside>
      <b-media-body class="ml-2">
        <p class="mb-0">
          Disabling this will affect the way your email appears on different
          devices
        </p>
      </b-media-body>
    </b-media>
  </div>
</template>
<script>
import ContainerType from "../Properties/ContainerType.vue";
import EmailWidth from "../Properties/EmailWidth.vue";
import Alignment from "../Properties/Alignment.vue";
export default {
  name: "GeneralContainerSettings",
  components: { ContainerType, EmailWidth, Alignment },
  computed: {
    emailWidth: {
      get() {
        return this.$store.state.ddb.htmlJson.properties.emailWidth || 600;
      },
      set(val) {
        this.$store.dispatch("ddb/updateGneralProperty", {
          property: "emailWidth",
          value: val,
        });
      },
    },
    containerType: {
      get() {
        return (
          this.$store.state.ddb.htmlJson.properties.containerType || "card"
        );
      },
      set(val) {
        this.$store.dispatch("ddb/updateGneralProperty", {
          property: "containerType",
          value: val,
        });
      },
    },
    containerAlign: {
      get() {
        return (
          this.$store.state.ddb.htmlJson.properties.containerAlign || "center"
        );
      },
      set(val) {
        this.$store.dispatch("ddb/updateGneralProperty", {
          property: "containerAlign",
          value: val,
        });
      },
    },
    disableResponsive: {
      get() {
        return (
          this.$store.state.ddb.htmlJson.properties.disableResponsive || false
        );
      },
      set(val) {
        this.$store.dispatch("ddb/updateGneralProperty", {
          property: "disableResponsive",
          value: val,
        });
      },
    },
  },
};
</script>
