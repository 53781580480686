<template>
  <div>
    <div class="dd-right-sidebar-body">
      <b-row>
        <b-col>
          <background-color
            :value="block.props.backgroundColor"
            @input="updateBlockProps('backgroundColor', $event)"
            cols="12"
            >Section backgound</background-color
          >
        </b-col>
        <b-col>
          <background-color
            :value="
              block.props.contentBackground || generalProperty.contentBackground
            "
            @input="updateBlockProps('contentBackground', $event)"
            cols="12"
            >Content background</background-color
          >
        </b-col>
      </b-row>
      <image-upload
        prop="backgroundImage"
        :value="block.props.backgroundImage"
        @input="updateBlockProps('backgroundImage', $event)"
      />
      <template v-if="block.props.backgroundImage">
        <ruler />
        <dd-switch
          :value="block.props.backgroundRepeat"
          checked-value="repeat"
          unchecked-value="no-repeat"
          @input="updateBlockProps('backgroundRepeat', $event)"
          >Repeat image</dd-switch
        >
        <hr class="dd-my-15" />
        <bg-align
          :value="block.props.backgroundPosition"
          @input="updateBlockProps('backgroundPosition', $event)"
        />
      </template>
      <dd-help-text class="mb-0"
        >Background image may not support some email clients ( Windows Outlook
        2007 – 2019, Windows 10 Mail, Android 4.4, Gmail app on iOS & Android
        with non-Gmail account). Set background colour same as image colour to
        avoid this issue</dd-help-text
      >
    </div>
    <list-accrodion>
      <b-card no-body>
        <b-card-body class="pt-4">
          <padding-all
            hide-left-right
            :value="block.props.padding"
            @input="updateBlockProps('padding', $event)"
            >Padding</padding-all
          >
          <ruler />
          <border-settings
            :value="block.props.border"
            @input="updateBlockProps('border', $event)"
            >Border of content</border-settings
          >
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-body class="pt-4">
          <hide-element
            :value="block.props.hideElement"
            @input="updateBlockProps('hideElement', $event)"
          />
        </b-card-body>
      </b-card>
    </list-accrodion>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ImageUpload from "./ImageUpload.vue";
import BackgroundColor from "./BackgroundColor.vue";
import BgAlign from "../Properties/BgAlign.vue";
export default {
  name: "ComponentSettings",
  props: {
    generalProperty: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { ImageUpload, BackgroundColor, BgAlign },
  computed: {
    ...mapState({
      block: (state) => {
        return state.ddb.htmlJson.contents[state.ddb.mainIndex];
      },
    }),
  },
  methods: {
    updateBlockProps(prop, val) {
      this.$store.dispatch("ddb/updateBlockProps", { prop, val });
    },
  },
};
</script>
