const replaceLetter = function (word, find, replace = "") {
  return word.replace(find, replace);
};
export const replaceWithHyphen = function (word) {
  if (["Margin"].includes(word)) {
    return word;
  }
  return replaceLetter(word, /[A-Z]/g, (match) => `-${match.toLowerCase()}`);
};
const valOrZeroPx = (val) => {
  return val || "0px";
};
export const parseToSingleStyle = function (style) {
  if (!style || typeof style === "string") {
    return valOrZeroPx(style);
  }
  return (
    valOrZeroPx(style.top) +
    " " +
    valOrZeroPx(style.right) +
    " " +
    valOrZeroPx(style.bottom) +
    " " +
    valOrZeroPx(style.left)
  );
};
export const parseStyleProperty = function (style, format = "padding{-pos}") {
  let prop = {};
  let val = "";
  let isEqual = true;
  if (typeof style === "object") {
    for (let pos in style) {
      prop[replaceLetter(format, "{-pos}", "-" + replaceWithHyphen(pos))] =
        style[pos];
      if (!val) {
        val = style[pos];
      } else if (val !== style[pos]) {
        isEqual = false;
      }
    }
  }
  if (isEqual) {
    prop = {};
    prop[replaceLetter(format, "{-pos}")] =
      typeof style === "object"
        ? val
        : typeof style === "string"
        ? style
        : "0px";
  }
  return prop;
};
export const parseBorderStyle = function (style) {
  var borderData = parseStyleProperty(style, "border{-pos}");
  return filterBorder(borderData);
};

export const filterBorder = function (borderData) {
  if (typeof borderData === "object") {
    for (let bor in borderData) {
      const myArr = borderData[bor].split(" ");
      if (parseInt(myArr[0]) === 0) {
        delete borderData[bor];
      }
    }
  }
  return borderData;
};
export const parseBorderWidth = function (style) {
  return { "border-width": parseToSingleStyle(style) };
};
export const parseBorderRadius = function (style) {
  return parseStyleProperty(style, "border{-pos}-radius");
};
export const getCSS = function getCSS(styles) {
  var css = [];
  for (let selector in styles) {
    let style = selector + " {";
    for (let prop in styles[selector]) {
      style += replaceWithHyphen(prop) + ":" + styles[selector][prop] + ";";
    }
    style += "}";
    css.push(style);
  }
  return css.join("\n");
};
export const seperateCSS = function (styles) {
  var newStyles = {};
  for (let selector in styles) {
    selector.split(",").forEach((sel) => {
      let parent = newStyles;
      let key = "";
      sel
        .trim()
        .split(" ")
        .forEach((item) => {
          if (item) {
            if (key) {
              parent = parent[key];
            }
            if (!parent[item]) {
              parent[item] = {};
            }
            key = item;
          }
        });
      if (parent && key) {
        parent[key] = { ...parent[key], ...styles[selector] };
      }
    });
  }
  return newStyles;
};
