<template>
  <div>
    <p class="dd-label">Styles</p>
    <b-row align-v="center" class="dd-btn-style">
      <b-col @click="setVal('solid')">
        <span :class="buttonStyle === 'solid' ? 'active' : ''">
          <img
            src="../../assets/img/icon/button-style/button-style-1.svg"
            width="67"
          />
        </span>
      </b-col>
      <b-col @click="setVal('bottomBorder')">
        <span :class="buttonStyle === 'bottomBorder' ? 'active' : ''">
          <img
            src="../../assets/img/icon/button-style/button-style-2.svg"
            width="67"
          />
        </span>
      </b-col>
      <b-col @click="setVal('border')">
        <span :class="buttonStyle === 'border' ? 'active' : ''">
          <img
            src="../../assets/img/icon/button-style/button-style-3.svg"
            width="67"
          />
        </span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: String, default: "" },
  },
  name: "ButtonStyle",
  components: {},
  methods: {
    setVal(val) {
      this.buttonStyle = val;
    },
  },
  watch: {
    buttonStyle(val) {
      this.$emit("input", val);
    },
  },
  data() {
    return {
      buttonStyle: this.value ? this.value : "",
    };
  },
};
</script>
