<template comments>
  <div
    class="mld-block-btn"
    :class="{ 'mld-activated': activated }"
    contenteditable="false"
    @mouseenter="activated = true"
    @mouseleave="activated = false"
  >
    <more-icon v-if="selected" @click.native.stop />
    <move-icon />
    <save-icon v-if="selected" @click.native.stop />
    <copy-icon v-if="selected" @click.native.stop="$emit('copy')" />
    <delete-icon
      v-if="selected"
      :delete-disabled="deleteDisabled"
      @click.native.stop="$emit('delete')"
    />
  </div>
</template>
<script>
import CopyIcon from "./CopyIcon";
import DeleteIcon from "./DeleteIcon";
import MoreIcon from "./MoreIcon";
import MoveIcon from "./MoveIcon";
import SaveIcon from "./SaveIcon";

export default {
  name: "ActionButtons",
  components: {
    CopyIcon,
    DeleteIcon,
    MoreIcon,
    MoveIcon,
    SaveIcon,
  },
  props: {
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { activated: false };
  },
};
</script>
