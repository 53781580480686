var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.props.isPreview || _vm.props.src)?_c('a',{class:{ rollover: _vm.props.rolloverEffect && _vm.props.rolloverSrc },attrs:{"target":"_blank","href":_vm.props.link ? _vm.htmlEncode(_vm.props.link) : false},on:{"click":_vm.onClick}},[(_vm.props.src)?_c('img',{class:{
      'rollover-first': _vm.props.rolloverEffect,
      'adapt-img': _vm.props.responsive !== false,
    },style:({ display: 'block', ..._vm.maxWidthStyle }),attrs:{"src":_vm.props.src,"alt":_vm.props.alt || '',"width":_vm.width,"height":_vm.props.imageSize == 'custom' && _vm.props.proportion === false
        ? _vm.height
        : false,"title":_vm.props.alt ? _vm.props.alt : null},on:{"load":_vm.imageOnload}}):_c('img',{staticClass:"mld-empty-img",attrs:{"src":_vm.defaultSrc,"width":"100%","height":"100"}}),(_vm.props.rolloverEffect && _vm.props.rolloverSrc)?_c('div',[_c('img',{staticClass:"rollover-second",class:{ 'adapt-img': _vm.props.responsive !== false },style:({ display: 'none', ..._vm.maxWidthStyle }),attrs:{"src":_vm.props.rolloverSrc,"alt":_vm.props.alt || '',"width":_vm.width,"height":_vm.props.imageSize == 'custom' && _vm.props.proportion === false
          ? _vm.height
          : false,"title":_vm.props.alt ? _vm.props.alt : null}})]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }