<template>
  <div>
    <dd-label v-if="!hideLabel" class="dd-mb-10"
      ><slot
        >Background image
        <span id="tooltip-bg-warning"
          ><img
            src="../../assets/img/icon/info-circle-fill.svg"
            width="14"
            class="info-tool"
        /></span>
        <b-tooltip target="tooltip-bg-warning" triggers="hover">
          For visibility,<br />upload the blocks on top of the structure. (
          Here, "Blocks" can be an image, text, button, divider, social, spacer,
          menu or HTML )
        </b-tooltip>
      </slot>
    </dd-label>
    <b-tabs nav-wrapper-class="dd-tab sm" pills fill>
      <b-tab active title="From computer" @click="error = ''">
        <div v-if="!src && loaded" class="dd-img-choose-holder">
          <label :for="'choose-id-' + _uid">
            <img src="../../assets/img/icon/upload.svg" width="24" alt="" />
            <p>
              Click here to upload an image<br />
              PNG, JPG, GIF.<br />
              Not more than 3 MB or {{ width }} x {{ height }} px.
            </p>
          </label>
        </div>
        <b-media v-else class="dd-img-uploaded" no-body>
          <b-media-aside vertical-align="center">
            <span class="dd-thumbnail">
              <img
                v-show="loaded"
                :src="src"
                @load="imageOnload($event, $store.state.ddb.selected, prop)"
              />
              <img
                v-show="!loaded"
                src="../../assets/img/icon/image-loader.gif"
              />
              <span class="dd-hover-area">
                <label
                  :for="'choose-id-' + _uid"
                  class="dd-change-btn dd-vh-center"
                  v-b-tooltip
                  title="Change image"
                >
                  <img
                    src="../../assets/img/icon/change-ico.svg"
                    alt=""
                    width="20"
                  />
                </label>
              </span>
            </span>
          </b-media-aside>
          <b-media-body class="ml-3">
            <b-row>
              <b-col>
                <p class="dd-label-light">
                  {{ imageName | showImageName }}<br />
                  {{ imageDimensions }}<br />
                </p>
              </b-col>
              <b-col cols="auto" class="ml-auto">
                <a href="#" class="dd-close" @click.prevent="removeImage">
                  <img
                    src="../../assets/img/icon/close.svg"
                    width="18"
                    alt=""
                  />
                </a>
              </b-col>
            </b-row>
          </b-media-body>
        </b-media>
        <b-form ref="addImageForm">
          <b-form-file
            :id="'choose-id-' + _uid"
            ref="fileupload"
            plain
            class="d-none"
            accept=".png,.jpeg,.jpg,.gif"
            @input="changeFile"
          ></b-form-file>
        </b-form>
      </b-tab>
      <b-tab title="External sources" @click="validateLink(externalLink)">
        <b-row class="dd-sm-row dd-mb-15">
          <b-col>
            <b-form-input
              v-model="externalLink"
              placeholder="External links"
              class="dd-form-control"
              @input="clearValidationMsg"
              @change="validateLink"
            ></b-form-input>
          </b-col>
          <b-col cols="auto">
            <b-button
              variant="dd-outline-tick"
              :disabled="!isValidUrl"
              @click="clickOnTick"
              ><img src="../../assets/img/icon/tick.svg"
            /></b-button>
          </b-col>
        </b-row>
        <b-media no-body v-if="src">
          <b-media-aside vertical-align="center">
            <span class="dd-thumbnail">
              <img
                v-show="loaded"
                :src="src"
                @load="imageOnload($event, $store.state.ddb.selected, prop)"
              />
              <img
                v-show="!loaded"
                src="../../assets/img/icon/image-loader.gif"
              />
            </span>
          </b-media-aside>
          <b-media-body class="ml-3">
            <b-row>
              <b-col>
                <p class="dd-label-light">
                  {{ imageName | showImageName }}<br />
                  {{ imageDimensions }}<br />
                </p>
              </b-col>
              <b-col cols="auto" class="ml-auto">
                <a href="#" class="dd-close" @click.prevent="removeImage">
                  <img
                    src="../../assets/img/icon/close.svg"
                    width="18"
                    alt=""
                  />
                </a>
              </b-col>
            </b-row>
          </b-media-body>
        </b-media>
      </b-tab>
    </b-tabs>
    <b-row class="dd-mt-15" v-if="error">
      <b-col>
        <dd-alert>{{ error }}</dd-alert>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import DdAlert from "../Labels/DdAlert.vue";
export default {
  name: "ImageUpload",
  components: { DdAlert },
  props: {
    width: { type: Number, default: 4000 },
    height: { type: Number, default: 4000 },
    value: { type: String, default: "" },
    prop: { type: [Object, String], default: "", required: true },
    hideLabel: { type: Boolean, default: false },
  },
  computed: {
    isValidUrl() {
      return this.isImgLink(this.externalLink);
    },
    imageName() {
      if (this.name) {
        return this.name;
      }
      const srcSplit = this.src.split("/");
      return srcSplit[srcSplit.length - 1];
    },
  },
  data() {
    return {
      externalLink: "",
      src: "",
      name: "",
      imageDimensions: "",
      loaded: true,
      error: "",
    };
  },
  watch: {
    value(val) {
      this.setValue(val);
    },
  },
  created() {
    this.setValue(this.value);
  },
  filters: {
    showImageName: function (fileName) {
      var name = fileName.split(".").slice(0, -1).join(".");
      var fileExtension = fileName.split(/[#?]/)[0].split(".").pop().trim();
      if (name.length > 10) {
        var newname = name.slice(0, 9) + " ... " + fileExtension;
        fileName = newname;
      }
      return fileName;
    },
  },
  methods: {
    setValue(val) {
      if (val !== this.src) {
        this.src = val;
        this.externalLink = val;
        this.loaded = false;
      }
    },
    changeFile(file) {
      this.error = "";
      if (!file) {
        return;
      }
      this.validateFile(file)
        .then(() => {
          this.src = "";
          this.imageDimensions = "";
          this.loaded = false;
          this.$emit("loding", true);
          const selected = this.$store.state.ddb.selected;
          const prop = this.prop;
          this.uploadImage(file, (src, error) => {
            if (this.$refs.addImageForm) {
              this.$refs.addImageForm.reset();
              this.$refs.fileupload.value = null;
            }
            this.$emit("loding", false);
            if (src) {
              this.externalLink = src;
              this.src = src;
              this.emitImage(src, selected, prop);
            }
            if (error) {
              this.loaded = true;
              this.ddToastMessage(error, "danger");
            }
          });
        })
        .catch(() => {
          this.$refs.addImageForm.reset();
          this.$refs.fileupload.value = null;
          this.error =
            "Uploaded image is more than the permitted limit of 3 MB or " +
            this.width +
            " x " +
            this.height +
            " px.";
        });
    },
    validateFile(file) {
      var vm = this;
      return new Promise(function (resolve, reject) {
        if (file.size > 3 * 1024 * 1024) {
          reject(new Error(""));
        }
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        const objectUrl = _URL.createObjectURL(file);
        img.onload = (image) => {
          _URL.revokeObjectURL(objectUrl);
          if (
            image.target.width > vm.width ||
            image.target.height > vm.height
          ) {
            reject(new Error(""));
          } else {
            resolve();
          }
        };
        img.src = objectUrl;
      });
    },
    clickOnTick() {
      if (this.isImgLink(this.externalLink) && this.src !== this.externalLink) {
        this.src = this.externalLink;
        this.name = this.imageDimensions = "";
        this.loaded = false;
        this.emitImage();
      }
    },
    removeImage() {
      this.src = this.externalLink = "";
      this.name = this.imageDimensions = "";
      this.emitImage();
    },
    imageOnload(e, selected, prop) {
      this.imageDimensions =
        e.target.naturalWidth + " x " + e.target.naturalHeight + " px";
      this.loaded = true;
      if (prop === "src") {
        this.$store.dispatch("ddb/updateSelectedProps", {
          selected,
          value: {
            originalWidth: e.target.naturalWidth,
            originalHeight: e.target.naturalHeight,
          },
        });
      }
    },
    isImgLink(url) {
      if (typeof url !== "string") {
        return false;
      }
      return url.match(/^http[^?]*.(jpg|jpeg|gif|png)(\?(.*))?$/gim) != null;
    },
    clearValidationMsg(val) {
      if (val && this.isImgLink(val)) {
        this.error = "";
      }
    },
    validateLink(val) {
      if (val && !this.isImgLink(val)) {
        this.error = "Supported Formats: JPG, JPEG, PNG, GIF";
      }
    },
    emitImage(value, selected, prop) {
      if (selected) {
        this.$store.dispatch("ddb/updateSelectedProps", {
          selected,
          value,
          prop,
        });
      } else {
        this.$emit("input", this.src);
      }
    },
  },
};
</script>
