<template>
  <div :class="'dd-alert-' + varient">
    <span
      ><img
        :src="require('../../assets/img/icon/' + varient + '-red.svg')"
        class="dd-mr-10"
        width="20"
        alt=""
    /></span>
    <span><slot /></span>
  </div>
</template>
<script>
export default {
  name: "DdAlert",
  props: {
    varient: { type: String, default: "danger" },
  },
};
</script>
