<template>
  <div class="accordion dd-list-accrodion" role="tablist">
    <b-card no-body>
      <b-card-header header-tag="header" role="tab">
        <h4 class="panel-title">
          <b-link v-b-toggle.accordion-1>
            Structures
            <span class="ico-chevron">
              <img src="../../assets/img/icon/ico-chevron.svg"
            /></span>
          </b-link>
        </h4>
      </b-card-header>
      <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <content-structures />
          <div v-if="isFullWidth" class="dd-alert-warning style-2 dd-mt-15">
            <b-media no-body>
              <b-media-aside vertical-align="center">
                <img
                  src="../../assets/img/icon/warning-ico-light.svg"
                  alt=""
                  width="18"
                />
              </b-media-aside>
              <b-media-body class="dd-ml-15">
                Multiple structures are not supported in full width emails.
              </b-media-body>
            </b-media>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body>
      <b-card-header header-tag="header" role="tab">
        <h4 class="panel-title">
          <b-link v-b-toggle.accordion-2>
            Blocks
            <span class="ico-chevron">
              <img src="../../assets/img/icon/ico-chevron.svg"
            /></span>
          </b-link>
        </h4>
      </b-card-header>
      <b-collapse
        id="accordion-2"
        accordion="my-accordion"
        role="tabpanel"
        visible
      >
        <b-card-body>
          <content-blocks />
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body>
      <b-card-header header-tag="header" role="tab">
        <h4 class="panel-title">
          <b-link v-b-toggle.accordion-3>
            Templates
            <span class="ico-chevron">
              <img src="../../assets/img/icon/ico-chevron.svg"
            /></span>
          </b-link>
        </h4>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body class="dd-content-template-body">
          <content-templates />
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ContentBlocks from "../LeftSidebar/ContentBlocks.vue";
import ContentTemplates from "../LeftSidebar/ContentTemplates.vue";
import ContentStructures from "../LeftSidebar/ContentStructures.vue";
export default {
  name: "SidebarLeft",
  components: {
    ContentBlocks,
    ContentTemplates,
    ContentStructures,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isFullWidth: (state) =>
        state.ddb.htmlJson.properties.containerType === "fullwidth",
    }),
  },
};
</script>
