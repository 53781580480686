<template>
  <div>
    <div class="dd-right-sidebar-body">
      <bread-crumb />
      <background-color
        :value="container.props.backgroundColor"
        @input="updateContainerProps('backgroundColor', $event)"
        >Backgound color of container</background-color
      >
      <image-upload
        prop="backgroundImage"
        :value="container.props.backgroundImage"
        @input="updateContainerProps('backgroundImage', $event)"
      />
      <template v-if="container.props.backgroundImage">
        <ruler />
        <dd-switch
          :value="container.props.backgroundRepeat"
          checked-value="repeat"
          unchecked-value="no-repeat"
          @input="updateContainerProps('backgroundRepeat', $event)"
          >Repeat image</dd-switch
        >
        <hr class="dd-my-15" />
        <bg-align
          :value="container.props.backgroundPosition"
          @input="updateContainerProps('backgroundPosition', $event)"
        />
      </template>
      <dd-help-text class="mb-0"
        >Background image may not support some email clients ( Windows Outlook
        2007 – 2019, Windows 10 Mail, Android 4.4, Gmail app on iOS & Android
        with non-Gmail account). Set background colour same as image colour to
        avoid this issue</dd-help-text
      >
    </div>
    <list-accrodion>
      <b-card no-body>
        <b-card-body class="pt-4">
          <border-settings
            :value="container.props.border"
            @input="updateContainerProps('border', $event)"
          />
          <ruler />
          <border-radius
            :value="container.props.borderRadius"
            @input="updateContainerProps('borderRadius', $event)"
          />
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-body class="pt-4">
          <hide-element
            :value="container.props.hideElement"
            @input="updateContainerProps('hideElement', $event)"
          />
        </b-card-body>
      </b-card>
    </list-accrodion>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ImageUpload from "./ImageUpload.vue";
import BackgroundColor from "./BackgroundColor.vue";
import BgAlign from "../Properties/BgAlign.vue";
export default {
  name: "ContainerSettings",
  components: { ImageUpload, BackgroundColor, BgAlign },
  computed: {
    ...mapState({
      container: (state) => {
        return state.ddb.htmlJson.contents[state.ddb.mainIndex].structures[
          state.ddb.structIndex
        ].columns[state.ddb.colIndex].containers[state.ddb.contIndex];
      },
    }),
  },
  methods: {
    updateContainerProps(prop, val) {
      this.$store.dispatch("ddb/updateContainerProps", { prop, val });
    },
  },
};
</script>
