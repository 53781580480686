<template>
  <div class="dd-fluid">
    <b-row align-v="center" class="dd-nav-height">
      <b-col>
        <b-row no-gutters align-v="center">
          <b-col>
            <span class="dd-text-body dd-font-17">Preview</span>
            <div class="dd-vh-center position-absolute">
              <div class="dd-btn-ico-group white">
                <a
                  class="dd-btn dd-btn-outline dd-btn-icon-only"
                  :class="{ active: !mobilePreview }"
                  href="#"
                  v-b-tooltip.hover
                  title="Desktop"
                  @click.prevent="changePreview(1)"
                  ><img src="../../assets/img/icon/hide-element/hide-pc.svg"
                /></a>
                <a
                  class="dd-btn dd-btn-outline dd-btn-icon-only"
                  :class="{ active: mobilePreview }"
                  href="#"
                  v-b-tooltip.hover
                  title="Mobile"
                  @click.prevent="changePreview(2)"
                  ><img
                    src="../../assets/img/icon/hide-element/hide-mobile.svg"
                /></a>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-button
          size="sm"
          variant="outline-primary"
          class="dd-btn-cancel dd-preview-close"
          @click="changePreview(0)"
          >Close</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "LayoutPreviewHeader",
  data() {
    return { mobilePreview: false };
  },
  methods: {
    changePreview(state) {
      this.mobilePreview = state === 2;
      this.$emit("preview", state);
    },
  },
};
</script>
