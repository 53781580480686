<template>
  <b-button-group class="dd-mt-15 dd-goto-group">
    <b-button
      variant="light"
      :class="value === 'content' ? 'active' : ''"
      @click="goTo('content')"
      >Content</b-button
    >
    <b-button
      variant="light"
      :class="value === 'typography' ? 'active' : ''"
      @click="goTo('typography')"
      >Typography</b-button
    >
    <b-button
      variant="light"
      :class="value === 'button' ? 'active' : ''"
      @click="goTo('button')"
      >Button
    </b-button>
  </b-button-group>
</template>
<script>
export default {
  name: "StickyMenu",
  props: {
    value: {
      type: String,
      default: "content",
    },
  },
  methods: {
    goTo(val) {
      if (val !== this.value) {
        this.$emit("input", val);
      }
    },
  },
};
</script>
