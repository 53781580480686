<template>
  <draggable
    class="dd-sm-row"
    direction="vertical"
    tag="b-row"
    :sort="false"
    :group="{ name: 'element', put: false, pull: 'clone' }"
    :invertSwap="true"
    :list="elements"
    :setData="setData"
    @end="onEnd"
    @start="onStart"
  >
    <b-col cols="4" v-for="(element, index) in elements" :key="'ele' + index">
      <div class="dd-thumbnail-block">
        <p>
          <span class="dd-thumb-ico">
            <img
              :src="
                require('../../assets/img/theme/blocks/block-' +
                  element.tag.replace('dd-', '') +
                  '.svg')
              "
            />
          </span>
        </p>
        {{
          element.tag == "dd-share"
            ? "Social"
            : capitalize(element.tag.replace("dd-", ""))
        }}
      </div>
    </b-col>
  </draggable>
</template>
<script>
import draggable from "../Draggable";
export default {
  name: "ContentBlocks",
  components: { draggable },
  data() {
    return {
      elements: [
        { tag: "dd-image", props: { src: "" } },
        { tag: "dd-text", props: { value: "<p>Sample text</p>" } },
        { tag: "dd-button", props: { text: "Button", align: "center" } },
        {
          tag: "dd-divider",
          props: {
            responsive: true,
            border: "1px solid #cccccc",
            align: "center",
            padding: "20px",
          },
        },
        // { tag: 'dd-video', props: {} },
        {
          tag: "dd-share",
          props: {
            align: "center",
            backgroundColor: "transparent",
            iconSize: "32px",
            iconPadding: "10px",
            logotype: "circle-color-logos",
            items: [
              {
                name: "Facebook",
                logo: "facebook",
                alt: "Fb",
                type: "socialmedia",
              },
              {
                name: "Twitter",
                logo: "twitter",
                alt: "Tw",
                type: "socialmedia",
              },
              {
                name: "Instagram",
                logo: "instagram",
                alt: "Be",
                type: "socialmedia",
              },
              {
                name: "Youtube",
                logo: "youtube",
                alt: "Yt",
                type: "socialmedia",
              },
            ],
          },
        },
        { tag: "dd-spacer", props: { height: 40 } },
        {
          tag: "dd-menu",
          props: {
            align: "center",
            tab: "links",
            menuSize: { property: "width", size: "14px" },
            menuAlign: "links-images-top",
            fontSize: 14,
            items: [
              { name: "Item 1", alt: "Item 1", backgroundColor: "transparent" },
              { name: "Item 2", alt: "Item 2", backgroundColor: "transparent" },
              { name: "Item 3", alt: "Item 3", backgroundColor: "transparent" },
            ],
            internalPadding: "10px",
          },
        },
        {
          tag: "dd-html",
          props: {
            value: "<p>Insert your HTML in the Code editor</p>",
            padding: "10px",
          },
        },
      ],
    };
  },
  methods: {
    onStart(e) {
      this.$store.commit("ddb/setDragging", "drag");
      const elaaa = document.createElement("DIV");
      elaaa.innerHTML = '<div class="mld-plc-label"> Drop here </div>';
      elaaa.classList.add("mld-drop-here-block");
      elaaa.classList.add("mld-placement");
      e.item.insertBefore(elaaa, e.item.firstChild);
      // this.$store.dispatch('ddb/setSelected', '')
    },
    onEnd(e) {
      this.$store.commit("ddb/setDragging", "");
      e.item.firstChild.remove();
    },
    setData(dataTransfer) {
      dataTransfer.setData("data", null);
    },
  },
};
</script>
