<template>
  <codemirror
    v-model="code"
    :options="cmOptions"
    :ref="id"
    :id="id"
  ></codemirror>
</template>
<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
// language js
import "codemirror/mode/htmlmixed/htmlmixed.js";
// active-line.js
import "codemirror/addon/selection/active-line.js";
// theme css
import "codemirror/theme/darcula.css";
import { getFilteredHtml } from "../../plugins/xssWhiteList";

export default {
  name: "CopyPasteEditor",
  components: {
    codemirror,
  },
  props: ["value", "id"],
  data() {
    return {
      code: "",
      cmOptions: {
        // codemirror options
        tabSize: 4,
        styleActiveLine: true,
        mode: "htmlmixed",
        theme: "darcula",
        lineNumbers: true,
        line: true,
        height: "100%",
        lineWrapping: true,
      },
    };
  },
  watch: {
    value(val) {
      if (val !== "<p>Insert your HTML in the Code editor</p>") {
        this.setValue(val);
      }
    },
    code(val) {
      this.$emit("input", getFilteredHtml(val));
    },
  },
  methods: {
    focus() {
      this.$refs[this.id].codemirror.focus();
    },
    setValue(val) {
      if (getFilteredHtml(this.code) !== val) {
        this.code = val;
      }
    },
  },
  created() {
    this.setValue(this.value);
  },
};
</script>
<style>
.CodeMirror {
  min-height: calc(100vh - 600px);
}
</style>
