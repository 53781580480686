<template>
  <div :id="'colorPickerContainer' + _uid">
    <p v-if="label" class="dd-label-light">{{ label }}</p>
    <div
      class="dd-btn-picker"
      @mouseenter="mouseOver"
      :id="'popoverAddTrigger' + _uid"
      :style="{ background: colorValue }"
      :class="{ dark: brightnessByColor(colorValue) < 170 }"
    >
      <span class="dd-color-code">{{ colorValue }}</span>
    </div>
    <b-popover
      :target="'popoverAddTrigger' + _uid"
      :triggers="showVal ? 'blur' : 'click blur'"
      placement="top"
      :container="'colorPickerContainer' + _uid"
      @shown="$event.relatedTarget.focus(), (showVal = true)"
      @hidden="showVal = false"
    >
      <b-row>
        <b-col>
          <div>
            <sketch-picker
              v-model="colors"
              :value="colors"
              :disableAlpha="true"
              @input="updateFromPicker"
            />
          </div>
        </b-col>
      </b-row>
    </b-popover>
  </div>
</template>
<script>
import { Sketch } from "vue-color";
export default {
  name: "ColorPicker",
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "" },
  },
  components: {
    "sketch-picker": Sketch,
  },
  data() {
    return {
      colors: {
        hex: this.value || "#00000000",
      },
      showVal: false,
      colorValue: this.value || "transparent",
    };
  },
  methods: {
    brightnessByColor(color) {
      if (!color) return 0;
      const hasFullSpec = color.length === 7;
      var m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
      if (m) var r = parseInt(m[0] + (hasFullSpec ? "" : m[0]), 16);
      var g = parseInt(m[1] + (hasFullSpec ? "" : m[1]), 16);
      var b = parseInt(m[2] + (hasFullSpec ? "" : m[2]), 16);
      if (typeof r !== "undefined") return (r * 299 + g * 587 + b * 114) / 1000;
    },
    updateColors(hex) {
      let a = 1;
      if (hex === "transparent") {
        hex = "#00000000";
        a = 0;
      }
      this.colors = { hex, a };
    },
    updateFromPicker(color) {
      this.colors = color;
      if (["#FFFFFF00", "#00000000"].includes(color.hex8)) {
        this.colorValue = "transparent";
      } else {
        this.colorValue = color.hex;
      }
    },
    mouseOver(e) {
      e.target.setAttribute(
        "style",
        "--box-shadow-color: " +
          this.colorValue +
          "; background: " +
          this.colorValue
      );
    },
  },
  watch: {
    colorValue(val) {
      this.updateColors(val);
      if (val !== this.value) {
        this.$emit("input", val);
      }
    },
    value(val) {
      this.colorValue = val || "transparent";
    },
  },
};
</script>
