<template comments>
  <div
    class="ml-wrapper-color"
    :bgcolor="backgroundColor"
    :style="{ backgroundColor }"
    @click="$store.dispatch('ddb/setSelected', '')"
  >
    <dd-table
      class="ml-wrapper"
      :class="{ 'mld-full-width': isFullWidth }"
      width="100%"
      :style="{ ...backgroundImage }"
    >
      <tr v-if="disableResponsive" class="fix-gmail" height="0">
        <td style="margin: 0; padding: 0">
          <table
            cellpadding="0"
            cellspacing="0"
            border="0"
            align="center"
            style="
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
              border-collapse: collapse;
              border-spacing: 0px;
              width: 600px;
            "
          >
            <tbody>
              <tr>
                <td
                  cellpadding="0"
                  cellspacing="0"
                  border="0"
                  height="0"
                  style="
                    padding: 0;
                    margin: 0;
                    line-height: 1px;
                    min-width: 600px;
                  "
                >
                  <img
                    :src="setImageDomain('/img/spacer.png')"
                    height="1"
                    width="600"
                    style="
                      border: 0;
                      outline: none;
                      display: block;
                      text-decoration: none;
                      -ms-interpolation-mode: bicubic;
                      min-height: 0px;
                      max-height: 0px;
                      width: 600px;
                      min-width: 600px;
                    "
                    alt=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <draggable
          tag="dd-email-paddings"
          draggable=".ml-content"
          direction="vertical"
          handle=".dragg-handle"
          group="mainblock"
          :invertSwap="true"
          :value="value"
          @add="onAdd"
          @input="emitter"
          @update="onUpdate"
          @start="$emit('start', $event, 'drag-mailer')"
          @end="$emit('end', $event)"
        >
          <slot />
        </draggable>
      </tr>
    </dd-table>
  </div>
</template>
<script>
import draggable from "../Draggable";
export default {
  name: "Wrapper",
  components: { draggable },
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
  },
  computed: {
    disableResponsive() {
      return (
        this.$store.state.ddb.htmlJson.properties.disableResponsive || false
      );
    },
    properties() {
      return this.$store.state.ddb.htmlJson.properties;
    },
    backgroundColor() {
      return this.setColor(this.properties.backgroundColor);
    },
    backgroundImage() {
      if (this.properties.backgroundImage) {
        return {
          backgroundImage: "url(" + this.properties.backgroundImage + ")",
          backgroundRepeat: this.properties.backgroundRepeat || "no-repeat",
          backgroundPosition:
            this.properties.backgroundPosition || "center top",
        };
      }
      return {};
    },
    isFullWidth() {
      return this.properties.containerType === "fullwidth";
    },
  },
  methods: {
    onUpdate(e) {
      if (
        e.newDraggableIndex > e.oldDraggableIndex &&
        e.newDraggableIndex !== this.value.length
      ) {
        const value = [...this.value];
        const el = value.splice(e.newDraggableIndex, 1);
        value.splice(e.newDraggableIndex - 1, 0, el[0]);
        this.$emit("input", value);
      }
    },
    onAdd(e) {
      if (e.pullMode === "clone") {
        this.$store.dispatch("ddb/replaceMainBlock", {
          mainIndex: e.newIndex,
          domain: this.$ls.get("imageDomain"),
        });
      }
    },
    emitter(value) {
      this.$emit("input", value);
    },
  },
};
</script>
