<template>
  <b-card no-body :class="{ 'border-top-0': isFullWidth }">
    <b-card-header header-tag="header" role="tab">
      <b-row>
        <b-col>
          <panel-title> Default padding </panel-title>
        </b-col>
        <advance-option
          :active="advanceOption"
          @click="advanceOption = !advanceOption"
        />
      </b-row>
    </b-card-header>
    <b-card-body>
      <padding-all
        v-model="paddingVal"
        hide-header
        :show-advance="advanceOption"
      />
      <b-row class="dd-mt-15">
        <b-col>
          <b-form-group class="dd-radio-froup">
            <b-form-radio
              v-model="selected"
              :value="false"
              @change="setSelected"
              >Apply to only new structures</b-form-radio
            >
            <b-form-radio v-model="selected" :value="true" @change="setSelected"
              >Apply to all structures</b-form-radio
            >
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <modal-confirm
      @cancel="modalTrigger(false)"
      @confirm="modalTrigger(true)"
    />
  </b-card>
</template>
<script>
import PaddingAll from "../Properties/PaddingAll.vue";
export default {
  name: "GeneralPaddingSettings",
  components: {
    PaddingAll,
  },
  props: {
    isFullWidth: { type: Boolean, default: false },
  },
  data() {
    return {
      advanceOption: false,
      selected: false,
    };
  },
  created() {
    var paddingType = typeof this.paddingVal;
    if (paddingType === "string") {
      this.advanceOption = false;
    } else {
      this.advanceOption = true;
    }
    this.selected = !!this.properties.paddingForAll;
  },
  watch: {
    "properties.paddingForAll": {
      handler(val) {
        this.selected = !!val;
      },
    },
  },
  computed: {
    properties() {
      return this.$store.state.ddb.htmlJson.properties;
    },
    paddingVal: {
      get() {
        return this.properties.padding ? this.properties.padding : "";
      },
      set(val) {
        this.$store.dispatch("ddb/updateGneralProperty", {
          property: "padding",
          value: val,
        });
      },
    },
  },
  methods: {
    setSelected(value) {
      if (value) {
        this.$bvModal.show("modalConfirm");
      } else {
        this.$store.dispatch("ddb/updateGneralProperty", {
          property: "paddingForAll",
          value,
        });
      }
    },
    modalTrigger(value) {
      this.selected = value;
      if (value) {
        this.$store.dispatch("ddb/updateGneralProperty", {
          property: "paddingForAll",
          value,
        });
      }
    },
  },
};
</script>
