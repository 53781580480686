<template>
  <div class="dd-list-accrodion single individual" role="tablist">
    <b-card no-body>
      <b-collapse visible id="toggle-general-button-settings" role="tabpanel">
        <b-card-body class="dd-min-height">
          <bread-crumb />
          <b-row class="dd-mb-20">
            <b-col cols="6">
              <color-picker
                :value="
                  value.color ||
                  elementDefaultProps.color ||
                  generalProperty.textColor
                "
                @input="updateProps('color', $event)"
                label="Text color"
              />
            </b-col>
            <b-col cols="6">
              <color-picker
                :value="
                  value.linkTextColor ||
                  elementDefaultProps.linkTextColor ||
                  generalProperty.linkColor
                "
                @input="updateProps('linkTextColor', $event)"
                label="Link text color"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <color-picker
                :value="value.backgroundColor || 'transparent'"
                @input="updateProps('backgroundColor', $event)"
                label="Background color"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ruler />
              <line-spacing
                :value="value.lineHeight"
                @input="updateProps('lineHeight', $event)"
              />
            </b-col>
          </b-row>
          <ruler />
          <b-row>
            <b-col>
              <personalization />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ruler />
              <dd-switch
                :value="value.linkUnderLine !== false"
                @input="updateProps('linkUnderLine', $event)"
                >Underline links</dd-switch
              >
              <ruler />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <padding-all
                :value="value.padding"
                @input="updateProps('padding', $event)"
                >Padding</padding-all
              >
            </b-col>
          </b-row>
          <ruler />
          <b-row>
            <b-col>
              <align-horizontal
                :value="value || {}"
                @change-input="updateProps($event.key, $event.value)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ruler class="dd-mx--20" />
              <hide-element
                :value="value.hideElement"
                @input="updateProps('hideElement', $event)"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import ColorPicker from "../Controls/ColorPicker.vue";
import LineSpacing from "../Properties/LineSpacing.vue";
import HideElement from "../Properties/HideElement.vue";
import AlignHorizontal from "../Properties/AlignHorizontal.vue";
import Personalization from "../Properties/Personalization.vue";
export default {
  name: "TextSettings",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    generalProperty: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Personalization,
    ColorPicker,
    LineSpacing,
    HideElement,
    AlignHorizontal,
  },
  data() {
    return {
      marginVal: "",
      paddingVal: "",
      borderRadiusVal: "",
      hideElementVal: "",
    };
  },
  computed: {
    elementDefaultProps() {
      return this.$store.state.ddb.elementDefaultProps;
    },
  },
  methods: {
    updateProps(prop, val) {
      this.$store.dispatch("ddb/updateElementProperty", {
        property: prop,
        value: val,
      });
    },
  },
};
</script>
