<template>
  <div>
    <dd-label>Networks settings</dd-label>
    <draggable
      tag="div"
      handle=".panel-title"
      group="socialBlock"
      v-model="socialData"
      class="dd-menu-accrodion"
      role="tablist"
    >
      <b-card
        :class="openIndex === index ? 'active' : ''"
        no-body
        v-for="(col, index) in socialData"
        :key="'menu-tab' + index"
      >
        <b-card-header header-tag="header" role="tab">
          <b-row align-v="center">
            <b-col>
              <div v-b-toggle="'social-accordion-' + index" class="panel-title">
                <b-row align-v="center" class="dd-sm-row">
                  <b-col cols="auto"
                    ><img :src="getSrc(col)" width="24" alt=""
                  /></b-col>
                  <b-col>{{ col.name }}</b-col>
                </b-row>
              </div>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <b-link
                v-b-toggle="'social-accordion-' + index"
                v-b-tooltip.hover
                title="Edit"
                class="dd-btn-ico dd-32 acc-edit"
                ><img src="../../assets/img/icon/edit-ico.svg" width="14"
              /></b-link>
              <b-link
                :disabled="socialData.length === 1 ? true : false"
                @click="deleteItem(index)"
                class="dd-btn-ico dd-32 dd-btn-red dd-ml-10 acc-delete"
                v-b-tooltip.hover
                title="Delete"
                ><img src="../../assets/img/icon/trash-ico.svg" width="14"
              /></b-link>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse
          @shown="openCollapse(index)"
          @hide="hiddenCollapse(index)"
          :id="'social-accordion-' + index"
          :visible="showVisibleMenu(index)"
          accordion="dd-menu-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <div v-if="col.custom">
              <b-row class="dd-mb-10">
                <b-col>
                  <dd-label-light>Custom Name</dd-label-light>
                  <b-form-input
                    :value="col.customName || ''"
                    @input="updateVal('customName', index, $event)"
                    class="dd-form-control"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="dd-mb-15">
                <b-col>
                  <image-upload
                    :width="64"
                    :height="64"
                    :prop="{ index: index, propVal: 'src', label: 'items' }"
                    :value="col.src ? col.src : ''"
                    @input="updateVal('src', index, $event)"
                    :hideLabel="true"
                  />
                </b-col>
              </b-row>
              <div class="dd-alert-warning style-2 dd-mt-10 dd-mb-15">
                <b-media no-body>
                  <b-media-aside vertical-align="center">
                    <img
                      src="../../assets/img/icon/warning-ico-light.svg"
                      alt=""
                      width="18"
                    />
                  </b-media-aside>
                  <b-media-body class="dd-ml-15">
                    Custom added icons will not change when you choose another
                    social media style
                  </b-media-body>
                </b-media>
              </div>
            </div>
            <b-row class="dd-mb-10">
              <b-col>
                <link-href
                  :value="col.href || ''"
                  @input="updateVal('href', index, $event)"
                />
              </b-col>
            </b-row>
            <b-row class="dd-mb-10">
              <b-col>
                <dd-label-light>Alt</dd-label-light>
                <b-form-input
                  :value="col.alt ? col.alt : ''"
                  @input="updateVal('alt', index, $event)"
                  class="dd-form-control"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <dd-label-light>Title</dd-label-light>
                <b-form-input
                  v-model="col.name"
                  class="dd-form-control"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </draggable>
  </div>
</template>
<script>
import draggable from "../Draggable";
import { mapGetters } from "vuex";
import LinkHref from "../Properties/LinkHref.vue";
import ImageUpload from "../Properties/ImageUpload.vue";
import { getSocialmediaPath } from "../../plugins/JsonHelper.js";
export default {
  name: "SocialItem",
  components: {
    LinkHref,
    draggable,
    ImageUpload,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    logoType: { type: String(), default: "" },
  },
  data() {
    return {
      openIndex: "",
      socialData: this.value ? this.value : [],
    };
  },
  computed: {
    ...mapGetters("ddb", {
      showMenu: "getSelectedMenu",
    }),
  },
  watch: {
    socialData: {
      handler(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.value)) {
          this.$emit("input", val);
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        this.socialData = val;
      },
      deep: true,
    },
  },
  methods: {
    openCollapse(index) {
      this.openIndex = index;
    },
    hiddenCollapse(val) {
      this.openIndex = "";
      if (this.showMenu.social === val) {
        this.$store.dispatch("ddb/setSelectedMenu", {
          type: "social",
          index: "",
        });
      }
    },
    showVisibleMenu(index) {
      return this.showMenu.social === index;
    },
    getSrc(value) {
      let image = getSocialmediaPath(value, this.logoType);
      if (
        image ===
        (process.env.VUE_APP_VUE2_APP_URL || "") + "/img/default-img.png"
      ) {
        return image;
      }
      return this.setImageDomain(image);
    },
    deleteItem(index) {
      this.socialData.splice(index, 1);
    },
    updateVal(key, index, val) {
      var newValue = this.socialData[index];
      newValue[key] = val;
      this.socialData.splice(index, 1, newValue);
    },
  },
};
</script>
