<template>
  <draggable
    class="dd-structure-group"
    direction="vertical"
    draggable=".dd-draggable"
    tag="ul"
    :sort="false"
    :group="{ name: 'structure', put: false, pull: 'clone' }"
    :invertSwap="true"
    :list="structures"
    @end="onEnd"
    @start="onStart"
  >
    <li
      v-for="(struct, index) in structures"
      :key="'structures' + index"
      :class="
        !isFullWidth || struct.columns.length === 1
          ? 'dd-draggable'
          : 'disabled'
      "
    >
      <b-row class="dd-sm-row">
        <b-col
          :cols="column.cols"
          v-for="(column, ind) in struct.columns"
          :key="'col-' + index + '-' + ind"
        >
          <div class="dd-structure"></div>
        </b-col>
      </b-row>
    </li>
  </draggable>
</template>
<script>
import { mapState } from "vuex";
import draggable from "../Draggable";
export default {
  name: "ContentStructures",
  components: { draggable },
  data() {
    return {
      structures: [
        { columns: [{ cols: 12 }] },
        { columns: [{ cols: 6 }, { cols: 6 }] },
        { columns: [{ cols: 4 }, { cols: 4 }, { cols: 4 }] },
        { columns: [{ cols: 3 }, { cols: 3 }, { cols: 3 }, { cols: 3 }] },
        { columns: [{ cols: 4 }, { cols: 8 }] },
        { columns: [{ cols: 8 }, { cols: 4 }] },
      ],
    };
  },
  computed: {
    ...mapState({
      isFullWidth: (state) =>
        state.ddb.htmlJson.properties.containerType === "fullwidth",
    }),
  },
  methods: {
    onStart(e) {
      this.$store.commit("ddb/setDragging", "drag-structure");
      const elaaa = document.createElement("DIV");
      elaaa.innerHTML = '<div class="mld-plc-label"> Drop here </div>';
      elaaa.classList.add("mld-drop-here-block");
      elaaa.classList.add("mld-placement");
      e.item.insertBefore(elaaa, e.item.firstChild);
      // this.$store.dispatch('ddb/setSelected', '')
    },
    onEnd(e) {
      this.$store.commit("ddb/setDragging", "");
      e.item.firstChild.remove();
    },
  },
};
</script>
<style type="text/css">
.mld-drop-here-block {
  display: none;
}
</style>
