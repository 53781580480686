<template>
  <tr class="mld-element">
    <td align="center" class="mld-empty-container">
      <span
        class="ml-icon-drop-here"
        title="Drop content here"
        :class="{ 'mld-sm-hide': width < 54 }"
      ></span>
      <span
        class="mldev-empty-container-text mld-container-text"
        :class="{ 'mld-sm-hide': width < 80 }"
        >Drop here</span
      >
      <span class="mldev-empty-container-icons mld-block-icons">
        <a
          class="mldev-insert-image"
          title="Image"
          @click.stop="addElement('dd-image')"
          ><img :src="getIcon('image')" width="18"
        /></a>
        <a
          class="mldev-insert-text"
          title="Text"
          @click.stop="addElement('dd-text')"
          ><img :src="getIcon('text')" width="18"
        /></a>
        <a
          class="mldev-insert-button"
          title="Button"
          id="btn-blc"
          @click.stop="addElement('dd-button')"
          ><img :src="getIcon('button')" width="18"
        /></a>
      </span>
    </td>
  </tr>
</template>
<script>
const defaultProps = {
  "dd-image": { src: "" },
  "dd-text": { value: "<p>Sample text</p>" },
  "dd-button": { text: "Button", align: "center" },
};
export default {
  name: "EmptyContainer",
  props: {
    contIndex: {
      type: Number,
      default: -1,
    },
    colIndex: {
      type: Number,
      default: -1,
    },
    structIndex: {
      type: Number,
      default: -1,
    },
    mainIndex: {
      type: Number,
      default: -1,
    },
    width: {
      type: Number,
      default: 600,
    },
  },
  methods: {
    addElement(tag) {
      this.$store.dispatch("ddb/addNewElement", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
        colIndex: this.colIndex,
        contIndex: this.contIndex,
        element: { tag, props: defaultProps[tag] },
      });
    },
    getIcon(icon) {
      return (
        (process.env.VUE_APP_VUE2_APP_URL || "") +
        "/img/icon-insert-" +
        icon +
        ".svg"
      );
    },
  },
};
</script>
