<template>
  <table cellpadding="0" cellspacing="0" width="100%" class="ml-menu">
    <tbody>
      <tr :class="getTrClass">
        <td
          :class="menuClass(col)"
          :align="props.align || 'center'"
          v-for="(col, index) in props.items"
          :bgcolor="col.backgroundColor ? setColor(col.backgroundColor) : null"
          :style="tdStyle(index)"
          :key="'menu-tab' + index"
          valign="top"
          :width="getWidth"
        >
          <a
            @click="selectMenu($event, index)"
            v-if="props.tab === 'icons'"
            target="_blank"
            :href="htmlEncode(col.href || '#')"
          >
            <img
              v-if="showImg(col)"
              v-bind="imgAttributes"
              :src="col.src ? col.src : defaultSrc"
              :alt="col.alt"
              :title="col.name"
            />
          </a>
          <a
            @click="selectMenu($event, index)"
            v-else-if="
              props.tab === 'iconsAndlinks' &&
              props.menuAlign === 'links-images-top'
            "
            :style="fontStyle(index)"
            target="_blank"
            :href="htmlEncode(col.href || '#')"
          >
            <img
              v-if="showImg(col)"
              style="padding-bottom: 5px"
              align="absmiddle"
              v-bind="imgAttributes"
              :src="col.src ? col.src : defaultSrc"
              :alt="col.alt"
              :title="col.name"
            />
            <br />{{ col.name }}
          </a>
          <a
            @click="selectMenu($event, index)"
            v-else-if="
              props.tab === 'iconsAndlinks' &&
              props.menuAlign === 'links-images-left'
            "
            :style="fontStyle(index)"
            target="_blank"
            :href="htmlEncode(col.href || '#')"
          >
            <img
              v-if="showImg(col)"
              style="padding-right: 5px"
              align="absmiddle"
              v-bind="imgAttributes"
              :src="col.src ? col.src : defaultSrc"
              :alt="col.alt"
              :title="col.name"
            />
            {{ col.name }}
          </a>
          <a
            @click="selectMenu($event, index)"
            v-else-if="
              props.tab === 'iconsAndlinks' &&
              props.menuAlign === 'links-images-right'
            "
            :style="fontStyle(index)"
            target="_blank"
            :href="htmlEncode(col.href || '#')"
          >
            {{ col.name }}
            <img
              v-if="showImg(col)"
              style="padding-left: 5px"
              align="absmiddle"
              v-bind="imgAttributes"
              :src="col.src ? col.src : defaultSrc"
              :alt="col.alt"
              :title="col.name"
            />
          </a>
          <a
            @click="selectMenu($event, index)"
            v-else
            target="_blank"
            :href="htmlEncode(col.href || '#')"
            :style="fontStyle(index)"
          >
            {{ col.name }}
          </a>
          <div
            v-if="col.hideElement && !props.isPreview"
            class="mld-hidden-status"
          >
            <span
              :class="
                col.hideElement === 'hide-mobile'
                  ? 'ms-icon-hide-on-mobile'
                  : 'ms-icon-hide-on-desktop'
              "
            ></span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { parseStyleProperty } from "../../plugins/StyleConverter.js";
export default {
  name: "DdMenu",
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      defaultSrc:
        (process.env.VUE_APP_VUE2_APP_URL || "") + "/img/default-img.png",
    };
  },
  methods: {
    showImg(val) {
      return !(this.props.isPreview && !val.src);
    },
    menuClass(val) {
      const menuClass = [];
      if (val.hideElement) {
        var classval = "mld-hidden-element";
        if (this.props.isPreview) {
          classval =
            val.hideElement === "hide-mobile"
              ? "ml-mobile-hidden"
              : "ml-desk-menu-hidden ml-desk-hidden";
        }
        menuClass.push(classval);
      }
      if (this.props.alignMobile) {
        menuClass.push("ml-m-txt-" + this.props.alignMobile[0]);
      }
      return menuClass;
    },
    selectMenu(e, val) {
      if (!this.props.isPreview) {
        e.preventDefault();
      }
      this.$store.dispatch("ddb/setSelectedMenu", { type: "menu", index: val });
    },
    fontStyle(index) {
      var value = this.props.items[index] ? this.props.items[index] : {};
      var attr = {};
      if (this.props.fontFamily) {
        attr.fontFamily = this.props.fontFamily;
      }
      if (this.props.fontSize) {
        attr.fontSize = this.props.fontSize;
      }
      attr.textDecoration = this.props.textDecoration
        ? this.props.textDecoration
        : "none";
      attr.fontWeight = this.props.fontWeight
        ? this.props.fontWeight
        : "normal";
      attr.fontStyle = this.props.fontStyle ? this.props.fontStyle : "normal";
      var commonColor = this.props.linkColor ? this.props.linkColor : "";
      attr.letterSpacing = this.props.letterSpacing
        ? this.props.letterSpacing
        : "normal";
      attr.textTransform = this.props.textTransform
        ? this.props.textTransform
        : "none";
      attr.color = value.linkColor ? value.linkColor : commonColor;
      return attr;
    },
    tdStyle(index) {
      var attr = {
        ...(this.props.internalPadding
          ? parseStyleProperty(this.props.internalPadding)
          : {}),
      };
      if (this.props.seperator && index > 0) {
        attr.borderLeft = this.props.seperator;
      }
      /* if (this.props.internalPadding) {
        ...(this.props.internalPadding ? parseStyleProperty(this.props.internalPadding) : {})
      } */

      return attr;
    },
  },
  computed: {
    imgAttributes() {
      var attr = {};
      if (this.props.menuSize && this.props.menuSize.property) {
        attr[this.props.menuSize.property] = this.props.menuSize.size
          ? this.props.menuSize.size
          : "14px";
      }
      return attr;
    },
    getWidth() {
      var width = 100 / this.props.items.length;
      return Math.round(width * 100) / 100 + "%";
    },
    getTrClass() {
      var classVal = "";
      if (this.props.tab === "icons") {
        classVal = "images";
      } else if (this.props.tab === "iconsAndlinks") {
        classVal = this.props.menuAlign;
      } else {
        classVal = "links";
      }
      return classVal;
    },
  },
};
</script>
