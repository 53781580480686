<template>
  <div>
    <dd-label>Alignment</dd-label>
    <b-row align-v="center">
      <b-col cols="auto">
        <div class="dd-btn-ico-group dd-align-settings">
          <a
            href="javascript:void(0)"
            @click="isPc = true"
            :class="isPc ? 'active' : ''"
            v-b-tooltip.hover
            title="On desktop"
          >
            <img
              src="../../assets/img/icon/hide-element/hide-pc.svg"
              width="19"
              alt="pc"
            />
          </a>
          <a
            href="javascript:void(0)"
            @click="isPc = false"
            :class="!isPc ? 'active' : ''"
            v-b-tooltip.hover
            title="On mobile"
          >
            <img
              src="../../assets/img/icon/hide-element/hide-mobile.svg"
              width="11"
              alt="mobile"
            />
          </a>
        </div>
      </b-col>
      <b-col cols="auto" class="ml-auto">
        <alignment :value="alignValueCommon" @input="setVal" />
      </b-col>
    </b-row>
    <b-media no-body class="dd-help-text dd-mt-15">
      <b-media-aside>
        <img
          src="../../assets/img/icon/info-circle-fill.svg"
          width="14"
          class="dd-mt-3"
        />
      </b-media-aside>
      <b-media-body class="ml-2">
        <p class="mb-0">
          Change the alignment over
          {{ isPc ? "PC/Desktop " : "mobile" }} screen<br />(Changes won't be
          observed if used with the text editor alignment option.)
        </p>
      </b-media-body>
    </b-media>
  </div>
</template>
<script>
import Alignment from "./Alignment.vue";
export default {
  name: "AlignHorizontal",
  components: { Alignment },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      alignValue: this.value && this.value.align ? this.value.align : "left",
      alignValueMobile:
        this.value && this.value.alignMobile ? this.value.alignMobile : "",
      isPc: true,
    };
  },
  computed: {
    alignValueCommon() {
      return this.isPc
        ? this.alignValue
        : this.alignValueMobile || this.alignValue;
    },
  },
  methods: {
    setVal(val) {
      if (this.isPc) {
        this.alignValue = val;
        this.$emit("change-input", { key: "align", value: val });
      } else {
        this.alignValueMobile = val;
        this.$emit("change-input", { key: "alignMobile", value: val });
      }
    },
  },
  watch: {
    value(val) {
      this.alignValue = val && val.align ? val.align : "left";
      this.alignValueMobile = val && val.alignMobile ? val.alignMobile : "";
    },
  },
};
</script>
