<template>
  <b-col cols="auto" class="ml-auto dd-advance-col">
    <b-link :class="{ active }" @click="$emit('click', $event)">
      <b-row no-gutters align-v="center">
        <b-col class="text-right">
          <span class="dd-text-advance">Advance option</span>
        </b-col>
        <b-col align-self="center" cols="auto" class="ml-auto">
          <span class="ico">
            <img src="../../assets/img/icon/ico-chevron.svg"
          /></span>
        </b-col>
      </b-row>
    </b-link>
  </b-col>
</template>
<script>
export default {
  name: "AdvanceOption",
  props: {
    active: { type: Boolean, default: false },
  },
};
</script>
