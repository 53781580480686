const calculateScreenPos = (el, vnode) => {
  if (el.getBoundingClientRect().left > window.frameWindow.innerWidth / 2) {
    vnode.data.on.align("mld-screen-right");
  } else {
    vnode.data.on.align("mld-screen-left");
  }
};
const AlignmentDirective = {
  inserted(el, binding, vnode) {
    if (vnode.data.on && vnode.data.on.align) {
      calculateScreenPos(el, vnode);
      el.addEventListener("mouseenter", function () {
        calculateScreenPos(el, vnode);
      });
    }
  },
};
export default AlignmentDirective;
