<template>
  <b-row class="dd-mb-20">
    <b-col>
      <b-breadcrumb class="dd-breadcrumb">
        <b-breadcrumb-item
          @click="goTo('main')"
          v-b-tooltip.hover
          title="Go to section"
          >Sections</b-breadcrumb-item
        >
        <b-breadcrumb-item
          @click="goTo('struct')"
          v-if="showStructure"
          :active="selected.indexOf('struct') === 0"
          v-b-tooltip.hover
          title="Go to structure"
          >Structure</b-breadcrumb-item
        >
        <b-breadcrumb-item
          @click="goTo('cont')"
          v-if="showContainer"
          :active="selected.indexOf('cont') === 0"
          v-b-tooltip.hover
          title="Go to container"
          >Container</b-breadcrumb-item
        >
        <b-breadcrumb-item v-if="tag" active>
          {{ getElementName }}</b-breadcrumb-item
        >
      </b-breadcrumb>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    goTo(val) {
      if (this.selected.indexOf(val) !== 0) {
        let block = this.content;
        const indexes = {
          structIndex: -1,
          colIndex: -1,
          contIndex: -1,
          elIndex: -1,
        };
        if (val !== "main") {
          block = block.structures[this.state.structIndex];
          delete indexes.structIndex;
          if (val !== "struct") {
            delete indexes.colIndex;
            delete indexes.contIndex;
            block =
              block.columns[this.state.colIndex].containers[
                this.state.contIndex
              ];
          }
        }
        this.$store.dispatch("ddb/setAllIndexes", indexes);
        this.$store.dispatch("ddb/setSelected", val + "-" + block.key);
      }
    },
  },
  computed: {
    state() {
      return this.$store.state.ddb;
    },
    content() {
      return this.state.htmlJson.contents[this.state.mainIndex];
    },
    ...mapGetters("ddb", {
      selected: "getSelected",
      tag: "getSelectedElementTag",
    }),
    showContainer() {
      return !!(
        this.selected.indexOf("el") === 0 || this.selected.indexOf("cont") === 0
      );
    },
    showStructure() {
      return !!(
        this.selected.indexOf("el") === 0 ||
        this.selected.indexOf("cont") === 0 ||
        this.selected.indexOf("struct") === 0
      );
    },
    getElementName() {
      switch (this.tag) {
        case "dd-button":
          return "Button";
        case "dd-image":
          return "Image";
        case "dd-text":
          return "Text";
        case "dd-divider":
          return "Divider";
        case "dd-spacer":
          return "Spacer";
        case "dd-html":
          return "Html";
        case "dd-menu":
          return "Menu";
        case "dd-share":
          return "Social";
        default:
          return "";
      }
    },
  },
};
</script>
