import DdButton from "./DdButton.vue";
import DdDivider from "./DdDivider.vue";
import DdImage from "./DdImage.vue";
import DdMenu from "./DdMenu.vue";
import DdShare from "./DdShare.vue";
import DdSpacer from "./DdSpacer.vue";
import DdText from "./DdText.vue";
import DdHtml from "./DdHtml.vue";
export default {
  DdButton,
  DdDivider,
  DdImage,
  DdMenu,
  DdShare,
  DdSpacer,
  DdText,
  DdHtml,
};
