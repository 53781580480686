<template>
  <div class="dd-mb-15">
    <multiselect
      v-model="fontValue"
      placeholder="Select font"
      group-values="family"
      group-label="type"
      label="label"
      :options="options"
      class="dd-multiselect dd-select-radius-8"
      @select="onSelected"
    >
      <template slot="singleLabel" slot-scope="props">
        <span>{{ props.option.label }}</span>
      </template>
      <template slot="option" slot-scope="props">
        <span :style="{ 'font-family': [props.option.value] }">{{
          props.option.label || props.option.$groupLabel
        }}</span>
      </template>
    </multiselect>
  </div>
</template>
<script>
import "../../assets/scss/custom/vendor/g-fonts.css";
import { nonStandardFontWarning } from "../../constants/messages.js";
export default {
  name: "FontFamily",
  props: { value: { type: String, default: "" } },
  created() {
    if (this.value) {
      this.fontValue = this.getSelectedVal(this.value);
    }
  },
  data() {
    return {
      fontValue: { label: "Arial" },
      options: [
        {
          type: "Standard font",
          family: [
            {
              label: "Arial",
              value: "arial,'helvetica neue',helvetica,sans-serif",
            },
            {
              label: "Comic sans ms",
              value: "'comic sans ms','marker felt-thin',arial, sans-serif",
            },
            {
              label: "Courier New",
              value:
                "'courier new',courier,'lucida sans typewriter','lucida typewriter',monospace",
            },
            {
              label: "Georgia",
              value: "georgia, times, 'times new roman', serif",
            },
            {
              label: "Helvetica",
              value: "helvetica,'helvetica neue',arial,verdana,sans-serif",
            },
            {
              label: "Lucida",
              value: "'Lucida Sans Unicode', 'Lucida Grande', sans-serif",
            },
            { label: "Tahoma", value: "'tahoma', verdana, segoe, sans-serif" },
            {
              label: "Times new roman",
              value: "'times new roman',times,baskerville,georgia,serif",
            },
            {
              label: "Trebuchet MS",
              value:
                "'trebuchet ms', 'lucida grande', 'lucida sans unicode', 'lucida sans', tahoma, sans-serif",
            },
            { label: "Verdana", value: "'verdana', geneva, sans-serif" },
          ],
        },
        {
          type: "Non standard font",
          family: [
            {
              label: "Calibri",
              value: "'Calibri',Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
            },
            { label: "Poppins", value: "'Poppins', sans-serif" },
            { label: "Arvo", value: "arvo, courier, georgia,serif" },
            {
              label: "Lato",
              value: "'Lato', 'helvetica neue', helvetica,arial, sans-serif",
            },
            { label: "Lora", value: "lora, georgia,'times new roman', serif" },
            {
              label: "Merriweather",
              value:
                "'Merriweather', 'helvetica neue', helvetica,arial, sans-serif",
            },
            {
              label: "Open Sans",
              value:
                "'Open Sans', 'helvetica neue', helvetica,arial, sans-serif",
            },
            {
              label: "Roboto",
              value: "'Roboto', 'helvetica neue', helvetica,arial, sans-serif",
            },
            {
              label: "Playfair Display",
              value: "'Playfair Display', georgia, 'times new roman', serif",
            },
            {
              label: "Source Sans Pro",
              value:
                "'Source Sans Pro', 'helvetica neue', helvetica,arial, sans-serif",
            },
            {
              label: "Abril Fatface",
              value: "'Abril Fatface', Georgia, 'Times New Roman', serif",
            },
            {
              label: "Anton",
              value: "'Anton', Helvetica, Arial, Verdana, sans-serif",
            },
            { label: "Raleway", value: "'Raleway', sans-serif" },
            { label: "Work Sans", value: "'Work Sans', sans-serif" },
            {
              label: "Syncopate",
              value:
                "'Syncopate', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            },
            { label: "Montserrat", value: "'Montserrat', sans-serif" },
            { label: "Neuton", value: "neuton, serif" },
            {
              label: "Palatino Linotype",
              value:
                "Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia,serif",
            },
            {
              label: "Copperplate",
              value: " Copperplate,Copperplate Gothic Light,fantasy",
            },
            {
              label: "Brush Script MT",
              value: " Brush Script MT,cursive ",
            },
            { label: "Noto Sans", value: "'Noto Sans', sans-serif" },
            { label: "Montserrat", value: "'Montserrat', sans-serif" },
            {
              label: "PT Sans",
              value: "'PT Sans', Arial, Helvetica, sans-serif",
            },
            {
              label: "Montserrat Alternates",
              value: "'Montserrat Alternates', sans-serif",
            },
          ],
        },
      ],
    };
  },
  methods: {
    getSelectedVal(val) {
      for (let i = 0; i < this.options.length; i++) {
        let selected = this.options[i].family.find((option) => {
          return option.value === val;
        });
        if (selected) {
          return selected;
        }
      }
      return this.options[0].family[0] || "";
    },
    onSelected(val) {
      if (
        this.options[1].family.find((option) => {
          return option.value === val.value;
        })
      ) {
        this.ddToastMessage(nonStandardFontWarning, "warning");
      }
    },
  },
  watch: {
    fontValue(val) {
      if (val && val !== this.value && val.value !== this.value) {
        this.$emit("input", val.value);
      }
    },
    value(val) {
      this.fontValue = this.getSelectedVal(val);
    },
  },
};
</script>
