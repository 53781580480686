import { debounce, getEditorNamespace } from "ckeditor4-integrations-common";

const parseStyleTagToJson = (styles) => {
  if (!styles) {
    return {};
  }
  styles = styles.split(";");
  let newStyles = {};
  styles.forEach((style) => {
    if (style.trim()) {
      style = style.split(":");
      if (style[0].trim() && style[1].trim()) {
        newStyles[style[0].trim()] = style[1].trim();
      }
    }
  });
  return newStyles;
};
export default {
  name: "ckeditor",
  render(createElement) {
    return createElement("div", {}, [createElement("div")]);
  },
  data() {
    return {
      instance: null,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: null, // Use null as the default value, so `config.readOnly` can take precedence.
    },
    color: {
      type: String,
      default: null,
    },
    lineHeight: {
      type: String,
      default: null,
    },
    linkTextColor: {
      type: String,
      default: null,
    },
    linkUnderLine: {
      type: Boolean,
      default: null,
    },
  },

  mounted() {
    getEditorNamespace(
      (process.env.VUE_APP_VUE2_APP_URL || "") + "/ckeditor/ckeditor.js?t=MAS9",
      (namespace) => {
        this.$emit("namespaceloaded", namespace);
      }
    ).then(() => {
      if (this.$_destroyed) {
        return;
      }

      const config = this.config || {};

      if (this.readOnly !== null) {
        config.readOnly = this.readOnly;
      }

      const element = this.$el.firstElementChild;
      const editor = (this.instance = window.CKEDITOR["inline"](
        element,
        config
      ));

      editor.on("instanceReady", () => {
        this.$emit("instanceChange", editor);
        const data = this.value;
        editor.fire("lockSnapshot");

        editor.setData(data, {
          callback: () => {
            this.$_setUpEditorEvents();

            const newData = editor.getData();

            // Locking the snapshot prevents the 'change' event.
            // Trigger it manually to update the bound data.
            if (data !== newData) {
              this.$once("input", () => {
                this.$emit("ready", editor);
              });

              this.$emit("input", newData);
            } else {
              this.$emit("ready", editor);
            }

            editor.fire("unlockSnapshot");
          },
        });
      });

      window.CKEDITOR.on("dialogDefinition", (e) => {
        var dialogDefinition = e.data.definition;
        var dialogName = e.data.name;
        if (dialogName == "link") {
          let protocol = dialogDefinition.getContents("info").get("protocol");
          let url = dialogDefinition.getContents("info").get("url");
          let tags = [
            ["unsubscribe", "%%unsubscribe%%"],
            ["view in browser", "%%view_in_browser%%"],
            ["report abuse", "%%report_abuse%%"],
            ["mailing preference", "%%mailing_preference%%"],
          ];
          let tagVals = tags.map((tag) => tag[1]);
          protocol["items"] = [
            ["http://", "http://"],
            ["https://", "https://"],
            ...tags,
          ];
          let isOkClicked = false;
          url.onChange = function () {
            let value = this.getValue();
            let prot = e.data.dialog.getContentElement("info", "protocol");
            if (!isOkClicked && !prot.getValue() && tagVals.includes(value)) {
              prot.setValue(value);
            }
          };
          protocol.onChange = function () {
            let value = this.getValue();
            let urlField = e.data.dialog.getContentElement("info", "url");
            if (tagVals.includes(value)) {
              urlField.setValue(value);
              urlField.disable();
            } else {
              if (value && tagVals.includes(urlField.getValue())) {
                urlField.setValue("");
              }
              urlField.enable();
            }
          };
          let originalOnShow = dialogDefinition.onShow;
          dialogDefinition.onShow = function () {
            isOkClicked = false;
            originalOnShow.call(this);
          };
          let originalOnOk = dialogDefinition.onOk;
          dialogDefinition.onOk = function () {
            isOkClicked = true;
            let prot = e.data.dialog.getContentElement("info", "protocol");
            if (tagVals.includes(prot.getValue())) {
              prot.setValue("");
            }
            originalOnOk.call(this);
          };
        }
      });
    });
  },

  beforeDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }

    this.$_destroyed = true;
  },

  watch: {
    value(val) {
      if (this.instance && this.instance.getData() !== val) {
        this.instance.setData(val);
      }
    },
    color(color) {
      this.setTextColor(color);
    },
    lineHeight() {
      this.setLineHeight();
    },
    linkTextColor(color) {
      this.setLinkProps("color", color);
    },
    linkUnderLine(val) {
      this.setLinkProps("textDecoration", val ? "underline" : "none");
    },

    readOnly(val) {
      if (this.instance) {
        this.instance.setReadOnly(val);
      }
    },
  },

  methods: {
    $_setUpEditorEvents() {
      const editor = this.instance;

      const onChange = debounce((evt) => {
        editor.fire("lockSnapshot");
        const sel = editor.getSelection();
        const text = sel.getSelectedText();
        const ranges = sel.getRanges();
        let selected = ranges[0]?.getBoundaryNodes();
        editor.element
          .getElementsByTag("*")
          .toArray()
          .forEach((el) => {
            if (
              [
                "div",
                "p",
                "li",
                "h1",
                "h2",
                "h3",
                "h4",
                "h5",
                "h6",
                "a",
              ].includes(el.getName()) &&
              el.getChildCount() === 1 &&
              el.getFirst().getName &&
              el.getFirst().getName() === "span" &&
              !(el.getFirst().getAttribute("class") || "").includes(
                "cke_placeholder"
              ) &&
              !(el.getFirst().getAttribute("class") || "").includes(
                "cke_widget_placeholder"
              )
            ) {
              const json = {
                ...parseStyleTagToJson(el.getAttribute("style")),
                ...parseStyleTagToJson(el.getFirst().getAttribute("style")),
              };
              el.getFirst()
                .getChildren()
                .toArray()
                .forEach((child) => {
                  el.append(child);
                });
              el.getFirst().$.remove();
              el.setAttribute(
                "style",
                Object.keys(json)
                  .map((k) => k + ":" + json[k])
                  .join(";")
              );
              editor.fire("saveSnapshot");
            }
          });
        if (ranges[0] && text) {
          ranges[0].setStart(selected.startNode, 0);
          let node = selected.endNode.$.nodeValue;
          ranges[0].setEnd(selected.endNode, node ? node.length : 0);
          sel.selectRanges([ranges[0]]);
        }
        this.setLineHeight();
        if (this.color) {
          this.setTextColor(this.color, false);
        }
        if ([false, true].includes(this.linkUnderLine)) {
          this.setLinkProps(
            "textDecoration",
            this.linkUnderLine ? "underline" : "none"
          );
        }
        if (this.linkTextColor) {
          this.setLinkProps("color", this.linkTextColor, false);
        }
        editor.fire("unlockSnapshot");
        editor.fire("saveSnapshot");
        this.$nextTick(() => {
          const data = editor.getData();
          // Editor#change event might be fired without an actual data change.
          if (this.value !== data) {
            // The compatibility with the v-model and general Vue.js concept of input–like components.
            this.$emit("input", data, evt, editor);
          }
        });
      }, 80);

      editor.on("change", onChange);

      editor.on("focus", (evt) => {
        this.$emit("focus", evt, editor);
      });

      editor.on("blur", (evt) => {
        this.$emit("blur", evt, editor);
      });
    },
    setTextColor(color, update = true) {
      if (!this.instance) {
        return;
      }
      this.instance.element
        .getChildren()
        .toArray()
        .forEach((el) => {
          if (update || !el.getStyle("color")) {
            el.setStyle("color", color);
          }
        });
      this.instance.fire("saveSnapshot");
    },
    setLinkProps(prop, val, update = true) {
      if (!this.instance) {
        return;
      }
      this.instance.element
        .getElementsByTag("A")
        .toArray()
        .forEach((el) => {
          if (update || !el.getStyle(prop)) {
            el.setStyle(prop, val);
          }
        });
      this.instance.fire("saveSnapshot");
    },
    setLineHeight() {
      if (this.lineHeight && this.instance) {
        this.instance.element
          .getChildren()
          .toArray()
          .forEach((el) => {
            var fontSizeExist = false;
            if (el.getElementsByTag) {
              el.getElementsByTag("*")
                .toArray()
                .forEach((spEl) => {
                  if (spEl.getStyle("fontSize")) {
                    spEl.setStyle("lineHeight", this.lineHeight);
                    fontSizeExist = true;
                  }
                });
            }
            if (!fontSizeExist) {
              el.setStyle("lineHeight", this.lineHeight);
            } else if (el.getStyle("lineHeight")) {
              el.setStyle("lineHeight", null);
            }
            this.instance.fire("saveSnapshot");
          });
      }
    },
  },
};
