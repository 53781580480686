<template>
  <div class="dd-list-accrodion single individual" role="tablist">
    <b-card no-body>
      <b-collapse visible id="toggle-general-button-settings" role="tabpanel">
        <b-card-body>
          <bread-crumb />
          <b-row>
            <b-col>
              <image-upload
                :key="selected"
                prop="src"
                :value="value.src || ''"
                @input="updateProps('src', $event)"
                :hideLabel="true"
              />
            </b-col>
          </b-row>
          <b-row v-if="value.src">
            <b-col>
              <ruler />
              <dd-switch
                :value="!!value.rolloverEffect"
                @input="updateProps('rolloverEffect', $event)"
                >Rollover effect</dd-switch
              >
              <dd-help-text
                >The image that will display over current image on mouse
                hover.</dd-help-text
              >
            </b-col>
          </b-row>
          <b-row v-if="value.src && value.rolloverEffect" class="dd-mt-15">
            <b-col>
              <image-upload
                prop="rolloverSrc"
                :value="value.rolloverSrc || ''"
                @input="updateProps('rolloverSrc', $event)"
                :hideLabel="true"
              />
              <b-media no-body class="dd-help-text">
                <b-media-aside>
                  <img
                    src="../../assets/img/icon/info-circle-fill.svg"
                    width="14"
                    class="dd-mt-3"
                  />
                </b-media-aside>
                <b-media-body class="ml-2">
                  <p class="mb-0">
                    This feature works in a limited number of email services:
                    Gmail, Yahoo Mail, Ukr.net, AOL, iCloud Mail, Outlook 2003
                    and macOS Apple Mail.
                  </p>
                </b-media-body>
              </b-media>
              <div class="dd-alert-warning style-2 dd-mt-15">
                <b-media no-body>
                  <b-media-aside vertical-align="center">
                    <img
                      src="../../assets/img/icon/warning-ico-light.svg"
                      alt=""
                      width="18"
                    />
                  </b-media-aside>
                  <b-media-body class="dd-ml-15">
                    Please use the same size / proportion (width:
                    {{ value.originalWidth }}px, height:
                    {{ value.originalHeight }}px) image you used as the main
                    image (otherwise design looks broken)
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <hr class="dd-my-15" />
              <link-href
                :value="value.link || ''"
                @input="updateProps('link', $event)"
              />
            </b-col>
          </b-row>
          <b-row class="dd-mt-15">
            <b-col>
              <alt-text
                :value="value.alt || ''"
                @input="updateProps('alt', $event)"
              />
            </b-col>
          </b-row>
          <ruler class="dd-mx--20" />
          <b-row>
            <b-col cols="5">
              <dd-label>Background color</dd-label>
              <color-picker
                :value="value.backgroundColor || 'transparent'"
                @input="updateProps('backgroundColor', $event)"
              />
            </b-col>
          </b-row>
          <ruler />
          <b-row>
            <b-col>
              <align-horizontal
                :value="value || {}"
                @change-input="updateProps($event.key, $event.value)"
              />
              <ruler />
              <image-size
                :value="value || {}"
                @change="updateProps($event.key, $event.val)"
              />
              <ruler />
              <dd-switch
                :value="value.responsive !== false"
                @input="updateProps('responsive', $event)"
                >Responsive image</dd-switch
              >
              <div
                v-if="
                  value.responsive === false &&
                  value &&
                  value.src &&
                  value.width &&
                  value.width > 320
                "
                class="dd-alert-warning style-2 dd-mt-15"
              >
                <b-media no-body>
                  <b-media-aside vertical-align="center">
                    <img
                      src="../../assets/img/icon/warning-ico-light.svg"
                      alt=""
                      width="18"
                    />
                  </b-media-aside>
                  <b-media-body class="dd-ml-15">
                    It may affect your layout
                  </b-media-body>
                </b-media>
              </div>
              <dd-help-text>
                Enabling this feature makes uploaded image adjust automatically
                with the device’s screen size.<br />
                <strong>Warning:</strong> Adaptivity only works on supported
                devices.
              </dd-help-text>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ruler class="dd-mx--20" />
              <padding-all
                :value="value.padding || ''"
                @input="updateProps('padding', $event)"
                >Padding</padding-all
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ruler class="dd-mx--20" />
              <hide-element
                :value="value.hideElement || ''"
                @input="updateProps('hideElement', $event)"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ImageUpload from "../Properties/ImageUpload.vue";
import LinkHref from "../Properties/LinkHref.vue";
import AltText from "../Properties/AltText.vue";
import AlignHorizontal from "../Properties/AlignHorizontal.vue";
import ImageSize from "../Properties/ImageSize.vue";
import HideElement from "../Properties/HideElement.vue";
import ColorPicker from "../Controls/ColorPicker.vue";
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  name: "ImageSettings",
  components: {
    ImageUpload,
    LinkHref,
    AltText,
    AlignHorizontal,
    ImageSize,
    HideElement,
    ColorPicker,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("ddb", {
      selected: "getSelected",
    }),
  },
  methods: {
    updateProps(prop, val) {
      this.$store.dispatch("ddb/updateElementProperty", {
        property: prop,
        value: val,
      });
    },
  },
};
</script>
