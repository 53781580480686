<template>
  <b-row>
    <b-col>
      <dd-label-light>Letter Spacing</dd-label-light>
      <b-row>
        <b-col cols="auto" class="dd-max-w-130">
          <count-range
            label="letter spacing"
            :max="20"
            :value="parseInt(value || 0)"
            :inc-by="1"
            @input="$emit('input', $event + 'px')"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import CountRange from "../Controls/CountRange.vue";
export default {
  name: "LetterSpacing",
  components: { CountRange },
  props: {
    value: { type: String, default: "" },
  },
};
</script>
