var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"ml-menu",attrs:{"cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',{class:_vm.getTrClass},_vm._l((_vm.props.items),function(col,index){return _c('td',{key:'menu-tab' + index,class:_vm.menuClass(col),style:(_vm.tdStyle(index)),attrs:{"align":_vm.props.align || 'center',"bgcolor":col.backgroundColor ? _vm.setColor(col.backgroundColor) : null,"valign":"top","width":_vm.getWidth}},[(_vm.props.tab === 'icons')?_c('a',{attrs:{"target":"_blank","href":_vm.htmlEncode(col.href || '#')},on:{"click":function($event){return _vm.selectMenu($event, index)}}},[(_vm.showImg(col))?_c('img',_vm._b({attrs:{"src":col.src ? col.src : _vm.defaultSrc,"alt":col.alt,"title":col.name}},'img',_vm.imgAttributes,false)):_vm._e()]):(
            _vm.props.tab === 'iconsAndlinks' &&
            _vm.props.menuAlign === 'links-images-top'
          )?_c('a',{style:(_vm.fontStyle(index)),attrs:{"target":"_blank","href":_vm.htmlEncode(col.href || '#')},on:{"click":function($event){return _vm.selectMenu($event, index)}}},[(_vm.showImg(col))?_c('img',_vm._b({staticStyle:{"padding-bottom":"5px"},attrs:{"align":"absmiddle","src":col.src ? col.src : _vm.defaultSrc,"alt":col.alt,"title":col.name}},'img',_vm.imgAttributes,false)):_vm._e(),_c('br'),_vm._v(_vm._s(col.name)+" ")]):(
            _vm.props.tab === 'iconsAndlinks' &&
            _vm.props.menuAlign === 'links-images-left'
          )?_c('a',{style:(_vm.fontStyle(index)),attrs:{"target":"_blank","href":_vm.htmlEncode(col.href || '#')},on:{"click":function($event){return _vm.selectMenu($event, index)}}},[(_vm.showImg(col))?_c('img',_vm._b({staticStyle:{"padding-right":"5px"},attrs:{"align":"absmiddle","src":col.src ? col.src : _vm.defaultSrc,"alt":col.alt,"title":col.name}},'img',_vm.imgAttributes,false)):_vm._e(),_vm._v(" "+_vm._s(col.name)+" ")]):(
            _vm.props.tab === 'iconsAndlinks' &&
            _vm.props.menuAlign === 'links-images-right'
          )?_c('a',{style:(_vm.fontStyle(index)),attrs:{"target":"_blank","href":_vm.htmlEncode(col.href || '#')},on:{"click":function($event){return _vm.selectMenu($event, index)}}},[_vm._v(" "+_vm._s(col.name)+" "),(_vm.showImg(col))?_c('img',_vm._b({staticStyle:{"padding-left":"5px"},attrs:{"align":"absmiddle","src":col.src ? col.src : _vm.defaultSrc,"alt":col.alt,"title":col.name}},'img',_vm.imgAttributes,false)):_vm._e()]):_c('a',{style:(_vm.fontStyle(index)),attrs:{"target":"_blank","href":_vm.htmlEncode(col.href || '#')},on:{"click":function($event){return _vm.selectMenu($event, index)}}},[_vm._v(" "+_vm._s(col.name)+" ")]),(col.hideElement && !_vm.props.isPreview)?_c('div',{staticClass:"mld-hidden-status"},[_c('span',{class:col.hideElement === 'hide-mobile'
                ? 'ms-icon-hide-on-mobile'
                : 'ms-icon-hide-on-desktop'})]):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }