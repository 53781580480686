import Vue from "vue";
import Vuex from "vuex";
import App from "./App";
import ddb from "./store";
import BootstrapVue from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import Mixins from "./plugins/mixins";
import Components from "./plugins/components";
import VueLocalStorage from "vue-localstorage";
import "./assets/scss/builder.scss";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.component("multiselect", Multiselect);
Vue.use(Mixins);
Vue.use(Components);
Vue.use(VueLocalStorage, {
  name: "ls",
  bind: true,
});
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: { ddb },
  strict: false,
});

new Vue({
  render: (h) => h(App),
  store,
}).$mount("#app");
