var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"mld-dragg-structure",on:{"mouseenter":function($event){return _vm.mouseAction(true)},"mouseleave":function($event){return _vm.mouseAction(false)},"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('td',{staticClass:"mld-structure mld-frame mld-structure-frame mldev-disable-select mld-hover",class:{
      'mld-hidden-element': _vm.props.hideElement,
      'mld-activated': _vm.activated && 'struct-' + _vm.propKey != _vm.selected && !_vm.hover,
      'mld-active': 'struct-' + _vm.propKey == _vm.selected,
    },style:({
      backgroundColor: _vm.setColor(_vm.props.backgroundColor),
      ..._vm.backgroundImage,
      ..._vm.padding,
    }),attrs:{"align":"left","bgcolor":_vm.setColor(_vm.props.backgroundColor)}},[_c('div',{staticClass:"mld-structure-type"},[_vm._v("Structure")]),_c('action-buttons',{attrs:{"selected":'struct-' + _vm.propKey == _vm.selected,"delete-disabled":_vm.deleteDisabled},on:{"delete":_vm.onDelete,"copy":_vm.onCopy}}),_vm._t("default"),(_vm.props.hideElement)?_c('div',{staticClass:"mld-hidden-status"},[_c('span',{class:_vm.props.hideElement === 'hide-mobile'
            ? 'ms-icon-hide-on-mobile'
            : 'ms-icon-hide-on-desktop'})]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }