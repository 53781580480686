<script>
export default {
  name: "Comment",
  props: ["value"],
  render(h) {
    let comment = h(null);
    comment.text = this.value;
    return comment;
  },
};
</script>
