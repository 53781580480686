<template>
  <div class="dd-list-accrodion single individual" role="tablist">
    <b-card no-body>
      <b-card-body>
        <bread-crumb />
        <b-row class="dd-mb-15">
          <b-col>
            <dd-label>Button label</dd-label>
            <b-form-input
              class="dd-form-control"
              v-model="label"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <link-href
              :value="value.href || ''"
              @input="updateProps('href', $event)"
            />
            <b-media no-body class="dd-help-text">
              <b-media-aside>
                <img
                  src="../../assets/img/icon/info-circle-fill.svg"
                  width="14"
                  class="dd-mt-3"
                />
              </b-media-aside>
              <b-media-body class="ml-2">
                <p class="mb-0">A valid button link is mandatory</p>
              </b-media-body>
            </b-media>
            <ruler />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <button-style
              :value="''"
              @input="updateProps('buttonStyle', $event)"
            />
            <ruler />
          </b-col>
        </b-row>
        <dd-switch
          :value="value.fullwidth || false"
          @input="updateProps('fullwidth', $event)"
          >Full width</dd-switch
        >
        <ruler />
        <b-row>
          <b-col>
            <align-horizontal
              @change-input="changeInput"
              :value="value || {}"
            />
            <ruler />
          </b-col>
        </b-row>
        <p class="dd-label">Properties</p>
        <b-row class="dd-mb-20">
          <b-col>
            <font-family
              :value="value.fontFamily || button.fontFamily"
              @input="updateProps('fontFamily', $event)"
            />
            <div>
              <font-settings
                @change="changeInput"
                :value="{ ...button, ...(value || {}) }"
              />
            </div>
          </b-col>
        </b-row>

        <b-row class="dd-mb-20">
          <b-col cols="4">
            <color-picker
              :value="value.buttonColor || button.buttonColor"
              @input="updateProps('buttonColor', $event)"
              label="Button color"
            />
          </b-col>
          <b-col cols="4">
            <color-picker
              :value="value.fontColor || button.fontColor"
              @input="updateProps('fontColor', $event)"
              label="Font color"
            />
          </b-col>
          <b-col cols="4">
            <font-size
              :value="value.fontSize || button.fontSize"
              selectClass="fit-right"
              @input="updateProps('fontSize', $event)"
              label="Font size"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <dd-label-light>Background color</dd-label-light>
            <b-row>
              <b-col cols="4">
                <color-picker
                  :value="value.backgroundColor || 'transparent'"
                  @input="updateProps('backgroundColor', $event)"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ruler />
            <border-settings
              :value="value.border || button.border"
              @input="updateProps('border', $event)"
            />
            <ruler />
            <border-radius
              :value="value.borderRadius || button.borderRadius"
              @input="updateProps('borderRadius', $event)"
            />
            <ruler />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <padding-all
              label="Internal padding"
              :value="value.internalPadding || button.internalPadding"
              @input="updateProps('internalPadding', $event)"
            />
            <ruler />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <padding-all
              :value="value.padding || ''"
              @input="updateProps('padding', $event)"
              label="Margin"
              >Margin</padding-all
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ruler class="dd-mx--20" />
            <hide-element
              :value="value.hideElement || ''"
              @input="updateProps('hideElement', $event)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ColorPicker from "../Controls/ColorPicker.vue";
import FontSize from "../Properties/FontSize.vue";
import FontFamily from "../Properties/FontFamily.vue";
import FontSettings from "../Properties/FontSettings.vue";
import BorderRadius from "../Properties/BorderRadius.vue";
import BorderSettings from "../Properties/BorderSettings.vue";
import AlignHorizontal from "../Properties/AlignHorizontal.vue";
import ButtonStyle from "../Properties/ButtonStyle.vue";
import LinkHref from "../Properties/LinkHref.vue";
import HideElement from "../Properties/HideElement.vue";
export default {
  name: "ButtonSettings",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    ColorPicker,
    FontSize,
    FontFamily,
    FontSettings,
    BorderRadius,
    BorderSettings,
    AlignHorizontal,
    ButtonStyle,
    LinkHref,
    HideElement,
  },
  computed: {
    ...mapGetters("ddb", {
      properties: "getCommonProps",
    }),
    button() {
      return this.properties.button;
    },
    label: {
      get() {
        return this.value.text;
      },
      set(val) {
        this.updateProps("text", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    changeInput(data) {
      this.$store.dispatch("ddb/updateElementProperty", {
        property: data.key,
        value: data.value,
      });
    },
    updateProps(prop, val) {
      if (prop === "buttonStyle") {
        var btncolor = "#FB6376"; // (this.value.buttonColor && this.value.buttonColor !== 'transparent') || this.button.buttonColor
        var fontColor = "#FFFFFF";
        var borderValue = "0px";
        if (val === "solid") {
          borderValue = "0px";
        } else if (val === "bottomBorder") {
          borderValue = {
            left: "0px",
            right: "0px",
            top: "0px",
            bottom: "1px solid #000000",
          };
        } else if (val === "border") {
          btncolor = "transparent";
          fontColor = "#000000";
          borderValue = "1px solid #000000";
        }
        this.$store.dispatch("ddb/updateElementProperty", {
          property: "fontSize",
          value: "16px",
        });
        this.$store.dispatch("ddb/updateElementProperty", {
          property: "buttonColor",
          value: btncolor,
        });
        this.$store.dispatch("ddb/updateElementProperty", {
          property: "fontColor",
          value: fontColor,
        });
        this.$store.dispatch("ddb/updateElementProperty", {
          property: "border",
          value: borderValue,
        });
      } else {
        this.$store.dispatch("ddb/updateElementProperty", {
          property: prop,
          value: val,
        });
      }
    },
  },
};
</script>
