import { getOldJsonDefaultData } from "./OldJsonData.js";
const paddingAll0 = () => {
  return { left: "0px", right: "0px", top: "0px", bottom: "0px" };
};
export const migrateHtmlJson = (oldJson, domain) => {
  oldJson = JSON.parse(
    JSON.stringify(oldJson).replace(/"\//g, '"' + domain + "/")
  );
  const htmlJson = { properties: {} };
  htmlJson.properties.backgroundColor = oldJson.background;
  htmlJson.properties.fontFamily = oldJson.fontFamily;
  htmlJson.properties.emailWidth = parseInt(oldJson.contentWidth);
  htmlJson.properties.containerType = oldJson.blockType
    .toLowerCase()
    .replace(" ", "");
  const nml = "normal";
  htmlJson.properties.button = {
    buttonColor: "#007BFF",
    internalPadding: {
      top: "10px",
      bottom: "10px",
      left: "20px",
      right: "20px",
    },
    fontColor: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "arial,'helvetica neue',helvetica,sans-serif",
    fontWeight: nml,
    fontStyle: nml,
    borderRadius: "3px",
    textDecoration: "none",
    border: "0px solid #1C3F6F",
  };
  htmlJson.contents = [];
  oldJson.tree.forEach((content) => {
    htmlJson.contents.push(
      getMainContents(content, domain, htmlJson.properties)
    );
  });
  return htmlJson;
};
const getMainContents = (content, domain, properties) => {
  const structures = [];
  let data = getOldJsonDefaultData(content.name);
  let props = data.props;
  if (content.props) {
    Object.assign(props, content.props);
  }
  props = JSON.parse(JSON.stringify(props).replace(/"\//g, '"' + domain + "/"));
  if (content.name === "ButtonsAndMoreType3") {
    props.divider = {
      padding: {
        bottom: parseInt(props.paddingContent.bottom) + 20 + "px",
        top: props.paddingContent.top,
        left: "0px",
        right: "0px",
      },
      align: "center",
      border: "1px solid #f3eded",
      responsive: true,
    };
    props.menu.links.forEach((link, index) => {
      if (index) {
        data.structures[0].columns[0].elements.push({ tag: "divider" });
      }
      data.structures[0].columns[0].elements.push({ tag: "button" + index });
      props["button" + index] = {
        align: "center",
        backgroundColor: "transparent",
        borderRadius: { tl: "0px", tr: "0px", bl: "0px", br: "0px" },
        background: "transparent",
        fontColor: "#111111",
        fontSize: "16px",
        font: { bold: true, underline: true },
        margin: "0px",
        padding: "0px",
        text: link.label,
        href: link.href,
      };
    });
    delete props.menu;
    delete props.paddingContent;
  } else if (content.name === "SpecialType7") {
    data.structures[0].columns[1].background = props.profile.background;
    props.image = props.profile.image;
    props.image.align = "center";
    props.spacer = { height: parseInt(props.profile.padding.top) };
    props.spacer2 = { height: parseInt(props.profile.padding.bottom) };
    props.heading1 = {
      text: props.profile.name,
      fontColor: props.profile.fontColor,
      fontSize: "28px",
      fontFamily: '"Trebuchet MS", Helvetica, sans-serif',
      font: { bold: true },
      align: "center",
      padding: { top: "0px", bottom: "0px", left: "40px", right: "40px" },
    };
    props.subHeading1 = {
      text: props.profile.position,
      fontColor: props.profile.fontColor,
      fontSize: "14px",
      fontFamily: '"Trebuchet MS", Helvetica, sans-serif',
      font: { bold: true },
      align: "center",
      padding: { top: "0px", bottom: "10px", left: "40px", right: "40px" },
    };
  } else if (content.name === "SpecialType8") {
    data.structures[0].columns[1].background = props.profile.background;
    props.image = props.profile.image;
    props.image.align = "center";
    props.image.padding = props.profile.padding;
  }
  data.structures.forEach((structure) => {
    structures.push(getStructure(structure, props, properties));
  });
  const contentProps = {};
  if (props.background) {
    if (properties.containerType === "fullwidth") {
      contentProps.contentBackground = props.background;
    } else {
      contentProps.backgroundColor = props.background;
    }
  }
  contentProps.hideElement = props.hideOnMobile ? "hide-mobile" : "";
  return { props: contentProps, structures };
};
const getStructure = (structure, props, properties) => {
  let emailWidth = properties.emailWidth;
  const newStructure = { columns: [], props: { padding: paddingAll0() } };
  structure.columns.forEach((col) => {
    newStructure.columns.push(getColumn(col, props));
  });
  if (props.blockBackground) {
    newStructure.props.backgroundColor = props.blockBackground;
  }
  if (props.bgImage) {
    newStructure.props.backgroundImage = props.bgImage;
  }
  if (structure.padding) {
    newStructure.props.padding.left = structure.padding.left;
    newStructure.props.padding.right = structure.padding.right;
    emailWidth =
      emailWidth -
      parseInt(structure.padding.left) -
      parseInt(structure.padding.right);
    if (props.menuPadding) {
      props.menu.padding = {
        top: props.menuPadding.top,
        bottom: props.menuPadding.bottom,
      };
    }
    if (structure.columns[0].elements[0].tag !== "menu" && props.padding) {
      newStructure.props.padding.top = props.padding.top;
      newStructure.props.padding.bottom = props.padding.bottom;
    }
  }
  if (newStructure.columns.length === 1) {
    newStructure.columns[0].props.width = emailWidth;
  } else {
    if (properties.containerType === "fullwidth") {
      properties.containerType = "card";
    }
    let width = newStructure.columns.reduce((width, col) => {
      return width + (col.props.width || 0);
    }, 0);
    width = emailWidth - width;
    const noWidthCols = newStructure.columns.reduce((total, col) => {
      return total + (col.props.width ? 0 : 1);
    }, 0);
    width = parseInt(width / noWidthCols);
    if (width > 0) {
      newStructure.columns.forEach((col) => {
        if (!col.props.width) {
          col.props.width = width;
        }
      });
    }
  }
  return newStructure;
};
const getColumn = (col, props) => {
  const container = { elements: [], props: {} };
  col.elements.forEach((el, index) => {
    let tag = el.tag;
    if (
      tag &&
      tag.includes("price") &&
      col.elements[index + 1].tag.includes("oldPrice") &&
      props[col.elements[index + 1].tag]
    ) {
      props[tag].oldPrice = getElement(col.elements[index + 1], props);
    }
    if (tag && tag.indexOf("oldPrice") === -1) {
      el = getElement(el, props);
      if (el) {
        container.elements.push(el);
      }
    }
  });
  let columnProp = {};
  if (col.background) {
    container.props.backgroundColor = col.background;
  }
  if (Object.prototype.hasOwnProperty.call(col, "width")) {
    columnProp.width = col.width;
  } else {
    const width = container.elements.reduce((width, el) => {
      return (el.props.width || 0) > width ? el.props.width : width;
    }, 0);
    if (width) {
      columnProp.width = parseInt(width);
    }
  }
  return { containers: [container], props: columnProp };
};
const getElement = (el, props) => {
  let newProp = props[el.tag];
  let tag = null;
  if (newProp) {
    newProp.hideElement = newProp.hideOnMobile ? "hide-mobile" : "";
    delete newProp.hideOnMobile;
    if (newProp.padding) {
      newProp.padding = { ...paddingAll0(), ...newProp.padding };
    }
  } else {
    newProp = {};
  }
  if (["logo", "image"].includes(el.tag) || el.tag.includes("image")) {
    tag = "dd-image";
    if (newProp.fixedWidth) {
      newProp.width = newProp.fixedWidth;
      newProp.originalWidth = newProp.fixedWidth;
      delete newProp.fixedWidth;
    }
  }
  if (el.tag.includes("button")) {
    tag = "dd-button";
    setButtonProps(newProp);
  }
  if (
    ["unsubscribe", "email", "phone", "name", "position"].includes(el.tag) ||
    el.tag.includes("heading") ||
    el.tag.includes("text") ||
    el.tag.includes("subHeading") ||
    el.tag.includes("content") ||
    el.tag.includes("price") ||
    el.tag.includes("oldPrice")
  ) {
    tag = "dd-text";
    setFontProps(newProp, el.tag);
    if (el.tag.includes("oldPrice")) {
      return newProp.value;
    }
  }
  if (el.tag === "space") {
    newProp.height =
      parseInt(props.padding.top) + parseInt(props.padding.bottom) + 20;
    tag = "dd-spacer";
  }
  if (el.tag === "line") {
    newProp.padding = {
      top: props.padding.top,
      bottom: parseInt(props.padding.bottom) + 20 + "px",
      left: "0px",
      right: "0px",
    };
    newProp.border = "1px solid " + props.lineColor;
    newProp.responsive = true;
    tag = "dd-divider";
  }
  if (el.tag.includes("spacer")) {
    tag = "dd-spacer";
  }
  if (el.tag === "divider") {
    tag = "dd-divider";
  }
  if (el.tag === "social") {
    tag = "dd-share";
    newProp.iconSize = "24px";
    newProp.iconPadding = newProp.iconSpacing + "px";
    newProp.items = newProp.icons.map((link) => {
      return {
        name: link.icon,
        logo: "",
        alt: link.icon,
        type: "custom",
        custom: true,
        customName: link.icon,
        href: link.link,
        src: link.imgUrl,
      };
    });
    delete newProp.icons;
    delete newProp.iconSet;
    delete newProp.iconSpacing;
  }
  if (el.tag === "menu") {
    tag = "dd-menu";
    newProp.fontWeight = "bold";
    newProp.items = newProp.links.map((link) => {
      return { name: link.label, href: link.href };
    });
    newProp.linkColor = newProp.fontColor;
    delete newProp.links;
    delete newProp.fontColor;
  }
  if (tag) {
    return { tag, props: newProp };
  }
  return null;
};
const setButtonProps = (prop) => {
  prop.href = prop.link || "";
  delete prop.link;
  prop.buttonColor = prop.background;
  delete prop.background;
  prop.internalPadding = { ...paddingAll0(), ...prop.padding };
  prop.padding = { ...paddingAll0(), ...prop.margin };
  delete prop.margin;
  prop.borderRadius = {
    bottomLeft: prop.borderRadius.bl,
    bottomRight: prop.borderRadius.br,
    topLeft: prop.borderRadius.tl,
    topRight: prop.borderRadius.tr,
  };
  prop.border = "";
  if (prop.borderStyle === "all") {
    prop.border = "2px solid " + prop.borderColor;
  } else if (prop.borderStyle === "bottom") {
    prop.border = {
      bottom: "2px solid " + prop.borderColor,
      top: "0px solid " + prop.borderColor,
      left: "0px solid " + prop.borderColor,
      right: "0px solid " + prop.borderColor,
    };
  }
  delete prop.borderColor;
  prop.fullwidth = !prop.autoWidth;
  delete prop.autoWidth;
  prop.textDecoration = getTextdecoration(prop);
  prop.fontWeight = prop.font && prop.font.bold ? "bold" : "normal";
  prop.fontStyle = prop.font && prop.font.italic ? "italic" : "normal";
  delete prop.font;
};
const setFontProps = (prop, tag) => {
  prop.color = prop.fontColor;
  prop.linkColor = prop.fontColor;
  delete prop.fontColor;
  let p = document.createElement("P");
  let text = document.createElement("SPAN");
  if (prop.html) {
    let html = document.createElement("DIV");
    prop.text = html.innerHTML = prop.html;
    if (
      html.firstChild &&
      ["P", "H1", "H2", "H3", "H4", "H5", "H5", "DIV"].includes(
        html.firstChild.tagName
      )
    ) {
      prop.text = html.firstChild.innerHTML;
      html.firstChild.innerHTML = "";
      p = html.firstChild;
    }
  }
  text.innerHTML = prop.text;
  const styles = getTextStyle(prop);
  delete prop.font;
  delete prop.fontSize;
  Object.assign(text.style, styles);
  if (prop.link) {
    let a = document.createElement("A");
    a.setAttribute("href", prop.link);
    a.setAttribute("target", "_blank");
    a.style.color = prop.linkColor;
    a.appendChild(text);
    text = a;
  }
  p.style.color = prop.color;
  p.appendChild(text);
  if (prop.oldPrice) {
    let newp = document.createElement("P");
    newp.innerHTML = prop.oldPrice;
    p.innerHTML = p.innerHTML + "&nbsp;&nbsp;&nbsp;" + newp.innerHTML;
    delete prop.oldPrice;
  }
  prop.value = p.outerHTML;
  if (tag.includes("oldPrice")) {
    prop.value = p.innerHTML;
  }
};
const getTextStyle = (prop) => {
  return {
    "font-family": prop.fontFamily || null,
    "font-size": parseInt(prop.fontSize) + "px",
    "font-weight": prop.font && prop.font.bold ? "bold" : "normal",
    "text-transform": prop.font && prop.font.uppercase ? "uppercase" : "none",
    "font-style": prop.font && prop.font.italic ? "italic" : "normal",
    "text-decoration": getTextdecoration(prop),
  };
};
const getTextdecoration = (prop) => {
  if (!prop.font || (!prop.font.strike && !prop.font.underline)) {
    return "none";
  }
  const decoration = [];
  if (prop.font.strike) {
    decoration.push("line-through");
  }
  if (prop.font.underline) {
    decoration.push("underline");
  }
  return decoration.join(" ");
};
