<template>
  <div id="app">
    <dd-builder />
  </div>
</template>
<script>
import DdBuilder from "./DdBuilder.vue";
export default {
  name: "App",
  components: { DdBuilder },
};
</script>
