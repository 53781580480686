<template>
  <div>
    <dd-label>Line spacing</dd-label>
    <b-row>
      <b-col cols="8">
        <div class="dd-btn-ico-group dd-line-space">
          <a
            href="#"
            v-for="(h, ind) in ['100%', '120%', '150%', '200%']"
            :class="{ active: h == lineHeight }"
            :key="'line-height-' + ind"
            @click="emitValue(h)"
          >
            <img
              :src="
                require('../../assets/img/icon/line-height/line-height-' +
                  (ind + 1) +
                  '.svg')
              "
              width="18"
              alt="1"
            />
          </a>
        </div>
      </b-col>
      <b-col cols="4" class="ml-auto text-right">
        <b-form-input
          class="dd-form-control"
          placeholder="1.5"
          v-model="inputVal"
          @change="inputChange"
        ></b-form-input>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "LineSpacing",
  props: {
    value: { type: String, default: "150%" },
  },
  data() {
    return {
      lineHeight: "",
      inputVal: "",
    };
  },
  created() {
    this.setValue(this.value);
  },
  watch: {
    value(val) {
      this.setValue(val);
    },
  },
  methods: {
    emitValue(val, input = false) {
      this.lineHeight = val;
      var originalValue = parseInt(this.lineHeight) / 100;
      if (input && this.inputVal > originalValue) {
        this.showLimitExceedMessage(originalValue, "line spacing");
      }
      this.inputVal = originalValue;
      this.$emit("input", val);
    },
    setValue(val) {
      val = parseInt(val);
      this.inputVal = (isNaN(val) ? 150 : val) / 100;
      this.lineHeight = this.inputVal * 100 + "%";
    },
    inputChange() {
      let val = parseFloat(this.inputVal);
      if (!isNaN(val) && val <= 9) {
        this.lineHeight = Math.round((isNaN(val) ? 1.5 : val) * 100) + "%";
      }
      this.emitValue(this.lineHeight, true);
    },
  },
};
</script>
