<template>
  <div>
    <div class="dd-right-sidebar-body" :class="{ 'pb-0': isFullWidth }">
      <bread-crumb />
      <template v-if="!isFullWidth">
        <b-row align-v="center" class="dd-mb-10">
          <b-col>
            <p class="dd-label mb-0">Columns</p>
          </b-col>
          <b-col cols="auto" class="ml-auto text-right">
            <b-row no-gutters align-v="center">
              <b-col cols="auto" class="dd-label-light mb-0"> Equalizer </b-col>
              <b-col cols="auto">
                <span class="pull-right dd-switch">
                  <b-form-checkbox v-model="equalWidth" switch>
                  </b-form-checkbox>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="dd-mb-10">
          <b-col>
            <ul class="dd-structure-group">
              <li>
                <add-structure @click.native="addColumn(0)" />
                <div class="row dd-sm-row">
                  <div
                    class="col"
                    v-for="(col, index) in structure.columns"
                    :key="'col-struct' + index"
                  >
                    <div class="dd-structure">{{ index + 1 }}</div>
                  </div>
                </div>
                <add-structure
                  position="right"
                  tooltip="right"
                  @click.native="addColumn(structure.columns.length)"
                />
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ul class="dd-structure-group structure-width">
              <li
                v-for="(col, index) in structure.columns"
                :key="'col-setting-' + index"
                @mouseenter="$store.commit('ddb/setHoverColIndex', index)"
                @mouseleave="$store.commit('ddb/setHoverColIndex', -1)"
              >
                <b-row align-v="center">
                  <b-col cols="auto dd-pl-20">{{ index + 1 }}</b-col>
                  <b-col cols="6">
                    <count-range
                      label="column width"
                      :value="col.props.width"
                      :inc-by="1"
                      :min="isFullWidth ? 4 : 30"
                      :max="maxColWidth"
                      @input="updateColumnProps(index, 'width', $event)"
                    />
                  </b-col>
                  <b-col cols="auto" class="ml-auto">
                    <a
                      href="javascript:void(0)"
                      class="dd-btn-trash"
                      v-b-tooltip
                      title="Delete"
                      @click="deleteColumn(index)"
                    >
                      <img
                        src="../../assets/img/icon/trash-ico.svg"
                        width="14"
                        alt="trash"
                      />
                    </a>
                  </b-col>
                </b-row>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-media no-body class="dd-help-text">
              <b-media-aside>
                <img
                  src="../../assets/img/icon/info-circle-fill.svg"
                  width="14"
                  class="dd-mt-3"
                />
              </b-media-aside>
              <b-media-body class="ml-2">
                <p class="mb-0">
                  Without adding any value for width. its works with auto width
                </p>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <b-row class="dd-mt-20" v-if="structure.columns.length > 1">
          <b-col>
            <p class="dd-label">Indent between containers</p>
            <b-row>
              <b-col cols="6">
                <count-range
                  label="indent"
                  :inc-by="isFullWidth ? 1 : 5"
                  :max="isFullWidth ? 15 : 40"
                  :value="structure.props.indent"
                  @input="updateStructureProps('indent', $event)"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </div>
    <list-accrodion>
      <background-settings
        :value="structure.props"
        @change="updateStructureProps($event.prop, $event.val)"
      />
      <b-card no-body>
        <b-card-body class="dd-mt-12">
          <padding-all
            :value="structure.props.padding"
            @input="updateStructureProps('padding', $event)"
            >Padding</padding-all
          >
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-body class="dd-mt-12">
          <!--<dd-switch :value="structure.props.inversionMobile ? structure.props.inversionMobile : false" @input="updateStructureProps('inversionMobile', $event)">Containers inversion on mobile</dd-switch>-->
          <!--<dd-help-text>-->
          <!--  <span v-if="structure.props.inversionMobile">-->
          <!--    The interface flip from right to left-->
          <!--  </span>-->
          <!--  <span v-else>-->
          <!--    Containers of the structure will be located one below the other on mobile devices.-->
          <!--  </span>-->
          <!--</dd-help-text>-->
          <!--<ruler />-->
          <!--<dd-switch :value="structure.props.responsiveStructure ? structure.props.responsiveStructure : false" @input="updateStructureProps('responsiveStructure', $event)">Responsive structure</dd-switch>-->
          <!--<dd-help-text>-->
          <!--  <span v-if="structure.props.responsiveStructure">-->
          <!--    Containers of the structure will be located one below the other on mobile devices.-->
          <!--  </span>-->
          <!--  <span v-else>-->
          <!--    Containers of the structure will be located in one row on mobile devices. This can lead to incorrect display when content with a large width (large words, etc.).-->
          <!--  </span>-->
          <!--</dd-help-text>-->
          <!--<ruler />-->
          <dd-switch
            :value="structure.props.containerPaddingMobile !== false"
            @input="updateStructureProps('containerPaddingMobile', $event)"
            >Padding between containers on mobile</dd-switch
          >
          <dd-help-text>
            In mobile view, you get a 20px gap between two columns
          </dd-help-text>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-body class="dd-mt-12">
          <hide-element
            :value="structure.props.hideElement"
            @input="updateStructureProps('hideElement', $event)"
          />
        </b-card-body>
      </b-card>
    </list-accrodion>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getPadding, getBorderWidth } from "../../plugins/JsonHelper.js";
import AddStructure from "../Buttons/AddStructure.vue";
export default {
  name: "StructureSettings",
  components: { AddStructure },
  computed: {
    ...mapState({
      structure: (state) =>
        state.ddb.htmlJson.contents[state.ddb.mainIndex].structures[
          state.ddb.structIndex
        ],
      content: (state) => state.ddb.htmlJson.contents[state.ddb.mainIndex],
      mainIndex: (state) => state.ddb.mainIndex,
      structIndex: (state) => state.ddb.structIndex,
      emailWidth: (state) => state.ddb.htmlJson.properties.emailWidth,
      isFullWidth: (state) =>
        state.ddb.htmlJson.properties.containerType === "fullwidth",
    }),
    equalWidth: {
      get() {
        return this.structure.props.equalWidth;
      },
      set(val) {
        this.updateStructureProps("equalWidth", val);
      },
    },
    maxColWidth() {
      let width = 100;
      if (!this.isFullWidth) {
        width =
          this.emailWidth -
          getPadding(this.structure.props.padding) -
          getBorderWidth(this.content.props.border);
      }
      width -=
        (this.structure.props.indent || 0) *
        (this.structure.columns.length - 1);
      width -=
        (this.isFullWidth ? 4 : 30) * (this.structure.columns.length - 1);
      return width;
    },
  },
  methods: {
    addColumn(colIndex) {
      if (this.structure.columns.length < 8) {
        this.$store.dispatch("ddb/addColumn", {
          mainIndex: this.mainIndex,
          structIndex: this.structIndex,
          colIndex,
        });
      }
    },
    deleteColumn(colIndex) {
      if (this.structure.columns.length > 1) {
        this.$store.dispatch("ddb/deleteColumn", {
          mainIndex: this.mainIndex,
          structIndex: this.structIndex,
          colIndex,
        });
      }
    },
    updateStructureProps(prop, val) {
      this.$store.dispatch("ddb/updateStructureProps", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
        prop,
        val,
      });
    },
    updateColumnProps(colIndex, prop, val) {
      this.$store.dispatch("ddb/updateColumnProps", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
        colIndex,
        prop,
        val,
      });
    },
  },
};
</script>
