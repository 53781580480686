<template>
  <div>
    <p v-if="label" class="dd-label-light">{{ label }}</p>
    <multiselect
      v-model="fontValue"
      :options="options"
      :allow-empty="false"
      class="dd-multiselect dd-font-size dd-select-radius-8"
      :class="selectClass"
    >
      <template slot="option" slot-scope="props">
        <span :style="{ fontSize: props.option + 'px' }">{{
          props.option
        }}</span>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { maxFontSize } from "../../constants";
export default {
  name: "FontSize",
  props: {
    label: { type: String, default: "" },
    value: { type: String, default: "" },
    start: { type: Number, default: 8 },
    end: { type: Number, default: maxFontSize },
    selectClass: { type: String, default: "" },
  },
  data() {
    return {
      fontValue: isNaN(parseInt(this.value)) ? 14 : parseInt(this.value),
    };
  },
  computed: {
    options() {
      return Array.from(
        { length: this.end - this.start + 1 },
        (_, i) => i + this.start
      );
    },
  },
  watch: {
    value(val) {
      val = parseInt(val);
      this.fontValue = isNaN(val) ? 14 : val;
    },
    fontValue(val) {
      if (this.value !== val) {
        this.$emit("input", val + "px");
      }
    },
  },
};
</script>
