<template>
  <div>
    <multiselect
      v-model="styleValue"
      :options="options"
      :option-height="104"
      :allow-empty="false"
      :show-labels="false"
      class="dd-multiselect dd-social-type dd-select-radius-8 dd-select-border"
    >
      <template slot="singleLabel" slot-scope="props">
        <b-row align-v="center">
          <b-col class="dd-font-13 text-truncate">
            {{ props.option.name }}
          </b-col>
          <b-col cols="auto" class="ml-auto">
            <img :src="setImageDomain(props.option.img)" width="90" />
          </b-col>
        </b-row>
      </template>
      <template slot="option" slot-scope="props">
        <b-row align-v="center">
          <b-col cols="auto" class="dd-font-13">
            {{ props.option.name }}
          </b-col>
          <b-col cols="auto" class="ml-auto">
            <img :src="setImageDomain(props.option.img)" width="90" />
          </b-col>
        </b-row>
      </template>
    </multiselect>
  </div>
</template>
<script>
export default {
  name: "SocialMediaType",
  props: {
    value: { type: String(), default: "" },
  },
  components: {},
  created() {
    if (this.value) {
      this.styleValue = this.getSelectedVal(this.value);
    }
  },
  methods: {
    getSelectedVal(val) {
      let selected = this.options.find((option) => {
        return option.value === val;
      });
      return selected || this.options[0] || "";
    },
  },
  data() {
    return {
      styleValue: "",
      options: [
        {
          name: "Colored logos",
          value: "colored-logos",
          img: "/img/social-media/colored-logos/preview-32-logo.png",
        },
        {
          name: "Black logos",
          value: "black-logos",
          img: "/img/social-media/black-logos/preview-32-logo.png",
        },
        {
          name: "Gray logos",
          value: "gray-logos",
          img: "/img/social-media/gray-logos/preview-32-logo.png",
        },
        {
          name: "White logos",
          value: "white-logos",
          img: "/img/social-media/white-logos/preview-32-logo.png",
        },
        {
          name: "Circle color logos",
          value: "circle-color-logos",
          img: "/img/social-media/circle-color-logos/preview-32-logo.png",
        },
        {
          name: "Circle color with border",
          value: "circle-color-with-border",
          img: "/img/social-media/circle-color-with-border/preview-32-logo.png",
        },
        {
          name: "Rounded color logos",
          value: "rounded-color-logos",
          img: "/img/social-media/rounded-color-logos/preview-32-logo.png",
        },
        {
          name: "Rounded color with border",
          value: "rounded-color-with-border",
          img: "/img/social-media/rounded-color-with-border/preview-32-logo.png",
        },
        {
          name: "Square color logos",
          value: "square-color-logos",
          img: "/img/social-media/square-color-logos/preview-32-logo.png",
        },
        {
          name: "Square color with border",
          value: "square-color-with-border",
          img: "/img/social-media/square-color-with-border/preview-32-logo.png",
        },
        {
          name: "Circle black logos",
          value: "circle-black-logos",
          img: "/img/social-media/circle-black-logos/preview-32-logo.png",
        },
        {
          name: "Circle black with border",
          value: "circle-black-with-border",
          img: "/img/social-media/circle-black-with-border/preview-32-logo.png",
        },
        {
          name: "Square black logos",
          value: "square-black-logos",
          img: "/img/social-media/square-black-logos/preview-32-logo.png",
        },
        {
          name: "Square black with border",
          value: "square-black-with-border",
          img: "/img/social-media/square-black-with-border/preview-32-logo.png",
        },
        {
          name: "Circle gray logos",
          value: "circle-gray-logos",
          img: "/img/social-media/circle-gray-logos/preview-32-logo.png",
        },
        {
          name: "Circle gray with border",
          value: "circle-gray-with-border",
          img: "/img/social-media/circle-gray-with-border/preview-32-logo.png",
        },
        {
          name: "Rounded gray logos",
          value: "rounded-gray-logos",
          img: "/img/social-media/rounded-gray-logos/preview-32-logo.png",
        },
        {
          name: "Rounded gray with border",
          value: "rounded-gray-with-border",
          img: "/img/social-media/rounded-gray-with-border/preview-32-logo.png",
        },
        {
          name: "Square gray logos",
          value: "square-gray-logos",
          img: "/img/social-media/square-gray-logos/preview-32-logo.png",
        },
        {
          name: "Square gray with border",
          value: "square-gray-with-border",
          img: "/img/social-media/square-gray-with-border/preview-32-logo.png",
        },
        {
          name: "Circle white logos",
          value: "circle-white-logos",
          img: "/img/social-media/circle-white-logos/preview-32-logo.png",
        },
        {
          name: "Circle white with border",
          value: "circle-white-with-border",
          img: "/img/social-media/circle-white-with-border/preview-32-logo.png",
        },
        {
          name: "Rounded white logos",
          value: "rounded-white-logos",
          img: "/img/social-media/rounded-white-logos/preview-32-logo.png",
        },
        {
          name: "Rounded white with border",
          value: "rounded-white-with-border",
          img: "/img/social-media/rounded-white-with-border/preview-32-logo.png",
        },
        {
          name: "Square white logos",
          value: "square-white-logos",
          img: "/img/social-media/square-white-logos/preview-32-logo.png",
        },
        {
          name: "Square white with border",
          value: "square-white-with-border",
          img: "/img/social-media/square-white-with-border/preview-32-logo.png",
        },
      ],
    };
  },
  watch: {
    styleValue(val) {
      if (val && val !== this.value && val.value !== this.value) {
        this.$emit("input", val.value);
      }
    },
    value(val) {
      this.styleValue = this.getSelectedVal(val);
    },
  },
};
</script>
