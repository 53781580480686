import DdGroupTitle from "../components/Labels/DdGroupTitle";
import PanelTitle from "../components/Labels/PanelTitle";
import DdLabel from "../components/Labels/DdLabel";
import DdLabelLight from "../components/Labels/DdLabelLight";
import AdvanceOption from "../components/Labels/AdvanceOption";
import Ruler from "../components/Labels/Ruler";
import BreadCrumb from "../components/Labels/BreadCrumb";
import ModalConfirm from "../components/Labels/ModalConfirm";
import CountRange from "../components/Controls/CountRange.vue";
import ListAccrodion from "../components/Frames/ListAccrodion";
import DdTable from "../components/Frames/DdTable";
import DdEmailPaddings from "../components/Frames/DdEmailPaddings";
import PaddingAll from "../components/Properties/PaddingAll.vue";
import HideElement from "../components/Properties/HideElement.vue";
import BorderRadius from "../components/Properties/BorderRadius.vue";
import BorderSettings from "../components/Properties/BorderSettings.vue";
import DdSwitch from "../components/Properties/DdSwitch.vue";
import DdHelpText from "../components/Labels/DdHelpText.vue";
import ColorPicker from "../components/Controls/ColorPicker.vue";
import BackgroundSettings from "../components/GeneralSettings/GeneralBackgroundSettings.vue";
export default {
  install(Vue) {
    Vue.component("DdGroupTitle", DdGroupTitle);
    Vue.component("PanelTitle", PanelTitle);
    Vue.component("DdLabel", DdLabel);
    Vue.component("DdLabelLight", DdLabelLight);
    Vue.component("AdvanceOption", AdvanceOption);
    Vue.component("ListAccrodion", ListAccrodion);
    Vue.component("DdTable", DdTable);
    Vue.component("DdEmailPaddings", DdEmailPaddings);
    Vue.component("PaddingAll", PaddingAll);
    Vue.component("BorderRadius", BorderRadius);
    Vue.component("BorderSettings", BorderSettings);
    Vue.component("DdSwitch", DdSwitch);
    Vue.component("Ruler", Ruler);
    Vue.component("BreadCrumb", BreadCrumb);
    Vue.component("ModalConfirm", ModalConfirm);
    Vue.component("HideElement", HideElement);
    Vue.component("CountRange", CountRange);
    Vue.component("ColorPicker", ColorPicker);
    Vue.component("DdHelpText", DdHelpText);
    Vue.component("BackgroundSettings", BackgroundSettings);
  },
};
