<template>
  <table cellpadding="0" cellspacing="0" class="ml-table-not-adapt ml-smedia">
    <tbody>
      <tr>
        <td
          v-for="(col, index) in props.items"
          :style="tdStyle(index)"
          :key="'menu-tab' + index"
          align="center"
          valign="top"
        >
          <a
            @click="selectMenu($event, index)"
            target="_blank"
            :href="htmlEncode(col.href || '#')"
          >
            <img
              v-if="getSrc(col)"
              :title="col.name"
              :src="getSrc(col)"
              :alt="col.alt"
              :width="parseInt(props.iconSize)"
              :height="parseInt(props.iconSize)"
            />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { getSocialmediaPath } from "../../plugins/JsonHelper.js";
export default {
  name: "DdShare",
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    selectMenu(e, val) {
      if (!this.props.isPreview) {
        e.preventDefault();
      }
      this.$store.dispatch("ddb/setSelectedMenu", {
        type: "social",
        index: val,
      });
    },
    getSrc(value) {
      const image = getSocialmediaPath(value, this.props.logotype);
      if (
        image ===
        (process.env.VUE_APP_VUE2_APP_URL || "") + "/img/default-img.png"
      ) {
        return !this.props.isPreview ? image : "";
      }
      return this.setImageDomain(image);
    },
    tdStyle(index) {
      var attr = {};
      if (this.props.iconPadding && index < this.props.items.length - 1) {
        attr.paddingRight = this.props.iconPadding;
      }

      return attr;
    },
  },
};
</script>
