<template>
  <div>
    <dd-switch
      :value="value"
      @input="emitProps"
      :checked-value="true"
      :unchecked-value="false"
      >Responsive divider</dd-switch
    >
    <p class="dd-help-text">
      Enabling this feature makes your divider adjust automatically with the
      device’s screen size.<br />
      <strong>Warning:</strong> Adaptivity only works on supported devices.
    </p>
  </div>
</template>
<script>
export default {
  name: "ResponsiveDivider",
  props: {
    value: { type: Boolean(), default: false },
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    emitProps(val) {
      this.$emit("input", val);
    },
  },
};
</script>
