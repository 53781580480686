<template>
  <span class="ml-button-border msohide" :style="borderStyle">
    <a
      :href="htmlEncode(props.href || '#')"
      class="ml-button"
      :style="buttonStyle"
      target="_blank"
      @click="onClick"
      >{{ props.text }}</a
    >
  </span>
</template>
<script>
import {
  parseBorderStyle,
  parseBorderWidth,
  parseBorderRadius,
} from "../../plugins/StyleConverter.js";
export default {
  name: "DdButton",
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return { parseBorderRadius };
  },
  computed: {
    borderStyle() {
      return {
        ...(this.props.border ? parseBorderStyle(this.props.border) : {}),
        ...(this.props.borderRadius
          ? parseBorderRadius(this.props.borderRadius)
          : {}),
        background: this.props.buttonColor,
        display: this.props.fullwidth ? "block" : "inline-block",
      };
    },
    buttonStyle() {
      return {
        fontFamily: this.props.fontFamily,
        fontWeight: this.props.fontWeight,
        fontStyle: this.props.fontStyle,
        textDecoration: this.props.textDecoration,
        background: this.props.buttonColor,
        color: this.props.fontColor,
        fontSize: this.props.fontSize,
        ...(this.props.borderRadius
          ? parseBorderRadius(this.props.borderRadius)
          : {}),
        ...(this.props.internalPadding
          ? parseBorderWidth(this.props.internalPadding)
          : {}),
        display: this.props.fullwidth ? "block" : "inline-block",
        borderColor: this.props.buttonColor,
      };
    },
  },
  methods: {
    onClick(e) {
      if (!this.props.isPreview) {
        e.preventDefault();
      }
    },
  },
};
</script>
