var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dd-table',{staticClass:"ml-content",class:{
    'ml-desk-hidden': _vm.props.hideElement === 'hide-pc',
    'ml-mobile-hidden': _vm.props.hideElement === 'hide-mobile',
  },style:(_vm.borderTopBottom),attrs:{"width":"100%","align":_vm.align}},[_c('tbody',[_c('tr',[_c('td',{style:({
          ..._vm.padding,
          backgroundColor: _vm.setColor(_vm.props.backgroundColor),
          ..._vm.backgroundImage,
        }),attrs:{"align":_vm.align,"bgcolor":_vm.setColor(_vm.props.backgroundColor)}},[_c('dd-table',{staticClass:"ml-content-body",style:({
            width: _vm.width,
            backgroundColor: _vm.setColor(
              _vm.props.contentBackground || _vm.commonProps.contentBackground
            ),
            ..._vm.border,
          }),attrs:{"align":_vm.align,"width":_vm.width === '100%' ? '100%' : parseInt(_vm.width),"bgcolor":_vm.setColor(_vm.props.contentBackground || _vm.commonProps.contentBackground)}},[_c('tbody',[_vm._t("default")],2)])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }