<template>
  <b-row>
    <b-col>
      <b-input-group class="dd-count-range">
        <b-input-group-prepend>
          <b-button variant="dd-light" class="dd-btn" @click="sub">-</b-button>
        </b-input-group-prepend>
        <b-form-input
          class="dd-form-control"
          v-filter="/^\d*$/"
          v-model="result"
          @change="emitValue"
        ></b-form-input>
        <b-input-group-append>
          <b-button variant="dd-light" class="dd-btn" @click="add">+</b-button>
        </b-input-group-append>
      </b-input-group>
      <slot />
    </b-col>
  </b-row>
</template>

<script>
import Filter from "../../directives/filter";
export default {
  name: "CountRange",
  directives: { Filter },
  props: {
    label: { default: "", type: String },
    value: { default: 0, type: [Number, String] },
    incBy: { default: 1, type: Number },
    min: { default: 0, type: Number },
    max: { default: 0, type: Number },
  },
  data() {
    return {
      oldVal: 0,
      result: 0,
    };
  },
  watch: {
    value(val) {
      this.result = val;
      this.oldVal = val;
    },
  },
  created() {
    this.result = this.value;
    this.oldVal = this.value;
  },
  methods: {
    add() {
      var newval = parseInt(this.oldVal) + this.incBy;
      if ((this.max && newval <= this.max) || !this.max) {
        this.emitValue(newval);
      }
    },
    sub() {
      this.emitValue(parseInt(this.oldVal) - this.incBy);
    },
    emitValue(val) {
      val = val < this.min ? this.oldVal : parseInt(val);
      if (this.max && val > this.max) {
        this.showLimitExceedMessage(this.max, this.label);
      }
      val = this.max && val > this.max ? this.max : parseInt(val);
      this.$nextTick(() => {
        this.result = isNaN(val) ? this.oldVal : val;
        if (this.result !== this.oldVal) {
          this.$emit("input", this.result);
        }
        this.oldVal = this.result;
      });
    },
  },
};
</script>
