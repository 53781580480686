<template>
  <tr
    class="mld-dragg-structure"
    @mouseenter="mouseAction(true)"
    @mouseleave="mouseAction(false)"
    @click.stop="onClick"
  >
    <td
      class="mld-structure mld-frame mld-structure-frame mldev-disable-select mld-hover"
      :class="{
        'mld-hidden-element': props.hideElement,
        'mld-activated': activated && 'struct-' + propKey != selected && !hover,
        'mld-active': 'struct-' + propKey == selected,
      }"
      align="left"
      :bgcolor="setColor(props.backgroundColor)"
      :style="{
        backgroundColor: setColor(props.backgroundColor),
        ...backgroundImage,
        ...padding,
      }"
    >
      <div class="mld-structure-type">Structure</div>
      <action-buttons
        :selected="'struct-' + propKey == selected"
        :delete-disabled="deleteDisabled"
        @delete="onDelete"
        @copy="onCopy"
      />
      <slot />
      <div v-if="props.hideElement" class="mld-hidden-status">
        <span
          :class="
            props.hideElement === 'hide-mobile'
              ? 'ms-icon-hide-on-mobile'
              : 'ms-icon-hide-on-desktop'
          "
        ></span>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapState } from "vuex";
import { parseStyleProperty } from "../../plugins/StyleConverter.js";
import ActionButtons from "./ActionButtons";
export default {
  name: "Structure",
  components: {
    ActionButtons,
  },
  props: {
    structIndex: {
      type: Number,
      default: -1,
    },
    mainIndex: {
      type: Number,
      default: -1,
    },
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
    propKey: {
      type: Number,
      default: -1,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      activated: false,
    };
  },
  computed: {
    ...mapState({
      selected: (state) => state.ddb.selected,
    }),
    backgroundImage() {
      if (this.props.backgroundImage) {
        return {
          backgroundImage: "url(" + this.props.backgroundImage + ")",
          backgroundRepeat: this.props.backgroundRepeat || "no-repeat",
          backgroundPosition: this.props.backgroundPosition || "center top",
        };
      }
      return {};
    },
    padding() {
      return parseStyleProperty(this.props.padding);
    },
  },
  methods: {
    onClick() {
      this.$store.dispatch("ddb/setAllIndexes", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
      });
      this.$store.dispatch("ddb/setSelected", "struct-" + this.propKey);
      this.$emit("click");
    },
    mouseAction(enter) {
      this.activated = enter;
      this.$emit("hover", enter);
    },
    onCopy() {
      this.$store.dispatch("ddb/duplicateStructure", {
        mainIndex: this.mainIndex,
        structIndex: this.structIndex,
      });
    },
    onDelete() {
      if (!this.deleteDisabled) {
        this.$emit("confirmDelete", {
          action: "ddb/deleteStructure",
          data: { mainIndex: this.mainIndex, structIndex: this.structIndex },
        });
      }
    },
  },
};
</script>
