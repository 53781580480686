<template>
  <b-card no-body class="border-top-0">
    <b-card-header header-tag="header" role="tab">
      <panel-title id="idTypography"> Default font settings </panel-title>
    </b-card-header>
    <b-card-body>
      <font-family
        :value="value.fontFamily"
        @input="updateProps('fontFamily', $event)"
      />
      <b-row class="dd-sm-row dd-mb-15">
        <b-col cols="auto">
          <color-picker
            :value="value.textColor || '#000000'"
            label="Text color"
            @input="updateProps('textColor', $event)"
          />
        </b-col>
        <b-col cols="auto">
          <color-picker
            :value="value.linkColor || '#1376c8'"
            label="Link text color"
            @input="updateProps('linkColor', $event)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="dd-label dd-mb-15">Headings</p>
          <font-heading
            label="H1"
            :value="value.h1"
            @input="updateProps('h1', $event)"
          />
          <hr class="mt-0 dd-mb-15" />
          <font-heading
            label="H2"
            :value="value.h2"
            @input="updateProps('h2', $event)"
          />
          <hr class="mt-0 dd-mb-15" />
          <font-heading
            label="H3"
            :value="value.h3"
            @input="updateProps('h3', $event)"
          />
          <hr class="mt-0 dd-mb-15" />
          <font-heading
            label="Paragraph"
            :value="value.paragraph"
            @input="updateProps('paragraph', $event)"
          />
        </b-col>
      </b-row>
      <div class="dd-mb-20">
        <line-spacing
          :value="value.lineHeight"
          @input="updateProps('lineHeight', $event)"
        />
      </div>
      <dd-switch
        :value="value.bottomSpace"
        @input="updateProps('bottomSpace', $event)"
        >Paragraph bottom space</dd-switch
      >
      <b-media no-body class="dd-help-text">
        <b-media-aside>
          <img
            src="../../assets/img/icon/info-circle-fill.svg"
            width="14"
            class="dd-mt-3"
          />
        </b-media-aside>
        <b-media-body class="ml-2">
          <p class="mb-0">
            Enabling this will add some space between the two consecutive
            paragraphs.
          </p>
        </b-media-body>
      </b-media>
    </b-card-body>
  </b-card>
</template>
<script>
import FontFamily from "../Properties/FontFamily.vue";
import LineSpacing from "../Properties/LineSpacing.vue";
import ColorPicker from "../Controls/ColorPicker.vue";
import FontHeading from "../Properties/FontHeading.vue";
export default {
  name: "GeneralFontSettings",
  components: {
    FontFamily,
    LineSpacing,
    ColorPicker,
    FontHeading,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    updateProps(prop, val) {
      const props = { ...this.value };
      props[prop] = val;
      this.$emit("input", props);
      this.$emit("change", { prop, val });
    },
  },
};
</script>
