<script>
import Vue from "vue";
import { commonHead } from "../../constants";
export default {
  data() {
    return { iApp: null, styleEl: null, timer: null };
  },
  props: {
    commonStyle: { type: String, default: "" },
  },
  beforeDestroy() {
    if (this.iApp) {
      this.iApp.$destroy();
    }
  },
  beforeUpdate() {
    // freezing to prevent unnessessary Reactifiation of vNodes
    if (this.iApp) {
      this.iApp.children = Object.freeze(this.$slots.default);
    }
  },
  computed: {
    disableResponsive() {
      return (
        this.$store.state.ddb.htmlJson.properties.disableResponsive || false
      );
    },
    dragging() {
      return this.$store.state.ddb.dragging;
    },
  },
  watch: {
    commonStyle(val) {
      this.styleEl.innerHTML = val;
    },
    dragging: {
      handler(val) {
        if (val) {
          this.$el.contentDocument.body.className = "mld-" + val;
        } else {
          this.$el.contentDocument.body.className = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    currentYPosition() {
      // Firefox, Chrome, Opera, Safari
      if (this.$el.contentWindow.pageYOffset) {
        return this.$el.contentWindow.pageYOffset;
      }
      // Internet Explorer 6 - standards mode
      if (
        this.$el.contentDocument.documentElement &&
        this.$el.contentDocument.documentElement.scrollTop
      ) {
        return this.$el.contentDocument.documentElement.scrollTop;
      }
      // Internet Explorer 6, 7 and 8
      if (this.$el.contentDocument.body.scrollTop) {
        return this.$el.contentDocument.body.scrollTop;
      }
      return 0;
    },
    smoothScroll(e) {
      if (e.clientY !== 0 && this.dragging) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        if (
          e.clientY > 0 &&
          e.view !== this.$el.contentWindow &&
          e.clientY < 60 &&
          this.currentYPosition() > 0
        ) {
          this.timer = setInterval(() => {
            this.$el.contentWindow.scrollTo(0, this.currentYPosition() - 2);
            if (this.currentYPosition() - 2 <= 0 || !this.dragging) {
              clearInterval(this.timer);
            }
          }, 1);
        } else if (
          this.$el.contentWindow.innerHeight - 10 <= e.clientY ||
          e.clientY < 0
        ) {
          this.timer = setInterval(() => {
            this.$el.contentWindow.scrollTo(0, this.currentYPosition() + 2);
            if (!this.dragging) {
              clearInterval(this.timer);
            }
          }, 1);
        }
      }
    },
    renderChildren() {
      if (!this.iApp) {
        if (window.CKEDITOR) {
          delete window.CKEDITOR;
        }
        if (window.frameWindow && window.frameWindow.CKEDITOR) {
          delete window.frameWindow.CKEDITOR;
        }
        const children = this.$slots.default;
        const style = this.commonStyle;
        this.$el.contentWindow.document.open();
        this.$el.contentWindow.document.write(
          '<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">'
        );
        this.$el.contentWindow.document.close();
        const body = this.$el.contentDocument.body;
        const head = this.$el.contentDocument.head;
        const vm = this;
        this.$el.contentWindow.addEventListener("dragover", function (e) {
          vm.smoothScroll(e);
        });
        this.$el.contentWindow.addEventListener("drag", function (e) {
          vm.smoothScroll(e);
        });
        window.frameDocument = this.$el.contentDocument;
        window.frameWindow = this.$el.contentWindow;
        head.innerHTML =
          commonHead(this.$ls.get("imageDomain"), this.disableResponsive) +
          `
      <link rel="stylesheet" href="` +
          (process.env.VUE_APP_VUE2_APP_URL || "") +
          `/custom-preview-styles.css?v=1.6">`;
        const el = document.createElement("DIV"); // we will mount or nested app to this element
        body.appendChild(el);
        this.styleEl = document.createElement("style");
        this.styleEl.type = "text/css";
        head.appendChild(this.styleEl);
        this.styleEl.innerHTML = style;
        if (this.$store.state.ddb.dragging) {
          this.$el.contentDocument.body.className =
            "mld-" + this.$store.state.ddb.dragging;
        }
        const iApp = new Vue({
          name: "IApp",
          comments: true,
          // freezing to prevent unnessessary Reactifiation of vNodes
          data: { children: Object.freeze(children) },
          render(h) {
            return h("div", this.children);
          },
          store: this.$store,
        });
        iApp.$mount(el); // mount into iframe
        this.iApp = iApp; // cache instance for later updates
      }
    },
  },
  render(h) {
    return h("iframe", {
      on: { load: this.renderChildren },
    });
  },
};
</script>
