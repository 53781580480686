<template>
  <div id="side-right-menu" class="dd-sidebar-body">
    <div class="dd-right-sidebar-head sticky-menu bg-white">
      <b-row align-v="center">
        <dd-group-title v-if="isButton">Button</dd-group-title>
        <dd-group-title v-else-if="isText">Text</dd-group-title>
        <dd-group-title v-else-if="isSocial">Social</dd-group-title>
        <dd-group-title v-else-if="isImage">Image</dd-group-title>
        <dd-group-title v-else-if="isSpacer">Spacer</dd-group-title>
        <dd-group-title v-else-if="isDivider">Divider</dd-group-title>
        <dd-group-title v-else-if="isHtml">HTML</dd-group-title>
        <dd-group-title v-else-if="isMenu">Menu</dd-group-title>
        <dd-group-title v-else-if="selected.indexOf('main') === 0"
          >Section Settings</dd-group-title
        >
        <dd-group-title v-else-if="selected.indexOf('struct') === 0"
          >Structure Settings</dd-group-title
        >
        <dd-group-title v-else-if="selected.indexOf('cont') === 0"
          >Container Settings</dd-group-title
        >
        <dd-group-title v-else>General Settings</dd-group-title>
        <b-col v-if="selected != ''" cols="auto" class="ml-auto">
          <a
            href="javascript:void(0)"
            class="dd-close-boxed"
            @click="$store.dispatch('ddb/setSelected', '')"
          >
            <img src="../../assets/img/icon/close.svg" width="18" alt="" />
          </a>
        </b-col>
      </b-row>
      <sticky-menu v-model="tab" v-if="selected === ''" />
    </div>
    <transition name="slide-fade" mode="out-in">
      <button-settings v-if="isButton" :value="props" />
      <text-settings
        v-else-if="isText"
        :value="props"
        :general-property="properties"
      />
      <image-settings v-else-if="isImage" :value="props" />
      <divider-settings v-else-if="isDivider" :value="props" />
      <html-settings v-else-if="isHtml" :value="props" />
      <menu-settings
        v-else-if="isMenu"
        :value="props"
        :general-property="properties"
      />
      <social-settings v-else-if="isSocial" :value="props" />
      <spacer-settings v-else-if="isSpacer" :value="props" />
      <component-settings
        v-else-if="selected.indexOf('main') === 0"
        :general-property="properties"
      />
      <structure-settings v-else-if="selected.indexOf('struct') === 0" />
      <container-settings v-else-if="selected.indexOf('cont') === 0" />
    </transition>
    <template v-if="selected === ''">
      <general-container-settings v-if="!isFullWidth && tab == 'content'" />
      <list-accrodion>
        <general-padding-settings
          :isFullWidth="isFullWidth"
          v-if="tab == 'content'"
        />

        <background-settings
          v-if="tab == 'content'"
          :is-general="true"
          :value="properties"
          @change="updateGneralProperty($event.prop, $event.val)"
        />

        <general-font-settings
          v-if="tab == 'typography'"
          :value="properties"
          @change="updateGneralProperty($event.prop, $event.val)"
        />

        <general-button-settings
          v-if="tab == 'button'"
          :value="properties.button || {}"
          @input="updateGneralProperty('button', $event)"
        />
      </list-accrodion>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import GeneralContainerSettings from "../GeneralSettings/GeneralContainerSettings.vue";
import GeneralPaddingSettings from "../GeneralSettings/GeneralPaddingSettings.vue";
import GeneralFontSettings from "../GeneralSettings/GeneralFontSettings.vue";
import GeneralButtonSettings from "../GeneralSettings/GeneralButtonSettings.vue";
import StickyMenu from "../GeneralSettings/StickyMenu.vue";
import StructureSettings from "../Properties/StructureSettings.vue";
import ContainerSettings from "../Properties/ContainerSettings.vue";
import ComponentSettings from "../Properties/ComponentSettings.vue";
import ButtonSettings from "../IndividualSettings/ButtonSettings.vue";
import TextSettings from "../IndividualSettings/TextSettings.vue";
import ImageSettings from "../IndividualSettings/ImageSettings.vue";
import DividerSettings from "../IndividualSettings/DividerSettings.vue";
import SpacerSettings from "../IndividualSettings/SpacerSettings.vue";
import HtmlSettings from "../IndividualSettings/HtmlSettings.vue";
import MenuSettings from "../IndividualSettings/MenuSettings.vue";
import SocialSettings from "../IndividualSettings/SocialSettings.vue";
export default {
  name: "SidebarRight",
  components: {
    GeneralContainerSettings,
    GeneralPaddingSettings,
    GeneralFontSettings,
    GeneralButtonSettings,
    StickyMenu,
    StructureSettings,
    ContainerSettings,
    ComponentSettings,
    ButtonSettings,
    TextSettings,
    ImageSettings,
    DividerSettings,
    SpacerSettings,
    HtmlSettings,
    MenuSettings,
    SocialSettings,
  },
  data() {
    return {
      tab: "content",
    };
  },
  watch: {
    selected() {
      this.scrollToTop();
      if (this.showMenu.menu !== "") {
        this.$store.dispatch("ddb/setSelectedMenu", {
          type: "menu",
          index: "",
        });
      }
      if (this.showMenu.social !== "") {
        this.$store.dispatch("ddb/setSelectedMenu", {
          type: "social",
          index: "",
        });
      }
    },
  },
  computed: {
    ...mapGetters("ddb", {
      selected: "getSelected",
      properties: "getCommonProps",
      props: "getSelectedElementProps",
      tag: "getSelectedElementTag",
      showMenu: "getSelectedMenu",
    }),
    isFullWidth() {
      return (
        this.$store.state.ddb &&
        this.$store.state.ddb.htmlJson.properties &&
        this.$store.state.ddb.htmlJson.properties.containerType === "fullwidth"
      );
    },
    isButton() {
      return this.tag === "dd-button";
    },
    isImage() {
      return this.tag === "dd-image";
    },
    isText() {
      return this.tag === "dd-text";
    },
    isDivider() {
      return this.tag === "dd-divider";
    },
    isSpacer() {
      return this.tag === "dd-spacer";
    },
    isHtml() {
      return this.tag === "dd-html";
    },
    isMenu() {
      return this.tag === "dd-menu";
    },
    isSocial() {
      return this.tag === "dd-share";
    },
  },
  methods: {
    scrollToTop() {
      document.getElementById("side-right-menu").scrollTop = 0;
    },
    updateGneralProperty(property, value) {
      this.$store.dispatch("ddb/updateGneralProperty", { property, value });
    },
  },
};
</script>
