<template>
  <div>
    <b-row>
      <b-col>
        <b-button-group class="dd-goto-group">
          <b-button
            @click="emitValue('property', 'width')"
            variant="light"
            :class="value.property === 'width' ? 'active' : ''"
            v-b-tooltip.hover
            title="Width"
            ><img src="../../assets/img/icon/width-arrow.svg" width="19" alt=""
          /></b-button>
          <b-button
            @click="emitValue('property', 'height')"
            variant="light"
            :class="value.property === 'height' ? 'active' : ''"
            v-b-tooltip.hover
            title="Height"
            ><img
              src="../../assets/img/icon/height-arrow.svg"
              height="18"
              alt=""
          /></b-button>
        </b-button-group>
      </b-col>
      <b-col>
        <count-range
          label="icon size"
          @input="emitValue('size', $event)"
          :value="value.size ? parseInt(value.size) : 0"
          :min="0"
          :max="100"
          :inc-by="incBy"
        >
        </count-range>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  name: "MenuSize",
  data() {
    return {
      incBy: 1,
    };
  },
  methods: {
    emitValue(key, val) {
      val = key === "size" ? val + "px" : val;
      var sizeVal = JSON.parse(JSON.stringify(this.value));
      sizeVal[key] = val;
      if (JSON.stringify(this.value) !== JSON.stringify(sizeVal)) {
        this.$emit("input", sizeVal);
      }
    },
  },
};
</script>
