var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"mld-element",on:{"mouseenter":function($event){_vm.activated = true},"mouseleave":function($event){_vm.activated = false},"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('td',{directives:[{name:"alignment",rawName:"v-alignment"}],staticClass:"mld-frame mld-hover mldev-disable-select mld-draggable mld-block",class:{
      'mld-hidden-element': _vm.props.hideElement,
      'mld-activated': _vm.activated && 'el-' + _vm.propKey != _vm.selected,
      'mld-active': 'el-' + _vm.propKey == _vm.selected,
      'mld-block-menu': _vm.tag === 'dd-menu',
      'mld-block-social': _vm.tag === 'dd-share',
      'mld-block-image': _vm.tag === 'dd-image',
      'mld-block-text': _vm.tag === 'dd-text',
      'mld-block-spacer': _vm.tag === 'dd-spacer',
      'mld-block-divider': _vm.tag === 'dd-divider',
      'mld-block-html': _vm.tag === 'dd-html',
      'mld-block-nutton': _vm.tag === 'dd-button',
      ..._vm.mobileAlign,
      ..._vm.screenAlignment,
    },style:({ ..._vm.padding, backgroundColor: _vm.setColor(_vm.props.backgroundColor) }),attrs:{"align":_vm.tag === 'dd-html' ? null : _vm.props.align || 'left',"height":_vm.tag === 'dd-spacer' ? _vm.props.height : false,"bgcolor":_vm.setColor(_vm.props.backgroundColor)},on:{"align":_vm.setScreenAlign}},[_c('action-buttons',{attrs:{"selected":'el-' + _vm.propKey == _vm.selected},on:{"delete":_vm.onDelete,"copy":_vm.onCopy}}),_vm._t("default"),(_vm.props.hideElement)?_c('div',{staticClass:"mld-hidden-status"},[_c('span',{class:_vm.props.hideElement === 'hide-mobile'
            ? 'ms-icon-hide-on-mobile'
            : 'ms-icon-hide-on-desktop'})]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }