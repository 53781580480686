<template>
  <div class="dd-btn-ico-group dd-align-settings">
    <a
      href="#"
      @click.prevent="setVal('left')"
      :class="{ active: value === 'left' }"
      v-b-tooltip.hover
      title="Align left"
    >
      <img
        src="../../assets/img/icon/align/align-left.svg"
        width="18"
        alt="L"
      />
    </a>
    <a
      href="#"
      @click.prevent="setVal('center')"
      :class="{ active: value === 'center' }"
      v-b-tooltip.hover
      title="Align center"
    >
      <img
        src="../../assets/img/icon/align/align-center.svg"
        width="18"
        alt="C"
      />
    </a>
    <a
      href="#"
      @click.prevent="setVal('right')"
      :class="{ active: value === 'right' }"
      v-b-tooltip.hover
      title="Align right"
      v-if="!hideAlignRight"
    >
      <img
        src="../../assets/img/icon/align/align-right.svg"
        width="18"
        alt="R"
      />
    </a>
  </div>
</template>
<script>
export default {
  name: "Alignment",
  props: {
    value: {
      type: String,
      default: "",
    },
    hideAlignRight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setVal(val) {
      if (val !== this.value) {
        this.$emit("input", val);
      }
    },
  },
};
</script>
