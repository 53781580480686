<template>
  <div class="dd-list-accrodion single individual" role="tablist">
    <b-card no-body>
      <b-card-body>
        <bread-crumb />
        <b-row class="dd-mb-20">
          <b-col>
            <dd-label-light>Icons style</dd-label-light>
            <social-media-type
              :value="value.logotype"
              @input="updateProps('logotype', $event)"
            ></social-media-type>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <dd-label-light>Icon size</dd-label-light>
            <count-range
              label="icon size"
              :value="value.iconSize ? parseInt(value.iconSize) : 0"
              :max="64"
              @input="updateProps('iconSize', $event + 'px')"
              :min="0"
              :inc-by="incBy"
            >
            </count-range>
          </b-col>
          <b-col>
            <dd-label-light>Icon padding</dd-label-light>
            <count-range
              label="icon padding"
              :value="value.iconPadding ? parseInt(value.iconPadding) : 0"
              :max="100"
              @input="updateProps('iconPadding', $event + 'px')"
              :min="0"
              :inc-by="incBy"
            >
            </count-range>
          </b-col>
        </b-row>
        <ruler />
        <b-row>
          <b-col cols="6">
            <dd-label-light class="dd-mb-10">Background color</dd-label-light>
            <color-picker
              :value="value.backgroundColor || 'transparent'"
              @input="updateProps('backgroundColor', $event)"
            />
          </b-col>
        </b-row>
        <ruler />
        <b-row>
          <b-col>
            <align-horizontal
              :value="value || {}"
              @change-input="updateProps($event.key, $event.value)"
            />
          </b-col>
        </b-row>
        <ruler />
        <social-item
          :logo-type="value.logotype"
          :value="value.items"
          @input="updateProps('items', $event)"
        />
        <div
          v-if="value.items.length > 7"
          class="dd-alert-warning dd-mt-10 dd-mb-10"
        >
          <b-media no-body>
            <b-media-aside vertical-align="center">
              <img
                src="../../assets/img/icon/warning-ico-light.svg"
                alt=""
                width="18"
              />
            </b-media-aside>
            <b-media-body class="dd-ml-15">
              <strong>Warning!</strong> adding more social media icons may cause
              unsightliness
            </b-media-body>
          </b-media>
        </div>
        <b-button
          size="sm"
          id="popoverSocialIcons"
          variant="outline-primary"
          block
          class="dd-btn-doted"
        >
          <img
            src="../../assets/img/icon/plus-blue-ico.svg"
            width="12"
            alt=""
            class="dd-mr-10"
          />
          Add another
        </b-button>
        <div id="socialIconContainer"></div>
        <b-popover
          ref="socialpopover"
          target="popoverSocialIcons"
          triggers="click blur"
          placement="top"
          container="socialIconContainer"
        >
          <b-row>
            <b-col>
              <b-tabs pills fill content-class="mt-3" align="center">
                <b-tab title="Social media" active>
                  <ul class="list-inline">
                    <li
                      class="list-inline-item"
                      @click="AddSocialmedia('socialmedia', col)"
                      v-for="(col, index) in socialMedia"
                      :key="'social-' + index"
                    >
                      <img
                        :src="getImageWidthDomain(col.logo + '.png')"
                        width="32"
                        alt=""
                        v-b-tooltip.hover
                        :title="col.name"
                      />
                    </li>
                  </ul>
                </b-tab>
                <b-tab title="Chat">
                  <ul class="list-inline">
                    <li
                      class="list-inline-item"
                      @click="AddSocialmedia('chat', col)"
                      v-for="(col, index) in chat"
                      :key="'chat-' + index"
                    >
                      <img
                        :src="getImageWidthDomain('chat/' + col.logo + '.png')"
                        width="32"
                        alt=""
                        v-b-tooltip.hover
                        :title="col.name"
                      />
                    </li>
                  </ul>
                </b-tab>
                <b-tab title="Other">
                  <ul class="list-inline">
                    <li
                      class="list-inline-item"
                      @click="AddSocialmedia('other', col)"
                      v-for="(col, index) in others"
                      :key="'others-' + index"
                    >
                      <img
                        :src="getImageWidthDomain('other/' + col.logo + '.png')"
                        width="32"
                        alt=""
                        v-b-tooltip.hover
                        :title="col.name"
                      />
                    </li>
                  </ul>
                </b-tab>
              </b-tabs>
              <b-button
                @click="addCustom"
                block
                size="sm"
                variant="primary"
                class="dd-btn-gradiant"
              >
                Add custom icon
              </b-button>
            </b-col>
          </b-row>
        </b-popover>
        <ruler class="dd-mx--20" />
        <padding-all
          :value="value.padding"
          @input="updateProps('padding', $event)"
        >
          <slot>Padding</slot>
        </padding-all>
        <ruler class="dd-mx--20" />
        <hide-element
          :value="value.hideElement || ''"
          @input="updateProps('hideElement', $event)"
        />
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import CountRange from "../Controls/CountRange.vue";
import AlignHorizontal from "../Properties/AlignHorizontal.vue";
import SocialItem from "../Properties/SocialItem.vue";
import PaddingAll from "../Properties/PaddingAll.vue";
import HideElement from "../Properties/HideElement.vue";
import SocialMediaType from "../Properties/SocialMediaType.vue";
export default {
  name: "SocialSettings",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    CountRange,
    SocialMediaType,
    AlignHorizontal,
    SocialItem,
    PaddingAll,
    HideElement,
  },
  data() {
    return {
      styleValue: "outlined",
      others: [
        { name: "Blogger", logo: "blogger", alt: "Blogger" },
        { name: "Email", logo: "email", alt: "Email" },
        { name: "Website", logo: "link", alt: "Website" },
        { name: "Map Marker", logo: "location", alt: "Map Marker" },
        { name: "World", logo: "globe", alt: "World" },
        { name: "Gmail", logo: "gmail", alt: "gm" },
        { name: "Address", logo: "address", alt: "Address" },
        { name: "Phone", logo: "phone", alt: "Phone" },
        { name: "Share", logo: "share", alt: "Share" },
        { name: "TripAdvisor", logo: "tripadvisor", alt: "Tp" },
        { name: "RSS", logo: "rss", alt: "RSS" },
        { name: "AppStore", logo: "applestore", alt: "AppStore" },
        { name: "Google Play", logo: "playmarket", alt: "Google Play" },
        { name: "Windows Store", logo: "windowsstore", alt: "Windows Store" },
        { name: "Medium", logo: "medium", alt: "Medium" },
        { name: "Dropbox", logo: "dropbox", alt: "Dropbox" },
        { name: "GoogleDrive", logo: "googledrive", alt: "GoogleDrive" },
        { name: "GitHub", logo: "github", alt: "GitHub" },
        { name: "Yandex.Dzen", logo: "yandexdzen", alt: "Yandex.Dzen" },
        { name: "Yandex.Znatoki", logo: "yandexznatoki", alt: "Yz" },
        { name: "PDF", logo: "pdf", alt: "PDF" },
        { name: "DOC", logo: "doc", alt: "DOC" },
        { name: "XLS", logo: "xls", alt: "XLS" },
        { name: "PPT", logo: "ppt", alt: "PPT" },
        { name: "OneDrive", logo: "onedrive", alt: "OneDrive" },
        { name: "Patreon", logo: "patreon", alt: "Patreon" },
        { name: "Ko-fi", logo: "kofi", alt: "Ko-fi" },
        {
          name: "Buy me a coffee",
          logo: "buymeacoffee",
          alt: "Buy me a coffee",
        },
      ],
      chat: [
        { name: "Workplace", logo: "wokplace", alt: "Wp" },
        { name: "Hangouts", logo: "hangouts", alt: "Hangouts" },
        { name: "ICQ", logo: "icq", alt: "ICQ" },
        { name: "Mail.Ru Agent", logo: "mail", alt: "Mail.Ru Agent" },
        { name: "Messenger", logo: "messenger", alt: "Messenger" },
        { name: "Skype", logo: "skype", alt: "Skype" },
        { name: "Snapchat", logo: "snapchat", alt: "Snapchat" },
        { name: "Telegram", logo: "telegram", alt: "Telegram" },
        { name: "Viber", logo: "viber", alt: "Viber" },
        { name: "Wechat", logo: "wechat", alt: "Wechat" },
        { name: "Whatsapp", logo: "whatsapp", alt: "Whatsapp" },
        { name: "Slack", logo: "slack", alt: "Slack" },
        { name: "Zoom", logo: "zoom", alt: "Zm" },
        { name: "Microsoft Teams", logo: "teams", alt: "MT" },
        { name: "Discord", logo: "discort", alt: "Discord" },
        { name: "Line", logo: "line", alt: "Line" },
      ],
      socialMedia: [
        { name: "Ask.fm", logo: "askfm", alt: "Af" },
        { name: "Behance", logo: "behance", alt: "Be" },
        { name: "Dribbble", logo: "dribbble", alt: "Db" },
        { name: "Facebook", logo: "facebook", alt: "Fb" },
        { name: "Flickr", logo: "flickr", alt: "Fl" },
        { name: "Foursquare", logo: "foursquare", alt: "Ig" },
        { name: "Fleeped", logo: "fleeped", alt: "Fleeped" },
        { name: "Instagram", logo: "instagram", alt: "Be" },
        { name: "iTunesPodcasts", logo: "itunespodcasts", alt: "iTp" },
        { name: "Last.fm", logo: "lastfm", alt: "Lf" },
        { name: "Linkedin", logo: "link", alt: "In" },
        { name: "Livejournal", logo: "livejournal", alt: "Lj" },
        { name: "Myspace", logo: "myspace", alt: "Ms" },
        { name: "Odnoklassniki", logo: "odnoklassniki", alt: "Ok" },
        { name: "Pinterest", logo: "pinterest", alt: "P" },
        { name: "Soundcloud", logo: "soundcloud", alt: "Sc" },
        { name: "Spotify", logo: "spotify", alt: "Sp" },
        { name: "TikTok", logo: "tiktok", alt: "Tt" },
        { name: "Tumblr", logo: "tumblr", alt: "Tb" },
        { name: "Twitter", logo: "twitter", alt: "Tw" },
        { name: "Vimeo", logo: "vimeo", alt: "V" },
        { name: "Vkontakte", logo: "vk", alt: "VK" },
        { name: "Weibo", logo: "weibo", alt: "Weibo" },
        { name: "Yandex.Q", logo: "yandexq", alt: "Yq" },
        { name: "Youtube", logo: "youtube", alt: "Yt" },
        { name: "Xing", logo: "xing", alt: "Xing" },
        { name: "Meetup", logo: "meetup", alt: "meetup" },
        { name: "Twitch", logo: "twitch", alt: "Twitch" },
        { name: "Yammer", logo: "yammer", alt: "Yammer" },
      ],
      incBy: 1,
    };
  },
  methods: {
    getImageWidthDomain(image) {
      let domain = "";
      if (this.$ls.get("imageDomain")) {
        domain = this.$ls.get("imageDomain");
      }
      return domain + "/img/social-media/circle-color-logos/" + image;
    },
    addCustom() {
      var items = this.value.items;
      var name = "item " + (items.length + 1);
      var newIndex = {
        name: name,
        logo: "",
        alt: name,
        type: "custom",
        custom: true,
        customName: name,
      };
      items.push(newIndex);
      this.updateProps("items", items);
      this.$refs.socialpopover.$emit("close");
      this.$store.dispatch("ddb/setSelectedMenu", {
        type: "social",
        index: items.length - 1,
      });
    },
    AddSocialmedia(type, newIndex) {
      newIndex.type = type;
      var item = this.value.items ? this.value.items : [];
      item.push(newIndex);
      this.updateProps("items", item);
      this.$refs.socialpopover.$emit("close");
      this.$store.dispatch("ddb/setSelectedMenu", {
        type: "social",
        index: item.length - 1,
      });
    },
    updateProps(prop, val) {
      this.$store.dispatch("ddb/updateElementProperty", {
        property: prop,
        value: val,
      });
    },
  },
};
</script>
