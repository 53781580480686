<template>
  <div class="dd-fluid">
    <b-row align-v="center" class="dd-nav-height">
      <b-col cols="auto">
        <a
          class="btn dd-btn dd-btn-outline"
          href="#"
          @click.prevent="$emit('back')"
          ><span class="dd-btn-icon"></span> Exit</a
        >
      </b-col>
      <slot name="header"> </slot>
      <b-col cols="auto" v-if="showLayout">
        <a
          class="btn dd-btn dd-btn-outline dd-btn-icon-left"
          href="#"
          v-b-modal.modalConfirmChangeLayout
          @click.prevent
          ><span class="dd-btn-icon dd-mr-5"
            ><img src="../../assets/img/icon/change-layout.svg" width="19"
          /></span>
          Change layout</a
        >
      </b-col>
      <div class="dd-vh-center position-absolute">
        <b-row>
          <b-col cols="auto">
            <b-button-group class="dd-btn-group icon-group">
              <b-button
                variant="light"
                :disabled="!undoArray || !undoArray.length"
                @click="$emit('undo')"
                ><img
                  src="../../assets/img/icon/undo.svg"
                  width="24"
                  v-b-tooltip.hover
                  title="Undo"
              /></b-button>
              <b-button
                variant="light"
                :disabled="!redoArray || !redoArray.length"
                @click="$emit('redo')"
                ><img
                  src="../../assets/img/icon/redo.svg"
                  width="24"
                  v-b-tooltip.hover
                  title="Redo"
              /></b-button>
            </b-button-group>
          </b-col>
          <b-col cols="auto">
            <a
              class="btn dd-btn dd-btn-outline dd-btn-icon-left"
              href="#"
              @click.prevent="$emit('preview')"
              v-b-tooltip.hover
              title="Email preview"
              ><span class="dd-btn-icon dd-mr-8"
                ><img
                  src="../../assets/img/icon/eye-preview.svg"
                  width="18"
                  class="text-top" /></span
              >Preview</a
            >
          </b-col>
        </b-row>
      </div>

      <b-col cols="auto" class="ml-auto text-right dd-header-right">
        <b-dropdown
          v-if="finishButtonsAll.length > 1"
          variant="link"
          right
          toggle-class="dd-btn dd-btn-outline dd-btn-icon-only dd-mr-15"
          class="dd-dropdown"
          no-caret
        >
          <template #button-content>
            <img src="../../assets/img/icon/vertical-bullets.svg" />
          </template>
          <b-dropdown-item
            v-for="(btn, ind) in finishButtonsLast"
            :key="'btn-' + ind"
            @click="$emit('export', btn.action)"
            >{{ btn.text }}</b-dropdown-item
          >
        </b-dropdown>
        <a
          v-if="finishButtonsAll.length === 1"
          @click.prevent="$emit('export', 'export')"
          href="#"
          class="dd-btn dd-btn-outline dd-btn-icon-only dd-mr-15"
          v-b-tooltip.hover
          title="Download"
        >
          <img src="../../assets/img/icon/download.svg" />
        </a>
        <a
          class="btn dd-btn dd-btn-blue"
          href="#"
          :class="{
            'dd-btn-icon-right dd-ico-white': finishButtonsAll[0].text
              .toLowerCase()
              .includes('continue'),
          }"
          @click.prevent="$emit('export', finishButtonsAll[0].action)"
          >{{ finishButtonsAll[0].text
          }}<span
            class="dd-btn-icon"
            v-if="finishButtonsAll[0].text.toLowerCase().includes('continue')"
            ><img src="../../assets/img/icon/arrow-right.svg" /></span
        ></a>
      </b-col>
    </b-row>
    <modal-confirm
      id="modalConfirmChangeLayout"
      confirmText="Yes, change it"
      content="When you choose a new layout or theme, your current design will lost. it will work as a new creative"
      @confirm="$emit('showLayout', true)"
    />
  </div>
</template>
<script>
export default {
  name: "LayoutHeader",
  props: {
    finishButtons: {
      type: Array,
      default: null,
    },
    undoArray: {
      type: Array,
      default: null,
    },
    redoArray: {
      type: Array,
      default: null,
    },
    showLayout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    finishButtonsAll() {
      if (this.finishButtons) {
        return this.finishButtons;
      }
      return [
        { text: "Save and close", action: "save" },
        { text: "Save in template", action: "saveAs" },
        { text: "Download", action: "export" },
      ];
    },
    finishButtonsLast() {
      let finishButtons = JSON.parse(JSON.stringify(this.finishButtonsAll));
      return finishButtons.splice(1);
    },
  },
};
</script>
