<template>
  <div>
    <p class="dd-label-light">Text Transform</p>
    <multiselect
      :value="value"
      :custom-label="customLabel"
      :options="options"
      class="dd-multiselect dd-font-size dd-select-radius-8"
      @input="$emit('input', $event)"
    ></multiselect>
  </div>
</template>
<script>
export default {
  name: "TextTransform",
  props: {
    value: { type: String, default: "none" },
  },
  data() {
    return {
      options: ["none", "capitalize", "lowercase", "uppercase"],
    };
  },
  methods: {
    customLabel(label) {
      return label === "none" ? "No transform" : this.capitalize(label);
    },
  },
};
</script>
